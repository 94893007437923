import React, {PropsWithChildren, ReactElement, ReactNode} from "react";
import {Link, LinkProps} from "react-router-dom";
import classNames from "classnames";

export type FrameOneButtonType =
	LinkProps
	| React.AnchorHTMLAttributes<HTMLAnchorElement>
	| React.ButtonHTMLAttributes<HTMLButtonElement>;

export type FrameOneButtonColor =
	"lightGray"
	| "gray"
	| "purple"
	| "light-purple"
	| "purple-outline"
	| "darkBlue"
	| "lightBlue"
	| "warning"
	| "danger"
	| "success"
	| "white-drop-shadow";

export type FrameOneButtonSize =
	| "normal"
	| "large"
	| "narrow"
	| "icon" // Intended to be used with a single icon & no children.
	| "icon-square"; // Intended to be used with a single icon & no children.

interface Props<T extends FrameOneButtonType> {
	// Manually set allowed colors for specific styling; update _frame-one-button.scss file, and add to the classNames object on the Button in this component if adding a new color.
	color: FrameOneButtonColor,
	size?: FrameOneButtonSize;
	leftIcon?: React.FC; //todo
	rightIcon?: React.FC; //todo
	narrow?: boolean; // Makes the button shorter
	className?: string;
	disabled?: boolean;

	to?: string; // When present, this component renders a <Link/> tag from react-router-dom and acts like a link. Otherwise, behaves like a regular button. Overrides presence of href prop.
	href?: string; // When present, this component renders a <a/> tag. Otherwise, behaves like a regular button.
	onClick?: () => void | Promise<void>;
	forwardProps?: T; // props forwarded to Link / <a> / <button>
}

function FrameButton<T extends FrameOneButtonType>(props: PropsWithChildren<Props<T>>): ReactElement {

	const LeftIcon: any = props.leftIcon;
	const RightIcon: any = props.rightIcon;

	function onClickHelper(e: React.MouseEvent): void {
		if (props.disabled) {
			e.preventDefault();
			return;
		}

		if (props.onClick) {
			props.onClick();
		}
	}

	const fullClassNames: string = classNames(
		"frame-one-button",
		`frame-one-button_color_${props.color}`,
		`frame-one-button_size_${props.size}`,
		props.className,
		{"frame-one-button_narrow": props.narrow},
		{"frame-one-button_disabled": props.disabled}
	);

	// Generate the content here so we can selectively use it in the return logic below
	const content: ReactNode = (
		<React.Fragment>
			{props.leftIcon && (<LeftIcon className="frame-one-button_left-icon"/>)}

			{props.children && (
				<span>{props.children}</span>
			)}

			{props.rightIcon && (<RightIcon className="frame-one-button_right-icon"/>)}
		</React.Fragment>
	);

	if (props.to) {
		return (
			<Link
				{...props.forwardProps as LinkProps}
				to={props.to}
				onClick={onClickHelper}
				className={fullClassNames}
			>
				{content}
			</Link>
		);
	} else if (props.href) {
		return (
			<a
				{...props.forwardProps as React.AnchorHTMLAttributes<HTMLAnchorElement>}
				href={props.href}
				onClick={onClickHelper}
				target="_blank"
				rel="noopener noreferrer"
				className={fullClassNames}
			>
				{content}
			</a>
		);
	} else {
		return (
			<button
				{...props.forwardProps as React.ButtonHTMLAttributes<HTMLButtonElement>}
				onClick={onClickHelper}
				className={fullClassNames}
			>
				{content}
			</button>
		);
	}
}

export default FrameButton;
