import React, {ReactElement, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {User} from "@devour/client";
import ManageUsersToggleEnableModal from "../../modals/ManageUsersToggleEnableModal";
import UserExperienceModal from "../../modals/UserExperienceModal";
import ManageUserDebitCreditDpayModal from "../../modals/ManageUserDebitCreditDpayModal";
import ManageUserDebitCreditExperienceModal from "../../modals/ManageUserDebitCreditExperienceModal";

interface Props {
	user: User;
	onDone: () => Promise<void>;
}

function DebitOrCreditExperienceCell(props: Props): ReactElement {

	const [showModal, setShowModal] = useState(false);

	/**
	 * Hide or Show the toggle modal.
	 *
	 */
	function toggleShowToggleModal(): void {
		setShowModal(!showModal);
	}

	/**
	 * Hide the modal when done. Run any additional action if needed after.
	 *
	 */
	function onDoneDpayTransaction(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageUserDebitCreditExperienceModal
				isOpen={showModal}
				user={props.user}
				onClose={toggleShowToggleModal}
				onDone={onDoneDpayTransaction}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="lightGray"
					onClick={toggleShowToggleModal}
				>
					Debit or Credit Experience
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default DebitOrCreditExperienceCell;
