import React, {PropsWithChildren, useEffect, useState} from "react";
import {ReactElement} from "react";
import {connect, ConnectedProps} from "react-redux";
import classNames from "classnames";
import {IStore} from "../../redux/defaultStore";
import {toggleSidebar} from "../../redux/meta/MetaActions";
import variables from "../../style/variables/variables";
import SideBarInner from "./SideBarInner";

// Grab the width at which the sidebar should be become "docked". Use -1px (or any negative value in the scss) to prevent it from docking.
const sidebarDockedAt: number = parseInt(variables["f1-sidebar-docked-at"]);

function getDocked(windowWidth: number, sidebarDockedAt: number): boolean {
	return windowWidth >= sidebarDockedAt && sidebarDockedAt >= 0;
}

interface StateProps {
	sidebarOpenButNotDocked: boolean;
}

interface Props {
	dockingEnabled: boolean; // set to `false` for pages where the sidebar should not be visible at desktop, ie. Login, etc.
}

function FrameOneSidebar(props: FrameOneSidebarProps): ReactElement {

	const [localOpen, setLocalOpen] = useState(props.sidebarOpenButNotDocked); // Use to help manage the fade-out animation for the overlay so it doesn't disappear instantly when sidebar is closed.
	const [docked, setDocked] = useState(getDocked(window.innerWidth, sidebarDockedAt));

	/**
	 * Set up toggling className on body element for easy handling of styles.
	 * Also add/remove listener for screen resize to auto-close sidebar at the screen width where hamburger menu is hidden.
	 *
	 */
	useEffect(() => {
		if (props.sidebarOpenButNotDocked) {
			setLocalOpen(true);
			document.body.classList.add("mobile-sidebar-open");
		} else {
			document.body.classList.remove("mobile-sidebar-open");
		}

		function resizeAutoCloseHandler(): void {
			if (getDocked(window.innerWidth, sidebarDockedAt) && props.sidebarOpenButNotDocked) {
				props.dispatch(toggleSidebar(false));
				setLocalOpen(false);
			}
		}

		window.addEventListener("resize", resizeAutoCloseHandler);

		return () => {
			window.removeEventListener("resize", resizeAutoCloseHandler);
		}
	}, [props.sidebarOpenButNotDocked]);

	/**
	 * Responsible for handling auto switching to desktop/docked mode when screen reaches required width.
	 *
	 */
	useEffect(() => {
		function handleResize(): void {
			const _docked: boolean = getDocked(window.innerWidth, sidebarDockedAt);
			setDocked(_docked);

			if (_docked && props.sidebarOpenButNotDocked) {
				setLocalOpen(false);
				props.dispatch(toggleSidebar(false));
			}
		}

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}, []);

	/**
	 * Handle closing the sidebar; update the redux store first and then the locally tracked state on a delay,
	 * allowing us better control for the "fade out" animation while the overlay disappears.
	 *
	 */
	function handleClose(): void {
		if (!localOpen || !props.sidebarOpenButNotDocked) {
			return;
		}

		props.dispatch(toggleSidebar(false));

		function closeHelper(): void {
			setLocalOpen(false);
		}

		setTimeout(() => {
			closeHelper();
		}, 500);
	}

	return (
		<div
			className={classNames("f1-sidebar", {
				"f1-sidebar_docked": docked || props.sidebarOpenButNotDocked,
				"f1-sidebar_docking-disabled": !props.dockingEnabled && !props.sidebarOpenButNotDocked,
			})}
		>
			<div
				className={classNames("f1-sidebar_overlay", {
					"f1-sidebar_overlay_open": localOpen,
					"f1-sidebar_overlay_closing": !props.sidebarOpenButNotDocked && localOpen,
				})}
				onClick={handleClose}
			/>

			<div className="f1-sidebar_bar">
				<SideBarInner/>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			sidebarOpenButNotDocked: store.metaStore.sidebarOpen,
			...props,
		}
	});
}

type FrameOneSidebarProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(FrameOneSidebar);
