import React, {Dispatch, ReactNode, SetStateAction} from "react";
import {cloneDeep} from "lodash";
import {addURLsToFiles} from "../../utils/renderAssetsHelper";
import { IoImageOutline } from "react-icons/io5";
import FileInputButton from "../inputs/FileInputButton";
import FrameButton from "../buttons/FrameButton";

import {isAsset} from "../../utils/typeGuards";
import {
    CreateBrandMapBodyFrontend,
    EditAdvertisementBlockFormData,
    EditBrandMapBodyFrontend
} from "../../pages/brandMaps/BrandMapFormValues";
import {FiX} from "react-icons/fi";


interface Props {
    advertisementBlocks: Array<EditAdvertisementBlockFormData>;
    onChange: Dispatch<SetStateAction<CreateBrandMapBodyFrontend | EditBrandMapBodyFrontend>>
}
function BrandAdvertisementImages(props: Props) {
    function renderAdvertisementBlock(block: EditAdvertisementBlockFormData, i: number): ReactNode {
        function onAdvertisementBlockUrlChange(e: React.ChangeEvent<HTMLInputElement>): void {
            const newAdvertisementBlocks: Array<EditAdvertisementBlockFormData> = cloneDeep(props.advertisementBlocks);
            newAdvertisementBlocks[i].url = e.target.value;
            props.onChange(prevState => ({
                ...prevState,
                advertisementBlocks: newAdvertisementBlocks,
            }));
        }

        async function onAdvertisementBlockImageChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
            const newAsset = (await addURLsToFiles(e.target.files))[0];
            const newAdvertisementBlocks: Array<EditAdvertisementBlockFormData> = cloneDeep(props.advertisementBlocks);
            newAdvertisementBlocks[i].image = newAsset;
            props.onChange(prevState => ({
                ...prevState,
                advertisementBlocks: newAdvertisementBlocks,
            }));
        }

        function onRemoveAdvertisementImage(): void {
            const newAdvertisementBlocks: Array<EditAdvertisementBlockFormData> = cloneDeep(props.advertisementBlocks);
            newAdvertisementBlocks[i].image = undefined;
            newAdvertisementBlocks[i].url = "";

            props.onChange(prevState => ({
                ...prevState,
                advertisementBlocks: newAdvertisementBlocks,
            }))
        }

        const backgroundImage = block.image ? isAsset(block.image) ? block.image.url : block.image.imageSRC : undefined;
        return (
            <div key={`advertisement-${i}`} className="promos_preview_list_item">
                <div className="promos_preview_list_item_img-container">
                    <div
                        className="upload-image-preview has-image is-main"
                        style={{
                            backgroundImage: (backgroundImage) ? `url(${backgroundImage})` : `url(${process.env.REACT_APP_CDN_URL}/images/brand-map-advertisement-img-upload-bg.svg)`
                        }}
                    >
                        {(!block.image) ? (
                            <>
                                <IoImageOutline className="upload-image-preview_icon"/>
                                <div className="upload-image-preview_optional">
                                    Optional
                                </div>
                            </>
                        ) : (
                            <FiX
                                className="create-map-page_img-remove-icon"
                                onClick={onRemoveAdvertisementImage}
                            />
                        )}
                        <FileInputButton
                            accept=".png, .jpg, .jpeg"
                            multiple={false}
                            onChange={onAdvertisementBlockImageChange}
                        >
                            <FrameButton
                                color="white-drop-shadow"
                                size="narrow"
                                className="upload-image-preview_button"
                            >
                                + Upload Image
                            </FrameButton>
                        </FileInputButton>
                    </div>
                </div>

                <input
                    type="url"
                    placeholder="Input redirect link..."
                    value={block.url}
                    onChange={onAdvertisementBlockUrlChange}
                    disabled={!block.image}
                    className="promos_preview_list_item_input"
                />
            </div>
        );
    }

    return (
        <div className="create-map-page_content_data_others">
            <h4>Other Settings</h4>
            <div className="create-map-page_content_data_others_promos">
                <div className="create-map-page_content_data_others_promos_top">
                    <h5>
                        Add Image Advertisements and Link
                    </h5>
                    <p>
                        These images will appear at the bottom of your brand page. If a link is set, then it will redirect on click. For the best results on all devices,
                        use an image that’s at least 350 x 350 in resolution. It is optional to upload more than 1 image.
                    </p>
                </div>

                <div className="create-map-page_content_data_others_promos_preview">
                    <p className="small-text">Preview</p>
                    <div className="create-map-page_content_data_others_promos_preview_list">
                        {props.advertisementBlocks.map(renderAdvertisementBlock)}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default BrandAdvertisementImages;
