import React, {PropsWithChildren, ReactElement, ReactNode} from "react";
import classNames from "classnames";

interface Props {
	title: string;
	contentClassName?: string;
	titleClassName?: string;
	titleContainerClassName?: string;
}

function SectionLabel(props: PropsWithChildren<Props>): ReactElement {

	return (
		<div className="section-label">
			<div className={classNames("section-label_title-container", props.titleContainerClassName)}>
				<p className={classNames("section-label_title", props.titleClassName)}>
					{props.title}
				</p>
			</div>

			<div className={classNames("section-label_content-container", props.contentClassName)}>
				{props.children}
			</div>
		</div>
	);
}

export default SectionLabel;
