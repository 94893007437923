import {ReactElement, ReactNode, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {PosApi, GetPosResponse, Pos} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import React from "react";
import FrameButton from "../components/buttons/FrameButton";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment";
import AddPosModal from "../components/modals/AddPosModal";
import ManagePosDeleteCell from "../components/tables/cells/ManagePosDeleteCell";
import ManagePosEditCell from "../components/tables/cells/ManagePosEditCell";


function ManagePos(): ReactElement {

	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [posRes, setPosRes] = useState<GetPosResponse>(undefined);
	const [showAddModal, setShowAddModal] = useState(false);

	useEffect(() => {
		getPos().then().catch();
	}, []);

	async function getPos(): Promise<void> {
		dispatch(incrementLoading());

		try {
			const res = await new PosApi(getConfig(fullToken)).getPos();
			setPosRes(res);
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	function toggleAddPosModal(): void {
		setShowAddModal(s => !s);
	}

	function onDoneAddModal(): void {
		setShowAddModal(false);
		getPos().then().catch();
	}

	function renderDeleteCell(pos: Pos): ReactNode {
		return (
			<ManagePosDeleteCell
				pos={pos}
				onDone={getPos}
			/>
		);
	}

	function renderEditCell(pos: Pos): ReactNode {
		return (
			<ManagePosEditCell
				pos={pos}
				onDone={getPos}
			/>
		);
	}

	return (
		<React.Fragment>
			<AddPosModal
				isOpen={showAddModal}
				onClose={toggleAddPosModal}
				onDone={onDoneAddModal}
			/>

			<div className="manage-pos">
				<PageHeader>
					<div>
						<h3>
							Point of Sales
						</h3>

						<p>
							Here you can view, add, and edit point of sales on the platform.
						</p>

						<FrameButton
							color="lightBlue"
							onClick={toggleAddPosModal}
						>
							Add Point of Sale
						</FrameButton>
					</div>
				</PageHeader>

				<div className="manage-pos_table">
					<FrameOneTableContainer
						data={posRes?.pos}
						columnOptions={[
							{
								key: "name",
								headerValue: "Name",
							},
							{
								key: "description",
								headerValue: "Description",
							},
							{
								key: "itsaCheckmateLabel",
								headerValue: "ItsaCheckmate Label",
							},
							{
								key: "createdAt",
								headerValue: "Created",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: "updatedAt",
								headerValue: "Last Updated",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: undefined,
								headerValue: "Edit Point of Sale",
								cellRender: renderEditCell,
							},
							{
								key: undefined,
								headerValue: "Delete Point of Sale",
								cellRender: renderDeleteCell,
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

export default ManagePos;
