import React, {ChangeEventHandler, ReactElement, FormEvent, useEffect, useState} from "react";
import {
    BusinessesApi,
    Business,
    BusinessAddUserBody,
    BusinessPermission,
    Token,
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameButton from "../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import {cloneDeep} from "lodash";
import {permissionDescription} from "./EditBusinessUsersModal";
import NewMerchantUserModal from "./NewMerchantUserModal";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

const defaultValues: BusinessAddUserBody = {
    email: "",
    permissions: [],
};

interface StateProps {
    fullToken: Token;
}

interface Props {
    business?: Business;
    onClose: () => void;
    onUpdate: () => void;
}

function BusinessAddUserModal(props: BusinessAddUserModalProps): ReactElement {

    const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<BusinessAddUserBody>(defaultValues);
    const [permissions, setPermissions] = useState<Array<BusinessPermission>>([]);

    useEffect(() => {
        fetchBusinessPermissions().then();
    }, [props.business]);

    /**
     * Get the details for this business from our api.
     */
    async function fetchBusinessPermissions(): Promise<void> {
        if (!props?.business?.id) {
            return;
        }
        props.dispatch(incrementLoading());

        try {
            const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessPermissions({
                id: props.business.id,
                limit: Number.MAX_SAFE_INTEGER,
            });
            setPermissions(res.authUser);
        } catch (e) {
            console.log("fetchBusinessPermissions err")
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }

    }

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof BusinessAddUserBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
        return (e) => {
            setFormValues({
                ...formValues,
                [key]: e.target.value,
            });
        }
    }

    async function onFormSubmit(e?: FormEvent<HTMLFormElement>): Promise<void> {
        e?.preventDefault();
        props.dispatch(incrementLoading());

        try {
            await new BusinessesApi(getConfig(props.fullToken)).addBusinessUser({
                id: props.business.id,
                businessAddUserBody: formValues,
            });
            setFormValues(defaultValues);
            props.onUpdate();
        } catch (e) {
            if (e.status === 404) {
                setShowNewUserModal(true);
            } else {
                props.dispatch(await addError(e));
            }
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    /**
     * Automatically resubmit permission form on completion of new merchant account creation.
     */
    function onNewMerchantCreation() {
        setShowNewUserModal(false);
        void onFormSubmit();
    }

    /**
     * Handle all checkbox input onChange events.
     *
     * @param permission
     */
    function checkboxOnToggle(permission: BusinessPermission): void {
        // Attempt to find this option in the checked items array
        const newPermissions: Array<BusinessPermission> = cloneDeep(formValues.permissions);


        const thisIndex = newPermissions.indexOf(permission);

        if (thisIndex > -1) {
            // Remove this option from list of selected items
            newPermissions.splice(thisIndex, 1);
        } else {
            newPermissions.push(permission);
        }
        setFormValues({
            ...formValues,
            permissions: newPermissions,
        });

    }

    return (
        <React.Fragment>
            <NewMerchantUserModal
                display={showNewUserModal}
                email={formValues.email}
                onUpdate={onNewMerchantCreation}
                onClose={() => setShowNewUserModal(false)}
            />

            <FrameOneModal
                isOpen={!!props?.business}
                toggle={props.onClose}
                size="sm"
                contentClassName="add-merchant-user-modal"
            >
                <FrameModalHeader
                    title="Add Business User"
                    toggle={props.onClose}
                />

                <form onSubmit={onFormSubmit}>
                    <FrameModalBody className="add-merchant-user-modal_body">
                        <div className="add-merchant-user-modal_field add-merchant-user-modal_email-container">
                            <label>User Email *</label>
                            <input
                                type="email"
                                placeholder="User's Email"
                                value={formValues.email}
                                onChange={inputOnChange("email")}
                                required={true}
                            />
                        </div>

                        <div className="add-merchant-user-modal_field add-merchant-user-modal_permissions-container">
                            <label>User Permissions</label>
                            {permissionDescription.map((item) => (
                                <FrameOneCheckbox
                                    key={item.permission}
                                    onToggle={() => checkboxOnToggle(item.permission)}
                                    checked={formValues.permissions.includes(item.permission)}
                                    disabled={!permissions.includes(item.permission)}
                                >
                                    <div>
                                        <strong>{item.name}</strong>
                                    </div>
                                    <div>
                                        {item.description}
                                    </div>
                                </FrameOneCheckbox>
                            ))}
                        </div>

                        <div className="add-merchant-user-modal_warning">
                            <p>
                                Warning: Adding this user will also extend their permissions to all businesses
                                under {props.business?.name}.
                            </p>
                        </div>
                    </FrameModalBody>
                    <FrameModalFooter>
                        <FrameButton
                            <React.ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            className="add-merchant-user-modal_submit-button"
                            forwardProps={{type: "submit"}}
                        >
                            Add New Manager
                        </FrameButton>
                    </FrameModalFooter>
                </form>
            </FrameOneModal>
        </React.Fragment>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.metaStore.fullToken,
            ...props,
        }
    });
}

type BusinessAddUserModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(BusinessAddUserModal);
