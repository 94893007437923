import React, {ReactElement} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Token, User, UsersApi, UserType} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {getUserFullName} from "../../utils/getUserFullName";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	user: User;
	onClose: () => void;
	onDone: () => void;
}

function ManageUsersUpdateToMerchantModal(props: ManageUsersUpdateToMerchantModalProps): ReactElement {

	async function confirmAccountTypeUpdate(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).updateUserType({
				id: props.user.id,
				updateUserTypeBody: {
					type: (props.user.type === UserType.STANDARDUSER) ? UserType.MERCHANT : UserType.STANDARDUSER,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				toggle={props.onClose}
				title={(props.user.type === UserType.STANDARDUSER) ? "Upgrade User to Merchant Account" : "Downgrade User to Customer Account"}
			/>

			<FrameModalBody>
				{(props.user.type === UserType.STANDARDUSER) ? (
					<p>
						Are you sure you want to upgrade <b>{getUserFullName(props.user)} ({props.user.email})</b> to a
						merchant account type?
					</p>
				) : (
					<p>
						Are you sure you want to downgrade <b>{getUserFullName(props.user)} ({props.user.email})</b> to
						a standard customer account type?
					</p>
				)}

			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="lightGray"
					onClick={props.onClose}
				>
					Cancel
				</FrameButton>

				<FrameButton
					color="lightBlue"
					onClick={confirmAccountTypeUpdate}
				>
					Confirm, update
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageUsersUpdateToMerchantModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageUsersUpdateToMerchantModal);
