import React, {ReactElement, useEffect, useState} from "react";
import {DialCode, PhoneNumberBody, UtilsApi} from "@devour/client";
import classNames from "classnames";
import {PatternFormat} from "react-number-format";
import {NumberFormatValues} from "react-number-format/types/types";
import {FiChevronDown} from "react-icons/fi";
import FrameOneSelect from "./FrameOneSelect";
import SelectOptionsFactory from "./SelectOptionsFactory";

interface Props {
	placeholder?: string;
	value?: PhoneNumberBody
	onChange: (newPhoneNumber: PhoneNumberBody) => void;
}

function FrameOnePhoneNumberInput(props: Props): ReactElement {

	const [dialCodes, setDialCodes] = useState<Array<DialCode>>([]);
	const [flagLoaded, setFlagLoaded] = useState(false);

	useEffect(() => {
		fetchDialCodes().then().catch();
	}, []);

	/**
	 * Get the list of dial codes from our api.
	 *
	 */
	async function fetchDialCodes(): Promise<void> {
		try {
			const res = await new UtilsApi().getListOfDialCodes();
			setDialCodes(res);
		} catch (e) {
		}
	}

	/**
	 * Handle changing either of the values and pass the combined entry to the callback.
	 *
	 * @param e
	 */
	function selectOnChange(e: React.ChangeEvent<HTMLSelectElement>): void {
		props.onChange({
			...props.value,
			countryCode: e.target.value,
		});
	}

	/**
	 * Handle changing either of the values and pass the combined entry to the callback.
	 *
	 * @param values
	 */
	function inputOnChange(values: NumberFormatValues): void {
		props.onChange({
			...props.value,
			nationalNumber: values.value,
		});
	}

	/**
	 * Sets a "flag" when the initial flag image is loaded to avoid the html no-image icon initially during render.
	 *
	 */
	function onFlagLoad(): void {
		setFlagLoaded(true);
	}

	const selectedDialCode = dialCodes.find(d => d.code === props.value.countryCode);

	return (
		<div className="frame-one-phone-input">
			<PatternFormat
				value={props.value.nationalNumber}
				valueIsNumericString={true}
				type="tel"
				format="### ### ####"
				placeholder={props.placeholder}
				className="frame-one-phone-input_text"
				onValueChange={inputOnChange}
			/>

			<FrameOneSelect
				value={props.value.countryCode}
				onChange={selectOnChange}
			>
				<option disabled={true}>Select Country</option>
				<SelectOptionsFactory
					<DialCode>
					options={dialCodes.map((d) => {
						return {
							value: d.code,
							label: `${d.name} ${d.dialCode}`
						}
					})}
				/>
			</FrameOneSelect>

			<div
				className={classNames("frame-one-phone-input_country-overlay", {
					"frame-one-phone-input_country-overlay_loaded": flagLoaded,
				})}
			>
				<img
					src={`https://flagcdn.com/w40/${selectedDialCode?.code?.toLowerCase()}.png`}
					alt={`${selectedDialCode?.code} flag`}
					className="frame-one-phone-input_country-overlay_flag"
					onLoad={onFlagLoad}
				/>

				<FiChevronDown className="frame-one-phone-input_country-overlay_chevron"/>

				<span className="frame-one-phone-input_country-overlay_dial-code">
					{selectedDialCode?.dialCode}
				</span>
			</div>
		</div>
	);
}

FrameOnePhoneNumberInput.defaultProps = {
	placeholder: "Enter Phone Number...",
	value: {
		countryCode: "US",
		nationalNumber: "",
	},
};

export default FrameOnePhoneNumberInput;
