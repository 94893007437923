import React, {ReactElement, useEffect, useState} from "react";
import classNames from "classnames";
import {UtilsApi, VersionResponse} from "@devour/client";

interface Props {
	className?: string;
}

function SideBarVersionDisplay(props: Props): ReactElement {

	const [version, setVersion] = useState<VersionResponse>()

	useEffect(() => {
		new UtilsApi().version()
			.then(setVersion)
			.catch(console.error);
	}, [])

	return (
		<div className={classNames("side-bar-version-display", props.className)}>
			<h6 className="side-bar-version-display_label">
				Version
			</h6>

			<p className="side-bar-version-display_name">
				{process.env.REACT_APP_PROJECT_NAME}
			</p>

			<p className="side-bar-version-display_version">
				{version?.version}
			</p>
		</div>
	);
}

export default SideBarVersionDisplay;
