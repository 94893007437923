import React, {ReactElement, useEffect, useState} from "react";
import {
	Business,
	Token,
	BusinessesApi,
	BusinessUserFull,
	User
} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameButton from "../buttons/FrameButton";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import EditBusinessUsersModal, {permissionDescription} from "../modals/EditBusinessUsersModal";
import {convertBooleanToString} from "../../utils/convertBooleanToString";

interface StateProps {
	fullToken: Token;
	currentUser: User;
}

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function MerchantUsers(props: MerchantUsersProps): ReactElement {

	const [showUpdateUsersModal, setShowUpdateUsersModal] = useState(false);
	const [businessUsers, setBusinessUsers] = useState<Array<BusinessUserFull>>([]);

	useEffect(() => {
		void fetchBusinessPermissions();
	}, []);

	/**
	 * Get the details for this business from our api.
	 */
	async function fetchBusinessPermissions(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessPermissions({
				id: props.business?.id,
				limit: Number.MAX_SAFE_INTEGER,
			});
			setBusinessUsers(res.currentUsers);

		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}

	}

	function onDone() {
		setShowUpdateUsersModal(false);
		void props.onDone();
	}

	return (
		<React.Fragment>

			<EditBusinessUsersModal
				isOpen={showUpdateUsersModal}
				onClose={onDone}
				business={props.business}
			/>

			<div className="merchant-users">
				<h4>
					Merchant user access
				</h4>

				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="lightBlue"
					className="merchant-users_open-modal-button"
					onClick={() => setShowUpdateUsersModal(true)}
					forwardProps={{
						type: "button"
					}}
				>
					Update Users
				</FrameButton>

				<FrameOneTableContainer
					data={businessUsers.filter(u => u.user !== props.currentUser?.id)}
					columnOptions={[].concat([
							{
								key: "firstName",
								headerValue: "First Name",
							},
							{
								key: "lastName",
								headerValue: "Last Name",
							},
							{
								key: "email",
								headerValue: "Email",
							}],
						permissionDescription.map((p) => (
							{
								key: undefined,
								headerValue: p.name,
								cellRender: (businessUser) =>
									convertBooleanToString(businessUser.permissions.includes(p.permission))
							}
						)),
					)}
				/>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			currentUser: store.metaStore.currentUser,
			fullToken: store.metaStore.fullToken,
			...props
		}
	});
}

type MerchantUsersProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(MerchantUsers);
