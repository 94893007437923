import React, {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Token, VelocityApi, VelocityReport} from "@devour/client";
import {addError} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment";

interface StateProps {
	fullToken: Token;
}

function VelocityReportingPage(props: VelocityReportingPageProps): ReactElement {

	const [localLoading, setLocalLoading] = useState(false);
	const [velocityReport, setVelocityReport] = useState<VelocityReport | null>(null);
	const [viewingWeek, setViewingWeek] = useState<string | null>(null);

	useEffect(() => {
		void getVelocityData();
	}, []);

	async function getVelocityData(): Promise<void> {
		setLocalLoading(true);

		try {
			const res = await new VelocityApi(getConfig(props.fullToken)).getVelocityReport();
			setVelocityReport(res);
			if (Object.keys(res.weeklyReports).length > 0) {
				setViewingWeek(Object.keys(res.weeklyReports)[0]);
			}
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			setLocalLoading(false);
		}
	}

	function viewingWeekOnChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
		setViewingWeek(e.target.value);
	}

	return (
		<div className="velocity-reporting-page">
			<PageHeader className="velocity-reporting-page_header">
				<div>
					<h3>
						Velocity Reports
					</h3>

					<p>
						Here you can find...
					</p>
					<ul>
						<li>
							The total number of points completed on Devour (since we began estimating in March 2023)
						</li>
						<li>
							Developer overview with stats for each team member, including:
							<ul>
								<li>
									Lifetime points per employee
								</li>
								<li>
									Weekly average velocity, based on the previous 3 full weeks (ie not counting the current
									week, with a week being defined as Sunday 12:00am {"->"} Saturday 11:59pm)
								</li>
							</ul>
						</li>
						<li>
							Weekly breakdown showing a developer's velocity for that week as well as the issues that got
							completed that week, including links to the issues
						</li>
					</ul>

					<p>
						Things worth noting:
					</p>
					<ul>
						<li>
							All velocity & point distribution is based on when the issue is marked as closed, so even if
							an issue gets completed by a developer on Friday, if it is not marked as CLOSED before
							Sunday, the points will be awarded for the next week. If we have an intention of getting the
							most accurate weekly average velocity over time, try to check for issues in the "Ready for
							Approval" state, so they can get closed (or rejected) before Sunday.
						</li>
						<li>
							If multiple employees are included as assignees on an issue, the points get divided equally
							amongst them.
						</li>
						<li>
							If you guys want additional stats or changes (such as changing how weekly averages are calculated), obviously you can just let us know
						</li>
					</ul>
				</div>
			</PageHeader>

			<div className="velocity-reporting-page_content">
				{localLoading ? (
					<div className="velocity-reporting-page_content_loading">
						<b>
							Loading velocity report. This may take 30 seconds or so...
						</b>
					</div>
				) : (
					<React.Fragment>
						{velocityReport != null && (
							<div className="velocity-reporting-page_content_data">
								<div className="velocity-reporting-page_content_data_high-level">
									<div>
										<h4>
											Team Lifetime Points
										</h4>
										<p>
											{velocityReport.lifetimePoints}
										</p>
									</div>
								</div>

								<div className="velocity-reporting-page_content_data_employees">
									<div className="velocity-reporting-page_content_data_employees_content">
										<h4>
											Developers Overview
										</h4>
									</div>

									<div className="velocity-reporting-page_content_data_employees_table">
										<FrameOneTableContainer
											data={Object.keys(velocityReport.employeeStats).map((employee) => {
												return {
													employee,
													lifetimePoints: velocityReport.employeeStats[employee].lifetimePoints,
													averageVelocity: velocityReport.employeeStats[employee].averageVelocity,
												}
											})}
											columnOptions={[
												{
													key: "employee",
													headerValue: "Employee",
												},
												{
													key: "lifetimePoints",
													headerValue: "Lifetime Points",
													cellRender: v => v.toFixed(2),
												},
												{
													key: "averageVelocity",
													headerValue: "Weekly Average Velocity (Last 3 Full Weeks)",
													cellRender: v => v.toFixed(2),
												},
											]}
										/>
									</div>
								</div>

								<div className="velocity-reporting-page_content_data_breakdowns">
									<div className="velocity-reporting-page_content_data_breakdowns_content">
										<h4>
											Weekly Breakdowns
										</h4>

										<div>
											<label>
												Select Week to see developer stats
											</label>

											<select
												value={viewingWeek}
												onChange={viewingWeekOnChange}
											>
												<option value="">Select Week</option>
												{Object.keys(velocityReport.weeklyReports).map((week) => (
													<option key={week} value={week}>
														{moment(parseInt(week)).format("MMM DD YYYY")}
													</option>
												))}
											</select>
										</div>
									</div>

									{viewingWeek != null && viewingWeek.length > 0 && (
										<div className="velocity-reporting-page_content_data_breakdowns_table">
											<FrameOneTableContainer
												data={Object.keys(velocityReport.weeklyReports[viewingWeek]).map((employee) => {
													return {
														employee,
														velocity: velocityReport.weeklyReports[viewingWeek][employee].velocity,
														issues: velocityReport.weeklyReports[viewingWeek][employee].issues,
													}
												})}
												columnOptions={[
													{
														key: "employee",
														headerValue: "Employee",
													},
													{
														key: "velocity",
														headerValue: "Velocity",
														cellRender: v => v.toFixed(2),
													},
													{
														key: "issues",
														headerValue: "Issues Completed",
														cellRender: (issues) => {
															return issues.map((issue, i) => {
																return (
																	<div key={`issue_${i}_${issue}`}>
																		<a
																			href={issue}
																			target="_blank"
																			rel="noopener noreferrer"
																		>
																			{issue}
																		</a>
																	</div>
																);
															});
														}
													},
												]}
											/>
										</div>
									)}
								</div>
							</div>
						)}
					</React.Fragment>
				)}
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type VelocityReportingPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(VelocityReportingPage);
