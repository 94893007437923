import React, {ReactElement} from "react";

import {BrandMap, BrandMapStatus} from "@devour/client";

import {LinkProps} from "react-router-dom";
import FrameOneModal from "../modalComponents/FrameOneModal";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameModalFooter from "../modalComponents/FrameModalFooter";
import FrameButton from "../../buttons/FrameButton";

interface Props {
    isOpen: boolean;
    brandMap: BrandMap;
    new: boolean;
    originalBrandMap?: BrandMap;
    onContinueEditing?: () => void;
}

function BrandeMapSaveDraftSuccessModal(props: Props): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            size="sm"
        >
            <FrameModalHeader title="Draft saved successfully!"/>
            <FrameModalBody>
                {props.new ? (
                    <p>
                        {`Your new map, '${props.brandMap?.name}' was successfully saved as a draft.`}
                    </p>
                ) : (
                    <React.Fragment>
                        {props.originalBrandMap?.status === BrandMapStatus.DRAFT ? (
                            <p>
                                {`Your map, '${props.brandMap?.name}' has been successfully updated.`}
                            </p>
                        ) : (
                            <p>
                                {`Your map, '${props.brandMap?.name}' was updated & reverted to a draft.`}
                            </p>
                        )}
                    </React.Fragment>
                )}
            </FrameModalBody>

            <FrameModalFooter>
                {props.new ? (
                    <FrameButton
                        <LinkProps>
                        color="purple"
                        size="normal"
                        forwardProps={{
                            to: `/manage-brands/maps/${props.brandMap?.slug}/edit`,
                            replace: true
                        }}
                    >
                        Continue Editing
                    </FrameButton>
                ) : (
                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={props.onContinueEditing}
                    >
                        Continue Editing
                    </FrameButton>
                )}

                <FrameButton
                    color="purple-outline"
                    size="normal"
                    to={`/manage-brands/${props.brandMap?.brand?.id}/maps`}
                >
                    Return to Brand Maps
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default BrandeMapSaveDraftSuccessModal;