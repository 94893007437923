import React, {ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {
	Asset,
	AssetsApi,
	NftGrouping,
	NftsApi,
	Token,
	UpdateNftGroupingBody
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FileInputButton from "../inputs/FileInputButton";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {isAsset} from "../../typeguards/isAsset";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";

interface UpdateNftGroupingBodyFrontend extends Omit<UpdateNftGroupingBody, "hero" | "icon"> {
	hero: FileWithSRC | Asset;
	icon: FileWithSRC | Asset;
}

export const defaultUpdateNftGroupingBody: UpdateNftGroupingBodyFrontend = {
	groupingId: undefined,
	name: "",
	description: "",
	discordUrl: "",
	twitterUrl: "",
	websiteUrl: "",
	hero: undefined,
	icon: undefined,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	grouping: NftGrouping;
	onClose: () => void;
	onDone: () => void;
}

function ManageNftGroupingsEditModal(props: ManageNftGroupingsEditModalProps): ReactElement {

	const [editGroupingBody, setEditGroupingBody] = useState<UpdateNftGroupingBodyFrontend>(defaultUpdateNftGroupingBody);

	/**
	 * Clean the form on open.
	 *
	 */
	useEffect(() => {
		if (props.isOpen && props.grouping) {
			setEditGroupingBody({
				groupingId: props.grouping.id,
				name: props.grouping.name,
				sortPriority: props.grouping.sortPriority,
				description: props.grouping.description,
				discordUrl: props.grouping.discordUrl,
				twitterUrl: props.grouping.twitterUrl,
				websiteUrl: props.grouping.websiteUrl,
				hero: props.grouping.hero,
				icon: props.grouping.icon,
			});
		}
	}, [props.isOpen]);

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<UpdateNftGroupingBody, "asset">): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setEditGroupingBody({
				...editGroupingBody,
				[key]: e.target.value,
			});
		}
	}

	function sortPriorityOnChange(values): void {
		setEditGroupingBody((_editGroupingBody) => {
			return {
				..._editGroupingBody,
				sortPriority: values.floatValue,
			}
		});
	}

	/**
	 * Handle onChange event for the hero asset upload input.
	 *
	 * @param e
	 */
	async function onHeroChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newAsset = (await addURLsToFiles(e.target.files))[0];
		setEditGroupingBody((_editGroupingBody) => {
			return {
				..._editGroupingBody,
				hero: newAsset,
			}
		});
	}

	/**
	 * Handle onChange event for the icon asset upload input.
	 *
	 * @param e
	 */
	async function onIconChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newAsset = (await addURLsToFiles(e.target.files))[0];
		setEditGroupingBody((_editGroupingBody) => {
			return {
				..._editGroupingBody,
				icon: newAsset,
			}
		});
	}

	/**
	 * Remove the asset from the form.
	 *
	 */
	function removeAsset(key: keyof Pick<UpdateNftGroupingBody, "hero" | "icon">): () => void {
		return () => {
			setEditGroupingBody((_editGroupingBody) => {
				return {
					..._editGroupingBody,
					[key]: undefined,
				}
			});
		}
	}

	/**
	 * Call api to create new NFT grouping, reset form & close modal on success.
	 *
	 */
	async function submitNewGrouping(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			// Validation to properly assign the assets based on if they were removed, replaced with new ones, or left as is.
			let hero: Asset;
			if (editGroupingBody.hero && !isAsset(editGroupingBody.hero)) {
				hero = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: editGroupingBody.hero,
				});
			} else if (editGroupingBody.hero && isAsset(editGroupingBody.hero)) {
				hero = editGroupingBody.hero;
			}

			let icon: Asset;
			if (editGroupingBody.icon && !isAsset(editGroupingBody.icon)) {
				icon = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: editGroupingBody.icon,
				});
			} else if (editGroupingBody.icon && isAsset(editGroupingBody.icon)) {
				icon = editGroupingBody.icon;
			}

			await new NftsApi(getConfig(props.fullToken)).updateNftGrouping({
				updateNftGroupingBody: {
					groupingId: props.grouping.id,
					name: editGroupingBody.name || undefined,
					sortPriority: editGroupingBody.sortPriority,
					description: editGroupingBody.description || undefined,
					discordUrl: editGroupingBody.discordUrl || undefined,
					twitterUrl: editGroupingBody.twitterUrl || undefined,
					websiteUrl: editGroupingBody.websiteUrl || undefined,
					hero: hero ? hero.id : undefined,
					icon: icon ? icon.id : undefined,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="sm"
			contentClassName="manage-groupings-add-modal"
		>
			<FrameModalHeader
				toggle={props.onClose}
				title={`Editing Grouping ${props.grouping?.name}`}
			/>

			<form onSubmit={submitNewGrouping}>
				<FrameModalBody className="manage-groupings-add-modal_body">
					<div>
						<label>
							Name*
						</label>
						<input
							value={editGroupingBody.name}
							placeholder="Grouping Name..."
							onChange={inputOnChange("name")}
						/>
					</div>

					<div>
						<label>
							Sort Priority
						</label>
						<NumberFormat
							placeholder="Sort Priority..."
							value={editGroupingBody.sortPriority}
							allowNegative={false}
							decimalScale={0}
							allowLeadingZeros={false}
							onValueChange={sortPriorityOnChange}
						/>
						</div>

					<div>
						<label>
							Description
						</label>
						<input
							value={editGroupingBody.description}
							placeholder="Grouping Description..."
							onChange={inputOnChange("description")}
						/>
					</div>

					<div>
						<label>
							Discord URL
						</label>
						<input
							value={editGroupingBody.discordUrl}
							placeholder="Grouping Discord URL..."
							onChange={inputOnChange("discordUrl")}
						/>
					</div>

					<div>
						<label>
							Twitter URL
						</label>
						<input
							value={editGroupingBody.twitterUrl}
							placeholder="Grouping Twitter URL..."
							onChange={inputOnChange("twitterUrl")}
						/>
					</div>

					<div>
						<label>
							Website URL
						</label>
						<input
							value={editGroupingBody.websiteUrl}
							placeholder="Grouping Website URL..."
							onChange={inputOnChange("websiteUrl")}
						/>
					</div>

					<div className="manage-groupings-add-modal_body_asset-upload">
						<label>
							Hero Image
						</label>

						<div className="manage-groupings-add-modal_body_asset-upload_content">
							{editGroupingBody.hero && (
								<React.Fragment>
									{isAsset(editGroupingBody.hero) ? (
										<img
											src={editGroupingBody.hero.url}
											alt="New NFT Grouping Hero"
											className="manage-groupings-add-modal_body_asset-upload_content_img"
										/>
									) : (
										<img
											src={editGroupingBody.hero.imageSRC as string}
											alt="New NFT Grouping Hero"
											className="manage-groupings-add-modal_body_asset-upload_content_img"
										/>
									)}
								</React.Fragment>
							)}

							<FileInputButton
								accept=".png, .jpg, .jpeg, .gif"
								multiple={false}
								onChange={onHeroChange}
							>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="purple"
									forwardProps={{
										type: "button",
									}}
								>
									{editGroupingBody.hero ? "Change Hero" : "Upload Hero"}
								</FrameButton>
							</FileInputButton>

							{editGroupingBody.hero && (
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="danger"
									onClick={removeAsset("hero")}
									forwardProps={{
										type: "button",
									}}
								>
									Remove Image
								</FrameButton>
							)}
						</div>
					</div>

					<div>
						<label>
							Icon Image
						</label>

						<div className="manage-groupings-add-modal_body_asset-upload_content">
							{editGroupingBody.icon && (
								<React.Fragment>
									{isAsset(editGroupingBody.icon) ? (
										<img
											src={editGroupingBody.icon.url}
											alt="New NFT Grouping Icon"
											className="manage-groupings-add-modal_body_asset-upload_content_img"
										/>
									) : (
										<img
											src={editGroupingBody.icon.imageSRC as string}
											alt="New NFT Grouping Icon"
											className="manage-groupings-add-modal_body_asset-upload_content_img"
										/>
									)}
								</React.Fragment>
							)}

							<FileInputButton
								accept=".png, .jpg, .jpeg, .gif"
								multiple={false}
								onChange={onIconChange}
							>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="purple"
									forwardProps={{
										type: "button",
									}}
								>
									{editGroupingBody.icon ? "Change Icon" : "Upload Icon"}
								</FrameButton>
							</FileInputButton>

							{editGroupingBody.icon && (
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="danger"
									onClick={removeAsset("icon")}
									forwardProps={{
										type: "button",
									}}
								>
									Remove Image
								</FrameButton>
							)}
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Save Grouping
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageNftGroupingsEditModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageNftGroupingsEditModal);
