import React, {PropsWithChildren, ReactElement, ReactNode} from "react";
import {FiCheck} from "react-icons/fi";
import classNames from "classnames";

interface Props {
	onToggle: () => void;
	checked: boolean;
	disabled?: boolean;
}

function FrameOneCheckbox(props: PropsWithChildren<Props>): ReactElement {

	/**
	 * onClick helper for when clicking on a part of the component that is not directly the input itself.
	 * Don't call the toggle function if this input is disabled.
	 *
	 */
	function onClickHelper(): void {
		if (!props.disabled) {
			props.onToggle();
		}
	}

	return (
		<div
			className={classNames("frame-one-checkbox", {
				"frame-one-checkbox_disabled": props.disabled
			})}
			onClick={onClickHelper}
		>
			<div>
				<div className="pretty p-svg p-curve p-smooth">
					<input
						type="checkbox"
						onChange={props.onToggle}
						checked={props.checked}
						disabled={props.disabled}
						onClick={onClickHelper}
					/>

					<div className="state p-dark-purple">
						<FiCheck className="svg svg-icon"/>

						<label/>
					</div>
				</div>
			</div>

			<label className="frame-one-checkbox_real-label">
				{props.children}
			</label>
		</div>
	);
}

export default FrameOneCheckbox;
