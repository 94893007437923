import React, {ReactElement, useEffect, useState} from "react";
import {Business, GetBusinessTaxonomiesResponse, Token, BusinessTaxonomiesApi, BusinessesApi} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameButton from "../buttons/FrameButton";
import SelectTaxonomiesModal from "../modals/SelectTaxonomiesModal";

interface StateProps {
	fullToken: Token;
}

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function MerchantTaxonomies(props: MerchantTaxonomiesProps): ReactElement {

	const [taxonomiesRes, setTaxonomiesRes] = useState<GetBusinessTaxonomiesResponse>(undefined);
	const [showTaxonomiesModal, setShowTaxonomiesModal] = useState<boolean>(false);
	const [newTaxonomies, setNewTaxonomies] = useState<Array<string>>(props.business.taxonomies);

	useEffect(() => {
		void fetchTaxonomies();
	}, []);

	async function fetchTaxonomies(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const taxonomiesRes = await new BusinessTaxonomiesApi(getConfig(props.fullToken)).getBusinessTaxonomies();

			setTaxonomiesRes(taxonomiesRes);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * For when the admin selects a taxonomy from the modal designed for such.
	 * If the taxonomy is already selected, it will be removed from the selected list,
	 * if the taxonomy is not already selected then it will be added to the list.
	 *
	 * @param id
	 */
	function handleSelectTaxonomy(id: string): void {
		const currentlySelectedTaxonomies = [...newTaxonomies];
		const foundIndex = currentlySelectedTaxonomies.indexOf(id);
		if (foundIndex > -1) {
			currentlySelectedTaxonomies.splice(foundIndex, 1);
		} else {
			currentlySelectedTaxonomies.push(id);
		}

		setNewTaxonomies(currentlySelectedTaxonomies);
	}

	async function onCloseTaxonomies() {
		props.dispatch(incrementLoading());

		try {
			await new BusinessesApi(getConfig(props.fullToken)).updateBusinessAdmin({
				id: props.business.id,
				updateBusinessAdmin: {
					...props.business,
					taxonomies: newTaxonomies.filter((tid) => taxonomiesRes?.businessTaxonomies.find((taxonomy) => taxonomy.id === tid)), // Only submit taxonomies that are still available and not deleted
				},
			});
			setShowTaxonomiesModal(false);
			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}

	}

	return (
		<React.Fragment>
			<SelectTaxonomiesModal
				isOpen={showTaxonomiesModal}
				taxonomies={taxonomiesRes?.businessTaxonomies}
				selectedTaxonomies={newTaxonomies}
				onSelect={handleSelectTaxonomy}
				onClose={onCloseTaxonomies}
			/>

			<div className="merchant-taxonomies">
				<h4>
					Categories
				</h4>
				{props.business.taxonomies.length < 1 ? (
					<p>
						No categories selected.
					</p>
				) : (
					<ul>
						{props.business.taxonomies.filter((tid) => taxonomiesRes?.businessTaxonomies.find((taxonomy) => taxonomy.id === tid)).map((t, i) => {
							return (
								<li key={`taxonomy_${i}`}>
									{taxonomiesRes?.businessTaxonomies?.find(bt => bt.id === t)?.name}
								</li>
							);
						})}
					</ul>
				)}

				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="lightBlue"
					onClick={() => setShowTaxonomiesModal(true)}
					forwardProps={{
						type: "button"
					}}
				>
					Select Categories
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props
		}
	});
}

type MerchantTaxonomiesProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(MerchantTaxonomies);
