import {MenuCategory, MenuItem, Asset, DiscountMenuItem} from "@devour/client";
import React, {ReactElement, useEffect, useState} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import FrameOneTableCellImagePreview from "../tables/cells/FrameOneTableCellImagePreview";
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";


const EN_SPACE_CODE = 8194;

interface Props {
    isOpen: boolean;
    selectedItems: Array<string>;
    menuCategories: Array<MenuCategory>;
    menuItems: Array<MenuItem>;
    businessId: string;
    onClose: () => void;
    onDone: (items: Array<DiscountMenuItem>) => void;
}

function SelectPromotionMenuItemsModal(props: Props): ReactElement {
    const [selectedItems, setSelectedItems] = useState<Array<string>>(undefined);
    const [filteredCategory, setFilteredCategory] = useState<string>("");
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        if (props.isOpen && props.selectedItems) {
            setSelectedItems(props.selectedItems);
        }
        if (props.isOpen) {
            setFilteredCategory("");
            setSearchString("");
        }
    }, [props.isOpen, props.selectedItems]);


    function renderCategoryOptions(categories: Array<MenuCategory>, depth = 0) {
        const space = String.fromCharCode(EN_SPACE_CODE);

        return categories.map(c => (
            <React.Fragment key={c.id}>
                <option value={c.id}>
                    {space.repeat(depth * 2)}
                    {c.name}
                </option>
                {c.menuSubcategories?.length && renderCategoryOptions(c.menuSubcategories, depth + 1)}
            </React.Fragment>
        ))
    }

    function onSelectItem(id: string) {
        if (selectedItems.indexOf(id) > -1) {
            setSelectedItems(selectedItems.filter(itemId => itemId !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    }

    function onSaveSelectedItems(): void {
        const updatedSelectedItems = props.menuItems?.filter(item => selectedItems.includes(item.id))
            .map(item => ({
                id: item.id,
                name: item.name,
                business: props.businessId
            }))
        props.onDone(updatedSelectedItems);
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="lg"
            contentClassName="select-promotion-items-modal"
        >
            <FrameModalHeader
                title="Select Menu Items"
                toggle={props.onClose}
            />
            <FrameModalBody>
                <div>
                    <label>Filter By Category</label>
                    <select
                        value={filteredCategory}
                        onChange={(e) => setFilteredCategory(e.target.value)}
                    >
                        <option value="">All Categories</option>
                        {props.menuCategories && renderCategoryOptions(props.menuCategories)}
                    </select>
                </div>
                <br />
                <div>
                    <label>Search Items</label>
                    <input
                        placeholder="Search Menu Items..."
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value.toLowerCase())}
                    />
                </div>
                <br />
                <FrameOneTableContainer
                    data={props.menuItems?.filter(item => {
                        //@ts-ignore
                        return (!filteredCategory || item.category._id === filteredCategory)
                            && item.name?.toLowerCase().includes(searchString)
                    })}
                    columnOptions={[
                        {
                            key: "image",
                            headerValue: "Image",
                            cellRender: (asset: Asset) => {
                                return (
                                    <FrameOneTableCellImagePreview asset={asset}/>
                                );
                            }
                        },
                        {
                            key: "name",
                            headerValue: "Name",
                        },
                        {
                            key: undefined,
                            headerValue: "Select/De-select",
                            cellRender: (item: MenuItem) => {
                                const isSelected: boolean = selectedItems.indexOf(item.id) > -1;

                                return (
                                    <div className="frame-one-table-cell">
                                        <FrameButton
                                            color={isSelected ? "danger" : "success"}
                                            onClick={() => onSelectItem(item.id)}
                                        >
                                            {isSelected ? "Remove" : "Add"}
                                        </FrameButton>
                                    </div>
                                );
                            }
                        },
                        {
                            key: "isActive",
                            headerValue: "Active",
                            cellRender: (isActive: boolean) => {
                                return isActive
                                    ? <AiFillCheckCircle className="table-icon table-text-success"/>
                                    : <AiFillCloseCircle className="table-icon  table-text-danger"/>
                            }
                        },
                        {
                            key: "isAlcohol",
                            headerValue: "Alcohol",
                            cellRender: (isAlcohol: boolean) => {
                                return isAlcohol
                                    ? <AiFillCheckCircle className="table-icon table-text-success"/>
                                    : <AiFillCloseCircle className="table-icon  table-text-danger"/>
                            }
                        },
                        {
                            key: "price",
                            headerValue: "Price"
                        }
                    ]}
                    />
            </FrameModalBody>
            <FrameModalFooter>
                <FrameButton
                    color="darkBlue"
                    onClick={onSaveSelectedItems}
                >
                    Add Items
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    )
}

export default SelectPromotionMenuItemsModal;