import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {
	Brand,
	CreateOrderDiscountBody,
	GetBusinessesOwnResponse,
	GetNftGroupingsResponse,
	MenuCategory,
	NftsApi,
	OrderDiscountCalculation,
	OrderDiscountFrequencyQualifier,
	OrderDiscountOrigin,
	OrderDiscountsApi,
	OrderDiscountType,
	Token,
	DiscountMenuItem,
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";
import moment from "moment/moment";
import FrameOneDatePicker from "../inputs/FrameOneDatePicker";
import AddDiscountSelectGroupingsModal from "./AddDiscountSelectGroupingsModal";
import generateCrytoSecureString from "../../utils/generateCryptoSecureString";
import {AiOutlineClose} from "react-icons/ai";
import SelectBusinessesModal from "./SelectBusinessesModal";

export interface CreateOrderDiscountBodyFrontEnd extends  Omit<CreateOrderDiscountBody, "menuCategories" | "menuItems"> {
	generateAmount: string;
	menuCategories?: Array<MenuCategory>;
	menuItems?: Array<DiscountMenuItem>;
}

function getDefaultAddDiscountForm(): CreateOrderDiscountBodyFrontEnd {
	return {
		label: "",
		description: "",
		businesses: [],
		codes: [],
		amount: undefined,
		amountMax: 0,
		subtotalMin: 0,
		expiresAt: undefined,
		type: undefined,
		calculation: undefined,
		origin: undefined,
		isEnabled: true,
		isSingleUseUser: false,
		isSingleUseSystem: false,
		isStackablePromo: false,
		isFirstOrder: false,
		isReferralOnly: false,
		nftGroupings: [],
		priority: 0,
		isDisplayedGoFrens: true,
		generateAmount: "1",
		maxRedemptions: undefined,
		useFrequency: undefined,
		useFrequencyQualifier: undefined,
		menuCategories: [],
		menuItems: [],
	}
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
	businessesRes: GetBusinessesOwnResponse;
	brands: Array<Brand>;
}

function AddOrderDiscountModal(props: AddOrderDiscountModalProps): ReactElement {

	const [addDiscountForm, setAddDiscountForm] = useState<CreateOrderDiscountBodyFrontEnd>(getDefaultAddDiscountForm());
	const [nftGroupingsRes, setNftGroupingsRes] = useState<GetNftGroupingsResponse>(undefined);
	const [showGroupingsModal, setShowGroupingsModal] = useState<boolean>(false);
	const [showBusinessesModal, setShowBusinessesModal] = useState<boolean>(false);

	useEffect(() => {
		if (props.isOpen) {
			setAddDiscountForm(getDefaultAddDiscountForm());
			getDataForDropDowns().then().catch();
		}
	}, [props.isOpen]);

	async function getDataForDropDowns(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const _nftGroupings = await new NftsApi(getConfig(props.fullToken)).getNftGroupings({
				limit: 100000,
				offset: 0,
			});

			setNftGroupingsRes(_nftGroupings);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Pick<CreateOrderDiscountBody, "label" | "type" | "priority" | "calculation" | "origin" | "description" | "internalName" | "maxRedemptions" | "useFrequency" | "useFrequencyQualifier"> | "generateAmount"): ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> {
		return (e) => {
			const value = e.target.value;
			const updatedForm = {
				...addDiscountForm,
				[key]: value,
			}

			if (key === "type" && (value === OrderDiscountType.CATEGORY || value === OrderDiscountType.MENUITEM)) {
				updatedForm.isStackablePromo = false;
			}

			setAddDiscountForm(updatedForm);
		}
	}

	function expiryDateOnChange(expiryDate: Date): void {
		setAddDiscountForm({
			...addDiscountForm,
			expiresAt: expiryDate === undefined ? undefined : moment(expiryDate).startOf("day").valueOf(),
		});
	}

	/**
	 * Generate multiple codes to list.
	 */
	function onGenerateMultipleCodes(): void {
		const amount = parseInt(addDiscountForm.generateAmount);
		const newItems: Array<string> = Array(amount).fill("").map(() => generateCrytoSecureString(["numbers", "uppercase"], 20));
		setAddDiscountForm({
			...addDiscountForm,
			codes: addDiscountForm.codes.concat(newItems),
		});
	}

	/**
	 * Add multiple codes to list.
	 */
	function onAddMultipleCodes(): void {
		const amount = parseInt(addDiscountForm.generateAmount);
		const newItems: Array<string> = Array(amount).fill("");
		setAddDiscountForm({
			...addDiscountForm,
			codes: addDiscountForm.codes.concat(newItems),
		});
	}

	/**
	 * Remove code.
	 */
	function onRemoveCode(index: number): void {
		const newCodes: Array<string> = [...addDiscountForm.codes];
		newCodes.splice(index, 1);
		setAddDiscountForm({
			...addDiscountForm,
			codes: newCodes,
		});
	}

	/**
	 * Generate random unique code.
	 */
	function onGenerateRandomCode(index: number): void {
		const newCodes: Array<string> = [...addDiscountForm.codes];
		newCodes[index] = generateCrytoSecureString(["numbers", "uppercase"], 20);
		setAddDiscountForm({
			...addDiscountForm,
			codes: newCodes,
		});
	}

	/**
	 * Handle discount code text input onChange events.
	 *
	 * @param e
	 * @param index
	 */
	function discountInputOnChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
		const newCodes: Array<string> = [...addDiscountForm.codes];
		newCodes[index] = e.target.value;
		setAddDiscountForm({
			...addDiscountForm,
			codes: newCodes,
		});
	}

	/**
	 * Handle the number format input for the amount field on change.
	 * If the entered amount is greater than the user's balance, set the input to match the balance.
	 *
	 * @param values
	 */
	function discountAmountOnChange(values): void {
		let newAmount = values.floatValue;

		if (
			addDiscountForm.calculation === OrderDiscountCalculation.PERCENTAGE
			&& newAmount > 100
		) {
			newAmount = 100;
		}

		setAddDiscountForm({
			...addDiscountForm,
			amount: newAmount,
		});
	}

	/**
	 * Handle the number format input for the amount field on change.
	 *
	 * @param values
	 */
	function discountAmountMaxOnChange(values): void {
		setAddDiscountForm({
			...addDiscountForm,
			amountMax: values.floatValue,
		});
	}

	/**
	 * Handle the number format input for the minimum order amount field on change.
	 *
	 * @param values
	 */
	function discountSubtotalMinOnChange(values): void {
		setAddDiscountForm({
			...addDiscountForm,
			subtotalMin: values.floatValue,
		});
	}

	/**
	 * Handle enabled checkbox on change event.
	 *
	 */
	function toggleDiscountEnabled(): void {
		setAddDiscountForm({
			...addDiscountForm,
			isEnabled: !addDiscountForm.isEnabled,
		});
	}

	/**
	 * Handle isReferralOnly checkbox on change event.
	 *
	 */
	function toggleIsReferralOnly(): void {
		setAddDiscountForm({
			...addDiscountForm,
			isReferralOnly: !addDiscountForm.isReferralOnly,
		});
	}

	/**
	 * Handle stackable checkbox on change event.
	 *
	 */
	function toggleStackableEnabled(): void {
		setAddDiscountForm({
			...addDiscountForm,
			isStackablePromo: !addDiscountForm.isStackablePromo,
		});
	}

	/**
	 * Handle single use system checkbox on change event.
	 *
	 */
	function toggleSingleUseSystem(): void {
		setAddDiscountForm({
			...addDiscountForm,
			isSingleUseSystem: !addDiscountForm.isSingleUseSystem,
		});
	}

	/**
	 * Handle single use user checkbox on change event.
	 *
	 */
	function toggleSingleUseUser(): void {
		setAddDiscountForm({
			...addDiscountForm,
			isSingleUseUser: !addDiscountForm.isSingleUseUser,
		});
	}

	/**
	 * Handle first order checkbox on change event.
	 *
	 */
	function toggleFirstOrder(): void {
		setAddDiscountForm({
			...addDiscountForm,
			isFirstOrder: !addDiscountForm.isFirstOrder,
		});
	}

	/**
	 * Handle displayed gofrens checkbox on change event.
	 *
	 */
	function toggleDisplayedGoFrens(): void {
		setAddDiscountForm({
			...addDiscountForm,
			isDisplayedGoFrens: !addDiscountForm.isDisplayedGoFrens,
		});
	}

	/**
	 * Handle the calculation react-select input onChange event for choosing calculation method.
	 * Reset amount max to 0 since it is only applicable to percentage calculations.
	 *
	 * @param e
	 */
	function handleCalculationMethodOnChange(e: React.ChangeEvent<HTMLSelectElement>): void {
		setAddDiscountForm({
			...addDiscountForm,
			amountMax: 0,
			calculation: e.target.value as OrderDiscountCalculation,
		});
	}

	/**
	 * For when the admin selects an nft grouping from the modal designed for such.
	 * If the grouping is already selected, it will be removed from the selected list,
	 * if the grouping is not already selected then it will be added to the list.
	 *
	 * @param id
	 */
	function handleSelectGrouping(id: string): void {
		const currentlySelectedGroupings = addDiscountForm.nftGroupings;
		const foundIndex = currentlySelectedGroupings.indexOf(id);
		if (foundIndex > -1) {
			currentlySelectedGroupings.splice(foundIndex, 1);
		} else {
			currentlySelectedGroupings.push(id);
		}

		setAddDiscountForm(prevState => {
			return {
				...prevState,
				nftGroupings: currentlySelectedGroupings,
			}
		});
	}

	/**
	 * For when the admin selects an business from the modal designed for such.
	 * If the grouping is already selected, it will be removed from the selected list,
	 * if the grouping is not already selected then it will be added to the list.
	 *
	 * @param id
	 */
	function handleSelectBusinesses(id: string): void {
		const currentlySelectedBusinesses = addDiscountForm.businesses;
		let currentlySelectedCategories: Array<MenuCategory> = addDiscountForm.menuCategories;
		let currentlySelectedMenuItems: Array<DiscountMenuItem> = addDiscountForm.menuItems;

		const foundIndex = currentlySelectedBusinesses.indexOf(id);
		if (foundIndex > -1) {
			currentlySelectedBusinesses.splice(foundIndex, 1);

			// remove all the menu categories of the deselected restaurant
			if (addDiscountForm.type === OrderDiscountType.CATEGORY) {
				currentlySelectedCategories = currentlySelectedCategories.filter((c) => c.business !== id);
			}

			if (addDiscountForm.type === OrderDiscountType.MENUITEM) {
				currentlySelectedMenuItems = currentlySelectedMenuItems.filter(item => item.business !== id);
			}
		} else {
			currentlySelectedBusinesses.push(id);
		}

		setAddDiscountForm(prevState => {
			return {
				...prevState,
				businesses: currentlySelectedBusinesses,
				menuCategories: currentlySelectedCategories,
				menuItems: currentlySelectedMenuItems
			}
		});
	}

	async function submitNewOrderDiscount(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new OrderDiscountsApi(getConfig(props.fullToken)).createOrderDiscount({
				createOrderDiscountBody: {
					...addDiscountForm,
					priority: Number(addDiscountForm.priority),
					maxRedemptions: addDiscountForm.maxRedemptions ? Number(addDiscountForm.maxRedemptions) : undefined,
					useFrequency: addDiscountForm.useFrequency ? Number(addDiscountForm.useFrequency) : undefined,
					useFrequencyQualifier: addDiscountForm.useFrequency ? addDiscountForm.useFrequencyQualifier : undefined,
					menuCategories: (addDiscountForm.type === OrderDiscountType.CATEGORY) ? addDiscountForm.menuCategories.map(c => c.id) : undefined,
					menuItems: (addDiscountForm.type === OrderDiscountType.MENUITEM) ? addDiscountForm.menuItems.map(c => c.id) : undefined
				},
			});

			setAddDiscountForm(getDefaultAddDiscountForm());
			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function toggleGroupingsModal(): void {
		setShowGroupingsModal(s => !s);
	}

	function toggleBusinessesModal(): void {
		setShowBusinessesModal(s => !s);
	}

	return (
		<React.Fragment>
			<AddDiscountSelectGroupingsModal
				isOpen={showGroupingsModal}
				nftGroupings={nftGroupingsRes?.nftGroupings}
				selectedGroupings={addDiscountForm.nftGroupings}
				onSelect={handleSelectGrouping}
				onClose={toggleGroupingsModal}
			/>

			<SelectBusinessesModal
				isOpen={showBusinessesModal}
				businesses={props.businessesRes?.businesses}
				selectedBusinesses={addDiscountForm.businesses}
				selectedCategories={addDiscountForm.menuCategories}
				selectedItems={addDiscountForm.menuItems}
				onSelect={handleSelectBusinesses}
				onClose={toggleBusinessesModal}
				discountType={addDiscountForm.type}
				onUpdateForm={setAddDiscountForm}
			/>

			<FrameOneModal
				isOpen={props.isOpen}
				toggle={props.onClose}
				contentClassName="add-order-discount-modal"
			>
				<FrameModalHeader
					title="Add New Order Promo"
					toggle={props.onClose}
				/>

				<form onSubmit={submitNewOrderDiscount}>
					<FrameModalBody className="add-order-discount-modal_body">
						<div>
							<label>
								Promotion Text*
							</label>
							<input
								value={addDiscountForm.label}
								placeholder="Promotion Text..."
								onChange={inputOnChange("label")}
							/>
							<p className="form-tip">
								This is the promo text displayed to the customer.
							</p>
						</div>

						<div>
							<label>
								Promotion Name
							</label>
							<input
								value={addDiscountForm.internalName}
								placeholder="Promotion Name..."
								onChange={inputOnChange("internalName")}
							/>
							<p className="form-tip">
								This is the internal name only visible to admins.
							</p>
						</div>

						<div>
							<label>
								Promo Type*
							</label>
							<select
								value={addDiscountForm.type}
								onChange={inputOnChange("type")}
							>
								<option value="" selected={true} disabled={true}>Select Promo Type</option>
								<option value={OrderDiscountType.ORDER}>Order</option>
								<option value={OrderDiscountType.DELIVERY}>Delivery</option>
								<option value={OrderDiscountType.PAYMENT}>Payment</option>
								<option value={OrderDiscountType.CATEGORY}>Category</option>
								<option value={OrderDiscountType.MENUITEM}>Menu Item</option>
							</select>
							<p className="form-tip">
								Percentage calculations for "Order" will be on the subtotal of order items.<br/>
								"Delivery" promos will only activate if the customer has chosen delivery,
								and percentage calculations will be on the delivery charge.<br/>
								"Payment" promos are for gift cards and are applied on the grand total.<br/>
								"Category" and "Menu Item" promos are applied on the subtotal of the eligible items.
							</p>
						</div>

						<div>
							<label>
								Promo Calculation Method*
							</label>
							<select
								value={addDiscountForm.calculation}
								onChange={handleCalculationMethodOnChange}
							>
								<option value="" selected={true} disabled={true}>Select Promo Calculation Method
								</option>
								<option value={OrderDiscountCalculation.FIXEDAMOUNT}>Fixed Amount</option>
								<option value={OrderDiscountCalculation.PERCENTAGE}>Percentage</option>
							</select>
						</div>

						<div>
							<label>
								Discount Amount*
							</label>
							<NumberFormat
								placeholder={addDiscountForm.calculation === undefined ? "Select Promo Calculation Method above first" : "Promo Amount..."}
								prefix={addDiscountForm.calculation === OrderDiscountCalculation.FIXEDAMOUNT ? "$" : ""}
								suffix={addDiscountForm.calculation === OrderDiscountCalculation.PERCENTAGE ? "%" : ""}
								disabled={addDiscountForm.calculation === undefined}
								value={addDiscountForm.amount}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={2}
								onValueChange={discountAmountOnChange}
							/>
						</div>

						<div>
							<label>
								Max Absolute Amount*
							</label>
							<NumberFormat
								placeholder={addDiscountForm.calculation === OrderDiscountCalculation.PERCENTAGE ? "Percentage Calculations only" : "Max Absolute Amount..."}
								prefix="$"
								disabled={addDiscountForm.calculation !== OrderDiscountCalculation.PERCENTAGE}
								value={addDiscountForm.amountMax}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={2}
								onValueChange={discountAmountMaxOnChange}
							/>
							<p className="form-tip">
								For percentage calculations only.
								Maximum dollar amount that can be applied.
								Leave "0" for unlimited / no limit.
							</p>
						</div>

						<div>
							<label>
								Minimum Subtotal Amount*
							</label>
							<NumberFormat
								placeholder={"Minimum Subtotal Amount..."}
								prefix={"$"}
								value={addDiscountForm.subtotalMin}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={2}
								onValueChange={discountSubtotalMinOnChange}
							/>
							<p className="form-tip">
								Require a minimum order subtotal to use this promo.
								This will use the <strong>original</strong> subtotal before any discounts are applied.
							</p>
						</div>

						<div>
							<label>Use Frequency</label>
							<div className="add-order-discount-modal_body_codes_row">
								<input
									placeholder={"Frequency Quantity..."}
									type="number"
									value={addDiscountForm?.useFrequency ?? ""}
									onChange={inputOnChange("useFrequency")}
									step={1}
								/>
								<select
									value={addDiscountForm?.useFrequencyQualifier}
									onChange={inputOnChange("useFrequencyQualifier")}
									defaultValue=""
								>
									<option value="" disabled={true}>Select Qualifier</option>
									{Object.values(OrderDiscountFrequencyQualifier).map((qualifier:string) => (
										<option value={qualifier}>{qualifier}</option>
									))}
								</select>
							</div>
							<p className="form-tip">
								If the quantity is set, the promo can only be used by a user that number of times daily, weekly or monthly.<br/>
								If left empty, there are no restrictions on the frequency of the promotion.
							</p>
						</div>
						<div>
							<label>
								Quantity Limit
							</label>
							<input
								type="number"
								value={addDiscountForm?.maxRedemptions}
								onChange={inputOnChange("maxRedemptions")}
								step={1}
							/>
							<p className="form-tip">
								If set, a maximum redemption limit will be applied on the promotion.
								If left empty, the promotion will have have an infinite quantity of redemptions.
							</p>
						</div>

						<div>
							<label>
								Priority*
							</label>
							<input
								type="number"
								value={addDiscountForm?.priority}
								onChange={inputOnChange("priority")}
								step={1}
							/>
							<p className="form-tip">
								If non-stackable, the promo with the highest priority will be applied by default for the
								customer on checkout.<br/>
								If displayed on GoFrens, it will display the promo with the highest priority.
							</p>
						</div>

						<div className="add-order-discount-modal_body_codes">
							<label>
								Codes
							</label>
							{addDiscountForm.codes.map((code, index) => (
								<div
									key={`discount-code-${index}`}
									className="add-order-discount-modal_body_codes_row"
								>
									<input
										value={addDiscountForm.codes[index]}
										onChange={(e) => discountInputOnChange(e, index)}
										placeholder={`Discount Code ${index}`}
										required={true}
									/>
									<FrameButton
										<React.ButtonHTMLAttributes<HTMLButtonElement>>
										color="lightGray"
										onClick={() => onGenerateRandomCode(index)}
										forwardProps={{
											type: "button"
										}}
									>
										Generate
									</FrameButton>
									<FrameButton
										<React.ButtonHTMLAttributes<HTMLButtonElement>>
										color="danger"
										onClick={() => onRemoveCode(index)}
										forwardProps={{
											type: "button"
										}}
									>
										<AiOutlineClose/>
									</FrameButton>
								</div>
							))}
							<div className="add-order-discount-modal_body_codes_row">
								<input
									type="number"
									value={addDiscountForm.generateAmount}
									onChange={inputOnChange("generateAmount")}
									min={1}
									step={1}
								/>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="lightGray"
									onClick={onGenerateMultipleCodes}
									forwardProps={{
										type: "button"
									}}
								>
									Generate {addDiscountForm.generateAmount} codes
								</FrameButton>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="lightGray"
									onClick={onAddMultipleCodes}
									forwardProps={{
										type: "button"
									}}
								>
									Add {addDiscountForm.generateAmount} codes
								</FrameButton>
							</div>
							<p className="form-tip">
								Require the customer to input a code to use the promo. Needs to be unique.
								Each code will have its own validation for the single use checks.
							</p>
						</div>

						<div>
							<label>
								Promo Origin*
							</label>
							<select
								value={addDiscountForm.origin}
								onChange={inputOnChange("origin")}
							>
								<option value="" selected={true} disabled={true}>Select Promo Origin</option>
								<option value={OrderDiscountOrigin.DEVOUR}>Devour</option>
								<option value={OrderDiscountOrigin.MERCHANT}>Merchant</option>
							</select>
							<p className="form-tip">
								How is the promo being financed?
							</p>
						</div>

						<div>
							<label>
								Promo Expiry Date
							</label>
							<FrameOneDatePicker
								oneTap={true}
								ranges={[]}
								onChange={expiryDateOnChange}
								cleanable={true}
								caretAs={undefined}
								value={addDiscountForm.expiresAt ? moment(addDiscountForm.expiresAt).startOf("day").toDate() : undefined}
							/>
							<p className="form-tip">
								If selected, will set a date at which the promo no longer works. Selected
								date/time will be midnight (12:00am) of the selected date, in the timezone of the admin
								who sets it.
							</p>
						</div>

						<div>
							<label>
								NFT Groupings
							</label>
							{addDiscountForm.nftGroupings.length < 1 ? (
								<p>
									No nft groupings selected.
								</p>
							) : (
								<ul>
									{addDiscountForm.nftGroupings.map((g, i) => {
										return (
											<li key={`taxonomy_${i}`}>
												{nftGroupingsRes?.nftGroupings?.find(bt => bt.id === g).name}
											</li>
										);
									})}
								</ul>
							)}

							<FrameButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="lightBlue"
								onClick={toggleGroupingsModal}
								forwardProps={{
									type: "button"
								}}
							>
								Select NFT Groupings
							</FrameButton>

							<p className="form-tip">
								If configured, users will need to own an nft of one of the selected groupings to be
								eligible for receiving the promo. Leave blank to allow all users to utilize promo.
							</p>
						</div>

						<div>
							<label>
								Businesses
							</label>
							{addDiscountForm.businesses.length < 1 ? (
								<p>
									No businesses selected.
								</p>
							) : (
								<ul>
									{addDiscountForm.businesses.map((g, i) => {
										return (
											<li key={`business_${i}`}>
												{props.businessesRes?.businesses?.find(bt => bt.id === g).name}
											</li>
										);
									})}
								</ul>
							)}

							<FrameButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="lightBlue"
								onClick={toggleBusinessesModal}
								forwardProps={{
									type: "button"
								}}
							>
								Select Businesses
							</FrameButton>

							<p className="form-tip">
								If configured, this promo will only apply to the select businesses and their descendent
								businesses.
								Leave empty for global site wide promo.
							</p>
						</div>

						<div>
							<FrameOneCheckbox
								onToggle={toggleStackableEnabled}
								checked={addDiscountForm.isStackablePromo}
								disabled={addDiscountForm.type === OrderDiscountType.CATEGORY || addDiscountForm.type === OrderDiscountType.MENUITEM}
							>
								Promo is stackable
							</FrameOneCheckbox>
							<p className="form-tip">
								Stackable promos are auto-applied if the customer qualifies. There is no limit on the
								amount of stackable promos on a single order.
								Non-stackable promos will allow the customer to pick one from their list of qualified
								promotions.<br/>
								Promos of type category or menu item are always non-stackable.
							</p>
						</div>

						<div>
							<FrameOneCheckbox
								onToggle={toggleSingleUseSystem}
								checked={addDiscountForm.isSingleUseSystem}
							>
								Single Use (system-wide)
							</FrameOneCheckbox>
						</div>

						<div>
							<FrameOneCheckbox
								onToggle={toggleSingleUseUser}
								checked={addDiscountForm.isSingleUseUser}
							>
								Single Use (per user)
							</FrameOneCheckbox>
						</div>

						<div>
							<FrameOneCheckbox
								onToggle={toggleFirstOrder}
								checked={addDiscountForm.isFirstOrder}
							>
								First Order Only
							</FrameOneCheckbox>
						</div>

						<div>
							<FrameOneCheckbox
								onToggle={toggleIsReferralOnly}
								checked={addDiscountForm.isReferralOnly}
							>
								Referred Users Only
							</FrameOneCheckbox>
							<p className="form-tip">
								If toggled, then this promo will only be available to those who signed up to the platform
								with a referral code or a referral link.
							</p>
						</div>

						<div>
							<FrameOneCheckbox
								onToggle={toggleDiscountEnabled}
								checked={addDiscountForm.isEnabled}
							>
								Promo is active
							</FrameOneCheckbox>
							<p className="form-tip">
								Leave un-checked for a promo you do not want to go live yet. Can be edited freely
								later to disable or re-enable the same promo whenever desired.
							</p>
						</div>

						<div>
							<FrameOneCheckbox
								onToggle={toggleDisplayedGoFrens}
								checked={addDiscountForm?.isDisplayedGoFrens}
							>
								Display on GoFrens
							</FrameOneCheckbox>
							<p className="form-tip">
								If toggled, then this promo will display in the restaurant card on the search results
								page GoFrens section,
								if it's the highest priority promo that the customer qualifies for.
							</p>
						</div>

						<div>
							<label>
								Description
							</label>
							<input
								value={addDiscountForm.description}
								placeholder="Describe this discount to your customers..."
								onChange={inputOnChange("description")}
							/>
						</div>
					</FrameModalBody>

					<FrameModalFooter>
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="lightBlue"
							forwardProps={{
								type: "submit"
							}}
						>
							Add Promo
						</FrameButton>
					</FrameModalFooter>
				</form>
			</FrameOneModal>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props
		}
	});
}

type AddOrderDiscountModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddOrderDiscountModal);
