import React, {ReactElement} from "react";
import {ReactComponent as FileText} from "../svgs/file-text.svg";
import classNames from "classnames";

interface Props {
	name: string;
	version: string;
	className?: string;
}

function LoginProjectInfoLabel(props: Props): ReactElement {

	return (
		<div className={classNames("login-project-info-label", props.className)}>
			<FileText className="login-project-info-label_icon"/>

			<div className="login-project-info-label_text-container">
				<span className="login-project-info-label_text-container_name">
					{props.name}
				</span>

				<span className="login-project-info-label_text-container_version">
					{props.version}
				</span>
			</div>
		</div>
	);
}

export default LoginProjectInfoLabel;
