import React, {ReactElement, useEffect, useState} from "react";
import {Business, UtilsApi} from "@devour/client";

interface Props {
	business: Business;
}

function MerchantPayment(props: Props): ReactElement {

	const [stripeTestMode, setStripeTestMode] = useState<boolean>(false);

	useEffect(() => {
		getVersion().then().catch(console.error);
	}, []);

	async function getVersion() {
		const version = await new UtilsApi().version();
		let testMode: boolean = false;

		if (version.version.includes("Local") ||
			version.version.includes("develop") ||
			version.version.includes("staging1") ||
			version.version.includes("staging2")) {
			testMode = true;
		}
		setStripeTestMode(testMode);
	}

	return (
		<div className="merchant-payment">
			<h4>
				Payment
			</h4>
			<p>
				Currency: {props.business.currency}
			</p>
			<p>
				Stripe Connect:
				{(props.business.stripeAccountId) ? (
					<a
						href={(stripeTestMode) ?
							`https://dashboard.stripe.com/test/connect/accounts/${props.business.stripeAccountId}/activity` :
							`https://dashboard.stripe.com/connect/accounts/${props.business.stripeAccountId}/activity`
						}
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.business.stripeAccountId}
					</a>
				) : (
					"N/A"
				)}
			</p>
		</div>
	);
}

export default MerchantPayment;
