import React, {ReactElement, useEffect, useState} from "react";
import {FiMail, FiPhoneCall} from "react-icons/fi";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {DpayMenuOrders, MenuOrder, MenuOrdersApi, Token} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {csvExport} from "../../utils/csvExport";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";

interface StateProps {
    fullToken: Token;
}

interface Props {
    isOpen: boolean;
    toggle: () => void;
    dateStart: number;
    dateEnd: number;
}

function DpayCsvFilterModal(props: DpayCsvFilterProps): ReactElement {

    const [onChain, setOnChain] = useState<boolean>(false);
    const [vdpay, setVdpay] = useState<boolean>(false);
    const [split, setSplit] = useState<boolean>(false);

    const [magic, setMagic] = useState<boolean>(false);
    const [external, setExternal] = useState<boolean>(false);

    function handleFiltering(menuOrder: DpayMenuOrders) {
        if (onChain) {
            if (menuOrder.walletType === "Not on-chain") {
                return false;
            }
            if (magic) {
                return menuOrder.walletType === "Magic";
            }
            if (external) {
                return menuOrder.walletType === "External";
            }
            // return true regardless of wallet if none chosen
            return true;
        }
        if (vdpay) {
            return menuOrder.walletType === "Not on-chain";
        }
        if (split) {
            if (menuOrder.walletType === "Not on-chain" || menuOrder.vdpayUsed === 0) {
                return false;
            }
            if (magic) {
                return menuOrder.walletType === "Magic";
            }
            if (external) {
                return menuOrder.walletType === "External";
            }
            // return true regardless of wallet if none chosen
            return true;
        }
        if (magic) {
            return menuOrder.walletType === "Magic";
        }
        if (external) {
            return menuOrder.walletType === "External";
        }
        // no filters applied
        return true;
    }


    async function exportDPAYDataCsv() {

        let response = await new MenuOrdersApi(getConfig(props.fullToken)).generateMenuOrderDpayData(
            {
                createDpayCsvDataBody: {
                    dateStart: props.dateStart,
                    dateEnd: props.dateEnd
                }
            }
        );

        const menuOrders = response.menuOrders.filter((menuOrder) => handleFiltering(menuOrder));

        const headers = [
            "Order ID",
            "Created At",
            "Fiat Grand Total",
            "Total DPAY Used",
            "DPAY Total in Fiat",
            "VDPAY Used",
            "On-chain DPAY Used",
            "Wallet Type",
            "Etherscan Link"
        ];

        const filename: string =
            `dpay-report-${new Date(props.dateStart).toLocaleString().split(",")[0]}-
			${new Date(props.dateEnd).toLocaleString().split(",")[0]}.csv`;

        const formattedContent = menuOrders.map((order) => {
            return [
                order.id,
                order.createdAt,
                order.grandTotalFiat,
                order.dpayUsed,
                order.dpayTotalFiat,
                order.vdpayUsed,
                order.onChainUsed,
                order.walletType,
                order.etherscanLink
            ];
        });

        csvExport(formattedContent, headers, filename);

    }

    function handleNoFilterToggle() {
        setOnChain(false);
        setVdpay(false);
        setSplit(false);
        setMagic(false);
        setExternal(false);
    }

    function handleVdpayOnlyToggle() {
        if (!vdpay) {
            setVdpay(true);
            setOnChain(false);
            setSplit(false);
            setMagic(false);
            setExternal(false);
        } else {
            setVdpay(false);
        }
    }

    function handleOnChainOnlyToggle() {
        if (!onChain) {
            setOnChain(true);
            setVdpay(false);
            setSplit(false);

        } else {
            setOnChain(false);
        }
    }

    function handleSplitOnlyToggle() {
        if (!split) {
            setSplit(true);
            setVdpay(false);
            setOnChain(false);

        } else {
            setSplit(false);
        }
    }

    function handleMagicToggle() {
        if (!magic) {
            setMagic(true);
            setExternal(false);
            setVdpay(false);

        } else {
            setMagic(false);
        }
    }

    function handleExternalToggle() {
        if (!external) {
            setExternal(true);
            setMagic(false);
            setVdpay(false);

        } else {
            setExternal(false);
        }
    }

    function handleDisabled(option: string) {
        if (option === "vdpay") {
            return onChain || split || magic || external;

        } else if (option === "onchain") {
            return vdpay || split;

        } else if (option === "split") {
            return onChain || vdpay;

        } else if (option === "magic") {
            return vdpay || external;

        } else if (option === "external") {
            return vdpay || magic;

        } else {
            return true;
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="contact-modal"
        >
            <FrameModalHeader
                title="Select Filters"
                toggle={props.toggle}
            />

            <FrameModalBody className="contact-modal_body">
                <div>
                    <h5>Payment Methods</h5>
                    <FrameOneCheckbox
                        checked={vdpay}
                        onToggle={handleVdpayOnlyToggle}
                        disabled={handleDisabled("vdpay")}
                    >
                        VDPAY only (no on-chain payments used)
                    </FrameOneCheckbox>
                    <FrameOneCheckbox
                        checked={onChain}
                        onToggle={handleOnChainOnlyToggle}
                        disabled={handleDisabled("onchain")}
                    >
                        On-chain only (no VDPAY used)
                    </FrameOneCheckbox>
                    <FrameOneCheckbox
                        checked={split}
                        onToggle={handleSplitOnlyToggle}
                        disabled={handleDisabled("split")}
                    >
                        Split payments only (VDPAY and on-chain both used)
                    </FrameOneCheckbox>

                    <br />

                    <h5>Wallet Types</h5>
                    <FrameOneCheckbox
                        checked={magic}
                        onToggle={handleMagicToggle}
                        disabled={handleDisabled("magic")}
                    >
                        Magic wallet only
                    </FrameOneCheckbox>
                    <FrameOneCheckbox
                        checked={external}
                        onToggle={handleExternalToggle}
                        disabled={handleDisabled("external")}
                    >
                        External wallet only
                    </FrameOneCheckbox>
                </div>
                <br />
                <p>
                    *Selecting no filters will export data from the entire time period.
                </p>
            </FrameModalBody>

            <FrameModalFooter>
                <FrameButton
                    color="lightGray"
                    onClick={handleNoFilterToggle}
                >
                    Clear all filters
                </FrameButton>
                <FrameButton
                    color="darkBlue"
                    onClick={async () => await exportDPAYDataCsv()}
                >
                    Generate CSV
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            ...props,
            fullToken: store.metaStore.fullToken,
        }
    });
}

type DpayCsvFilterProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(DpayCsvFilterModal);