import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {
	Brand, BrandsApi, Business,
	OrderDiscount,
	OrderDiscountCalculation,
} from "@devour/client";
import FrameButton from "./buttons/FrameButton";
import FrameOneTableContainer from "./tables/FrameOneTableContainer";
import {formatDiscountType} from "../utils/formatDiscountType";
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";
import AddPromoToBrandModal from "./modals/AddPromoToBrandModal";
import RemoveBrandPromoCell from "./tables/cells/RemoveBrandPromoCell";
import ManageBrandPromoAvailabilityCell from "./tables/cells/ManageBrandPromoAvailabilityCell";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";

interface Props {
	brand: Brand;
	onDone: () => Promise<void>;
}

function ManageBrandPromos(props: Props): ReactElement {
	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

	const [showAddModal, setShowAddModal] = useState(false);
	const [selectedPromo, setSelectedPromo] = useState<OrderDiscount>(undefined);
	const [businesses, setBusinesses] = useState<Array<Business>>(undefined);

	useEffect(() => {
		void getBrandBusinesses();
	}, [props.brand?.id]);

	async function getBrandBusinesses(): Promise<void> {
		dispatch(incrementLoading());
		try {
			const res = await new BrandsApi(getConfig(fullToken)).getBrandBusinesses({
				id: props.brand.id,
				limit: Number.MAX_SAFE_INTEGER,
				offset: 0
			});

			setBusinesses(res?.brandBusinesses);
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}


	/**
	 * Show or hide the Add Modal.
	 *
	 */
	function toggleAddPromoModal(): void {
		setShowAddModal(s => !s);
	}

	/**
	 * When a new promo is added successfully, close the "Add Modal" & reset the brand data.
	 *
	 */
	function onDoneAddBusiness(): void {
		setShowAddModal(false);
		props.onDone();
	}

	function renderRemoveCell(promo: OrderDiscount): ReactNode {
		return (
			<RemoveBrandPromoCell
				promo={promo}
				brand={props.brand}
				onDone={props.onDone}
			/>
		);
	}

	function renderManagePromoAvailabilityCell(promo: OrderDiscount): ReactElement {
		return (
			<ManageBrandPromoAvailabilityCell
				brand={props.brand}
				promo={promo}
				businesses={businesses}
				onDone={props.onDone}
			/>
		)
	}

	return (
		<React.Fragment>
			<AddPromoToBrandModal
				isOpen={showAddModal}
				brand={props.brand}
				onClose={toggleAddPromoModal}
				onDone={onDoneAddBusiness}
			/>

			<div className="manage-brand-businesses">
				<div className="manage-brand-businesses_header">
					<h4>
						Associated Promos
					</h4>

					<p>
						Use the add-button below to add a new promos to this brand entity. You can remove the promo
						afterwards at any time with the "Remove from Brand" button in the table for that promo.
						Removing a promo from this brand would remove it from any existing maps & make it no longer
						possible to add to new maps being created.
					</p>

					<FrameButton
						color="lightBlue"
						onClick={toggleAddPromoModal}
					>
						Add New Promo to this Brand
					</FrameButton>
				</div>

				<div className="manage-brand-businesses_table">
					<FrameOneTableContainer
						data={props.brand.promos}
						// pagination={{
						// 	...orderDiscountsRes?.paginationInfo,
						// 	...omit(frontendPagination, "frontendRenderKey"),
						// } as PaginationInfo}
						// onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "internalName",
								headerValue: "Promo Name",
							},
							{
								key: "label",
								headerValue: "Promo Text",
							},
							{
								key: "type",
								headerValue: "Promo Type",
								valueFormatter: formatDiscountType,
							},
							{
								key: undefined,
								headerValue: "Promo Amount",
								valueFormatter: (discount: OrderDiscount) => {
									if (discount.calculation === OrderDiscountCalculation.PERCENTAGE) {
										return discount.amount + "%";
									}

									return "$" + discount.amount;
								},
							},
							{
								key: "isEnabled",
								headerValue: "Active",
								cellRender: (isEnabled: boolean) => {
									if (isEnabled) {
										return (
											<AiFillCheckCircle className="table-icon table-text-success"/>
										);
									}

									return (
										<AiFillCloseCircle className="table-icon  table-text-danger"/>
									);
								},
							},
							{
								key: "id",
								headerValue: "ID",
							},
							{
								key: undefined,
								headerValue: "Remove Promo from Brand",
								cellRender: renderRemoveCell,
							},
							{
								key: undefined,
								headerValue: "Manage Promo Availability To Businesses",
								cellRender: renderManagePromoAvailabilityCell,
							}
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

export default ManageBrandPromos;
