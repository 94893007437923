import React, {ReactElement} from "react";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameOneModal from "../modalComponents/FrameOneModal";
import FrameButton from "../../buttons/FrameButton";

interface ConfirmBanOrUnbanFromAllCommunitiesProps {
    isOpen: boolean;
    onConfirmation: () => Promise<void>;
    onClose: () => void;
    ban: boolean;
}

function ConfirmBanOrUnbanFromAllCommunities(props: ConfirmBanOrUnbanFromAllCommunitiesProps): ReactElement {

    function renderMessage() {
        if (props.ban) {
            return "Are you sure you would like to ban this user from all communities?";
        } else {
            return "Are you sure you would like to unban this user from all communities?";
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="user-experience-modal"
        >
            <FrameModalHeader
                toggle={props.onClose}
                title="Confirm Referral Removal"
            />

            <FrameModalBody className="manage-user-referrals_modal">
                {renderMessage()}

                <div className="manage-user-referrals_confirm-buttons">

                    <FrameButton
                        className="manage-user-referrals_confirm-button"
                        color="success"
                        onClick={props.onConfirmation}
                    >
                        Confirm
                    </FrameButton>

                    <FrameButton
                        className="manage-user-referrals_cancel-button"
                        color="danger"
                        onClick={props.onClose}
                    >
                        Cancel
                    </FrameButton>

                </div>

            </FrameModalBody>
        </FrameOneModal>
    );
}

export default ConfirmBanOrUnbanFromAllCommunities;
