import React, {ReactElement, useState} from "react";
import FrameButton from "../buttons/FrameButton";
import {Address, Business} from "@devour/client";
import {concatAddressFields} from "../../utils/formatAddress";
import {MdCheckBox, MdOutlineCheckBoxOutlineBlank} from "react-icons/md";
import classNames from "classnames";

interface Props {
    businessesToRender: Array<Business>;
    defaultAddress: Address;
    selectedBusinesses: Array<string>;
    onToggle: (business: Business) => void;
    onSetDefault: (business: Business) => void;
    updateBusinesses: (businesses: Array<string>) => void;
    onRemoveDefault: (business: Business) => void;
}

function BrandLocationSelection(props: Props): ReactElement {

    const [originalSelectedBusinesses, _setOriginalSelectedBusinesses] = useState<Array<string>>(props?.selectedBusinesses?.length ? props.selectedBusinesses : []);
    const [originalDefaultAddress, _setOriginalDefaultAddress] = useState<Address>(props.defaultAddress);

    function truncateText(maxLength: number, text: string): string {
        if (text.length < maxLength) {
            return text;
        }
        return `${text.slice(0, maxLength)}...`;
    }

    function onReset(): void {
        if (originalDefaultAddress) {
            const originalDefault = props.businessesToRender.find((business) => business?.address?.placeId === originalDefaultAddress?.placeId);
            if (originalDefault) {
                props.onSetDefault(originalDefault);
            }
        } else {
            props.onSetDefault(undefined);
        }
        props.updateBusinesses(originalSelectedBusinesses);
    }

    function onAddAll(): void {
        if (!props.selectedBusinesses.length) {
            props.onSetDefault(props.businessesToRender[0]);
        }
        props.updateBusinesses(props.businessesToRender.map((business => business.id)));
    }

    function renderLocationRow(business: Business): ReactElement {
        const isSelected = props.selectedBusinesses.indexOf(business.id) > -1;
        const isDefaultAddress = business.address.placeId === props?.defaultAddress?.placeId;
        const maxLength = 12;

        return (
            <div
                key={business.id}
                className="create-map-page_main-promo_table_body_row"
            >
                <div
                    className="create-map-page_main-promo_table_body_row_item merchant-brand-locations_name-width-adjust">
                    <div
                        className="create-map-page_main-promo_table_body_row_item_truncate merchant-brand-locations_flex-row">
                        <button
                            onClick={() => {
                                if (isDefaultAddress) {
                                    props.onRemoveDefault(business);
                                }
                                props.onToggle(business);
                            }}
                            className="reset-button"
                        >
                            {(isSelected) ? (
                                <MdCheckBox className="is-selected"/>
                            ) : (
                                <MdOutlineCheckBoxOutlineBlank className="is-not-selected"/>
                            )}
                        </button>

                        {truncateText(maxLength, business.name)}
                    </div>
                </div>

                <div
                    className="create-map-page_main-promo_table_body_row_item merchant-brand-locations_address-width-adjust">
                    <div className="create-map-page_main-promo_table_body_row_item_truncate">
                        {concatAddressFields(business.address)}
                    </div>
                </div>

                <div className="create-map-page_main-promo_table_body_row_item">

                    {isDefaultAddress ? (
                        <div className="merchant-brand-locations_default-button">
                            <p>Default</p>
                        </div>
                    ) : (
                        <FrameButton
                            color="purple-outline"
                            size="narrow"
                            className="merchant-brand-locations_select-default-button"
                            onClick={() => {
                                if (!isSelected) {
                                    props.onToggle(business);
                                }
                                props.onSetDefault(business);
                            }}
                        >
                            Set as Default
                        </FrameButton>
                    )}

                </div>

            </div>
        );
    }

    return (
        <div className="merchant-brand-title-description merchant-brand-margin-bottom-adjust">
            <div className="merchant-brand-title-description_content">

                <div className="merchant-brand-locations_top-header">
                    <div>
                        <p className="merchant-brand-title-description_content_title">
                            Add Locations
                            <span className="create-map-page_optional">
                                Required
                            </span>
                        </p>
                        <p className="merchant-brand-title-description_content_subtitle">
                            Choose your brand locations to display on the map.
                        </p>
                    </div>

                    <div className="merchant-brand-locations_top-header_right">
                        <FrameButton
                            color="gray"
                            size="narrow"
                            className="merchant-brand-locations_top-header_right_reset"
                            onClick={onReset}
                        >
                            Reset
                        </FrameButton>
                        <FrameButton
                            color="purple"
                            size="narrow"
                            onClick={onAddAll}
                        >
                            Add All Locations
                        </FrameButton>
                    </div>
                </div>

                <div className="create-map-page_main-promo_table">
                    <div className="create-map-page_main-promo_table_header">
                        <div className="create-map-page_main-promo_table_header_row">
                            <div className="create-map-page_main-promo_table_header_row_item">
                                Name
                            </div>
                            <div className="create-map-page_main-promo_table_header_row_item">
                                Address
                            </div>
                            <div className="create-map-page_main-promo_table_header_row_item">
                                Default Location
                            </div>
                        </div>
                    </div>

                    <div className="create-map-page_main-promo_table_body">
                        {props?.businessesToRender?.map(renderLocationRow)}
                    </div>
                </div>
                {!props.businessesToRender?.length && (
                    <p className="create-map-page_main-promo_no-promos">
                        No locations available.
                    </p>
                )}

            </div>

        </div>
    );
}

export default BrandLocationSelection;