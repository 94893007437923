import React, {ReactElement} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {NftTracker} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {NftsApi} from "@devour/client";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    tracker: NftTracker;
}

function ManageNftTrackersDeleteModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

    async function deleteNftTracker() {
        dispatch(incrementLoading());
        try {
            await new NftsApi(getConfig(fullToken)).deleteNftTracker({
                id: props.tracker.id
            });
            props.toggle();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="contact-modal"
        >
            <FrameModalHeader
                title="Delete Tracker"
                toggle={props.toggle}
            />

            <FrameModalBody className="contact-modal_body">
                <p>Are you sure you really want to delete this tracker? It will also delete all NFT ownerships and
                    webhooks associated with the tracker. This cannot be undone!</p>
            </FrameModalBody>

            <FrameModalFooter>
                <FrameButton
                    color="danger"
                    onClick={async () => await deleteNftTracker()}
                >
                    Confirm
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default ManageNftTrackersDeleteModal;
