import {ReactElement, useState} from "react";
import {Business, OnboardingThirdParty} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import EditRestaurantModal from "../../modals/EditRestaurantModal";
import ManageThirdPartiesDeleteModal from "../../modals/ManageThirdPartiesDeleteModal";

interface Props {
	onboardingThirdParty: OnboardingThirdParty;
	onDone: () => void;
}

function ManageThirdPartiesDeleteCell(props: Props): ReactElement {

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	function toggleDeleteModal(): void {
		setShowDeleteModal(s => !s);
	}

	function onDoneDelete(): void {
		setShowDeleteModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<ManageThirdPartiesDeleteModal
				isOpen={showDeleteModal}
				onboardingThirdParty={props.onboardingThirdParty}
				onClose={toggleDeleteModal}
				onDone={onDoneDelete}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="danger"
					onClick={toggleDeleteModal}
				>
					Delete Third Party
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default ManageThirdPartiesDeleteCell;
