import React, {ReactElement, useState} from "react";
import {Business, Token} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import EditBusinessMarketplaceFeeModal from "../../modals/EditBusinessMarketplaceFeeModal";

interface StateProps {
	fullToken: Token;
}

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function ManageBusinessMarketplaceFeeCell(props: ManageBusinessMarketplaceFeeCellProps): ReactElement {

	const [showModal, setShowModal] = useState<boolean>(false);

	function toggleEditModal(): void {
		setShowModal(s => !s);
	}

	function onDoneEdit(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<EditBusinessMarketplaceFeeModal
				isOpen={showModal}
				business={props.business}
				onClose={toggleEditModal}
				onDone={onDoneEdit}
			/>

			<div>
				<h4>
					Marketplace Fee
				</h4>
				<p>
					Delivery:
					{(props.business.marketplacePercentageDelivery || props.business.marketplacePercentageDelivery === 0) ?
						`${props.business.marketplacePercentageDelivery}%` :
						"Inherit"}
				</p>
				<p>
					Pickup:
					{(props.business.marketplacePercentagePickup || props.business.marketplacePercentagePickup === 0) ?
						`${props.business.marketplacePercentagePickup}%` :
						"Inherit"}
				</p>
				<FrameButton
					color="lightBlue"
					onClick={toggleEditModal}
				>
					Edit Marketplace Fee
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageBusinessMarketplaceFeeCellProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageBusinessMarketplaceFeeCell);
