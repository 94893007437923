import React, {ReactElement, useState} from "react";
import {BusinessTaxonomy} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import ManageTaxonomiesDeleteModal from "../../modals/ManageTaxonomiesDeleteModal";

interface Props {
	taxonomy: BusinessTaxonomy;
	onDone: () => void;
}

function ManageTaxonomiesDeleteCell(props: Props): ReactElement {

	const [showModal, setShowModal] = useState(false);

	function toggleShowToggleModal(): void {
		setShowModal(!showModal);
	}

	function onDoneModal(): void {
		setShowModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<ManageTaxonomiesDeleteModal
				isOpen={showModal}
				taxonomy={props.taxonomy}
				onClose={toggleShowToggleModal}
				onDone={onDoneModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="danger"
					onClick={toggleShowToggleModal}
				>
					Delete Category
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default ManageTaxonomiesDeleteCell;
