import React, {ReactElement} from "react";
import {MenuOrder} from "@devour/client";
import moment from "moment";

interface Props {
	menuOrder: MenuOrder;
}

function MenuOrderLog(props: Props): ReactElement {

	return (
		<div className="menu-order-log">
			<h3>
				Order Log
			</h3>
			<dl>
				{props.menuOrder.log.map((item, index) => (
					<React.Fragment key={`log-${index}`}>
						<dt>
							{item.status}
						</dt>
						<dd>
							{moment(item.date).format("MMM DD YYYY HH:mm")}
						</dd>
					</React.Fragment>
				))}
			</dl>
		</div>
	);
}

export default MenuOrderLog;
