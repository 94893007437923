import React, {ReactElement} from "react";
import {IconType} from "react-icons";
import {FiBox} from "react-icons/fi";
import classNames from "classnames";

export interface IconWithLabelProps {
	icon?: IconType;
	label: string;
	className?: string;
}

function IconWithLabel(props: IconWithLabelProps): ReactElement {

	const Icon = props.icon;

	return (
		<div className={classNames("icon-with-label", props.className)}>
			<Icon className="icon-with-label_icon"/>

			<span className="icon-with-label_label">
				{props.label}
			</span>
		</div>
	);
}

IconWithLabel.defaultProps = {
	icon: FiBox,
};

export default IconWithLabel;
