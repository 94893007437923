import {WithdrawStatus} from "@devour/client";

export function parseWithdrawStatus(status: WithdrawStatus): string {
	switch (status) {
		case WithdrawStatus.PENDING:
			return "Pending";
		case WithdrawStatus.COMPLETED:
			return "Completed";
		case WithdrawStatus.REJECTED:
			return "Rejeceted";
		case WithdrawStatus.CANCELLED:
			return "Cancelled";
		default:
			return status;
	}
}
