import React, {ReactElement} from "react";
import {FiMail, FiPhoneCall} from "react-icons/fi";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";

interface Props {
	isOpen: boolean;
	toggle: () => void;
}

function ContactModal(props: Props): ReactElement {

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.toggle}
			contentClassName="contact-modal"
		>
			<FrameModalHeader
				title="Contact Support"
				toggle={props.toggle}
			/>

			<FrameModalBody className="contact-modal_body">
				<p>Need to get in touch with Frame One Software? Text, call, or email us any time.</p>

				<div className="contact-modal_body_link-container">
					<a href="tel:6043195219">
						<FiPhoneCall/>
						(604) 319-5219
					</a>
				</div>

				<div className="contact-modal_body_link-container">
					<a
						href="mailto:christopher@frameonesoftware.com?subject=Frame One Software Support"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FiMail/>
						Christopher@frameonesoftware.com
					</a>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="purple"
					onClick={props.toggle}
				>
					Close
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

export default ContactModal;
