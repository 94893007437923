import {
	Business,
	BusinessesApi,
	Token,
} from "@devour/client";
import React, {ReactElement, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";

interface FormValues {
	marketplacePercentageDelivery: number;
	isDefaultMarketplacePercentageDelivery: boolean;
	marketplacePercentagePickup: number;
	isDefaultMarketplacePercentagePickup: boolean;
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	business: Business;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function EditBusinessMarketplaceFeeModal(props: EditBusinessMarketplaceFeeModalProps): ReactElement {

	const [formValues, setFormValues] = useState<FormValues>({
		marketplacePercentageDelivery: props.business.marketplacePercentageDelivery,
		isDefaultMarketplacePercentageDelivery: (!props.business.marketplacePercentageDelivery && props.business.marketplacePercentageDelivery !== 0),
		marketplacePercentagePickup: props.business.marketplacePercentagePickup,
		isDefaultMarketplacePercentagePickup: (!props.business.marketplacePercentagePickup && props.business.marketplacePercentagePickup !== 0),
	});

	/**
	 * Submit the updated restaurant/business into the system & close modal.
	 *
	 * @param e
	 */
	async function submitEditRestaurant(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {

			await new BusinessesApi(getConfig(props.fullToken)).updateBusinessMarketplaceFee({
				id: props.business.id,
				updateBusinessMarketplaceFeeBody: {
					marketplacePercentageDelivery: (formValues.isDefaultMarketplacePercentageDelivery) ? undefined : Number(formValues.marketplacePercentageDelivery),
					marketplacePercentagePickup: (formValues.isDefaultMarketplacePercentageDelivery) ? undefined : Number(formValues.marketplacePercentagePickup),
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle checkbox on change event.
	 *
	 */
	function toggleMarketplacePercentageDelivery(): void {
		setFormValues({
			...formValues,
			isDefaultMarketplacePercentageDelivery: !formValues.isDefaultMarketplacePercentageDelivery,
		});
	}

	function toggleMarketplacePercentagePickup(): void {
		setFormValues({
			...formValues,
			isDefaultMarketplacePercentagePickup: !formValues.isDefaultMarketplacePercentagePickup,
		});
	}

	/**
	 * Handle the number format input for the amount field on change.
	 *
	 * @param values
	 */
	function marketplacePercentageDeliveryOnChange(values): void {
		const newAmount = values.floatValue;

		setFormValues({
			...formValues,
			marketplacePercentageDelivery: newAmount,
		});
	}

	function marketplacePercentagePickupOnChange(values): void {
		const newAmount = values.floatValue;

		setFormValues({
			...formValues,
			marketplacePercentagePickup: newAmount,
		});
	}


	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="edit-business-marketplace-fee-modal"
		>
			<FrameModalHeader
				title="Edit Business Marketplace Fee"
				toggle={props.onClose}
			/>

			<form onSubmit={submitEditRestaurant}>
				<FrameModalBody className="edit-business-marketplace-fee-modal_body">

					<p>
						Edit marketplace fees for {props.business?.internalName || props.business?.name}
					</p>

					<div>
						<label>
							Marketplace fee percentage (Delivery)
						</label>
						<div>
							<FrameOneCheckbox
								onToggle={toggleMarketplacePercentageDelivery}
								checked={formValues.isDefaultMarketplacePercentageDelivery}
							>
								Use parent business's fee percentage for delivery orders,
								or default to system percentage if no parents have a custom fee set
							</FrameOneCheckbox>
						</div>
					</div>

					{(!formValues.isDefaultMarketplacePercentageDelivery) && (
						<div>
							<label>
								Custom Marketplace fee percentage (Delivery)
							</label>
							<NumberFormat
								placeholder="Marketplace fee percentage (Delivery) ..."
								suffix="%"
								value={formValues.marketplacePercentageDelivery}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={2}
								onValueChange={marketplacePercentageDeliveryOnChange}
							/>
						</div>
					)}

					<div>
						<label>
							Marketplace fee percentage (Pickup)
						</label>
						<div>
							<FrameOneCheckbox
								onToggle={toggleMarketplacePercentagePickup}
								checked={formValues.isDefaultMarketplacePercentagePickup}
							>
								Use parent business's fee percentage for pickup orders,
								or default to system percentage if no parents have a custom fee set
							</FrameOneCheckbox>
						</div>
					</div>

					{(!formValues.isDefaultMarketplacePercentagePickup) && (
						<div>
							<label>
								Custom Marketplace fee percentage (Pickup)
							</label>
							<NumberFormat
								placeholder="Marketplace fee percentage (Pickup) ..."
								suffix="%"
								value={formValues.marketplacePercentagePickup}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={2}
								onValueChange={marketplacePercentagePickupOnChange}
							/>
						</div>
					)}

				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Save Business
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type EditBusinessMarketplaceFeeModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(EditBusinessMarketplaceFeeModal);
