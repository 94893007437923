import React, {ReactElement} from "react";
import {Restaurant, Address} from "@devour/client";
import {Link} from "react-router-dom";

interface Props {
	restaurant: Restaurant;
}

export function addPotentiallyEmptyAddressSection(_address: Address, key: keyof Address): string {
	if (typeof _address[key] === "string") {
		return " " + _address[key];
	}

	return "";
}

function MenuOrderBusiness(props: Props): ReactElement {

	const addressString = props.restaurant.address?.line1
		+ addPotentiallyEmptyAddressSection(props.restaurant.address, "line2")
		+ addPotentiallyEmptyAddressSection(props.restaurant.address, "locality")
		+ addPotentiallyEmptyAddressSection(props.restaurant.address, "administrativeArea")
		+ addPotentiallyEmptyAddressSection(props.restaurant.address, "postalCode")
		+ addPotentiallyEmptyAddressSection(props.restaurant.address, "country");

	return (
		<div className="menu-order-business">
			<h3>
				Business
			</h3>
			<p>
				Business ID:
				<Link to={`/merchants/${props.restaurant.id}`}>
					{props.restaurant.id}
				</Link>
			</p>
			<p>
				Name: {props.restaurant.name}
			</p>
			<p>
				Internal name: {props.restaurant.internalName || "N/A"}
			</p>
			<p>
				Address:
				<a
					href={props.restaurant.address?.placeId ? `https://www.google.com/maps/place/?q=place_id:${props.restaurant.address?.placeId}` : `https://www.google.com/maps?q=${addressString}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{addressString}
				</a>
			</p>
			<p>
				Phone: {props.restaurant.phoneNumber.nationalNumber}
			</p>
			<p>
				Email: {props.restaurant.email}
			</p>
		</div>
	);
}

export default MenuOrderBusiness;
