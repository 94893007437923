import axios from "axios";

async function uploadToSignedURL(signedURL: string, file: File): Promise<void> {
	try {
		await axios({
			url: signedURL,
			headers: {
				"Content-Type": file.type,
				"Access-Control-Allow-Origin": "*",
			},
			method: "PUT",
			data: file,
			timeout: 0,
		});
	} catch (err) {
		throw {
			message: "There was an issue uploading the file.",
		}
	}
}

export default uploadToSignedURL;