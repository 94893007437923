import React, {PropsWithChildren, ReactElement} from "react";

interface Props {
	href: string;
}

function SocialIconButton(props: PropsWithChildren<Props>): ReactElement {

	return (
		<a
			href={props.href}
			target="_blank"
			rel="noopener noreferrer"
			className="social-icon-button"
		>
			{props.children}
		</a>
	);
}

export default SocialIconButton;
