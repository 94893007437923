import {ReactElement, useEffect, useState} from "react";
import {Business, BusinessesApi, BusinessKeys, MenusApi} from "@devour/client";
import React from "react";
import {isRestaurant} from "../../../typeguards/isRestaurant";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import getConfig from "../../../utils/getConfig";
import FrameButton from "../../buttons/FrameButton";
import ManageRestaurantsUpdateChowlyApiKeyModal from "../../modals/ManageRestaurantsUpdateChowlyApiKeyModal";
import moment from "moment";
import classNames from "classnames";
import ManageRestaurantsUpdateMealmeModal from "../../modals/ManageRestaurantsUpdateMealmeModal";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function ManageRestaurantsViewPosKeyCell(props: Props): ReactElement {

	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [showChowlyModal, setShowChowlyModal] = useState<boolean>(false);
	const [showMealmeModal, setShowMealmeModal] = useState<boolean>(false);
	const [apiKeys, setApiKeys] = useState<BusinessKeys>(undefined);

	useEffect(() => {
		if (isRestaurant((props.business))) {
			void getApiKeys();
		}
	}, [props.business]);

	async function getApiKeys(): Promise<void> {
		try {
			const res = await new BusinessesApi(getConfig(fullToken)).getBusinessKeys({
				id: props.business.id,
			});

			setApiKeys(res);
		} catch (e) {
			// props.dispatch(await addError(e));
		}
	}

	async function doReimportMenu(): Promise<void> {
		dispatch(incrementLoading());
		try {
			await new MenusApi(getConfig(fullToken)).reimportMenu({
				id: props.business.id,
			});
			props.onDone().then().catch();
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	function closeModal(): void {
		setShowChowlyModal(false);
		setShowMealmeModal(false);
	}

	function onDoneUpdateModal(): void {
		setShowChowlyModal(false);
		setShowMealmeModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageRestaurantsUpdateChowlyApiKeyModal
				isOpen={showChowlyModal}
				business={props.business}
				apiKeys={apiKeys}
				onClose={closeModal}
				onDone={onDoneUpdateModal}
			/>
			<ManageRestaurantsUpdateMealmeModal
				isOpen={showMealmeModal}
				business={props.business}
				apiKeys={apiKeys}
				onClose={closeModal}
				onDone={onDoneUpdateModal}
			/>

			<div className="frame-one-table-cell manage-restaurants-view-chowly-api-key-cell">
				{!isRestaurant(props.business) && (
					<p>
						N/A (Not a restaurant)
					</p>
				)}

				{isRestaurant(props.business) && (
					<div>
						{apiKeys === undefined ? (
							"Loading..."
						) : (
							<div className="manage-restaurants-view-chowly-api-key-cell_content">
								{(props.business.menuSource) && (
									<p>
										Menu Source: {props.business.menuSource.name}
									</p>
								)}

								<p>
									{(apiKeys.chowlyApi) ? `Chowly: ${apiKeys.chowlyApi}` :
										(apiKeys.itsaCheckmateAccess) ? "ItsaCheckmate: Connected" :
											(apiKeys.mealmeStoreId) ? `Mealme Store: ${apiKeys.mealmeStoreId}` :
												"Not set"}
								</p>

								{(apiKeys.mealmeMenuId) && (
									<p className="manage-restaurants-view-chowly-api-key-cell_mealme-menu-id">
										Mealme Menu: {apiKeys.mealmeMenuId}
									</p>
								)}

								{(props.business.menuImportCounter > 0) && (
									<p>
										Menu import counter: {props.business.menuImportCounter}
									</p>
								)}

								{(props.business.menuImportStart) && (
									<p
										className={classNames({
											"is-error": !props.business.menuImportEnd || props.business.menuImportStart > props.business.menuImportEnd,
										})}
									>
										Import
										Start: {moment(props.business.menuImportStart).format("MMM DD YYYY hh:mma")}
									</p>
								)}

								{(props.business.menuImportEnd) && (
									<p
										className={classNames({
											"is-error": props.business.menuImportStart > props.business.menuImportEnd,
										})}
									>
										Import End: {moment(props.business.menuImportEnd).format("MMM DD YYYY hh:mma")}
									</p>
								)}

								{(!apiKeys.isPos || apiKeys.chowlyApi) && (
									<div className="manage-restaurants-view-chowly-api-key-cell_content_btn-con">
										<FrameButton
											color="lightBlue"
											narrow={true}
											onClick={() => setShowChowlyModal(true)}
										>
											Edit Chowly key
										</FrameButton>
									</div>
								)}

								{(!apiKeys.isPos || apiKeys.mealmeStoreId) && (
									<div className="manage-restaurants-view-chowly-api-key-cell_content_btn-con">
										<FrameButton
											color="lightBlue"
											narrow={true}
											onClick={() => setShowMealmeModal(true)}
										>
											Edit Mealme ID
										</FrameButton>
									</div>
								)}

								{(apiKeys.isPos) && (
									<div className="manage-restaurants-view-chowly-api-key-cell_content_btn-con">
										<FrameButton
											color="lightBlue"
											narrow={true}
											onClick={doReimportMenu}
										>
											Reimport Menu
										</FrameButton>
										<FrameButton
											color="lightBlue"
											narrow={true}
											to={`/api-responses?business=${props.business.id}`}
										>
											View API Logs
										</FrameButton>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

export default ManageRestaurantsViewPosKeyCell;
