import React, {ReactElement, useEffect, useState} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {CommunityMember, NftGrouping, UsersApi, NftsApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {GoFrensApi, ManageCommunityInfo} from "@devour/client/dist";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    grouping: NftGrouping;
}

enum ManageMembershipAction {
    NONE,
    KICK,
    BAN
}

function ManageCommunityModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const [isCommunityActive, setIsCommunityActive] = useState<boolean>(undefined);
    const [communityInfo, setCommunityInfo] = useState<ManageCommunityInfo>(undefined);
    const [manageMembershipAction, setManageMembershipAction] = useState<ManageMembershipAction>(ManageMembershipAction.NONE);
    const [targetUser, setTargetUser] = useState<CommunityMember>(undefined);

    useEffect(() => {
        if (props.isOpen) {
            void getAllTrackersFromGrouping();
        } else {
            setIsCommunityActive(undefined);
            setCommunityInfo(undefined);
            setTargetUser(undefined);
            setManageMembershipAction(ManageMembershipAction.NONE);
        }
    }, [props.isOpen]);

    async function getAllTrackersFromGrouping(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new NftsApi(getConfig(fullToken)).getNftTrackerFromGrouping({
                id: props.grouping.id
            });
            let communityStatus = false;

            for (const tracker of res.nftTrackers) {
                if (!tracker.isIneligibleForLeaderboards) {
                    communityStatus = true;
                }
            }
            if (communityStatus) {
                await getCommunityInfo();
            }
            setIsCommunityActive(communityStatus);

        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function getCommunityInfo(): Promise<void> {
        const res = await new GoFrensApi(getConfig(fullToken)).getAdminCommunityInfo({
            id: props.grouping.id
        });
        setCommunityInfo(res.communityInfo);
    }

    async function handleRevokeMembership(): Promise<void> {
        try {
            dispatch(incrementLoading());
            if (manageMembershipAction === ManageMembershipAction.BAN) {
                await new UsersApi(getConfig(fullToken)).banUserFromCommunity({
                    banUserFromCommunityBody: {
                        userId: targetUser.id,
                        groupingId: props.grouping.id
                    }
                });
            } else {
                await new UsersApi(getConfig(fullToken)).removeUserCommunityMembership({id: targetUser.id});
            }
            props.toggle();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderCommunityMembers(): Array<ReactElement> {
        return communityInfo.communityMembers.map((member) => {
            return (
                <div className="manage-community-modal_members_row">
                    <p><strong>Rank:</strong> {member.userRank}</p>
                    <p><strong>Nickname:</strong> {member.nickname}</p>
                    <p><strong>Email:</strong> {member.email}</p>
                    <p><strong>Points Earned For Community:</strong> {member.pointsEarnedForCommunity}</p>
                    <div className="manage-community-modal_members_row_buttons">
                        <FrameButton
                            color="warning"
                            narrow={true}
                            onClick={() => {
                                setTargetUser(member);
                                setManageMembershipAction(ManageMembershipAction.KICK);
                            }}
                        >
                            Kick User
                        </FrameButton>
                        <FrameButton
                            color="danger"
                            narrow={true}
                            onClick={() => {
                                setTargetUser(member);
                                setManageMembershipAction(ManageMembershipAction.BAN);
                            }}
                        >
                            Ban User
                        </FrameButton>
                    </div>
                </div>
            )
        });
    }

    function renderBanOrKickMember(): ReactElement {
        return (
            <div>
                <p><strong>Rank:</strong> {targetUser.userRank}</p>
                <p><strong>Nickname:</strong> {targetUser.nickname}</p>
                <p><strong>Email:</strong> {targetUser.email}</p>
                <p><strong>Points Earned For Community:</strong> {targetUser.pointsEarnedForCommunity}</p>
                <br />
                <p>
                    {`Are you sure you want to ${manageMembershipAction === ManageMembershipAction.KICK ? "kick" : "ban"} this user? This action CANNOT be undone.`}
                </p>
                <br />
                <FrameButton
                    color="danger"
                    onClick={handleRevokeMembership}
                >
                    {`CONFIRM ${manageMembershipAction === ManageMembershipAction.KICK ? "KICK" : "BAN"}`}
                </FrameButton>
            </div>
        )
    }

    function renderModalStates(): ReactElement {
        if (isCommunityActive === false) {
            return (
                <p>
                    This community is not active for competition. It either has no trackers assigned to it, or all the
                    trackers were marked as ineligible.
                </p>
            );
        } else if (isCommunityActive && communityInfo) {

            return (
                <div>
                    <h4>Community Info</h4>
                    <p><strong>Current Rank:</strong> {communityInfo.communityRank} </p>
                    <p><strong>Total Points Accumulated:</strong> {communityInfo.totalPoints}</p>
                    <p><strong>Number of Members:</strong> {communityInfo.communityMembers.length}</p>

                    {(communityInfo.communityMembers.length > 0) && (
                      <>
                          <h4>Community Members</h4>
                          <p>
                              <strong>Kicking</strong> a user will remove their membership, but they are able to rejoin on the next month.
                          </p>
                          <p>
                              <strong>Banning</strong> a user will result in the user being banned forever, unless the ban is lifted.
                          </p>
                          <p>
                              If either option is selected, the user's contributions will not be considered
                              when points are counted at the end of the current month for community leaders.
                          </p>

                          <hr />

                          <div className="manage-community-modal_members">
                              {manageMembershipAction === ManageMembershipAction.NONE ? renderCommunityMembers() : renderBanOrKickMember()}
                          </div>
                      </>
                    )}

                </div>
            );
        }
        return null;
    }


    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="contact-modal"
        >
            <FrameModalHeader
                title="Manage Community"
                toggle={props.toggle}
            />

            <FrameModalBody className="contact-modal_body">
                {renderModalStates()}
            </FrameModalBody>

            <FrameModalFooter>
                <FrameButton
                    color="lightBlue"
                    onClick={props.toggle}
                >
                    Dismiss
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default ManageCommunityModal;
