import React, {ReactElement, useState} from "react";
import {Brand, BrandToMerchantPermissions, Business} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import EditBrandModal from "../../modals/EditBrandModal";
import BrandMerchantPermissionsEditModal from "../../modals/BrandMerchantPermissionsEditModal";
import ConfirmRemoveBrandBusinessModal from "../../modals/ConfirmRemoveBrandBusinessModal";

interface Props {
	business: Business;
	brand: Brand;
	onDone: () => void;
}

function RemoveBrandBusinessCell(props: Props): ReactElement {

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	function toggleConfirmModal(): void {
		setShowConfirmModal(s => !s);
	}

	function onDoneConfirm(): void {
		setShowConfirmModal(false);
		void props.onDone();
	}

	return (
		<React.Fragment>
			<ConfirmRemoveBrandBusinessModal
				isOpen={showConfirmModal}
				business={props.business}
				brand={props.brand}
				onClose={toggleConfirmModal}
				onDone={onDoneConfirm}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="danger"
					onClick={toggleConfirmModal}
				>
					Remove from Brand
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default RemoveBrandBusinessCell;