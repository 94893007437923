import React, {ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import {GetSEOResponse, SEOApi, Token, ApiError} from "@devour/client";
import getConfig from "../utils/getConfig";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError} from "../redux/meta/MetaActions";
import SEOUpdateSuccessModal from "../components/modals/SEOUpdateSuccessModal";
import {removePlaceIDFromURL} from "../utils/removePlaceIDFromURL";

interface StateProps {
    fullToken: Token;
}

function SEOConfigurationPage(props: SEOConfigProps): ReactElement {
    const [search, setSearch] = useState<string>("");
    const [url, setURL] = useState<string>("");
    const [tags, setTags] = useState<string[]>();
    const [showModal, setShowModal] = useState(false);

    function toggleSetShowModal() {
        setShowModal(s => !s);
    }

    function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
        setSearch(e.target.value);
    }

    function tagsOnChange(i: number): ChangeEventHandler<HTMLInputElement> {
        return (e: ChangeEvent<HTMLInputElement>) => {
            setTags(t => [].concat(t.slice(0, i), e.target.value, t.slice(i + 1, t.length)));
        }
    }

    function tagsOnDelete(i: number): () => void {
        return () => {
            setTags(t => [].concat(t.slice(0, i), t.slice(i + 1, t.length)));
        }
    }

    function validateURL(url: string): boolean {
        if (!url) {
            return false;
        }
        return url.startsWith(process.env.REACT_APP_WEB_URL);
    }

    async function handleSubmitSearch(): Promise<void> {
        try {
            if (!validateURL(search)) {
                throw new Error(`The url should start with ${process.env.REACT_APP_WEB_URL}`);
            }

            const searchValue = removePlaceIDFromURL(search);
            setURL(searchValue);

            const seoResponse: GetSEOResponse = await new SEOApi().getSeoTags({
                url: searchValue
            });
            if (seoResponse.urlEntryExists) {
                // Entry exists, so use it.
                setTags(seoResponse.tags);
            } else {
                // Cannot be found, create a new entry
                await new SEOApi(getConfig(props.fullToken)).createSeoTags({
                    createSEOBody: {
                        url: searchValue,
                        tags: [""]
                    }
                });
                setTags([""]);
            }
        } catch (e) {
            props.dispatch(await addError(e));
        }
    }

    function handleAddEmptyTag(): void {
        setTags(tags.concat([""]));
    }

    async function handleSaveAndSubmitTags(): Promise<void> {
        try {
            await new SEOApi(getConfig(props.fullToken)).updateSeoTags({
                updateSEOBody: {
                    url: url,
                    tags: tags.filter(Boolean)
                }
            });
            setShowModal(true);
        } catch (e) {
            props.dispatch(await addError(e));
        }
    }

    return (
        <React.Fragment>
            <SEOUpdateSuccessModal
                isOpen={showModal}
                onClose={toggleSetShowModal}
                url={url}
                tags={tags?.filter(Boolean)}
            />
            <div className="seo-configs">
                <PageHeader>
                    <div>
                        <h3>
                            Manage Unique SEO tags
                        </h3>

                        <p>
                            On this page you can set or modify unique SEO tags for a given url link.
                        </p>
                    </div>
                </PageHeader>
                <div className="seo-configs_cta">
                    <label>
                        Search
                    </label>
                    <input
                        placeholder="Search for url..."
                        onChange={searchOnChange}
                    />
                    <br/>
                    <FrameButton
                        color="lightBlue"
                        onClick={handleSubmitSearch}
                    >
                        Search
                    </FrameButton>
                    <br/>
                    {(url && tags) &&
                        <div className="seo-configs_cta">
                            <label>
                                Edit Tags
                            </label>
                            <div className="seo-configs_edits">
                                {tags.map((t, i) =>
                                    <div className="seo-configs_edit-block" key={"input box " + i}>
                                        <input
                                            value={t}
                                            placeholder="Add a new tag..."
                                            onChange={tagsOnChange(i)}
                                        />
                                        <FrameButton
                                            color="danger"
                                            onClick={tagsOnDelete(i)}
                                        >
                                            Delete
                                        </FrameButton>
                                    </div>
                                )}
                                <br/>
                                <div className="seo-configs_buttons">
                                    <FrameButton
                                        color="lightBlue"
                                        onClick={handleAddEmptyTag}
                                    >
                                        Add tag
                                    </FrameButton>
                                    <FrameButton
                                        color="success"
                                        onClick={handleSaveAndSubmitTags}
                                    >
                                        Save & Submit
                                    </FrameButton>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

function connector() {
    return connect((store: IStore): StateProps => {
        return {
            fullToken: store.metaStore.fullToken,
        }
    });
}

type SEOConfigProps = ConnectedProps<ReturnType<typeof connector>>;
export default connector()(SEOConfigurationPage);
