import React, {ReactElement, ReactNode} from "react";
import classNames from "classnames";
import CompanyPhoto from "./CompanyPhoto";
import IconWithLabel, {IconWithLabelProps} from "./IconWithLabel";

interface Props {
	src: string;
	title: string;
	onClick?: () => void;
	metrics?: Array<IconWithLabelProps>;
	className?: string;
}

function CompanyCard(props: Props): ReactElement {

	/**
	 * Render each metric.
	 *
	 * @param metric
	 * @param i
	 */
	function makeMetric(metric: IconWithLabelProps, i: number): ReactNode {
		return (
			<IconWithLabel
				key={`company-card_${props.title}_${metric.icon}_${i}`}
				icon={metric.icon}
				label={metric.label}
				className="company-card_bottom_metrics-container_metric"
			/>
		);
	}

	return (
		<div className={classNames("company-card", props.className)}>
			<div className="company-card_top">
				<div className="company-card_top_left">
					<CompanyPhoto src={props.src}/>
				</div>

				<div className="company-card_top_right">
					{/*{props.onClick && (*/}
					{/*	<IconButton*/}
					{/*		onClick={props.onClick}*/}
					{/*	/>*/}
					{/*)}*/}
				</div>
			</div>

			<div className="company-card_divider"/>

			<div className="company-card_bottom">
				<h4 className="company-card_bottom_title">
					{props.title}
				</h4>

				{props.metrics?.length > 0 && (
					<div className="company-card_bottom_metrics-container">
						{props.metrics?.map(makeMetric)}
					</div>
				)}
			</div>
		</div>
	);
}

export default CompanyCard;
