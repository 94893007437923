import React, {ChangeEvent, ChangeEventHandler, ReactElement, ReactNode, useEffect, useState} from "react";
import {
	Address,
	Business,
	BusinessesApi,
	GetBusinessesOwnRequest,
	GetBusinessesOwnResponse,
	GetBusinessOwnEnabledStatus,
	GetBusinessOwnActiveStatus,
	GetBusinessOwnPos,
	GetBusinessOwnMenuStatus,
	GetBusinessOwnSort,
	PaginationInfo,
	SortOrder,
	Token,
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import FrameButton from "../components/buttons/FrameButton";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import AddRestaurantModal from "../components/modals/AddRestaurantModal";
import moment from "moment/moment";
import {convertBooleanToString} from "../utils/convertBooleanToString";
import BusinessesEditCell from "../components/tables/cells/BusinessesEditCell";
import {omit} from "lodash";
import ManageRestaurantsToggleEnabledStatusCell
	from "../components/tables/cells/ManageRestaurantsToggleEnabledStatusCell";
import ManageRestaurantsToggleActiveStatusCell
	from "../components/tables/cells/ManageRestaurantsToggleActiveStatusCell";
import {Link} from "react-router-dom";
import ManageRestaurantsViewPosKeyCell from "../components/tables/cells/ManageRestaurantsViewPosKeyCell";

const defaultGetBusinessesOwnRequest: GetBusinessesOwnRequest = {
	isEnabled: GetBusinessOwnEnabledStatus.ENABLED,
	sort: GetBusinessOwnSort.Name,
	order: SortOrder.Asc,
}

interface StateProps {
	fullToken: Token;
}

function ManageRestaurants(props: ManageRestaurantsProps): ReactElement {

	const [restaurantsRes, setRestaurantsRes] = useState<GetBusinessesOwnResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddRestaurantModal, setShowAddRestaurantModal] = useState(false);
	const [getBusinessesOwnFilter, setGetBusinessesOwnFilter] = useState<GetBusinessesOwnRequest>(defaultGetBusinessesOwnRequest);
	const [search, setSearch] = useState<string>("");

	useEffect(() => {
		getRestaurants().then().catch();
	}, [JSON.stringify(frontendPagination), JSON.stringify(getBusinessesOwnFilter)]);

	async function getRestaurants(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessesOwn({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				isEnabled: getBusinessesOwnFilter.isEnabled === GetBusinessOwnEnabledStatus.ENABLED ? GetBusinessOwnEnabledStatus.ENABLED : getBusinessesOwnFilter.isEnabled === GetBusinessOwnEnabledStatus.DISABLED ? GetBusinessOwnEnabledStatus.DISABLED : undefined,
				isActive: getBusinessesOwnFilter.isActive === GetBusinessOwnActiveStatus.ACTIVE ? GetBusinessOwnActiveStatus.ACTIVE : getBusinessesOwnFilter.isActive === GetBusinessOwnActiveStatus.INACTIVE ? GetBusinessOwnActiveStatus.INACTIVE : undefined,
				sort: getBusinessesOwnFilter.sort,
				order: getBusinessesOwnFilter.order,
				search: getBusinessesOwnFilter.search,
				pos: getBusinessesOwnFilter.pos || undefined,
				menus: getBusinessesOwnFilter.menus || undefined,
			});

			setRestaurantsRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof GetBusinessesOwnRequest): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setFrontendPagination((_frontendPagination) => {
				return {
					...defaultFrontendPagination,
					frontendRenderKey: _frontendPagination.frontendRenderKey + 1,
				}
			});
			setGetBusinessesOwnFilter((_getBusinessesOwnFilter) => {
				return {
					..._getBusinessesOwnFilter,
					[key]: e.target.value,
				}
			});
		}
	}

	function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
		setSearch(e.target.value);
	}

	function handleSubmitSearch(key: keyof GetBusinessesOwnRequest): () => void {
		return () => {
			setFrontendPagination((_frontendPagination) => {
				return {
					...defaultFrontendPagination,
					frontendRenderKey: _frontendPagination.frontendRenderKey + 1,
				}
			});
			setGetBusinessesOwnFilter((_getBusinessesOwnFilter) => {
				return {
					..._getBusinessesOwnFilter,
					[key]: search,
				}
			});
		}
	}

	function toggleAddRestaurantModal(): void {
		setShowAddRestaurantModal(s => !s);
	}

	function onDoneAddRestaurant(): void {
		setShowAddRestaurantModal(false);
		getRestaurants().then().catch();
	}

	function renderToggleEnabledStatus(business: Business): ReactNode {
		return (
			<ManageRestaurantsToggleEnabledStatusCell
				business={business}
				onDone={getRestaurants}
			/>
		);
	}

	function renderToggleActiveStatus(business: Business): ReactNode {
		return (
			<ManageRestaurantsToggleActiveStatusCell
				business={business}
				onDone={getRestaurants}
			/>
		);
	}

	function renderEditCell(business: Business): ReactNode {
		return (
			<BusinessesEditCell
				business={business}
				onDone={getRestaurants}
			/>
		);
	}

	function renderPosCell(business: Business): ReactNode {
		return (
			<ManageRestaurantsViewPosKeyCell
				business={business}
				onDone={getRestaurants}
			/>
		);
	}

	return (
		<React.Fragment>
			<AddRestaurantModal
				isOpen={showAddRestaurantModal}
				onClose={toggleAddRestaurantModal}
				onDone={onDoneAddRestaurant}
			/>

			<div className="manage-restaurants">
				<PageHeader>
					<div>
						<h3>
							Manage Merchants
						</h3>

						<p>
							Here you can view and add merchants associated with merchant users.
						</p>

						<FrameButton
							color="lightBlue"
							onClick={toggleAddRestaurantModal}
						>
							Create New Merchant
						</FrameButton>
					</div>
				</PageHeader>

				<div className="manage-restaurants_filtering">
					<div className="manage-restaurants_filtering_row">
						<div className="manage-restaurants_filtering_row_col">
							<label>Active Status</label>
							<select
								value={getBusinessesOwnFilter.isActive}
								onChange={inputOnChange("isActive")}
							>
								<option value="">All</option>
								<option value={GetBusinessOwnActiveStatus.ACTIVE}>Active</option>
								<option value={GetBusinessOwnActiveStatus.INACTIVE}>Inactive</option>
							</select>
						</div>
						<div className="manage-restaurants_filtering_row_col">
							<label>Enabled Status</label>
							<select
								value={getBusinessesOwnFilter.isEnabled}
								onChange={inputOnChange("isEnabled")}
							>
								<option value="">All</option>
								<option value={GetBusinessOwnEnabledStatus.ENABLED}>Enabled</option>
								<option value={GetBusinessOwnEnabledStatus.DISABLED}>Disabled</option>
							</select>
						</div>
						<div className="manage-restaurants_filtering_row_col">
							<label>POS</label>
							<select
								value={getBusinessesOwnFilter.pos}
								onChange={inputOnChange("pos")}
							>
								<option value="">All</option>
								<option value={GetBusinessOwnPos.NONE}>None</option>
								<option value={GetBusinessOwnPos.CHOWLY}>Chowly</option>
								<option value={GetBusinessOwnPos.ITSACHECKMATE}>ItsaCheckmate</option>
								<option value={GetBusinessOwnPos.MEALME}>Mealme</option>
							</select>
						</div>
						<div className="manage-restaurants_filtering_row_col">
							<label>Menu Status</label>
							<select
								value={getBusinessesOwnFilter.menus}
								onChange={inputOnChange("menus")}
							>
								<option value="">All</option>
								<option value={GetBusinessOwnMenuStatus.ACTIVE}>Active</option>
								<option value={GetBusinessOwnMenuStatus.INACTIVE}>Error</option>
							</select>
						</div>
					</div>

					<hr/>

					<div className="manage-restaurants_filtering_row">
						<div className="manage-restaurants_filtering_row_col">
							<label>Sort By...</label>
							<select
								value={getBusinessesOwnFilter.sort}
								onChange={inputOnChange("sort")}
							>
								<option value={GetBusinessOwnSort.Name}>Name</option>
								<option value={GetBusinessOwnSort.CreatedAt}>Creation Date</option>
							</select>
						</div>

						<div className="manage-restaurants_filtering_row_col">
							<label>Sort Order...</label>
							<select
								value={getBusinessesOwnFilter.order}
								onChange={inputOnChange("order")}
							>
								<option value={SortOrder.Asc}>Ascending</option>
								<option value={SortOrder.Desc}>Descending</option>
							</select>
						</div>
					</div>

					<hr/>

					<div className="manage-restaurants_filtering_col">
						<label>
							Search
						</label>
						<input
							value={search}
							placeholder="Search for name of the business..."
							onChange={searchOnChange}
						/>
						<br/>
						<FrameButton
							color="lightBlue"
							onClick={handleSubmitSearch("search")}
						>
							Search
						</FrameButton>
						<br/>
					</div>
				</div>

				<div className="manage-restaurants_table">
					<FrameOneTableContainer
						data={restaurantsRes?.businesses}
						pagination={{
							...restaurantsRes?.paginationInfo,
							...omit(frontendPagination, "frontendRenderKey"),
						} as PaginationInfo}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: undefined,
								headerValue: "Business ID",
								cellRender: (business: Business) => {
									return (
										<Link to={`/merchants/${business.id}`}>
											{business.id}
										</Link>
									);
								}
							},
							{
								key: "name",
								headerValue: "Name",
							},
							{
								key: "internalName",
								headerValue: "Internal Name",
							},
							{
								key: undefined,
								headerValue: "POS",
								cellRender: renderPosCell,
							},
							{
								key: "isActive",
								headerValue: "Active",
								valueFormatter: convertBooleanToString,
							},
							{
								key: undefined,
								headerValue: "Toggle Active",
								cellRender: renderToggleActiveStatus,
							},
							{
								key: "address",
								headerValue: "Address",
								valueFormatter: (addy: Address) => {
									function addPotentiallyEmptyAddressSection(_address: Address, key: keyof Address): string {
										if (typeof _address[key] === "string") {
											return " " + _address[key];
										}

										return "";
									}

									const addressString = addy?.line1
										+ addPotentiallyEmptyAddressSection(addy, "line2")
										+ addPotentiallyEmptyAddressSection(addy, "locality")
										+ addPotentiallyEmptyAddressSection(addy, "administrativeArea")
										+ addPotentiallyEmptyAddressSection(addy, "postalCode")
										+ addPotentiallyEmptyAddressSection(addy, "country");

									return (
										<a
											href={addy?.placeId ? `https://www.google.com/maps/place/?q=place_id:${addy?.placeId}` : `https://www.google.com/maps?q=${addressString}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{addressString}
										</a>
									);
								}
							},
							{
								key: undefined,
								headerValue: "Edit Business",
								cellRender: renderEditCell,
							},
							{
								key: "isEnabled",
								headerValue: "Enabled",
								valueFormatter: convertBooleanToString,
							},
							{
								key: undefined,
								headerValue: "Toggle Enabled",
								cellRender: renderToggleEnabledStatus,
							},
							{
								key: "brandSize",
								headerValue: "Brand Size",
							},
							{
								key: "createdAt",
								headerValue: "Created",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: "updatedAt",
								headerValue: "Last Updated",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type ManageRestaurantsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageRestaurants);
