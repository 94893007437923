import React, {ReactElement} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {ReferralRewardThreshold, ReferralsApi, Token} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface StateProps {
    fullToken: Token;
}

interface Props {
    referralRewardThreshold: ReferralRewardThreshold;
    onClose: () => void;
    onDone: () => void;
    isOpen: boolean;
}

function DeleteReferralRewardsThreshholdModal(props: DeleteReferralRewardsThresholdModalProps): ReactElement {
    async function confirmDelete(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            await new ReferralsApi(getConfig(props.fullToken)).updateReferralThreshold({
                id: props.referralRewardThreshold.id,
                createReferralRewardThresholdBody: {
                    ...props.referralRewardThreshold,
                    disabled: true,
                    hidden: true,
                }
            });

            props.onDone();

        } catch (err) {
            props.dispatch(await addError(err));
        } finally {
            props.dispatch(decrementLoading());
        }
    }


    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
        >
            <FrameModalHeader
                toggle={props.onClose}
                title="Deleting Referral Rewards Threshold"
            />
            <FrameModalBody>
                <p>
                    This action will be permanent. Are you sure you want to delete the Referral Reward Threshold instead of disabling it?
                </p>
            </FrameModalBody>
            <FrameModalFooter>
                <FrameButton
                    color="lightBlue"
                    onClick={props.onClose}
                >
                    Cancel
                </FrameButton>
                <FrameButton
                    color="danger"
                    onClick={confirmDelete}
                >
                    Delete
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.metaStore.fullToken,
            ...props,
        }
    });
}

type DeleteReferralRewardsThresholdModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(DeleteReferralRewardsThreshholdModal);
