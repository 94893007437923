import React, {ReactElement} from "react";
import {Brand, BrandsApi, OrderDiscount} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../../redux/defaultStore";

interface Props {
	brand: Brand;
	promo: OrderDiscount;
	onDone: () => void;
}

function RemoveBrandPromoCell(props: Props): ReactElement {
	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

	/**
	 * Call api to disassociate the brand & business.
	 *
	 */
	async function confirmRemoval(): Promise<void> {
		dispatch(incrementLoading());

		try {
			await new BrandsApi(getConfig(fullToken)).deleteBrandPromo({
				removeBrandPromoBody: {
					brand: props.brand.id,
					promo: props.promo.id,
				},
			});

			props.onDone();
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}


	return (
		<div className="frame-one-table-cell">
			<FrameButton
				color="danger"
				onClick={confirmRemoval}
			>
				Remove from Brand
			</FrameButton>
		</div>
	);
}

export default RemoveBrandPromoCell;
