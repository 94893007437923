import React, {ReactElement, useEffect} from "react";
import {connect, ConnectedProps} from "react-redux";
import {useLocation} from "react-router-dom";
import {Token, TokensApi} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {logout} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

// Don't run token manager logic on these routes
// Login route excluded because it handles token expiration on its own.
const tokenManagerHiddenRoutes: string[] = ["", "/"];

const tokenCheckInterval: number = 60000;

interface StateProps {
	fullToken: Token;
}

function TokenManager(props: TokenManagerProps): ReactElement {

	const location = useLocation();

	useEffect(() => {
		if (!tokenManagerHiddenRoutes.includes(location.pathname)) {
			checkTokenValid().then().catch();
			const interval = setInterval(checkTokenValid, tokenCheckInterval);
			return () => clearInterval(interval);
		}
	}, [JSON.stringify(props.fullToken)]);

	async function checkTokenValid(): Promise<void> {
		// don't need to check if token doesn't exist
		if (!props.fullToken) {
			return;
		}

		try {
			const res = await new TokensApi(getConfig(props.fullToken)).checkTokenExpiration({
				tokenBody: {
					token: props.fullToken?.token,
				},
			});

			if (res.expired === true) {
				props.dispatch(logout());
			}
		} catch (e) {

		}
	}

	return null;
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type TokenManagerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(TokenManager);
