import React, {ReactElement} from "react";
import {Business, Token, BusinessesApi, UpdateBusinessImagesBody} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import ImageUploadCard from "../cards/ImageUploadCard";

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function MerchantImages(props: Props): ReactElement {
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

	async function onNewImage(assetId: string, field: keyof UpdateBusinessImagesBody): Promise<void> {
		const formBody: UpdateBusinessImagesBody = {};
		formBody[field] = assetId;
		await new BusinessesApi(getConfig(fullToken)).updateBusinessImages({
			id: props.business.id,
			updateBusinessImagesBody: formBody,
		});
		props.onDone();
	}

	async function onUnsetImage(field: keyof UpdateBusinessImagesBody): Promise<void> {
		// Just unset the image reference on the restaurant.
		const formBody: UpdateBusinessImagesBody = {};
		formBody[field] = null;
		await new BusinessesApi(getConfig(fullToken)).updateBusinessImages({
			id: props.business.id,
			updateBusinessImagesBody: formBody,
		});
		props.onDone();
	}

	return (
		<div className="merchant-images">
			<h4>
				Images
			</h4>

			<div className="merchant-images_card">
				<ImageUploadCard
					asset={props.business.icon}
					assetName={`Icon ${props.business.name}`}
					assetDescription={props.business.id}
					label="Icon"
					description="Ideal Image Aspect Ratio: 1:1"
					onNew={(assetId) => onNewImage(assetId, "icon")}
					onUnsetImage={() => onUnsetImage("icon")}
				/>
			</div>

			<div className="merchant-images_card">
				<ImageUploadCard
					asset={props.business.headerImage}
					assetName={`Header ${props.business.name}`}
					assetDescription={props.business.id}
					label="Header Image"
					description="Ideal Image Aspect Ratio: 10:3"
					onNew={(assetId) => onNewImage(assetId, "headerImage")}
					onUnsetImage={() => onUnsetImage("headerImage")}
				/>
			</div>

		</div>
	);
}

export default MerchantImages;
