import React, {ReactElement, useEffect, useState} from "react";
import {ChatMessage, MealmeApi, MenuOrder, MenuOrdersApi, Token} from "@devour/client";
import moment from "moment";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameButton from "../buttons/FrameButton";
import classNames from "classnames";

interface StateProps {
	fullToken: Token;
}

interface Props {
	menuOrder: MenuOrder;
}

function MenuOrderChat(props: MenuOrderChatProps): ReactElement {

	const [chatMessages, setChatMessages] = useState<Array<ChatMessage>>([]);

	useEffect(() => {
		void fetchChatLog();
	}, [props.menuOrder.mealmeChatId]);

	async function fetchChatLog(): Promise<void> {
		if (!props.menuOrder.mealmeChatId) {
			setChatMessages([]);
			return;
		}

		props.dispatch(incrementLoading());

		try {
			const res = await new MenuOrdersApi(getConfig(props.fullToken)).getMenuOrderChat({
				id: props.menuOrder.id,
			});
			setChatMessages(res.chatMessages);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}

	}

	/**
	 * Reload chat log from Mealme servers
	 *
	 */
	async function reloadChat(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new MealmeApi(getConfig(props.fullToken)).reloadMealmeChat({
				id: props.menuOrder.id,
			});
			await fetchChatLog();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<div className="menu-order-chat">
			<h3>
				Chat Log
			</h3>

			<ul className="menu-order-chat_list">
				{chatMessages.sort((a, b) => a.updatedAt - b.updatedAt).map((item) => (
					<li
						key={item.id}
						className={classNames("menu-order-chat_list_item", {
							"is-support-agent": item.isSupportAgent,
						})}
					>
						<div className="menu-order-chat_list_item_message">
							{item.message}
						</div>
						<div className="menu-order-chat_list_item_sender">
							{item.senderName}
						</div>
						<div className="menu-order-chat_list_item_date">
							{moment(item.updatedAt).format("MMM DD YYYY HH:mm")}
						</div>
					</li>
				))}
			</ul>

			{(props.menuOrder.mealmeChatId) ? (
				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="purple"
					onClick={reloadChat}
				>
					Reload chat log
				</FrameButton>
			) : (
				<p>
					Order does not have an associated chat.
				</p>
			)}

		</div>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type MenuOrderChatProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(MenuOrderChat);
