import React, {ChangeEventHandler, ReactElement, FormEvent, useEffect, useState} from "react";
import {
    UtilsApi,
    User,
    BusinessServiceAvailabilityBody,
    BusinessServiceAvailability,
    BusinessesApi,
    Token,
    GetBusinessOwnOperatingStatus, Business
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameButton from "../../components/buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameOneSwitchInput from "../../components/inputs/FrameOneSwitchInput";
import {isRestaurant} from "../../typeguards/isRestaurant";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import ServiceAvailabilitiesInput from "../inputs/ServiceAvailabilitiesInput";
import moment from "moment";

interface FormValues {
    timeZone: string;
    suspendUntil?: string;
    serviceAvailabilities: Array<BusinessServiceAvailability>;
}

const defaultValues: FormValues = {
    suspendUntil: undefined,
    timeZone: "",
    serviceAvailabilities: [],
};

interface StateProps {
    fullToken: Token;
    currentUser: User;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onDone: () => void;
    business: Business;
}

function EditBusinessHoursModal(props: BusinessCreatePageProps): ReactElement {
    const [businessBody, setBusinessBody] = useState<FormValues>(defaultValues);
    const [timeZones, setTimeZones] = useState<Array<string>>([]);

    useEffect(() => {
        fetchTimeZones().then();
    }, []);

    useEffect(() => {
        setBusinessBody({
            suspendUntil: (props.business.suspendUntil && Date.now() < props.business.suspendUntil) ? moment(props.business.suspendUntil).format("YYYY-MM-DDTHH:mm") : undefined,
            timeZone: props.business.timeZone,
            serviceAvailabilities: (isRestaurant(props.business)) ? props.business.serviceAvailabilities : [],
        });
    }, [props.business]);

    /**
     * Get the list of time zones from our api.
     */
    async function fetchTimeZones(): Promise<void> {
        try {
            const res = await new UtilsApi().getTimeZones();
            setTimeZones(res);
        } catch (e) {
            props.dispatch(await addError(e));
        }
    }

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof BusinessServiceAvailabilityBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
        return (e) => {
            setBusinessBody({
                ...businessBody,
                [key]: e.target.value,
            });
        }
    }

    async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        props.dispatch(incrementLoading());

        try {
            await new BusinessesApi(getConfig(props.fullToken)).updateBusinessServiceAvailability({
                id: props.business?.id,
                businessServiceAvailabilityBody: {
                    ...businessBody,
                    suspendUntil: (businessBody.suspendUntil) ? moment(businessBody.suspendUntil).unix() * 1000 : undefined,
                },
            });
            props.onDone();
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    /**
     * Handle the operating status inputs onChange.
     */
    function operatingStatusOnChange(): void {
        const in24Hours = moment().add(1, 'day').format("YYYY-MM-DDTHH:mm");
        setBusinessBody({
            ...businessBody,
            suspendUntil: (businessBody.suspendUntil) ? undefined : in24Hours,
        });
    }

    /**
     * Handle the service availabilities inputs onChange.
     */
    function serviceAvailabilitiesOnChange(newAvailabilities: Array<BusinessServiceAvailability>): void {
        setBusinessBody({
            ...businessBody,
            serviceAvailabilities: newAvailabilities,
        });
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
        >
            <FrameModalHeader
                title={`Edit ${props.business.name} Business Hours`}
                toggle={props.onClose}
            />

            <form onSubmit={onFormSubmit}>
                <FrameModalBody>
                    <div className="edit-business-hours-modal">
                        <div className="edit-business-hours-modal_content">
                            <div className="edit-business-hours-modal_field edit-business-hours-modal_timezone-container">
                                <label>Timezone *</label>
                                <select
                                    value={businessBody.timeZone}
                                    onChange={inputOnChange("timeZone")}
                                    required={true}
                                >
                                    <option value="">Select Timezone</option>
                                    {timeZones.map((zone, index) => (
                                        <option key={`timezone-${index}`} value={zone}>
                                            {zone}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div
                                className="edit-business-hours-modal_field edit-business-hours-modal_operating-status-container"
                            >
                                <label>Operating Status</label>
                                <p>
                                    Use this to override the normal operating hours to temporarily close the restaurant.
                                    For example, if you are closed for a holiday.
                                </p>
                                <FrameOneSwitchInput
                                    <GetBusinessOwnOperatingStatus>
                                    name="schedule"
                                    value={(businessBody.suspendUntil) ? GetBusinessOwnOperatingStatus.TEMPORARILYCLOSED : GetBusinessOwnOperatingStatus.NORMAL}
                                    onToggle={operatingStatusOnChange}
                                    options={[
                                        {
                                            render: "Operating Normally",
                                            value: GetBusinessOwnOperatingStatus.NORMAL,
                                        },
                                        {
                                            render: "Temporarily Closed",
                                            value: GetBusinessOwnOperatingStatus.TEMPORARILYCLOSED,
                                        },
                                    ]}
                                />
                            </div>

                            {(businessBody.suspendUntil) && (
                                <div
                                    className="edit-business-hours-modal_field edit-business-hours-modal_suspend-until-container"
                                >
                                    <label>Suspend Until</label>
                                    <p>
                                        Temporarily close the restaurant until the specified date and time.
                                    </p>
                                    <input
                                        placeholder="Scheduled Time"
                                        type="datetime-local"
                                        value={businessBody.suspendUntil}
                                        onChange={inputOnChange("suspendUntil")}
                                        min={moment().format("YYYY-MM-DDTHH:mm")}
                                        required={true}
                                    />
                                </div>
                            )}


                            <div className="edit-business-hours-modal_field edit-business-hours-modal_days-container">
                                <label>Normal Operating Hours</label>
                                <ServiceAvailabilitiesInput
                                    onUpdateServiceAvailabilities={serviceAvailabilitiesOnChange}
                                    serviceAvailabilities={businessBody.serviceAvailabilities}
                                />
                            </div>
                        </div>
                    </div>
                </FrameModalBody>
                <FrameModalFooter>
                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        className="edit-business-hours-modal_submit-button"
                        forwardProps={{type: "submit"}}
                    >
                        Submit
                    </FrameButton>
                </FrameModalFooter>
            </form>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): Props & StateProps => {
        return {
            currentUser: store.metaStore.currentUser,
            fullToken: store.metaStore.fullToken,
            ...props,
        }
    });
}

type BusinessCreatePageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(EditBusinessHoursModal);
