import React, {ReactElement} from "react";
import {Address, Business} from "@devour/client";
import {isRestaurant} from "../../typeguards/isRestaurant";
import {convertBooleanToString} from "../../utils/convertBooleanToString";

interface Props {
	business: Business;
}

export function addPotentiallyEmptyAddressSection(_address: Address, key: keyof Address): string {
	if (typeof _address[key] === "string") {
		return " " + _address[key];
	}

	return "";
}

function MerchantInfo(props: Props): ReactElement {

	const addressString = props.business.address?.line1
		+ addPotentiallyEmptyAddressSection(props.business.address, "line2")
		+ addPotentiallyEmptyAddressSection(props.business.address, "locality")
		+ addPotentiallyEmptyAddressSection(props.business.address, "administrativeArea")
		+ addPotentiallyEmptyAddressSection(props.business.address, "postalCode")
		+ addPotentiallyEmptyAddressSection(props.business.address, "country");

	return (
		<div className="merchant-info">
			<h4>
				Contact
			</h4>
			<p>
				Business ID: {props.business.id}
			</p>
			<p>
				Business Type: {props.business.type}
			</p>
			<p>
				Name: {props.business.name}
			</p>
			<p>
				Internal name: {props.business.internalName || "N/A"}
			</p>
			<p>
				Description: {props.business.description}
			</p>
			<p>
				Address:
				<a
					href={props.business.address?.placeId ? `https://www.google.com/maps/place/?q=place_id:${props.business.address?.placeId}` : `https://www.google.com/maps?q=${addressString}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{addressString}
				</a>
			</p>
			<p>
				Phone: {props.business.phoneNumber?.nationalNumber}
			</p>
			<p>
				Email:
				{(props.business.email) ? (
					<a
						href={`mailto:${props.business.email}?subject=Your DevourGO merchant account`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.business.email}
					</a>
				) : (
					<span>-</span>
				)}
			</p>

			{(isRestaurant(props.business) && (
				<React.Fragment>
					<p>
						Handoff options: {props.business.handoffOptions.join(", ")}
					</p>
					<p>
						Average prep time: {props.business.prepTime} mins
					</p>
					<p>
						Allow special instructions: {convertBooleanToString(props.business.specialInstructions)}
					</p>
				</React.Fragment>
			))}
		</div>
	);
}

export default MerchantInfo;
