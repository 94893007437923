import React, {ReactElement, ReactNode} from "react";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {isNil} from "lodash";
import {PaginationInfo} from "@devour/client";
import FrameOnePaginatorButton from "./FrameOnePaginatorButton";

interface Props extends PaginationInfo {
	onChangeOffset: (offset: number) => void;
}

function FrameOnePaginationButtons(props: Props): ReactElement {

	/**
	 * Handles page offset changes.
	 *
	 * @param offset
	 */
	function changePageHelper(offset: number): () => void {
		return () => props.onChangeOffset(offset);
	}

	/**
	 * Render each of the buttons for use in the paginator
	 *
	 * @param offset
	 * @param i
	 */
	function renderPaginatorButton(offset: number, i: number): ReactNode {
		return (
			<FrameOnePaginatorButton
				key={`table-paginator-offset_${offset}_${i}`}
				active={props.offset === i}
				onClick={props.offset !== i ? changePageHelper(i) : undefined}
			>
				{offset + 1}
			</FrameOnePaginatorButton>
		);
	}

	return (
		<div className="frame-one-paginator_buttons-container">
			<div className="frame-one-paginator_buttons-container_buttons">
				<FrameOnePaginatorButton
					active={false}
					onClick={props.enablePrevious ? changePageHelper(0) : undefined}
				>
					<FiChevronLeft/>
				</FrameOnePaginatorButton>

				{props.availableOffsets?.filter((i) => !isNil(i)).map(renderPaginatorButton)}

				<FrameOnePaginatorButton
					active={false}
					onClick={props.enableNext ? changePageHelper(props.offset + 1) : undefined}
				>
					<FiChevronRight/>
				</FrameOnePaginatorButton>
			</div>
		</div>
	);
}

export default FrameOnePaginationButtons;
