import React, {ReactElement, useState} from "react";
import {BusinessTaxonomy} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import ManageTaxonomiesDeleteModal from "../../modals/ManageTaxonomiesDeleteModal";
import ManageTaxonomiesEditModal from "../../modals/ManageTaxonomiesEditModal";

interface Props {
	taxonomy: BusinessTaxonomy;
	onDone: () => void;
}

function ManageTaxonomiesEditCell(props: Props): ReactElement {

	const [showModal, setShowModal] = useState(false);

	function toggleShowToggleModal(): void {
		setShowModal(!showModal);
	}

	function onDoneModal(): void {
		setShowModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<ManageTaxonomiesEditModal
				isOpen={showModal}
				taxonomy={props.taxonomy}
				onClose={toggleShowToggleModal}
				onDone={onDoneModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="warning"
					onClick={toggleShowToggleModal}
				>
					Edit Category
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default ManageTaxonomiesEditCell;
