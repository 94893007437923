import {ApiError, Token, User} from "@devour/client";

export interface IMetaStore {
	fullToken?: Token;
	loadingIncrement?: number;
	errors?: Array<ApiError>;
	sidebarOpen: boolean;
	globalContactModalVisible: boolean;
	currentUser: User;
	activeModals: number; // Helps manage nested modal render order.
}

export default {
	loadingIncrement: 0,
	errors: [],
	sidebarOpen: false,
	globalContactModalVisible: false,
	currentUser: undefined,
	activeModals: 0,
} as IMetaStore;
