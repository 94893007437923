import React, {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	AddBrandMerchantBody,
	BrandsApi, BrandToMerchantPermissions,
	Token, UpdateBrandMerchantBody,
} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import {getUserFullName} from "../../utils/getUserFullName";

const defaultUpdateBrandMerchantBody: UpdateBrandMerchantBody = {
	createMaps: undefined,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	permissions: BrandToMerchantPermissions;
	onClose: () => void;
	onDone: () => void;
}

function BrandMerchantPermissionsEditModal(props: AddMerchantToBrandModalProps): ReactElement {

	const [permissionsForm, setPermissionsForm] = useState<UpdateBrandMerchantBody>(defaultUpdateBrandMerchantBody);

	/**
	 * When modal opens reset the form to defaults & current brand.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setPermissionsForm({
				createMaps: props.permissions.createMaps,
			});
		}
	}, [props.isOpen]);

	/**
	 * Handle checkbox on change events.
	 *
	 */
	function togglePermission(permission: keyof Pick<AddBrandMerchantBody, "createMaps">): () => void {
		return () => {
			setPermissionsForm((p) => {
				return {
					...p,
					[permission]: !p[permission],
				}
			});
		}
	}

	/**
	 * Call api to update the permissions for the selected merchant on the selected brand.
	 *
	 * @param e
	 */
	async function submitUpdatedPermissions(e: React.FormEvent): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new BrandsApi(getConfig(props.fullToken)).updateBrandMerchantPermissions({
				id: props.permissions.id,
				updateBrandMerchantBody: {
					createMaps: permissionsForm.createMaps,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-brand-edit-merchant-modal"
		>
			<FrameModalHeader
				title={`Update Merchant User Permissions for the "${props.permissions.brand.name}" Brand`}
				toggle={props.onClose}
			/>

			<form onSubmit={submitUpdatedPermissions}>
				<FrameModalBody className="manage-brand-edit-merchant-modal_body">
					<div className="manage-brand-edit-merchant-modal_body_merchant-details">
						<h5>
							Merchant User
						</h5>
						<h4>
							{getUserFullName(props.permissions.merchant)}
						</h4>
					</div>

					<div className="manage-brand-edit-merchant-modal_body_permissions">
						<h5>Permissions</h5>
						<div className="manage-brand-edit-merchant-modal_body_permissions_list">
							<div>
								<label>
									Create Maps
								</label>
								<FrameOneCheckbox
									onToggle={togglePermission("createMaps")}
									checked={permissionsForm.createMaps}
								>
									{`Selected Merchant User can create maps on behalf of ${props.permissions.brand.name}`}
								</FrameOneCheckbox>
							</div>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit",
						}}
					>
						Update Permissions
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddMerchantToBrandModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(BrandMerchantPermissionsEditModal);
