import {
	Address,
	Business,
	BusinessesApi,
	BusinessTaxonomiesApi,
	BusinessType,
	UpdateBusinessAdmin,
	GeoJsonType,
	GetBusinessesOwnResponse,
	GetBusinessTaxonomiesResponse,
	GetUsersResponse,
	Merchant,
	PhoneNumberBody,
	Token,
	UpdateBusinessChowlyBody,
	UsersApi,
	UserType,
	HandoffOptions,
	ConfigResponse,
	UtilsApi,
	RestaurantBrandSize
} from "@devour/client";
import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {formatOptionalPhoneNumberForApiSubmission} from "../../utils/formatOptionalPhoneNumberForApiSubmission";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";
import FrameOnePhoneNumberInput from "../inputs/FrameOnePhoneNumberInput";
import FrameOneAddressInputGroup from "../inputs/FrameOneAddressInputGroup";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import SelectTaxonomiesModal from "./SelectTaxonomiesModal";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import {cloneDeep} from "lodash";

const defaultAddRestaurantForm: UpdateBusinessAdmin = {
	type: BusinessType.CORPORATE,
	parent: undefined,
	name: "",
	timeZone: undefined,
	currency: "USD",
	internalName: "",
	description: "",
	email: "",
	prepTime: 20,
	phoneNumber: {
		countryCode: "US",
		nationalNumber: "",
	},
	address: {
		line1: "",
		line2: "",
		locality: "",
		administrativeArea: "",
		postalCode: "",
		country: "",
		placeId: undefined,
		location: {
			type: GeoJsonType.Point,
			coordinates: [],
		},
	},
	taxonomies: [],
	handoffOptions: Object.values(HandoffOptions),
	merchantId: undefined,
	firstDeliveryId: "",
	distanceDeliveryMax: 0,
}

const kmInMile = 1.609;

const defaultChowlyApiKeyForm: UpdateBusinessChowlyBody = {
	chowlyApi: "",
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function AddRestaurantModal(props: AddRestaurantModalProps): ReactElement {

	const [restaurantForm, setRestaurantForm] = useState<UpdateBusinessAdmin>(defaultAddRestaurantForm);
	const [systemConfig, setSystemConfig] = useState<ConfigResponse>(undefined);
	const [chowlyApiKey, setChowlyApiKey] = useState<UpdateBusinessChowlyBody>(defaultChowlyApiKeyForm);
	const [merchantList, setMerchantList] = useState<GetUsersResponse>(undefined);
	const [selectedMerchantBusinesses, setSelectedMerchantBusinesses] = useState<{ merchant: string, businesses: GetBusinessesOwnResponse }>(undefined);
	const [loadingMerchantBusinesses, setLoadingMerchantBusinesses] = useState(false);
	const [taxonomiesRes, setTaxonomiesRes] = useState<GetBusinessTaxonomiesResponse>(undefined);
	const [timeZones, setTimeZones] = useState<Array<string>>(undefined);
	const [showTaxonomiesModal, setShowTaxonomiesModal] = useState(false);

	useEffect(() => {
		if (props.isOpen) {
			setRestaurantForm(defaultAddRestaurantForm);
			getDataForDropDowns().then().catch();
		}
	}, [props.isOpen]);

	useEffect(() => {
		if (restaurantForm.merchantId && restaurantForm.merchantId !== selectedMerchantBusinesses?.merchant) {
			getBusinessesBelongingToSelectedMerchant().then().catch();
		}
	}, [JSON.stringify(restaurantForm)]);

	/**
	 * Get the list of merchant accounts for inflating the drop-down/select input.
	 *
	 */
	async function getDataForDropDowns(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const merchantsRes = await new UsersApi(getConfig(props.fullToken)).getUsers({
				type: [UserType.MERCHANT],
				limit: 1000000,
				offset: 0,
			});

			const taxonomiesRes = await new BusinessTaxonomiesApi(getConfig(props.fullToken)).getBusinessTaxonomies();

			const timeZones = await new UtilsApi().getTimeZones();

			const config = await new UtilsApi().getConfig();

			setMerchantList(merchantsRes);
			setTaxonomiesRes(taxonomiesRes);
			setTimeZones(timeZones);
			setSystemConfig(config);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	async function getBusinessesBelongingToSelectedMerchant(): Promise<void> {
		setLoadingMerchantBusinesses(true);

		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessesOwn({
				limit: 100000,
				offset: 0,
				merchant: restaurantForm.merchantId,
			});

			setSelectedMerchantBusinesses({
				merchant: restaurantForm.merchantId,
				businesses: res,
			});
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			setLoadingMerchantBusinesses(false);
		}
	}

	/**
	 * Handle the merchant id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleMerchantIdOnChange(v: IReactSelectOption): void {
		setSelectedMerchantBusinesses(undefined);
		setRestaurantForm({
			...restaurantForm,
			parent: undefined,
			merchantId: v?.value,
		});
	}

	/**
	 * Handle the parent business id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleParentBusinessIdOnChange(v: IReactSelectOption): void {
		setRestaurantForm({
			...restaurantForm,
			parent: v?.value,
		});
	}

	/**
	 * Handle the timezone react-select input onChange event.
	 *
	 * @param v
	 */
	function handleTimeZoneOnChange(v: IReactSelectOption): void {
		setRestaurantForm({
			...restaurantForm,
			timeZone: v?.value,
		});
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<UpdateBusinessAdmin, "parent" | "isEnabled" | "phoneNumber" | "address" | "taxonomies" | "merchantId">): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setRestaurantForm({
				...restaurantForm,
				[key]: e.target.value,
			});
		}
	}

	function inputOnChangeChowly(e: React.ChangeEvent<HTMLInputElement>): void {
		setChowlyApiKey({
			...chowlyApiKey,
			chowlyApi: e.target.value,
		})
	}

	/**
	 * Handle the phone number input onChange.
	 *
	 * @param phoneNumber
	 */
	function phoneNumberOnChange(phoneNumber: PhoneNumberBody): void {
		setRestaurantForm({
			...restaurantForm,
			phoneNumber,
		});
	}

	/**
	 * Handle the address inputs onChange.
	 *
	 * @param address
	 */
	function addressOnChange(address: Address): void {
		setRestaurantForm({
			...restaurantForm,
			address,
		});
	}

	/**
	 * Handle all handoff input onChange events.
	 *
	 * @param handoff
	 */
	function handoffOnToggle(handoff: HandoffOptions): void {
		// Attempt to find this option in the checked items array
		const newHandoff: Array<HandoffOptions> = cloneDeep(restaurantForm.handoffOptions);

		const thisIndex = newHandoff.indexOf(handoff);

		if (thisIndex > -1) {
			// Remove this option from list of selected items
			newHandoff.splice(thisIndex, 1);
		} else {
			newHandoff.push(handoff);
		}

		setRestaurantForm({
			...restaurantForm,
			handoffOptions: newHandoff,
		});
	}

	/**
	 * Submit the new restaurant/business into the system & close modal.
	 *
	 * @param e
	 */
	async function submitNewRestaurant(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const createBusinessRequest = {
				type: restaurantForm.type,
				parent: restaurantForm.parent,
				name: restaurantForm.name,
				internalName: restaurantForm.internalName,
				timeZone: restaurantForm.timeZone,
				currency: restaurantForm.currency,
				description: restaurantForm.description,
				email: restaurantForm.email,
				phoneNumber: formatOptionalPhoneNumberForApiSubmission(restaurantForm.phoneNumber),
				address: restaurantForm.address,
				taxonomies: restaurantForm.taxonomies,
				handoffOptions: restaurantForm.handoffOptions,
				prepTime: (restaurantForm.prepTime) ? Number(restaurantForm.prepTime) : undefined,
				merchantId: restaurantForm.merchantId,
			};
			const newBusiness = await new BusinessesApi(getConfig(props.fullToken)).createBusiness({
				createBusinessBody: createBusinessRequest,
			});

			await new BusinessesApi(getConfig(props.fullToken)).updateBusinessAdmin({
				id: newBusiness.id,
				updateBusinessAdmin: {
					...createBusinessRequest,
					firstDeliveryId: restaurantForm.firstDeliveryId,
					distanceDeliveryMax: (restaurantForm.distanceDeliveryMax) ? Number(restaurantForm.distanceDeliveryMax) * kmInMile * 1000 : 0,
					brandSize: restaurantForm.brandSize,
				},
			});

			if (restaurantForm.type === BusinessType.RESTAURANT && chowlyApiKey.chowlyApi.length > 0) {
				await new BusinessesApi(getConfig(props.fullToken, process.env.REACT_APP_BACKEND_REST_POWERFUL_URL)).updateBusinessChowly({
					id: newBusiness.id,
					updateBusinessChowlyBody: {
						chowlyApi: chowlyApiKey.chowlyApi,
					},
				});
			}

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function toggleTaxonomiesModal(): void {
		setShowTaxonomiesModal(s => !s);
	}

	/**
	 * For when the admin selects a taxonomy from the modal designed for such.
	 * If the taxonomy is already selected, it will be removed from the selected list,
	 * if the taxonomy is not already selected then it will be added to the list.
	 *
	 * @param id
	 */
	function handleSelectTaxonomy(id: string): void {
		const currentlySelectedTaxonomies = restaurantForm.taxonomies;
		const foundIndex = currentlySelectedTaxonomies.indexOf(id);
		if (foundIndex > -1) {
			currentlySelectedTaxonomies.splice(foundIndex, 1);
		} else {
			currentlySelectedTaxonomies.push(id);
		}

		setRestaurantForm(r => {
			return {
				...r,
				taxonomies: currentlySelectedTaxonomies,
			}
		});
	}

	const merchantIdOptions = makeReactSelectOptions<Merchant>(merchantList?.users as Array<Merchant>, "id", (merchant) => merchant.firstName + " " + merchant.lastName);
	const parentBusinessOptions = makeReactSelectOptions<Business>(selectedMerchantBusinesses?.businesses?.businesses, "id", "name");
	const timeZoneOptions = makeReactSelectOptions<{ id: string }>(timeZones?.map(t => {
		return {id: t}
	}), "id", "id");

	return (
		<React.Fragment>
			<SelectTaxonomiesModal
				isOpen={showTaxonomiesModal}
				taxonomies={taxonomiesRes?.businessTaxonomies}
				selectedTaxonomies={restaurantForm.taxonomies}
				onSelect={handleSelectTaxonomy}
				onClose={toggleTaxonomiesModal}
			/>

			<FrameOneModal
				isOpen={props.isOpen}
				toggle={props.onClose}
				contentClassName="manage-restaurants-add-modal"
			>
				<FrameModalHeader
					title="Add New Business"
					toggle={props.onClose}
				/>

				<form onSubmit={submitNewRestaurant}>
					<FrameModalBody className="manage-restaurants-add-modal_body">
						<div>
							<label>
								Merchant Account*
							</label>
							<FrameOneReactSelect
								name="Merchant Account"
								placeholder="Select Merchant Account..."
								isClearable={true}
								isLoading={merchantList === undefined}
								isDisabled={merchantList === undefined}
								value={merchantIdOptions.find(m => m.value === restaurantForm.merchantId)}
								onChange={handleMerchantIdOnChange}
								options={merchantIdOptions}
							/>
						</div>

						<div>
							<label>
								Parent Business
							</label>
							<FrameOneReactSelect
								key={restaurantForm.merchantId}
								name="Parent Business"
								placeholder="Select Parent Business..."
								isClearable={true}
								isLoading={loadingMerchantBusinesses}
								isDisabled={selectedMerchantBusinesses?.businesses?.businesses?.length < 1 || selectedMerchantBusinesses?.businesses === undefined}
								value={parentBusinessOptions.find(b => b.value === restaurantForm.parent)}
								onChange={handleParentBusinessIdOnChange}
								options={parentBusinessOptions}
							/>
						</div>

						<div>
							<label>
								Business Type
							</label>
							<select
								value={restaurantForm.type}
								onChange={inputOnChange("type")}
							>
								<option value={BusinessType.CORPORATE}>Corporate</option>
								<option value={BusinessType.RESTAURANT}>Restaurant</option>
							</select>
						</div>

						<div>
							<label>
								Business Name
							</label>
							<input
								value={restaurantForm.name}
								placeholder="Business Name..."
								onChange={inputOnChange("name")}
							/>
						</div>

						<div>
							<label>
								Internal Name
							</label>
							<input
								value={restaurantForm.internalName}
								placeholder="Internal Name..."
								onChange={inputOnChange("internalName")}
							/>
						</div>

						{(restaurantForm.type === BusinessType.RESTAURANT) && (
							<React.Fragment>
								<div>
									<label>Handoff Options *</label>
									{Object.values(HandoffOptions).map((option) => (
										<FrameOneCheckbox
											key={option}
											onToggle={() => handoffOnToggle(option)}
											checked={restaurantForm.handoffOptions.includes(option)}
										>
											{option}
										</FrameOneCheckbox>
									))}
								</div>
								<div>
									<label>Brand Size</label>
									<select
										value={restaurantForm.brandSize}
										onChange={inputOnChange("brandSize")}
									>
										<option value="">None</option>
										<option value={RestaurantBrandSize.LOCAL}>Local</option>
										<option value={RestaurantBrandSize.REGIONAL}>Regional</option>
										<option value={RestaurantBrandSize.NATIONAL}>National</option>
									</select>
								</div>
								<div>
									<label>
										Average Prep Time
									</label>
									<input
										type="number"
										value={restaurantForm.prepTime}
										placeholder="Average Order Prep Time..."
										onChange={inputOnChange("prepTime")}
									/>
								</div>
								{(restaurantForm.handoffOptions.includes(HandoffOptions.DELIVERY)) && (
									<div>
										<label>
											Max Delivery Distance
										</label>
										<input
											type="number"
											value={restaurantForm.distanceDeliveryMax}
											placeholder="Max Delivery Distance..."
											onChange={inputOnChange("distanceDeliveryMax")}
											min={0}
											max={systemConfig?.distanceDeliveryMax / kmInMile / 1000}
											step={0.1}
										/>
										<p className="form-tip">
											Distance is in miles.
											Maximum is {systemConfig?.distanceDeliveryMax / kmInMile / 1000} miles.
											Leave at "0" to default to the maximum distance.
										</p>
									</div>
								)}
							</React.Fragment>
						)}

						<div>
							<label>
								Business Description
							</label>
							<input
								value={restaurantForm.description}
								placeholder="Business Description..."
								onChange={inputOnChange("description")}
								type="textarea"
							/>
						</div>

						<div>
							<label>
								Business Email
							</label>
							<input
								value={restaurantForm.email}
								placeholder="Business Email..."
								onChange={inputOnChange("email")}
								type="email"
							/>
						</div>

						<div>
							<label>Phone Number</label>
							<FrameOnePhoneNumberInput
								value={restaurantForm.phoneNumber}
								onChange={phoneNumberOnChange}
							/>
						</div>

						<div>
							<label>Address</label>
							<FrameOneAddressInputGroup
								value={restaurantForm.address}
								onChange={addressOnChange}
							/>
						</div>

						<div>
							<label>
								Time Zone
							</label>
							<FrameOneReactSelect
								name="Time Zone"
								placeholder="Select Time Zone..."
								isClearable={true}
								isLoading={timeZones === undefined}
								isDisabled={timeZones === undefined}
								value={timeZoneOptions.find(t => t.value === restaurantForm.timeZone)}
								onChange={handleTimeZoneOnChange}
								options={timeZoneOptions}
							/>
						</div>

						{restaurantForm.type === BusinessType.RESTAURANT && (
							<div>
								<label>
									Chowly API Key
								</label>
								<input
									value={chowlyApiKey.chowlyApi}
									placeholder="Chowly API Key..."
									onChange={inputOnChangeChowly}
								/>
							</div>
						)}

						<div>
							<label>
								First Delivery Vendor ID
							</label>
							<input
								value={restaurantForm.firstDeliveryId}
								placeholder="First Delivery Vendor ID..."
								onChange={inputOnChange("firstDeliveryId")}
								type="text"
							/>
						</div>

						<div>
							<label>
								Categories
							</label>
							{restaurantForm.taxonomies.length < 1 ? (
								<p>
									No categories selected.
								</p>
							) : (
								<ul>
									{restaurantForm.taxonomies.map((t, i) => {
										return (
											<li key={`taxonomy_${i}`}>
												{taxonomiesRes?.businessTaxonomies?.find(bt => bt.id === t).name}
											</li>
										);
									})}
								</ul>
							)}

							<FrameButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="lightBlue"
								onClick={toggleTaxonomiesModal}
								forwardProps={{
									type: "button"
								}}
							>
								Select Categories
							</FrameButton>
						</div>
					</FrameModalBody>

					<FrameModalFooter>
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="lightBlue"
							forwardProps={{
								type: "submit"
							}}
						>
							Add Business
						</FrameButton>
					</FrameModalFooter>
				</form>
			</FrameOneModal>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddRestaurantModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddRestaurantModal);
