import React, {ReactElement, useEffect, useState} from "react";
import {
    NftGrouping, NftTracker,
} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import {IStore} from "../../redux/defaultStore";
import {useDispatch, useSelector} from "react-redux";
import {NftsApi} from "@devour/client/dist";
import getConfig from "../../utils/getConfig";

interface Props {
    isOpen: boolean;
    grouping: NftGrouping;
    onClose: () => void;
    onDone: () => void;
}

enum ModalStates {
    SHOW_TRACKERS,
    CONFIRMATION
}

function ManageNftGroupingsEditEligibilityModal(props: Props): ReactElement {

    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const dispatch = useDispatch();

    const [trackers, setTrackers] = useState<Array<NftTracker>>(undefined);
    const [modalState, setModalState] = useState<ModalStates>(ModalStates.SHOW_TRACKERS);
    const [activeTracker, setActiveTracker] = useState<NftTracker>(undefined);

    useEffect(() => {
        if (props.isOpen) {
            void getAllTrackersFromGrouping();
        } else {
            setModalState(ModalStates.SHOW_TRACKERS);
            setActiveTracker(undefined);
        }
    }, [props.isOpen]);

    async function getAllTrackersFromGrouping(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new NftsApi(getConfig(fullToken)).getNftTrackerFromGrouping({
                id: props.grouping.id
            });
            setTrackers(res.nftTrackers);

        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderModalContent() {
        if (modalState === ModalStates.SHOW_TRACKERS) {
            return renderTrackers();
        } else {
            return renderConfirmation();
        }
    }

    function renderConfirmation(): ReactElement {
        return (
            <div>
                <p>
                    {
                        `Are you sure you want to toggle? ${activeTracker.isIneligibleForLeaderboards ?
                            "Confirming will make this tracker eligible for competition." :
                            "This will remove ALL active users from the community who do not have another eligible NFT."}`
                    }
                </p>
                {!activeTracker.isIneligibleForLeaderboards && <p><strong>This action CANNOT be undone!</strong></p>}
                <FrameButton
                    <React.ButtonHTMLAttributes<HTMLButtonElement>>
                    color="danger"
                    onClick={() => toggleEligibility(activeTracker)}
                    className="manage-groupings-edit-eligibilities-modal_body_confirm-btn"
                >
                    Confirm
                </FrameButton>
            </div>
        )
    }

    function renderTrackers(): Array<ReactElement> {
        return trackers.map((tracker) => {
            return (
                <div className="manage-groupings-edit-eligibilities-modal_body_tracker">
                    <p>{`${tracker.name} - ${tracker.isIneligibleForLeaderboards ? "INACTIVE" : "ACTIVE"}`}</p>
                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="danger"
                        narrow={true}
                        onClick={() => setActiveTrackerForToggle(tracker)}
                    >
                        Toggle Eligibility
                    </FrameButton>
                </div>
            )
        })
    }

    function setActiveTrackerForToggle(tracker: NftTracker) {
        setActiveTracker(tracker);
        setModalState(ModalStates.CONFIRMATION);
    }

    async function toggleEligibility(tracker: NftTracker): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new NftsApi(getConfig(fullToken)).updateNftTracker({
                updateNftTrackerBody: {
                    trackerId: tracker.id,
                    groupingId: tracker.groupingId,
                    contractAddress: tracker.contractAddress || undefined,
                    evmChain: tracker.evmChain,
                    name: tracker.name,
                    openSea: tracker.openSea || undefined,
                    overrideSupportsInterface: tracker.overrideSupportsInterface,
                    isIneligibleForLeaderboards: !tracker.isIneligibleForLeaderboards
                }
            });
            props.onClose();
            props.onDone();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="manage-groupings-edit-eligibilities-modal"
        >
            <FrameModalHeader
                toggle={props.onClose}
                title={`Editing Eligibility for ${props.grouping?.name}`}
            />

            <FrameModalBody className="manage-groupings-edit-eligibilities-modal_body">
                <p>
                    You can edit which NFTs are eligible for competition here. Marking a tracker that uses this grouping
                    as its parent prevents holders from participating in GoFrens Leaderboard communities. If all the
                    trackers are marked as ineligible, this entire grouping will be hidden as a community.
                </p>
                <hr/>
                {trackers && (
                    <>
                        {trackers.length === 0 ? (
                            <p><strong>There are no trackers using this grouping.</strong></p>
                        ) : (
                            renderModalContent()
                        )}
                    </>
                )}

            </FrameModalBody>

        </FrameOneModal>
    );
}

export default ManageNftGroupingsEditEligibilityModal;
