import React, {ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Asset, AssetsApi, Brand, BrandsApi, BusinessTaxonomiesApi, CreateBrandBody, Token} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FileInputButton from "../inputs/FileInputButton";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {defaultCreateBrandBodyForm} from "./AddBrandModal";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {isAsset} from "../../utils/typeGuards";
import getConfig from "../../utils/getConfig";
import {BusinessTaxonomyBodyFrontend} from "./AddTaxonomyModal";

export interface UpdateBrandBodyFrontend extends Omit<CreateBrandBody, "image"> {
	image: Asset | FileWithSRC;
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	brand: Brand;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function EditBrandModal(props: EditBrandModalProps): ReactElement {

	async function submitEnabledStatusChange(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new BrandsApi(getConfig(props.fullToken)).updateBrand({
				id: props.brand.id,
				updateBrandBody: {
					image: props.brand?.image?.id,
					name: props.brand.name,
					description: props.brand.description,
					isEnabled: !props.brand.isEnabled,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				title="Toggle Enabled Status"
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<p>
					{`Are you sure you want to ${props.brand.isEnabled ? "disable" : "enable"} the ${props.brand.name} brand?`}
				</p>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="lightBlue"
					onClick={props.onClose}
				>
					Cancel
				</FrameButton>

				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color={props.brand.isEnabled ? "danger" : "lightBlue"}
					onClick={submitEnabledStatusChange}
				>
					{props.brand.isEnabled ? "Yes, Disable" : "Yes, Enable"}
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type EditBrandModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(EditBrandModal);
