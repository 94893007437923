import React, {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    GoVipPointMultiplier, GetGoVipPointMultiplierResponse, GoVipApi
} from "@devour/client";
import getConfig from "../utils/getConfig";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import {omit} from "lodash";
import moment from "moment/moment";
import GoVipPointMultiplierEditCell from "../components/tables/cells/GoVipPointMultiplierEditCell";
import GoVipPointMultiplierDeleteCell from "../components/tables/cells/GoVipPointMultiplierDeleteCell";
import AddGoVipPointMultiplierModal from "../components/modals/AddGoVipPointMultiplierModal";

export enum GoVipActionType {
    SPIN2WIN = "SPIN2WIN",
    GLEAM_REWARD = "GLEAM_REWARD",
    ALL_GAMES = "ALL_GAMES",
    CANDY_BLAST = "CANDY_BLAST",
    PIZZA_CAFE = "PIZZA_CAFE",
    BURGER_CAFE = "BURGER_CAFE",
    RALLY_CHAMPION = "RALLY_CHAMPION",
    IN_THE_WEEDS = "IN_THE_WEEDS",
    MENU_ORDER_REWARD = "MENU_ORDER_REWARD",
    LOAD_DPAY = "LOAD_DPAY",
    INDUSTRY_PASS_PURCHASE_REWARD = "INDUSTRY_PASS_PURCHASE_REWARD",
    REFER_A_FRIEND_FIRST_ORDER_REWARD = "REFER_A_FRIEND_FIRST_ORDER_REWARD",
    SIGN_UP = "SIGN_UP",
}

export const MULTIPLIER_VALUES: Array<number> = [
    1,
    1.5,
    2,
    2.5,
    3
];
function ManageGoVipPointMultipliers(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const [goVipPointMultipliers, setGoVipPointMultipliers] = useState<GetGoVipPointMultiplierResponse>(undefined);
    const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
    const [showNewMultiplierModal, setShowNewMultiplierModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        readGoVipPointMultipliers().then().catch();
    }, [JSON.stringify(frontendPagination)]);

    async function readGoVipPointMultipliers(){
        dispatch(incrementLoading());
        try {
            const res = await new GoVipApi(getConfig(fullToken)).getGoVipPointMultiplier({
                limit: frontendPagination.limit,
                offset: frontendPagination.offset
            });

            setGoVipPointMultipliers(res);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    /**
     * Show or hide the Create GoVip Point Multiplier Modal.
     *
     */
    function toggleNewGoVIPPointMultiplierModal(): void {
        setShowNewMultiplierModal(prevState => !prevState);
    }

    /**
     * Hide the Create GoVip Point Multiplier Modal when finished,
     * and call the api to get the updated list.
     *
     */
    function onDoneNewGoVipPointMultiplierModal(): void {
        setShowNewMultiplierModal(false);
        readGoVipPointMultipliers().then().catch();
    }
    function renderEditCell(goVipPointMultiplier: GoVipPointMultiplier): ReactElement {
        return (
            <GoVipPointMultiplierEditCell
                goVipPointMultiplier={goVipPointMultiplier}
                onDone={readGoVipPointMultipliers}
            />
        );
    }

    function renderDeleteCell(goVipPointMultiplier: GoVipPointMultiplier): ReactElement {
        return (
            <GoVipPointMultiplierDeleteCell
                goVipPointMultiplier={goVipPointMultiplier}
                onDone={readGoVipPointMultipliers}
            />
        );
    }

    return (
        <div className="manage-referral-reward-thresholds">
            <AddGoVipPointMultiplierModal
                isOpen={showNewMultiplierModal}
                onClose={toggleNewGoVIPPointMultiplierModal}
                onDone={onDoneNewGoVipPointMultiplierModal} />
            <PageHeader>
                <div>
                    <h3>
                        Manage GoVIP Point Multipliers
                    </h3>

                    <p>
                        On this page you can see a list of all the GoVIP Point Multipliers, GoVIP point earning action, when they become effective if set
                        (default is set to day of creation), when they expire if an expiry date was set, multiplier amount, and their created and updated dates.
                    </p>
                    <p>
                        To create a new GoVIP point multiplier, click on the "Create New GoVIP Point Multiplier" button.
                        Each multiplier can also be edited or deleted once created.
                    </p>

                    <div className="manage-referral-reward-thresholds_button-container">
                        <FrameButton
                            color="lightBlue"
                            onClick={toggleNewGoVIPPointMultiplierModal}
                        >
                            Create New GoVIP Point Multiplier
                        </FrameButton>
                    </div>

                </div>

            </PageHeader>

            <div className="manage-referral-reward-thresholds_table">
                <FrameOneTableContainer
                    data={goVipPointMultipliers?.goVipPointMultipliers}
                    pagination={{
                        ...goVipPointMultipliers?.paginationInfo,
                        ...omit(frontendPagination, "frontendRenderKey"),
                    }}
                    onPaginationChange={setFrontendPagination}
                    columnOptions={[
                        {
                            key: "id",
                            headerValue: "ID",
                        },
                        {
                            key: "action",
                            headerValue: "Action",
                        },
                        {
                            key: "startDate",
                            headerValue: "Start Date",
                            valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
                        },
                        {
                            key: "endDate",
                            headerValue: "End Date",
                            valueFormatter: (d: number) => d ? moment(d).format("MMM DD YYYY hh:mma") : "No end date",
                        },
                        {
                            key: "multiplier",
                            headerValue: "Multiplier"
                        },
                        {
                            key: "createdAt",
                            headerValue: "Created",
                            valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
                        },
                        {
                            key: "updatedAt",
                            headerValue: "Last Updated",
                            valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
                        },
                        {
                            key: undefined,
                            headerValue: "Edit GoVIP Point Multiplier",
                            cellRender: renderEditCell,
                        },
                        {
                            key: undefined,
                            headerValue: "Delete GoVIP Point Multiplier",
                            cellRender: renderDeleteCell,
                        },
                    ]}
                />
            </div>
        </div>
    );
}


export default ManageGoVipPointMultipliers;
