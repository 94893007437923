import React, {ReactElement, useEffect, useState} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {ForceUpdateNftOwnershipResponse, NftTracker} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {NftsApi} from "@devour/client";
import { v4 as uuid } from "uuid";

interface Props {
    isUpdateAll: boolean;
    isOpen: boolean;
    toggle: () => void;
    tracker?: NftTracker;
}

function ManageNftTrackersForceRefreshSingleOwnershipModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const [updatedData, setUpdatedData] = useState<ForceUpdateNftOwnershipResponse>(undefined);

    useEffect(() => {
        if (props.isOpen) {
            setUpdatedData(undefined);
        }
    }, [props.isOpen]);

    async function forceUpdateOwnerships() {
        dispatch(incrementLoading());
        try {
            const response: ForceUpdateNftOwnershipResponse = await new NftsApi(getConfig(fullToken)).forceNftOwnershipUpdate({
                forceUpdateNftOwnershipBody: {
                    isUpdateAll: props.isUpdateAll,
                    trackerId: props.isUpdateAll ? "" : props.tracker.id
                }
            });
            setUpdatedData(response);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderUpdatedDataResponse(updatedData: ForceUpdateNftOwnershipResponse) {
        let noTrackerFound = 0;
        const components = updatedData.updatedOwnerships.map((data) => {
            if (data.isRecorded) {
                return (
                    <div key={uuid()}>
                        <h5>{data.trackerName}</h5>
                        <p><strong>Tracker ID:</strong></p>
                        <p>{data.trackerId}</p>
                        <p><strong>Created Ownerships:</strong></p>
                        {data.createdOwnershipIds.length ? (
                            <>{data.createdOwnershipIds.map((ownership) => <p key={uuid()}>{ownership}</p>)}</>
                        ) : (
                            <p>No ownerships created.</p>
                        )}
                        <p><strong>Deleted Ownerships:</strong></p>
                        {data.deletedOwnershipIds.length ? (
                            <>{data.deletedOwnershipIds.map((ownership) => <p key={uuid()}>{ownership}</p>)}</>
                        ) : (
                            <p>No ownerships deleted.</p>
                        )}
                    </div>
                )
            } else {
                noTrackerFound++;
            }
        });
        if (noTrackerFound === updatedData.updatedOwnerships.length) {
            return (
                <p>
                    There was a critical error when updating ownerships, no trackers were found. Please contact support.
                </p>);
        }
        return components;
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="contact-modal"
        >
            <FrameModalHeader
                title="Force NFT Ownership Refresh"
                toggle={props.toggle}
            />

            <FrameModalBody className="contact-modal_body">
                {!updatedData ? (
                    <>
                        <p>
                            Are you sure you want to refresh NFT ownerships? It may result in database changes which
                            cannot be undone.
                        </p>
                        <p>
                            You will receive a list of all changes made once the operation completes. <strong>This might
                            take a while, so please be patient.</strong>
                        </p>
                    </>
                ) : (
                    <>
                        {renderUpdatedDataResponse(updatedData)}
                    </>
                )}
            </FrameModalBody>

            <FrameModalFooter>
                <FrameButton
                    color="danger"
                    onClick={async () => !updatedData ? await forceUpdateOwnerships() : props.toggle()}
                >
                    {!updatedData ? "Confirm" : "Close"}
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default ManageNftTrackersForceRefreshSingleOwnershipModal;
