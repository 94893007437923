import React, {ChangeEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import {
	Brand,
	BusinessesApi,
	GetBusinessesOwnResponse,
	GetOrderDiscountsResponse,
	OrderDiscount,
	OrderDiscountCalculation,
	OrderDiscountsApi,
	PaginationInfo,
	Token,
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import AddOrderDiscountModal from "../components/modals/AddOrderDiscountModal";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {formatDiscountType} from "../utils/formatDiscountType";
import {formatDiscountOrigin} from "../utils/formatDiscountOrigin";
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";
import moment from "moment";
import OrderDiscountsNftGroupingsCell from "../components/tables/cells/OrderDiscountsNftGroupingsCell";
import OrderDiscountsEditCell from "../components/tables/cells/OrderDiscountsEditCell";
import {omit} from "lodash";
import {csvExport} from "../utils/csvExport";

interface StateProps {
	fullToken: Token;
}

function OrderDiscountsPage(props: OrderDiscountsPageProps): ReactElement {

	const [businessesRes, setBusinessesRes] = useState<GetBusinessesOwnResponse>(undefined);
	const [brands, setBrands] = useState<Array<Brand>>(undefined);
	const [orderDiscountsRes, setOrderDiscountsRes] = useState<GetOrderDiscountsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddDiscountModal, setShowAddDiscountModal] = useState(false);
	const [search, setSearch] = useState<string>();

	useEffect(() => {
		getRestaurants().then().catch();
	}, [orderDiscountsRes]);

	useEffect(() => {
		getOrderDiscounts().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	async function getOrderDiscounts(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new OrderDiscountsApi(getConfig(props.fullToken)).getOrderDiscounts({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				search: search,
			});

			setOrderDiscountsRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}
	async function getRestaurants(): Promise<void> {
		if (!orderDiscountsRes) {
			return;
		}

		// props.dispatch(incrementLoading());

		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessesOwn({
				limit: 1000000,
				offset: 0,
				ids: orderDiscountsRes.orderDiscounts.map(d => d.businesses).flat(),
			});

			setBusinessesRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			// props.dispatch(decrementLoading());
		}
	}

	function toggleAddDiscountModal(): void {
		setShowAddDiscountModal(s => !s);
	}

	function onDoneAddDiscount(): void {
		setShowAddDiscountModal(false);
		getOrderDiscounts().then().catch();
	}

	async function exportToCsv(orderDiscount: OrderDiscount): Promise<void> {
		const headers = [
			"Code",
		];
		const filename: string = `devourgo-promo__${orderDiscount.label.replace(/\s+/g, '-').toLowerCase()}.csv`;

		const formattedContent = orderDiscount.codes.map((code) => {
			// Wrap in subarray. Since CSV potentially has multiple columns
			return [
				code,
			];
		});

		csvExport(formattedContent, headers, filename);
	}

	function renderCodesCell(orderDiscount: OrderDiscount): ReactNode {
		if (orderDiscount?.codes?.length) {
			return (
				<FrameButton
					color="lightBlue"
					onClick={() => exportToCsv(orderDiscount)}
				>
					Export {orderDiscount?.codes?.length} codes to CSV
				</FrameButton>
			);
		} else {
			return (
				<p>
					No codes.
				</p>
			)
		}
	}

	function renderEditCell(orderDiscount: OrderDiscount): ReactNode {
		return (
			<OrderDiscountsEditCell
				businessesRes={businessesRes}
				orderDiscount={orderDiscount}
				onDone={getOrderDiscounts}
				brands={brands}
			/>
		);
	}

	function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
		setSearch(e.target.value);
	}

	function handleSubmitSearch(): void {
		setFrontendPagination(p => {
			return {
				...defaultFrontendPagination,
				frontendRenderKey: p.frontendRenderKey + 1,
			}
		});
	}

	return (
		<React.Fragment>
			<AddOrderDiscountModal
				businessesRes={businessesRes}
				isOpen={showAddDiscountModal}
				onClose={toggleAddDiscountModal}
				onDone={onDoneAddDiscount}
				brands={brands}
			/>

			<div className="manage-discounts">
				<PageHeader>
					<div>
						<h3>
							Manage Promos
						</h3>

						<p>
							Here you can view and manage promos in the system.
						</p>

						<p>
							Relationships between promos and businesses are cached.
							Creating / editing a promo will not be reflected on the restaurant search results page for
							customers.
						</p>

						<div className="manage-discounts_cta">
							<FrameButton
								color="lightBlue"
								onClick={toggleAddDiscountModal}
							>
								Create New Promo
							</FrameButton>
						</div>
					</div>
				</PageHeader>

				<div className="manage-discounts_table">
					<div>
						<label>
							Search
						</label>
						<input
							value={search}
							placeholder="Search for name or email..."
							onChange={searchOnChange}
						/>
						<br/>
						<FrameButton
							color="lightBlue"
							onClick={handleSubmitSearch}
						>
							Search
						</FrameButton>
						<br/>
					</div>
					<FrameOneTableContainer
						data={orderDiscountsRes?.orderDiscounts}
						pagination={{
							...orderDiscountsRes?.paginationInfo,
							...omit(frontendPagination, "frontendRenderKey"),
						} as PaginationInfo}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "internalName",
								headerValue: "Promo Name",
							},
							{
								key: "label",
								headerValue: "Promo Text",
							},
							{
								key: "businesses",
								headerValue: "Businesses",
								cellRender: (ids: Array<string>) => {
									return (
										<ul>
											{ids.map((id) => (
												<li key={id}>
													{businessesRes?.businesses.find(b => b.id === id)?.name}
												</li>
											))}
										</ul>
									);
								}
							},
							{
								key: "type",
								headerValue: "Promo Type",
								valueFormatter: formatDiscountType,
							},
							{
								key: "origin",
								headerValue: "Promo Origin",
								valueFormatter: formatDiscountOrigin,
							},
							{
								key: undefined,
								headerValue: "Promo Amount",
								valueFormatter: (discount: OrderDiscount) => {
									if (discount.calculation === OrderDiscountCalculation.PERCENTAGE) {
										return discount.amount + "%";
									}

									return "$" + discount.amount;
								},
							},
							{
								key: "subtotalMin",
								headerValue: "Min Order Subtotal",
								valueFormatter: (subtotalMin: number) => {
									return "$" + subtotalMin;
								},
							},
							{
								key: undefined,
								headerValue: "Frequency Limit",
								cellRender: (discount: OrderDiscount) => {
									if (discount.useFrequency != null && discount.useFrequencyQualifier) {
										return `${discount.useFrequency}x ${discount.useFrequencyQualifier.toLowerCase()}`;
									} else {
										return "-"
									}
								}
							},
							{
								key: "priority",
								headerValue: "Priority",
							},
							{
								key: "isEnabled",
								headerValue: "Active",
								cellRender: (isEnabled: boolean) => {
									if (isEnabled) {
										return (
											<AiFillCheckCircle className="table-icon table-text-success"/>
										);
									}

									return (
										<AiFillCloseCircle className="table-icon  table-text-danger"/>
									);
								},
							},
							{
								key: "isStackablePromo",
								headerValue: "Stackable",
								cellRender: (isStackablePromo: boolean) => {
									if (isStackablePromo) {
										return (
											<AiFillCheckCircle className="table-icon table-text-success"/>
										);
									}

									return (
										<AiFillCloseCircle className="table-icon  table-text-danger"/>
									);
								},
							},
							{
								key: "isFirstOrder",
								headerValue: "First Order Only",
								cellRender: (isFirstOrder: boolean) => {
									if (isFirstOrder) {
										return (
											<AiFillCheckCircle className="table-icon table-text-success"/>
										);
									}

									return (
										<AiFillCloseCircle className="table-icon  table-text-danger"/>
									);
								},
							},
							{
								key: "isSingleUseUser",
								headerValue: "Single Use (per user)",
								cellRender: (isSingleUseUser: boolean) => {
									if (isSingleUseUser) {
										return (
											<AiFillCheckCircle className="table-icon table-text-success"/>
										);
									}

									return (
										<AiFillCloseCircle className="table-icon  table-text-danger"/>
									);
								},
							},
							{
								key: "isSingleUseSystem",
								headerValue: "Single Use (system)",
								cellRender: (isSingleUseSystem: boolean) => {
									if (isSingleUseSystem) {
										return (
											<AiFillCheckCircle className="table-icon table-text-success"/>
										);
									}

									return (
										<AiFillCloseCircle className="table-icon  table-text-danger"/>
									);
								},
							},
							{
								key: "isReferralOnly",
								headerValue: "Referred Users Only",
								cellRender: (isReferralOnly: boolean) => {
									if (isReferralOnly) {
										return (
											<AiFillCheckCircle className="table-icon table-text-success"/>
										);
									}

									return (
										<AiFillCloseCircle className="table-icon  table-text-danger"/>
									);
								},
							},
							{
								key: "isDisplayedGoFrens",
								headerValue: "Displayed in GoFrens",
								cellRender: (isDisplayedGoFrens: boolean) => {
									if (isDisplayedGoFrens) {
										return (
											<AiFillCheckCircle className="table-icon table-text-success"/>
										);
									}

									return (
										<AiFillCloseCircle className="table-icon  table-text-danger"/>
									);
								},
							},
							{
								key: "maxRedemptions",
								headerValue: "Quantity Limit"
							},
							{
								key: "timesRedeemed",
								headerValue: "Quantity Redeemed"
							},
							{
								key: undefined,
								headerValue: "Codes",
								cellRender: renderCodesCell,
							},
							{
								key: "nftGroupings",
								headerValue: "NFT Groupings",
								cellRender: (nftGroupings: string[]) => {
									return (
										<OrderDiscountsNftGroupingsCell nftGroupings={nftGroupings}/>
									);
								}
							},
							{
								key: "expiresAt",
								headerValue: "Expires At",
								valueFormatter: (expiryDate: number) => {
									if (expiryDate === undefined) {
										return "-"
									}

									return moment(expiryDate).format("MMM DD YYYY");
								},
							},
							{
								key: undefined,
								headerValue: "Edit Promo",
								cellRender: renderEditCell,
							},
							{
								key: "createdAt",
								headerValue: "Created",
								valueFormatter: (created: number) => moment(created).format("MMM DD YYYY HH:mm"),
							},
							{
								key: "updatedAt",
								headerValue: "Last Updated",
								valueFormatter: (updated: number) => moment(updated).format("MMM DD YYYY HH:mm"),
							},
							{
								key: "id",
								headerValue: "ID",
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type OrderDiscountsPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(OrderDiscountsPage);
