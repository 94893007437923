import React, {ReactElement} from "react";
import {BannedCommunity,} from "@devour/client";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameOneModal from "../modalComponents/FrameOneModal";
import FrameButton from "../../buttons/FrameButton";

interface ConfirmUnbanUserProps {
    isOpen: boolean;
    onConfirmation: () => Promise<void>;
    onClose: () => void;
    community: BannedCommunity;
}

function ConfirmUnbanUser(props: ConfirmUnbanUserProps): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="user-experience-modal"
        >
            <FrameModalHeader
                toggle={props.onClose}
                title="Confirm Unban User"
            />

            <FrameModalBody className="manage-user-referrals_modal">
                Are you sure you would like to unban this user from {props.community.name}? <br/>

                <div className="manage-user-referrals_confirm-buttons">

                    <FrameButton
                        className="manage-user-referrals_confirm-button"
                        color="success"
                        onClick={props.onConfirmation}
                    >
                        Confirm
                    </FrameButton>

                    <FrameButton
                        className="manage-user-referrals_cancel-button"
                        color="danger"
                        onClick={props.onClose}
                    >
                        Cancel
                    </FrameButton>

                </div>

            </FrameModalBody>
        </FrameOneModal>
    );
}


export default ConfirmUnbanUser;
