import React, {ReactElement} from "react";
import {BrandMapStatus} from "@devour/client";
import FrameButton from "../buttons/FrameButton";
import {BiSave} from "react-icons/bi";
import {FaEye} from "react-icons/fa";

interface Props {
    status?: BrandMapStatus;
    onSaveDraft: (status: BrandMapStatus.DRAFT) => Promise<void>;
    onPublish: (status: BrandMapStatus.PUBLISHED) => Promise<void>;
    onPreview: () => void;
}

function BrandButtons(props: Props): ReactElement {

    return (
        <div className="merchant-brand-buttons">
            <div className="merchant-brand-buttons_container">
                <div style={{flexGrow: "1"}}/>
                <FrameButton
                    <React.ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple-outline"
                    size="normal"
                    leftIcon={FaEye}
                    forwardProps={{type: "button"}}
                    onClick={props.onPreview}
                >
                    Preview
                </FrameButton>
                <FrameButton
                    color="purple-outline"
                    size="normal"
                    leftIcon={BiSave}
                    onClick={() => props.onSaveDraft(BrandMapStatus.DRAFT)}
                >
                    {props?.status === BrandMapStatus.DRAFT ? "Update Draft" : "Revert to Draft"}
                </FrameButton>
                <FrameButton
                    color="light-purple"
                    size="normal"
                    onClick={() => props.onPublish(BrandMapStatus.PUBLISHED)}
                >
                    Save and Publish
                </FrameButton>
            </div>
        </div>
    );
}

export default BrandButtons;