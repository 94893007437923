import React, {ReactElement, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {User} from "@devour/client";
import ManageReferredUsersModal from "../../modals/userReferrals/ManageReferredUsersModal";

interface Props {
    user: User;
}

function ManageReferredUsersCell(props: Props): ReactElement {

    const [showModal, setShowModal] = useState(false);

    /**
     * Hide or Show the toggle modal.
     *
     */
    async function toggleShowToggleModal()  {
        setShowModal(!showModal);
    }

    return (
        <React.Fragment>
            <ManageReferredUsersModal
                isOpen={showModal}
                user={props.user}
                onClose={toggleShowToggleModal}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightGray"
                    onClick={async () => await toggleShowToggleModal()}
                >
                    User Referrals
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ManageReferredUsersCell;
