import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {CreateOnboardingThirdPartyBody, OnboardingApi, OnboardingThirdParty, Token} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import OnBoardThirdPartyConfirmModal from "./OnBoardThirdPartyConfirmModal";

const defaultThirdPartyBody: CreateOnboardingThirdPartyBody = {
	title: "",
	description: "",
};

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function AddThirdPartyOnBoardModal(props: AddThirdPartyOnBoardModalProps): ReactElement {

	const [thirdPartyBody, setThirdPartyBody] = useState<CreateOnboardingThirdPartyBody>(defaultThirdPartyBody);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [createdThirdParty, setCreatedThirdParty] = useState<OnboardingThirdParty>();

	useEffect(() => {
		if (props.isOpen) {
			setThirdPartyBody(defaultThirdPartyBody);
			setCreatedThirdParty(null);
		}
	}, [props.isOpen]);

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof CreateOnboardingThirdPartyBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> {
		return (e) => {
			setThirdPartyBody((t) => {
				return {
					...t,
					[key]: e.target.value,
				}
			});
		}
	}

	async function submitNewOnBoarding(e: React.FormEvent): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new OnboardingApi(getConfig(props.fullToken)).createOnboardingThirdParty({
				createOnboardingThirdPartyBody: {
					title: thirdPartyBody.title,
					description: thirdPartyBody.description,
				},
			});

			setCreatedThirdParty(res);
			setShowConfirmationModal(true);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function onDismissConfirmationModal(): void {
		setShowConfirmationModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<OnBoardThirdPartyConfirmModal
				isOpen={showConfirmationModal}
				createdThirdParty={createdThirdParty}
				onDismiss={onDismissConfirmationModal}
			/>

			<FrameOneModal
				isOpen={props.isOpen}
				toggle={props.onClose}
				contentClassName="on-boarding-third-parties-add-modal"
			>
				<FrameModalHeader
					title="On Board New Third Party"
					toggle={props.onClose}
				/>

				<form onSubmit={submitNewOnBoarding}>
					<FrameModalBody className="on-boarding-third-parties-add-modal_body">
						<div>
							<label>
								Third Party Name
							</label>
							<input
								value={thirdPartyBody.title}
								placeholder="Third Party Name..."
								onChange={inputOnChange("title")}
							/>
						</div>

						<div>
							<label>
								Third Party Description
							</label>
							<textarea
								value={thirdPartyBody.description}
								placeholder="Third Party Description..."
								onChange={inputOnChange("description")}
							/>
						</div>
					</FrameModalBody>

					<FrameModalFooter>
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="lightBlue"
							forwardProps={{
								type: "submit"
							}}
						>
							On Board
						</FrameButton>
					</FrameModalFooter>
				</form>
			</FrameOneModal>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddThirdPartyOnBoardModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddThirdPartyOnBoardModal);
