import React, {ChangeEvent, useState} from "react";
import {ReactElement} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {Asset, BusinessTaxonomy} from "@devour/client";
import FrameOneTableCellImagePreview from "../tables/cells/FrameOneTableCellImagePreview";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

interface Props {
	isOpen: boolean;
	taxonomies: Array<BusinessTaxonomy>;
	selectedTaxonomies: string[];
	onSelect: (id: string) => void;
	onClose: () => void;
}

function SelectTaxonomiesModal(props: Props): ReactElement {

	const [searchString, setSearchString] = useState("");

	function onSearchInputChange(e: ChangeEvent<HTMLInputElement>): void {
		setSearchString(e.target.value);
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
			contentClassName="select-taxonomies-modal"
		>
			<FrameModalHeader
				title="Select Categories"
				toggle={props.onClose}
			/>

			<FrameModalBody className="select-taxonomies-modal_body">
				<div className="select-taxonomies-modal_body_input-container">
					<label>
						Search categories
					</label>
					<input
						placeholder="Search Categories..."
						value={searchString}
						onChange={onSearchInputChange}
					/>
				</div>

				<FrameOneTableContainer
					data={props.taxonomies?.filter((t) => {
						return t.name?.toLowerCase().includes(searchString.toLowerCase())
							|| t.description?.toLowerCase().includes(searchString.toLowerCase())
					})}
					columnOptions={[
						{
							key: "image",
							headerValue: "Image",
							cellRender: (asset: Asset) => {
								return (
									<FrameOneTableCellImagePreview asset={asset}/>
								);
							}
						},
						{
							key: "name",
							headerValue: "Name",
						},
						{
							key: undefined,
							headerValue: "Select/De-select",
							cellRender: (t: BusinessTaxonomy) => {
								const isSelected: boolean = props.selectedTaxonomies.indexOf(t.id) > -1;

								function clickHelper(): void {
									props.onSelect(t.id);
								}

								return (
									<div className="frame-one-table-cell">
										<FrameButton
											color={isSelected ? "danger" : "success"}
											onClick={clickHelper}
										>
											{isSelected ? "Remove" : "Add"}
										</FrameButton>
									</div>
								);
							}
						},
						{
							key: "description",
							headerValue: "Description",
							cellRender: (d: string) => {
								return (
									<div className="table-description-field">
										{d}
									</div>
								);
							}
						},
					]}
				/>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="darkBlue"
					onClick={props.onClose}
				>
					Done
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

export default SelectTaxonomiesModal;
