import React, {ReactElement, ReactNode,} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";
import {FiLogOut} from "react-icons/fi";
import classNames from "classnames";
import {Token, User} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {logout, toggleSidebar} from "../../redux/meta/MetaActions";
import {getSideBarNavigationLinks, ISideBarNavigationLink} from "../../utils/getSideBarNavigationLinks";
import SideBarFrameOneSocialInfo from "./SideBarFrameOneSocialInfo";
import SideBarVersionDisplay from "./SideBarVersionDisplay";
import SideBarButton from "../buttons/SideBarButton";

interface StateProps {
	fullToken: Token;
	currentUser: User;
	sidebarOpen: boolean;
}

function SideBarInner(props: SideBarInnerProps): ReactElement {

	const history = useNavigate();
	const location = useLocation();

	/**
	 * Helps toggle the sidebar.
	 *
	 */
	function closeSideBarHelper(): void {
		if (props.sidebarOpen) {
			props.dispatch(toggleSidebar(false));
		}
	}

	/**
	 * Handle the "Log Out" button click.
	 * Dispatch redux logout action, send user to "/" route.
	 *
	 */
	function logoutDispatcher(): void {
		closeSideBarHelper();
		props.dispatch(logout());
		history("/");
	}

	/**
	 * Render each of the navigation links.
	 *
	 * @param link
	 * @param i
	 */
	function createNavigationButtons(link: ISideBarNavigationLink, i: number): ReactNode {
		const selected: boolean = location.pathname?.indexOf(link.route) > -1;

		return (
			<Link
				key={`side-bar-navigation-link_${i}`}
				to={link.route}
				className={classNames("sidebar_inner_content_button-list-container_link", {
					"sidebar_inner_content_button-list-container_link_active": selected,
				})}
			>
				<SideBarButton
					className="sidebar_inner_content_button-list-container_button"
					icon={link.icon}
					active={selected}
					onClick={closeSideBarHelper}
				>
					{link.title}
				</SideBarButton>
			</Link>
		);
	}

	return (
		<div className="sidebar_inner">
			<div className="sidebar_inner_content">
				<div className="sidebar_inner_content_title-container">
					<h5 className="sidebar_inner_content_title-container_title">
						Admin Portal
					</h5>
				</div>

				<SideBarVersionDisplay className="sidebar_inner_content_version-info-container"/>

				<div className="sidebar_inner_content_button-list-container">
					{getSideBarNavigationLinks().map(createNavigationButtons)}

					<SideBarButton
						className="sidebar_inner_content_button-list-container_button"
						icon={FiLogOut}
						onClick={logoutDispatcher}
					>
						Log Out
					</SideBarButton>
				</div>
			</div>

			<SideBarFrameOneSocialInfo className="sidebar_inner_frame-one-info"/>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			currentUser: store.metaStore.currentUser,
			sidebarOpen: store.metaStore.sidebarOpen,
		}
	});
}

type SideBarInnerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(SideBarInner);
