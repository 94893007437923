import React, {ChangeEvent, ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {EditBrandMapBodyFrontend} from "../BrandMapFormValues";
import {Asset, Business, HandoffOptions, OrderDiscount, Restaurant} from "@devour/client";
import PreviewLocationCard from "./PreviewLocationCard";
import PreviewGoogleMapsWrapper from "./PreviewGoogleMapsWrapper";
import FrameOneSwitchInput from "../../../components/inputs/FrameOneSwitchInput";
import SearchInput from "../../../components/inputs/SearchInput";


interface Props {
    formValues: EditBrandMapBodyFrontend;
    businesses: Array<Business>;
    brandImage: Asset;
    brandName: string;
    brandId: string;
    promos: Array<OrderDiscount>;
    activeBusinessId: string;
    setActiveBusinessId: (id: string) => void;
}

function PreviewLocations(props: Props): ReactElement {
    const [handoff, setHandoff] = useState<HandoffOptions>(HandoffOptions.DELIVERY);
    const [searchValue, setSearchValue] = useState<string>("");

    const mapRef = useRef<google.maps.Map>(null);

    useEffect(() => {
        if (props.activeBusinessId && mapRef.current) {
            const activeRestaurant = props.businesses.find(b => b.id === props.activeBusinessId);
            if (activeRestaurant) {
                // Re-center map on newly selected restaurant
                mapRef.current.setZoom(15);
                mapRef.current.panTo({
                    lat: activeRestaurant.address.location.coordinates[1],
                    lng: activeRestaurant.address.location.coordinates[0],
                });
            }
        }
    }, [props.activeBusinessId, mapRef, props.businesses]);

    function toggleHandoff() {
        if (handoff === HandoffOptions.DELIVERY) {
            setHandoff(HandoffOptions.PICKUP);
        } else {
            setHandoff(HandoffOptions.DELIVERY);
        }
    }

    function sortRestaurants(a: Restaurant): number {
        const isDefaultAddress = a.address.placeId === props.formValues.defaultLocation.placeId;
        if (isDefaultAddress) {
            return -1;
        }
        return 0;
    }

    function filterRestaurant(restaurant: Restaurant): boolean {
        const mainPromoId = props.formValues?.mainPromo;

        const mainPromo = props.promos.find((promo) => promo?.id === mainPromoId);

        if (mainPromo && mainPromo.businesses.length && !mainPromo.businesses.includes(restaurant.id)) {
            return false;
        }

        if (!restaurant.handoffOptions.includes(handoff)) {
            return false;
        }

        if (searchValue && !restaurant.name.toLowerCase().includes(searchValue.toLowerCase())) {
            return (
                restaurant.name?.toLowerCase()?.includes(searchValue) ||
                restaurant.address?.line1?.toLowerCase()?.includes(searchValue) ||
                restaurant.address?.line2?.toLowerCase()?.includes(searchValue) ||
                restaurant.address?.locality?.toLowerCase()?.includes(searchValue) ||
                restaurant.address?.administrativeArea?.toLowerCase()?.includes(searchValue) ||
                restaurant.address?.postalCode?.toLowerCase()?.includes(searchValue)
            );
        }

        return true;
    }

    /**
     * Update state with new search value.
     *
     * @param e
     */
    function handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    /**
     * Clear the search value.
     *
     */
    function clearSearch(): void {
        setSearchValue("");
    }

    /**
     * Render each "card" for each business location.
     *
     * @param business
     * @param i
     */
    function renderBusiness(business: Restaurant, i: number): ReactNode {
        return (
            <div
                key={`business_${i}`}
                className="restaurant-map-landing_map_list_item"
            >
                <PreviewLocationCard
                    business={business}
                    onSelectClick={onRestaurantSelect}
                    isActive={props.activeBusinessId === business.id}
                />
            </div>
        );
    }

    /**
     * Save ref to the map.
     *
     * @param _mapRef
     */
    function saveMapRef(_mapRef: google.maps.Map): void {
        mapRef.current = _mapRef;
    }

    function onRestaurantSelect(business: Restaurant) {
        props.setActiveBusinessId(business.id);
    }

    const businessesToRender = props.businesses.filter(filterRestaurant);

    return (
        <div
            className="restaurant-map-landing_map"
            id="restaurant-map-landing_content_locations"
        >
            <div className="restaurant-map-landing_map_header">
                {(props.brandImage) && (
                    <div className="restaurant-map-landing_map_header_image">
                        <img
                            src={props.brandImage.url}
                            alt={props.brandName}
                        />
                    </div>
                )}

                <div className="restaurant-map-landing_map_header_text">
                    <h3 className="restaurant-map-landing_map_header_name">
                        {props.brandName}
                    </h3>

                    <p className="restaurant-map-landing_map_header_counter">
                        {businessesToRender?.length} Locations
                    </p>
                </div>
            </div>

            <div className="restaurant-map-landing_map_handoff">
                <FrameOneSwitchInput
                    <HandoffOptions>
                    name="handoff-options"
                    value={handoff}
                    onToggle={toggleHandoff}
                    options={[
                        {
                            render: "Delivery",
                            value: HandoffOptions.DELIVERY,
                        },
                        {
                            render: "Pickup",
                            value: HandoffOptions.PICKUP,
                        },
                    ]}
                />
            </div>

            <div className="restaurant-map-landing_map_search">
                <SearchInput
                    placeholder="Search for a location"
                    value={searchValue}
                    onChange={handleSearchChange}
                    maxLength={15}
                    onClear={clearSearch}
                />
            </div>

            <div className="restaurant-map-landing_map_render">
                <PreviewGoogleMapsWrapper
                    brandId={props.brandId}
                    businessesToRender={businessesToRender}
                    activeBusinessId={props.activeBusinessId}
                    formValues={props.formValues}
                    saveMapRef={saveMapRef}
                    onMapPinClick={onRestaurantSelect}
                    center={{
                        lat: props.formValues.defaultLocation?.location.coordinates?.[1],
                        lng: props.formValues.defaultLocation?.location.coordinates?.[0],
                    }}
                />
            </div>

            <div className="restaurant-map-landing_map_list">
                {(businessesToRender?.length > 0) ?
                    businessesToRender.sort(sortRestaurants).map(renderBusiness) :
                    (
                        <div className="restaurant-map-landing_map_list_empty">
                            <p>
                                No Businesses that match your filter
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default PreviewLocations;
