import React from "react";
import {ReactElement} from "react";

interface Props {
	link: string;
	display?: string;
}

function FrameOneTableCellLink(props: Props): ReactElement {

	return (
		<div className="frame-one-table-cell">
			<a
				href={props.link}
				target="_blank"
				rel="noopener noreferrer"
			>
				{props.display ? props.display : props.link}
			</a>
		</div>
	);
}

export default FrameOneTableCellLink;
