import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {
	ServiceFeesApi,
	ServiceFee,
	PaginationInfo,
	Token,
	GetServiceFeesResponse
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {omit} from "lodash";
import AddEditServiceFeeModal from "../components/modals/AddEditServiceFeeModal";
import ServiceFeesEditCell from "../components/tables/cells/ServiceFeesEditCell";
import OrderDiscountsNftGroupingsCell from "../components/tables/cells/OrderDiscountsNftGroupingsCell";

interface StateProps {
	fullToken: Token;
}

function ManageServiceFeesPage(props: ManageServiceFeesPageProps): ReactElement {

	const [serviceFeesRes, setServiceFeesRes] = useState<GetServiceFeesResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddServiceFeeModal, setShowAddServiceFeeModal] = useState(false);

	useEffect(() => {
		getServiceFees().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	async function getServiceFees(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new ServiceFeesApi(getConfig(props.fullToken)).getServiceFees({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
			});

			setServiceFeesRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	async function deleteServiceFee(serviceFeeId: string): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new ServiceFeesApi(getConfig(props.fullToken)).deleteServiceFee({
				id: serviceFeeId,
			});

			getServiceFees().then().catch();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function toggleAddServiceFeeModal(): void {
		setShowAddServiceFeeModal(s => !s);
	}

	function onDoneAddServiceFee(): void {
		setShowAddServiceFeeModal(false);
		getServiceFees().then().catch();
	}

	function renderEditCell(serviceFee: ServiceFee): ReactNode {
		return (
			<ServiceFeesEditCell
				serviceFee={serviceFee}
				onDone={getServiceFees}
			/>
		);
	}

	function renderDeleteCell(serviceFee: ServiceFee): ReactNode {
		return (
			<FrameButton
				<React.ButtonHTMLAttributes<HTMLButtonElement>>
				color="danger"
				onClick={() => deleteServiceFee(serviceFee.id)}
			>
				Delete Service Fee
			</FrameButton>
		);
	}

	return (
		<React.Fragment>
			<AddEditServiceFeeModal
				isOpen={showAddServiceFeeModal}
				onClose={toggleAddServiceFeeModal}
				onDone={onDoneAddServiceFee}
			/>

			<div className="manage-service-fees">
				<PageHeader>
					<div>
						<h3>
							Manage Service Fees
						</h3>

						<p>
							As Devour we want to be able to charge a Convenience Service Fee to consumers placing orders
							on DevourGO.
							This will enable us to negotiate enterprise merchant platform deals while still covering our
							cost of DevourGO development and operations.
						</p>

						<p>
							Customers are assigned a service fee amount ordered by the "Priority" field descending.
							Higher priority tiers should be more beneficial to the customer.
						</p>

						<div className="manage-service-fees_cta">
							<FrameButton
								color="lightBlue"
								onClick={toggleAddServiceFeeModal}
							>
								Create New Service Fee Tier
							</FrameButton>
						</div>
					</div>
				</PageHeader>

				<div className="manage-service-fees_table">
					<FrameOneTableContainer
						data={serviceFeesRes?.serviceFees}
						pagination={{
							...serviceFeesRes?.paginationInfo,
							...omit(frontendPagination, "frontendRenderKey"),
						} as PaginationInfo}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "name",
								headerValue: "Name",
							},
							{
								key: "priority",
								headerValue: "Priority",
							},
							{
								key: "percentageDelivery",
								headerValue: "Delivery Percentage",
							},
							{
								key: "percentagePickup",
								headerValue: "Pickup Percentage",
							},
							{
								key: "amountMax",
								headerValue: "Max Amount",
							},
							{
								key: "nftGroupings",
								headerValue: "NFT Groupings",
								cellRender: (nftGroupings: string[]) => {
									return (
										<OrderDiscountsNftGroupingsCell nftGroupings={nftGroupings}/>
									);
								}
							},
							{
								key: undefined,
								headerValue: "Edit",
								cellRender: renderEditCell,
							},
							{
								key: "description",
								headerValue: "Description",
							},
							{
								key: undefined,
								headerValue: "Delete",
								cellRender: renderDeleteCell,
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type ManageServiceFeesPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageServiceFeesPage);
