import React, {ReactElement, useEffect, useState} from 'react';
import {FileLibraryListItem, ReactMediaLibrary, FileLibrarySelectedItems} from 'react-media-library';
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {AssetsApi, CreateAssetRequest, Asset} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import FileLibraryTopBar from './FileLibraryTopBar';
import FileLibrarySelectedItem from "./FileLibrarySelectedItem";
import FrameButton from "../buttons/FrameButton";

export interface ReactMediaLibraryFilters {
	search: string;
	owner: string;
}

const defaultReactMediaLibraryFilters: ReactMediaLibraryFilters = {
	search: "",
	owner: "",
};

function convertAssetToMediaLibraryItem(asset: Asset): FileLibraryListItem {
	return {
		_id: asset.id,
		title: asset.name,
		size: asset.size,
		createdAt: asset.createdAt,
		thumbnailUrl: asset.url,
		description: asset.description,
		owner: asset.owner,
	};
}

const PAGER_LIMIT_ALL = 1000000;
const PAGER_OFFSET_ALL = 0;

interface Props {
	assetName: string;
	assetDescription: string;
	label: string;
	asset?: Asset;
	onNew?: (assetId: string) => void;
	onUpdate?: (assetId: string) => void;
	onUnsetImage?: () => void;
}

function ReactMediaLibraryWrapper(props: Props): ReactElement {
	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [display, setDisplay] = useState<boolean>(false);
	const [assets, setAssets] = useState<Array<Asset>>([]);
	const [libraryFilter, setLibraryFilter] = useState<ReactMediaLibraryFilters>(defaultReactMediaLibraryFilters);

	useEffect(() => {
		if (display) {
			void fetchAssets();
		}
	}, [display]);

	async function fetchAssets(): Promise<void> {
		dispatch(incrementLoading());

		try {
			const res = await new AssetsApi(getConfig(fullToken)).getAssets({
				limit: PAGER_LIMIT_ALL,
				offset: PAGER_OFFSET_ALL,
			});
			setAssets(res.assets);
		} catch (e) {
			setDisplay(false);
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	async function uploadCallback(file: File): Promise<boolean> {
		try {
			const assetRequest: CreateAssetRequest = {
				asset: file,
				name: props.assetName,
				description: props.assetDescription,
			};
			await new AssetsApi(getConfig(fullToken)).createAsset(assetRequest);
			return true;
		} catch {
			return false;
		}
	}

	function selectCallback(items: Array<FileLibraryListItem>): void {
		const item = items[0];
		props.onNew(item._id.toString());
		setDisplay(false);
	}

	async function deleteCallback(items: Array<FileLibraryListItem>): Promise<void> {
		const item = items[0];
		await new AssetsApi(getConfig(fullToken)).deleteAsset({
			id: item._id.toString(),
		});
		if (props.onUnsetImage && item._id === props.asset?.id) {
			props.onUnsetImage();
		}
		await fetchAssets();
	}

	function topBarComponent(): ReactElement {
		return (
			<FileLibraryTopBar
				filters={libraryFilter}
				setFilters={setLibraryFilter}
			/>
		);
	}

	function selectedItemComponent(item: FileLibraryListItem): ReactElement {
		const findAsset = assets.find((asset) => asset.id === item._id);
		return (
			<FileLibrarySelectedItem
				asset={findAsset}
				onUpdate={fetchAssets}
			/>
		);
	}

	function selectedItemsComponent(): ReactElement {
		return (
			<FileLibrarySelectedItems
				itemComponent={selectedItemComponent}
			/>
		);
	}

	function filterItem(item: Asset): boolean {
		if (libraryFilter.search && !item.name?.toLowerCase().includes(libraryFilter.search.toLowerCase())) {
			return false;
		}
		if (libraryFilter.owner && libraryFilter.owner !== item.owner) {
			return false;
		}
		return true;
	}

	return (
		<React.Fragment>
			<FrameButton
				<React.ButtonHTMLAttributes<HTMLButtonElement>>
				forwardProps={{type: "button"}}
				color="purple"
				onClick={() => setDisplay(true)}
			>
				Browse / Upload
			</FrameButton>
			<ReactMediaLibrary
				isOpen={display}
				onClose={() => setDisplay(false)}
				fileUploadCallback={uploadCallback}
				fileLibraryList={assets.filter(filterItem).map(convertAssetToMediaLibraryItem)}
				filesSelectCallback={selectCallback}
				filesDeleteCallback={deleteCallback}
				finishUploadCallback={fetchAssets}
				topBarComponent={topBarComponent}
				acceptedTypes={["image/jpeg", "image/png"]}
				selectedItemsComponent={selectedItemsComponent}
				defaultSelectedItemIds={[props.asset?.id]}
			/>
		</React.Fragment>
	);
}

export default ReactMediaLibraryWrapper;
