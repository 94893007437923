import React, {ChangeEvent, Dispatch, FormEvent, ReactElement, SetStateAction, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {MenuCategory} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

interface Props {
    isOpen: boolean;
    menuCategories: Array<MenuCategory>;
    selectedCategories: Array<string>;
    onClose: () => void;
    onDone: (categoriesIds: Array<string>) => void;
}

function SelectPromotionMenuCategoriesModal(props: Props): ReactElement {
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);

    useEffect(() => {
        if (props.isOpen && props.selectedCategories) {
            setSelectedIds(props.selectedCategories);
        }
    }, [props.isOpen, props.selectedCategories]);

    function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
        const id = e.target.value;
        if (e.target.checked) {
            setSelectedIds(prevState => [...prevState, id]);
        } else {
            setSelectedIds(prevState => prevState.filter(val => val !== id));
        }
    }

    function renderOptions(categories: Array<MenuCategory>, depth = 0) {
        return categories.map(c => (
            <React.Fragment key={c.id}>
                <label style={{marginLeft: `${depth}rem`}}>
                    <input
                        type="checkbox"
                        value={c.id}
                        checked={selectedIds.includes(c.id)}
                        onChange={handleCheckboxChange}
                    />
                    {c.name}
                </label>
                {c.menuSubcategories?.length > 0 && renderOptions(c.menuSubcategories, depth + 1)}
            </React.Fragment>
        ))
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName={"select-promotion-categories-modal"}
        >
            <FrameModalHeader
                title={"Select menu categories for promotion"}
                toggle={props.onClose}
            />
            <FrameModalBody>
                {props.menuCategories &&
                    <fieldset>
                        <legend>Categories</legend>
                        {renderOptions(props.menuCategories)}
                    </fieldset>
                }

            </FrameModalBody>
            <FrameModalFooter>
                <FrameButton
                    <React.ButtonHTMLAttributes<HTMLButtonElement>>
                    color="lightBlue"
                    onClick={() => props.onDone(selectedIds)}
                >
                    Add Categories
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    )
}

export default SelectPromotionMenuCategoriesModal;