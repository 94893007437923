import React, {
	ChangeEvent,
	Dispatch,
	ReactElement,
	SetStateAction,
	useEffect,
	useState
} from "react";
import {GetUsersResponse, Merchant, UsersApi, UserType} from "@devour/client";
import {addError} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";
import {useDispatch, useSelector} from "react-redux";
import {ReactMediaLibraryFilters} from "./ReactMediaLibraryWrapper";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import {IStore} from "../../redux/defaultStore";

interface Props {
	filters: ReactMediaLibraryFilters;
	setFilters: Dispatch<SetStateAction<ReactMediaLibraryFilters>>;
}

function FileLibraryTopBar(props: Props): ReactElement {
	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [merchantList, setMerchantList] = useState<GetUsersResponse>();
	const merchantIdOptions = makeReactSelectOptions<Merchant>(merchantList?.users as Array<Merchant>, "id", (merchant) => merchant.firstName + " " + merchant.lastName + " - " + merchant.email);

	/**
	 * When modal opens reset the form to defaults & current brand.
	 *
	 */
	useEffect(() => {
		void getMerchantsForDropDown();
	}, []);

	/**
	 * Get the list of merchant accounts for inflating the drop-down/select input.
	 *
	 */
	async function getMerchantsForDropDown(): Promise<void> {

		try {
			const merchantsRes = await new UsersApi(getConfig(fullToken)).getUsers({
				type: [UserType.MERCHANT, UserType.STANDARDADMIN, UserType.GODADMIN],
				limit: 1000000,
				offset: 0,
			});

			setMerchantList(merchantsRes);
		} catch (e) {
			dispatch(await addError(e));
		}
	}

	/**
	 * Handle the merchant id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleMerchantIdOnChange(v: IReactSelectOption): void {
		props.setFilters((f) => {
			return {
				...f,
				owner: v?.value,
			}
		});
	}

	function onSearchChange(e: ChangeEvent<HTMLInputElement>): void {
		props.setFilters((f) => {
			return {
				...f,
				search: e.target.value,
			}
		});
	}

	return (
		<div className="react-media-library_file-library-top-bar">
			<div className="react-media-library_file-library-top-bar_search">
				<input
					type="search"
					value={props.filters.search}
					onChange={onSearchChange}
					placeholder="Search file titles"
				/>
			</div>
			<div className="react-media-library_file-library-top-bar_owner">
				<FrameOneReactSelect
					name="Uploader"
					placeholder="Select Uploader..."
					isClearable={true}
					isLoading={!merchantList}
					isDisabled={!merchantList}
					value={merchantIdOptions.find(m => m.value === props.filters.owner)}
					onChange={handleMerchantIdOnChange}
					options={merchantIdOptions}
				/>
			</div>
		</div>
	);
}

export default FileLibraryTopBar;
