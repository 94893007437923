import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {AdminsApi, CreateAdminBody, Token, UserType} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";

const defaultAddAdminForm: CreateAdminBody = {
	email: "",
	password: "",
	confirmPassword: "",
	firstName: "",
	lastName: "",
	type: undefined,
};

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function AddAdminModal(props: AddAdminModalProps): ReactElement {

	const [form, setForm] = useState<CreateAdminBody>(defaultAddAdminForm);
	const [useRandomPassword, setUseRandomPassword] = useState(false);

	/**
	 * Clean the form on open.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setForm(defaultAddAdminForm);
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setForm(defaultAddAdminForm);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function createOnChange(key: keyof CreateAdminBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Toggle between using random password or not.
	 *
	 */
	function toggleRandomPassword(): void {
		setUseRandomPassword(!useRandomPassword);
	}

	/**
	 * Call api to create new admin, reset form & close modal on success.
	 *
	 */
	async function submitAddNewAdmin(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new AdminsApi(getConfig(props.fullToken)).createAdmin({
				createAdminBody: {
					email: form.email || undefined,
					password: useRandomPassword ? undefined : (form.password || undefined),
					confirmPassword: useRandomPassword ? undefined : (form.confirmPassword || undefined),
					firstName: form.firstName || undefined,
					lastName: form.lastName || undefined,
					type: form.type,
				},
			});

			setForm(defaultAddAdminForm);
			setUseRandomPassword(false);
			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={closeHelper}
			size="sm"
			contentClassName="manage-admins-add-modal"
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Add New Admin"
			/>

			<form onSubmit={submitAddNewAdmin}>
				<FrameModalBody className="manage-admins-add-modal_body">
					<div>
						<label>
							Email*
						</label>
						<input
							value={form.email}
							placeholder="Email..."
							onChange={createOnChange("email")}
						/>
					</div>

					<div>
						<FrameOneCheckbox
							onToggle={toggleRandomPassword}
							checked={useRandomPassword}
						>
							Use Random Password
						</FrameOneCheckbox>
					</div>

					{!useRandomPassword && (
						<React.Fragment>
							<div>
								<label>
									Password*
								</label>
								<input
									type="password"
									value={form.password}
									placeholder="Password..."
									onChange={createOnChange("password")}
								/>
							</div>

							<div>
								<label>
									Confirm Password*
								</label>
								<input
									type="password"
									value={form.confirmPassword}
									placeholder="Confirm Password..."
									onChange={createOnChange("confirmPassword")}
								/>
							</div>
						</React.Fragment>
					)}

					<div>
						<label>
							Admin Role*
						</label>
						<select
							value={form.type}
							onChange={createOnChange("type")}
						>
							<option value="" selected disabled>Select Admin Role</option>
							<hr/>
							<option value={UserType.GODADMIN}>God Admin</option>
							<option value={UserType.STANDARDADMIN}>Standard Admin</option>
						</select>
					</div>

					<div>
						<label>
							First Name*
						</label>
						<input
							value={form.firstName}
							placeholder="First Name..."
							onChange={createOnChange("firstName")}
						/>
					</div>

					<div>
						<label>
							Last Name*
						</label>
						<input
							value={form.lastName}
							placeholder="Last Name..."
							onChange={createOnChange("lastName")}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="purple"
						forwardProps={{
							type: "submit",
						}}
					>
						Add Admin
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddAdminModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddAdminModal);
