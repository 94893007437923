import {ReactElement, useEffect, useState} from "react";
import {SiteConfigurationKey, NftsApi, Token, GetNftGroupingsResponse} from "@devour/client";
import React from "react";
import PageHeader from "../components/PageHeader";
import SiteConfigurationForm from "../components/forms/SiteConfigurationForm";
import FrameOneAccordions from "../components/utility/FrameOneAccordions";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";

interface StateProps {
	fullToken: Token;
}

interface Props {
}

function ManageSiteConfigurations(props: ManageSiteConfigurationsProps): ReactElement {
	const [nftGroupingsRes, setNftGroupingsRes] = useState<GetNftGroupingsResponse>(undefined);

	useEffect(() => {
		getDataForDropDowns().then().catch();
	}, []);

	async function getDataForDropDowns(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const _nftGroupings = await new NftsApi(getConfig(props.fullToken)).getNftGroupings({
				limit: 100000,
				offset: 0,
			});

			setNftGroupingsRes(_nftGroupings);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}


	return (
		<div className="manage-site-configuration">
			<PageHeader>
				<div>
					<h3>
						Site Configuration
					</h3>

					<p>
						Here you can view, add, and edit site configurations on the platform.
					</p>

				</div>
			</PageHeader>

			<div className="manage-site-configuration_body">
				<div className="manage-site-configuration_body_group">
					<h4>
						Banner Management
					</h4>
					<FrameOneAccordions
						items={[
							{
								key: SiteConfigurationKey.RESTAURANTSEARCHHERO,
								title: "Restaurant Search Hero",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.RESTAURANTSEARCHHERO}
									fields={["asset", "title", "description", "buttonText", "url"]}
									description={
										<p>
											This is the banner above the GoFrens filters on the restaurant search
											results
											page.
										</p>
									}
								/>,
							},
							{
								key: SiteConfigurationKey.GOFRENSHERO,
								title: "GoFrens Hero",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.GOFRENSHERO}
									fields={["asset", "title", "description", "buttonText", "url"]}
									description={
										<p>
											This is the banner at the top of the GoFrens page.
										</p>
									}
								/>,
							},
							{
								key: SiteConfigurationKey.GOVIPHERO,
								title: "GoVIP Hero",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.GOVIPHERO}
									fields={["asset", "title", "description", "buttonText", "url"]}
									description={
										<p>
											This is the banner at the top of the GoVIP page.
										</p>
									}
								/>,
							},
							{
								key: SiteConfigurationKey.DPAYHERO,
								title: "DPAY Hero (TODO)",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.DPAYHERO}
									fields={["asset", "title", "description", "buttonText", "url"]}
									description={
										<p>
											This is the banner at the top of the DPAY page.
										</p>
									}
								/>,
							},
							{
								key: SiteConfigurationKey.INDUSTRYPAGEHERO,
								title: "The Industry Hero (TODO)",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.INDUSTRYPAGEHERO}
									fields={["asset", "title", "description", "buttonText", "url"]}
									description={
										<p>
											This is the banner at the top of the The Industry page.
										</p>
									}
								/>,
							},
							{
								key: SiteConfigurationKey.INDUSTRYPROMOBANNER,
								title: "The Industry Promo Banner",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.INDUSTRYPROMOBANNER}
									fields={["title", "description", "nftGroupings"]}
									description={
										<p>
											This is the banner on the restaurant search page to notify customers about
											their $0 delivery fee promotion if they hold The Industry NFT.
										</p>
									}
								/>,
							},
						]}
					/>
				</div>
				<div className="manage-site-configuration_body_group">
					<h4>
						Marketplace Variables
					</h4>
					<FrameOneAccordions
						items={[
							{
								key: SiteConfigurationKey.MEALME,
								title: "Mealme",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.MEALME}
									fields={["user", "business"]}
									description={
										<p>
											Designate the merchant & owner for newly activated Mealme restaurants.
										</p>
									}
								/>,
							},
							{
								key: SiteConfigurationKey.REWARDSDPAYMENUORDERS,
								title: "DPAY Back Reward on Menu Orders",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.REWARDSDPAYMENUORDERS}
									fields={["percentage", "nftGroupings"]}
									description={
										<p>
											This is the DPAY back reward for holding any of the following NFTs after
											checking out.
										</p>
									}
								/>,
							},
							{
								key: SiteConfigurationKey.MENUORDERDELIVERYCHARGE,
								title: "Menu order delivery charge",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.MENUORDERDELIVERYCHARGE}
									fields={["amount"]}
									description={
										<p>
											This is the amount to charge customers when they choose delivery order.
										</p>
									}
								/>,
							},
							{
								key: SiteConfigurationKey.DEFAULTMARKETPLACEFEEDELIVERY,
								title: "Default Marketplace Fee (Delivery)",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.DEFAULTMARKETPLACEFEEDELIVERY}
									fields={["percentage"]}
									description={
										<p>
											This is the default DevourGO marketplace fee percentage for delivery orders.
										</p>
									}
								/>,
							},
							{
								key: SiteConfigurationKey.DEFAULTMARKETPLACEFEEPICKUP,
								title: "Default Marketplace Fee (Pickup)",
								body: <SiteConfigurationForm
									nftGroupingsRes={nftGroupingsRes}
									configKey={SiteConfigurationKey.DEFAULTMARKETPLACEFEEPICKUP}
									fields={["percentage"]}
									description={
										<p>
											This is the default DevourGO marketplace fee percentage for pickup orders.
										</p>
									}
								/>,
							},
						]}
					/>
				</div>
				<div className="manage-site-configuration_body_group">
					<div className="manage-site-configuration_body_group_section">
						<h4>
							GoVIP Point Management
						</h4>
						<FrameOneAccordions
							items={[
								{
									key: SiteConfigurationKey.DPAYLOADSREWARD,
									title: "DPAY Load Reward Points",
									body: <SiteConfigurationForm
										nftGroupingsRes={nftGroupingsRes}
										configKey={SiteConfigurationKey.DPAYLOADSREWARD}
										fields={["amount"]}
										description={
											<p>
												This is the number of points users earn for DPAY loads per USD
											</p>
										}
									/>,
								},
								{
									key: SiteConfigurationKey.INDUSTRYPASSPURCHASEREWARD,
									title: "Industry Pass Purchase Reward Points",
									body: <SiteConfigurationForm
										nftGroupingsRes={nftGroupingsRes}
										configKey={SiteConfigurationKey.INDUSTRYPASSPURCHASEREWARD}
										fields={["amount"]}
										description={
											<p>
												This is the number of points users earn when they purchase an Industry Pass.
											</p>
										}
									/>,
								},
								{
									key: SiteConfigurationKey.REFERAFRIENDFIRSTORDERREWARD,
									title: "Refer-A-Friend First Order Reward Points",
									body: <SiteConfigurationForm
										nftGroupingsRes={nftGroupingsRes}
										configKey={SiteConfigurationKey.REFERAFRIENDFIRSTORDERREWARD}
										fields={["amount"]}
										description={
											<p>
												This is the number of points to the referrer when the referree completes
												their first order.
											</p>
										}
									/>,
								},
								{
									key: SiteConfigurationKey.SIGNUPREWARD,
									title: "Sign-Up Reward Points",
									body: <SiteConfigurationForm
										nftGroupingsRes={nftGroupingsRes}
										configKey={SiteConfigurationKey.SIGNUPREWARD}
										fields={["amount"]}
										description={
											<p>
												This is the number of points users earn when they first sign up.
											</p>
										}
									/>,
								},
								{
									key: SiteConfigurationKey.LEVELUPGOVIPPOINTREWARD,
									title: "Level-Up GoVIP Reward Points",
									body: <SiteConfigurationForm
										nftGroupingsRes={nftGroupingsRes}
										configKey={SiteConfigurationKey.LEVELUPGOVIPPOINTREWARD}
										fields={["amount", "startDate", "endDate"]}
										description={
											<p>
												This is the number of GoVIP points users earn when they level up.
											</p>
										}
									/>,
								},
							]}
						/>
					</div>

					<div className="manage-site-configuration_body_group_section">
						<h4>
							DPAY Reward Management
						</h4>
						<FrameOneAccordions
							items={[
								{
									key: SiteConfigurationKey.LEVELUPDPAYREWARD,
									title: "Level-Up DPAY Reward",
									body: <SiteConfigurationForm
										nftGroupingsRes={nftGroupingsRes}
										configKey={SiteConfigurationKey.LEVELUPDPAYREWARD}
										fields={["bonusDpayType","amount", "startDate", "endDate"]}
										description={
											<p>
												This is the amount of DPAY users earn when they level up.
											</p>
										}
									/>,
								}
							]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props
		}
	});
}

type ManageSiteConfigurationsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageSiteConfigurations);
