import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {
	ServiceFee,
	CreateServiceFeeBody,
	GetNftGroupingsResponse, NftsApi,
	ServiceFeesApi,
	Token
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AddDiscountSelectGroupingsModal from "./AddDiscountSelectGroupingsModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

const defaultFormValues: CreateServiceFeeBody = {
	priority: 0,
	percentageDelivery: 0,
	percentagePickup: 0,
	amountMax: 0,
	name: "",
	description: "",
	nftGroupings: [],
};

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	serviceFee?: ServiceFee;
	onClose: () => void;
	onDone: () => void;
}

function AddEditServiceFeeModal(props: AddEditServiceFeeModalProps): ReactElement {

	const [formValues, setFormValues] = useState<CreateServiceFeeBody>({...defaultFormValues, ...props?.serviceFee});
	const [nftGroupingsRes, setNftGroupingsRes] = useState<GetNftGroupingsResponse>(undefined);
	const [showGroupingsModal, setShowGroupingsModal] = useState<boolean>(false);

	useEffect(() => {
		getDataForDropDowns().then().catch();
	}, []);

	useEffect(() => {
		if (props.isOpen) {
			setFormValues({
				...defaultFormValues,
				...props?.serviceFee,
			});
		}
	}, [props.isOpen]);

	async function getDataForDropDowns(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const _nftGroupings = await new NftsApi(getConfig(props.fullToken)).getNftGroupings({
				limit: 100000,
				offset: 0,
			});

			setNftGroupingsRes(_nftGroupings);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof CreateServiceFeeBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> {
		return (e) => {
			setFormValues({
				...formValues,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Handle the number format input for the amount field on change.
	 *
	 * @param values
	 */
	function percentageDeliveryOnChange(values): void {
		let newAmount = values.floatValue;

		if (newAmount > 100) {
			newAmount = 100;
		}

		setFormValues({
			...formValues,
			percentageDelivery: newAmount,
		});
	}

	/**
	 * Handle the number format input for the amount field on change.
	 *
	 * @param values
	 */
	function percentagePickupOnChange(values): void {
		let newAmount = values.floatValue;

		if (newAmount > 100) {
			newAmount = 100;
		}

		setFormValues({
			...formValues,
			percentagePickup: newAmount,
		});
	}

	/**
	 * Handle the number format input for the amount field on change.
	 *
	 * @param values
	 */
	function serviceFeeAmountMaxOnChange(values): void {
		setFormValues({
			...formValues,
			amountMax: values.floatValue,
		});
	}

	/**
	 * Handle the number format input for the amount field on change.
	 *
	 * @param values
	 */
	function serviceFeePriorityOnChange(values): void {
		setFormValues({
			...formValues,
			priority: values.floatValue,
		});
	}

	/**
	 * For when the admin selects an nft grouping from the modal designed for such.
	 * If the grouping is already selected, it will be removed from the selected list,
	 * if the grouping is not already selected then it will be added to the list.
	 *
	 * @param id
	 */
	function handleSelectGrouping(id: string): void {
		const currentlySelectedGroupings = formValues.nftGroupings;
		const foundIndex = currentlySelectedGroupings.indexOf(id);
		if (foundIndex > -1) {
			currentlySelectedGroupings.splice(foundIndex, 1);
		} else {
			currentlySelectedGroupings.push(id);
		}

		setFormValues(r => {
			return {
				...r,
				nftGroupings: currentlySelectedGroupings,
			}
		});
	}

	async function submitUpdatedOrderDiscount(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();

		props.dispatch(incrementLoading());

		try {
			if (props.serviceFee) {
				// update existing service fee
				await new ServiceFeesApi(getConfig(props.fullToken)).updateServiceFee({
					id: props.serviceFee.id,
					createServiceFeeBody: formValues,
				});
			} else {
				// create existing service fee
				await new ServiceFeesApi(getConfig(props.fullToken)).createServiceFee({
					createServiceFeeBody: formValues,
				});

			}

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function toggleGroupingsModal(): void {
		setShowGroupingsModal(s => !s);
	}

	return (
		<React.Fragment>
			<AddDiscountSelectGroupingsModal
				isOpen={showGroupingsModal}
				nftGroupings={nftGroupingsRes?.nftGroupings}
				selectedGroupings={formValues?.nftGroupings}
				onSelect={handleSelectGrouping}
				onClose={toggleGroupingsModal}
			/>

			<FrameOneModal
				isOpen={props.isOpen}
				toggle={props.onClose}
				contentClassName="add-edit-service-fee-modal"
			>
				<FrameModalHeader
					title="Edit Service Fee"
					toggle={props.onClose}
				/>

				<form onSubmit={submitUpdatedOrderDiscount}>
					<FrameModalBody className="add-edit-service-fee-modal_body">
						<div>
							<label>
								Name
							</label>
							<input
								value={formValues?.name}
								placeholder="Name..."
								onChange={inputOnChange("name")}
							/>
						</div>

						<div>
							<label>
								Description*
							</label>
							<input
								value={formValues?.description}
								placeholder="Describe this service fee to your customers..."
								onChange={inputOnChange("description")}
							/>
						</div>

						<div>
							<label>
								Service Fee Percentage (Delivery Orders)*
							</label>
							<NumberFormat
								placeholder="Service Fee Percentage (Delivery Orders)..."
								suffix="%"
								value={formValues?.percentageDelivery}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={2}
								onValueChange={percentageDeliveryOnChange}
							/>
						</div>

						<div>
							<label>
								Service Fee Percentage (Pickup Orders)*
							</label>
							<NumberFormat
								placeholder="Service Fee Percentage (Pickup Orders)..."
								suffix="%"
								value={formValues?.percentagePickup}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={2}
								onValueChange={percentagePickupOnChange}
							/>
						</div>

						<div>
							<label>
								Max Absolute Amount*
							</label>
							<NumberFormat
								placeholder="Max Absolute Amount..."
								prefix="$"
								value={formValues?.amountMax}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={2}
								onValueChange={serviceFeeAmountMaxOnChange}
							/>
							<p className="form-tip">
								Maximum dollar amount that can be charged.
								Leave "0" for unlimited / no limit.
							</p>
						</div>

						<div>
							<label>
								Priority*
							</label>
							<NumberFormat
								placeholder="Priority..."
								value={formValues.priority}
								allowLeadingZeros={false}
								allowNegative={true}
								decimalScale={1}
								onValueChange={serviceFeePriorityOnChange}
							/>
							<p className="form-tip">
								Service fees are loaded by priority in descending order.
								The customer will be assigned the first service fee configuration that they qualify for.
								Service fees with a higher priority should be more beneficial to the customer.
							</p>
						</div>

						<div>
							<label>
								NFT Groupings
							</label>
							{formValues?.nftGroupings.length < 1 ? (
								<p>
									No nft groupings selected.
								</p>
							) : (
								<ul>
									{formValues?.nftGroupings.map((g, i) => {
										return (
											<li key={`taxonomy_${i}`}>
												{nftGroupingsRes?.nftGroupings?.find(bt => bt.id === g).name}
											</li>
										);
									})}
								</ul>
							)}

							<FrameButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="lightBlue"
								onClick={toggleGroupingsModal}
								forwardProps={{
									type: "button"
								}}
							>
								Select NFT Groupings
							</FrameButton>

							<p className="form-tip">
								If configured, users will need to own an nft of one of the selected groupings to be
								eligible for this tier of service fee. Leave blank to allow all users to utilize this
								tier.
							</p>
						</div>

					</FrameModalBody>

					<FrameModalFooter>
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="lightBlue"
							forwardProps={{
								type: "submit"
							}}
						>
							Save Changes
						</FrameButton>
					</FrameModalFooter>
				</form>
			</FrameOneModal>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props
		}
	});
}

type AddEditServiceFeeModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddEditServiceFeeModal);
