import React, {ChangeEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Token, User, CreateAssetRequest, AssetsApi, Asset} from "@devour/client";
import {BsCardImage} from "react-icons/bs";
import FrameButton from "../../components/buttons/FrameButton";
import ReactMediaLibraryWrapper from "../mediaLibrary/ReactMediaLibraryWrapper";

interface Props {
	disabled?: boolean;
	asset?: Asset;
	assetName: string;
	assetDescription: string;
	label: string;
	description: ReactNode;
	/** https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept **/
	acceptTypes?: string;
	onNew?: (assetId: string) => void;
	onUpdate?: (assetId: string) => void;
	onUnsetImage?: () => void;
	onUnsetImageError?: () => void;
}

function ImageUploadCard(props: Props): ReactElement {

	return (
		<div className="image-upload-card">
			<div className="image-upload-card_image">
				{(props.asset) ? (
					<div className="image-upload-card_image_photo">
						<img
							src={props.asset.url}
							alt=""
						/>
					</div>
				) : (
					<div className="image-upload-card_image_placeholder">
						<BsCardImage/>
					</div>
				)}
			</div>
			<div className="image-upload-card_info">
				<h4 className="image-upload-card_label">
					{props.label}
				</h4>
				<div className="image-upload-card_description">
					{props.description}
				</div>
				<div className="image-upload-card_actions">
					{(!props.disabled) && (
						<React.Fragment>
							<ReactMediaLibraryWrapper
								{...props}
							/>
							{(props.asset) && (
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									forwardProps={{type: "button"}}
									color="danger"
									onClick={props.onUnsetImage}
								>
									Unset Image
								</FrameButton>
							)}
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
}

ImageUploadCard.defaultProps = {
	acceptTypes: ".jpg,.jpeg,.png",
};

export default ImageUploadCard;
