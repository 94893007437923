import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    ReferredUserData,
    ReferredUsersResponse,
    User,
    Token, ReferralsApi
} from "@devour/client";
import {IStore} from "../../../redux/defaultStore";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameOneModal from "../modalComponents/FrameOneModal";
import getConfig from "../../../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import ConfirmRemoveReferralModal from "./ConfirmRemoveReferralModal";
import {csvExport} from "../../../utils/csvExport";
import FrameButton from "../../buttons/FrameButton";

interface UserReferralModalProps {
    isOpen: boolean;
    onClose: () => void;
    user: User;
}

export interface ReferralInfo {
    firstName: string;
    lastName: string;
    email: string;
    referralId: string;
    referralDate: string;
}

function ManageReferredUsersModal(props: UserReferralModalProps): ReactElement {

    const [referralsData, setReferralData] = useState<ReferredUsersResponse>();
    const [referralInfo, setReferralInfo] = useState<ReferralInfo>();
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const fullToken = useSelector<IStore, Token | undefined>(state => state.metaStore.fullToken);
    const dispatch = useDispatch();

    useEffect(() => {
        void fetchReferredUsers();
    }, [props.isOpen]);

    async function fetchReferredUsers() {
        if (props.isOpen) {
            dispatch(incrementLoading());

            try {
                const response = await new ReferralsApi(getConfig(fullToken)).getReferredUsers({id: props.user.id});
                setReferralData(response);
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }
    }

    // Set referral info to send to the confirmation modal which will display this info
    function handleReferralInfo(fn: string, ln: string, email: string, referralId: string, referralDate: string) {
        setShowConfirmationModal(!showConfirmationModal);
        setReferralInfo({
            firstName: fn,
            lastName: ln,
            email: email,
            referralId,
            referralDate
        });
    }

    function toggleConfirmationModal() {
        setShowConfirmationModal(!showConfirmationModal);
    }

    function closeHelper(): void {
        props.onClose();
        setReferralData(undefined);
    }

    function renderReferralData(): ReactElement {
        const referredUsersData: Array<ReferredUserData> = referralsData.referredUsersData;

        return (<>
            {referredUsersData.map((referee) => (
                <div className="manage-user-referrals_referral">

                    <div className="manage-user-referrals_referral-info">
                        <b> Name: </b>{referee.firstName} {referee.lastName}<br/>
                        <b> Email: </b>{referee.email}<br/>
                        <b> Referral Date:</b> {referee.createdAt}
                    </div>

                    <FrameButton
                        className="manage-user-referrals_referral-button"
                        color="danger"
                        onClick={() => handleReferralInfo(referee.firstName, referee.lastName, referee.email, referee.referralId, referee.createdAt)}
                    >
                        UnRefer
                    </FrameButton>

                    {showConfirmationModal && (
                        <ConfirmRemoveReferralModal
                            onConfirmation={fetchReferredUsers}
                            isOpen={showConfirmationModal}
                            onClose={toggleConfirmationModal}
                            referralInfo={referralInfo}
                        />
                    )}
                </div>

            ))}
        </>)
    }

    // Export to CSV
    async function exportReferralsToCsv() {

        const headers = [
            "Referral Date",
            "First Name",
            "Last Name",
            "Email",
            "Referral Id",
        ];

        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-US'); // 'en-US' locale formats the date as 'MM/DD/YYYY'

        const filename: string =
            `referrals-report-${formattedDate}.csv`;

        const formattedContent = referralsData.referredUsersData.map((referral) => {
            return [
                referral.createdAt,
                referral.firstName,
                referral.lastName,
                referral.email,
                referral.referralId,
            ];
        });

        csvExport(formattedContent, headers, filename);

    }


    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={closeHelper}
            size="sm"
            contentClassName="user-experience-modal"
        >
            <FrameModalHeader
                toggle={closeHelper}
                title="User Referrals"
            />
            <FrameModalBody className="manage-user-referrals_modal">
                {!referralsData ? (
                    <div className="spinner"/>
                ) : (
                    referralsData.referredUsersData.length > 0 ? (
                        <>
                            <div className="manage-user-referrals_total-referred-users">
                                <p><b>Number of Referred Users:</b> {referralsData.referredUsersData.length}</p>
                            </div>
                            <div className="manage-user-referrals_line"></div>
                            <h2 className="manage-user-referrals_title">Referred Users</h2>
                            {renderReferralData()}

                            <FrameButton
                                className="manage-user-referrals_csv-button"
                                color="lightBlue"
                                onClick={async () => await exportReferralsToCsv()}
                            >
                                Export to CSV
                            </FrameButton>
                        </>
                    ) : (
                        <p><b>There are no referrals for this user.</b></p>
                    )
                )}
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default ManageReferredUsersModal;
