import React, {Fragment, ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {AccountExperienceTransaction, AccountLevel, Token, User, UsersApi} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import getConfig from "../../utils/getConfig";
import {addError} from "../../redux/meta/MetaActions";
import SectionLabel from "../SectionLabel";
import FrameOneCard from "../FrameOneCard";

interface StateProps {
    fullToken: Token;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    user: User;
}

function UserExperienceModal(props: UserExperienceModalProps): ReactElement {

    const [level, setLevel] = useState<AccountLevel>();

    useEffect(() => {
        if (props.isOpen) {
            new UsersApi(getConfig(props.fullToken)).getLevel({id: props.user.id})
                .then(setLevel)
                .catch(async (err) => {
                    props.dispatch(await addError(err));
                });
        }
    }, [props.isOpen]);

    function closeHelper(): void {
        props.onClose();
        setLevel(undefined);
    }

    function renderExperienceTransaction(transaction: AccountExperienceTransaction, i: number): ReactNode {
        const date = new Date(transaction.createdAt);

        return (
            <FrameOneCard
                key={transaction.id}
                className="user-experience-modal_transaction-entry"
            >
                <div className="user-experience-modal_transaction-entry_top">
                    <h6>{date.toLocaleString()}</h6>
                    <p>{transaction.subject}</p>
                </div>
                <div className="user-experience-modal_transaction-entry_bottom">
                    <h6>{transaction.amount} XP</h6>

                    {transaction.notes && (
                        <aside>
                            <label>note:</label>
                            <p>{transaction.notes}</p>
                        </aside>
                    )}

                    {transaction.adminNotes && (
						<aside>
                            <label>admin note:</label>
                            <p>{transaction.adminNotes}</p>
                        </aside>
                    )}
                </div>
            </FrameOneCard>
        );
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={closeHelper}
            size="sm"
            contentClassName="user-experience-modal"
        >
            <FrameModalHeader
                toggle={closeHelper}
                title="User Experience"
            />
            <FrameModalBody className="user-experience-modal_body">
                {level == null ? (
                    <div className="spinner"/>
                ) : (
                    <React.Fragment>
                        <SectionLabel
                            title="Level"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{level.level}</p>
                        </SectionLabel>

                        <SectionLabel
                            title="Total Experience"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{level.totalExperience}</p>
                        </SectionLabel>

                        <SectionLabel
                            title="Experience Required For Next Level"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{level.experienceRequiredForNextLevel}</p>
                        </SectionLabel>

                        <SectionLabel
                            title="Experience Gained At This Level"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{level.experienceGainedAtThisLevel}</p>
                        </SectionLabel>

                        <SectionLabel
                            title="User Owns Industry Collection Nft"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{level.userOwnsIndustryCollectionNft.toString()}</p>
                        </SectionLabel>

                        {level.transactions.length > 0 ? level.transactions.map(renderExperienceTransaction) : (
                            <p><b>There are no experience transactions for this user.</b></p>
                        )}
                    </React.Fragment>
                )}
            </FrameModalBody>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.metaStore.fullToken,
            ...props,
        }
    });
}

type UserExperienceModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(UserExperienceModal);
