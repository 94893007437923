import React, {ReactElement, useState} from "react";
import {MenuOrder, MenuOrderStatus} from "@devour/client";
import FrameButton from "../buttons/FrameButton";
import RefundMenuOrderModal from "../modals/RefundMenuOrderModal";

/**
 * Statuses that mark the order as confirmed by the customer. Basically all the statuses post-paid.
 */
export const menuOrderFinalizedStatuses: Array<MenuOrderStatus> = [
	MenuOrderStatus.PAID,
	MenuOrderStatus.PROCESSING,
	MenuOrderStatus.RECEIVED,
	MenuOrderStatus.DELIVERYSERVICE,
	MenuOrderStatus.CUSTOMERPICKUP,
	MenuOrderStatus.DRIVERPICKUP,
	MenuOrderStatus.OUTFORDELIVERY,
	MenuOrderStatus.CUSTOMERPICKEDUP,
	MenuOrderStatus.DRIVERATDESTINATION,
	MenuOrderStatus.DELIVERED,
	MenuOrderStatus.OPENISSUE,
	MenuOrderStatus.RESOLVED,
	MenuOrderStatus.ERRORDEVOUR,
	MenuOrderStatus.ERRORRESTAURANT,
	MenuOrderStatus.ERRORMEALME,
	MenuOrderStatus.ERRORDELIVERY,
	MenuOrderStatus.CUSTOMERCANCELLED,
	MenuOrderStatus.CHATINITIATED,
	MenuOrderStatus.REFUNDED,
	MenuOrderStatus.SYSTEMCANCELLED,
	MenuOrderStatus.AUTOREFUNDED,
];

/**
 * Statuses that mark the order as refunded.
 */
 const menuOrderRefundedStatuses: Array<MenuOrderStatus> = [
	MenuOrderStatus.REFUNDED,
	MenuOrderStatus.AUTOREFUNDED,
];

interface Props {
	menuOrder: MenuOrder;
	onDone: () => void;
}

function MenuOrderRefund(props: Props): ReactElement {
	const [showRefundModal, setShowRefundModal] = useState<boolean>(false);

	function onRefund() {
		setShowRefundModal(false);
		props.onDone();
	}

	if (!menuOrderFinalizedStatuses.includes(props.menuOrder.status)) {
		return (
			<div className="menu-order-refund">
				<h3>
					Refund
				</h3>
				<p>
					Order has not been paid yet.
				</p>
			</div>
		);
	}

	if (!menuOrderRefundedStatuses.includes(props.menuOrder.status)) {
		return (
			<div className="menu-order-refund">
				<RefundMenuOrderModal
					menuOrder={props.menuOrder}
					isOpen={showRefundModal}
					onClose={() => setShowRefundModal(false)}
					onDone={onRefund}
				/>
				<h3>
					Refund
				</h3>
				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="danger"
					onClick={() => setShowRefundModal(true)}
				>
					Refund Customer Order
				</FrameButton>
			</div>
		);
	}

	return (
		<div className="menu-order-refund">
			<h3>
				Refund
			</h3>
			<div className="menu-order-refund_body">
				<p>
					Nullify Promos: {(props.menuOrder.discountNullify) ? "Yes" : "No"}
				</p>
				<p>
					Refund Reason: {props.menuOrder.refunds[0]?.reason}
				</p>
				<p>
					Responsible Party: {props.menuOrder.refunds[0]?.responsibility}
				</p>
				<p>
					Responsible Party Amount: ${props.menuOrder.refunds[0]?.responsiblePartyAmount.toFixed(2)}
				</p>
				<p>
					Notes: {props.menuOrder.refunds[0]?.notes}
				</p>
				<p>
					Fiat: ${props.menuOrder.refunds[0]?.fiat.toFixed(2)}
				</p>
				<p>
					DPAY: {props.menuOrder.refunds[0]?.dpay} DPAY
				</p>
				<p>
					Rewards Clawback: {props.menuOrder.refunds[0]?.rewardsClawback} DPAY
				</p>
				<p>
					Payment Transfer Reversal: ${props.menuOrder.refunds[0]?.transferReverseAmount.toFixed(2)}
				</p>
			</div>
		</div>
	);
}

export default MenuOrderRefund;
