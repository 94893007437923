import {IconType} from "react-icons";
import {FaBitcoin, FaStore, FaTrademark, FaUserShield} from "react-icons/fa";
import {FiCoffee, FiUsers} from "react-icons/fi";
import {GiMeepleGroup, GiPayMoney, GiThreeFriends} from "react-icons/gi";
import {MdPayment, MdTrackChanges, MdOutlineIosShare, MdOutlineAddBusiness} from "react-icons/md";
import {RiDashboardLine} from "react-icons/ri";
import {IoFastFoodOutline} from "react-icons/io5";
import {cloneDeep} from "lodash";
import {isDev} from "./isDev";
import {CgList} from "react-icons/cg";
import {TbReportAnalytics, TbShoppingCartDiscount} from "react-icons/tb";
import {HiOutlineDocumentCheck} from "react-icons/hi2";
import {AiFillDatabase, AiOutlineLink, AiOutlineSetting} from "react-icons/ai";
import {BiFoodMenu} from "react-icons/bi";
import {TfiIdBadge} from "react-icons/tfi";
import {BsSpeedometer} from "react-icons/bs";
import {RiCoupon2Line} from "react-icons/ri";
export interface ISideBarNavigationLink {
	title: string;
	route: string;
	icon: IconType;
}

const defaultSideBarLinks: Array<ISideBarNavigationLink> = [
	{
		title: "Dashboard",
		route: "/dashboard",
		icon: RiDashboardLine,
	},
	{
		title: "Manage Admins",
		route: "/manage-admins",
		icon: FaUserShield,
	},
	{
		title: "Manage Users",
		route: "/manage-users",
		icon: FiUsers,
	},
	{
		title: "Referral Reward Thresholds",
		route: "/referral-rewards-thresholds",
		icon: GiThreeFriends,
	},
	{
		title: "GoVip Point Multipliers",
		route: "/manage-go-vip-point-multipliers",
		icon: RiCoupon2Line,
	},
	{
		title: "NFT Groupings & Communities",
		route: "/manage-nft-groupings",
		icon: GiMeepleGroup,
	},
	{
		title: "Manage NFT Trackers",
		route: "/manage-nft-trackers",
		icon: MdTrackChanges,
	},
	{
		title: "DPAY Withdraw Requests",
		route: "/withdraw-requests",
		icon: GiPayMoney,
	},
	{
		title: "Merchant Users",
		route: "/merchant-users",
		icon: FaStore,
	},
	{
		title: "Merchants",
		route: "/merchants",
		icon: IoFastFoodOutline,
	},
	{
		title: "Merchant Categories",
		route: "/merchant-categories",
		icon: CgList,
	},
	{
		title: "Manage Point of Sales",
		route: "/manage-pos",
		icon: AiOutlineLink,
	},
	{
		title: "Manage Brands",
		route: "/manage-brands",
		icon: FaTrademark,
	},
	{
		title: "Promos",
		route: "/promos",
		icon: TbShoppingCartDiscount,
	},
	{
		title: "Merchant Agreement Terms",
		route: "/merchant-agreement-terms",
		icon: HiOutlineDocumentCheck,
	},
	{
		title: "Menu Orders",
		route: "/menu-orders",
		icon: BiFoodMenu,
	},
	{
		title: "DPAY Purchases",
		route: "/dpay-purchases",
		icon: FaBitcoin,
	},
	{
		title: "Industry Pass Minting",
		route: "/industry-pass-minting",
		icon: TfiIdBadge,
	},
	{
		title: "Third Parties",
		route: "/third-party-on-boarding",
		icon: MdOutlineAddBusiness,
	},
	{
		title: "Site Configurations",
		route: "/site-configurations",
		icon: AiOutlineSetting,
	},
	{
		title: "Service Fees",
		route: "/service-fees",
		icon: MdPayment,
	},
	{
		title: "Industry Collection Payouts Report",
		route: "/industry-collection-payouts-report",
		icon: TbReportAnalytics,
	},
	{
		title: "Configure SEO Meta tags",
		route: "/seo-configurations",
		icon: MdOutlineIosShare,
	},
	{
		title: "Velocity Reporting",
		route: "/velocity-reporting",
		icon: BsSpeedometer,
	},
	{
		title: "API Logs",
		route: "/api-responses",
		icon: AiFillDatabase,
	},
];

/**
 * Utility to generate dynamic list of navigation pages for the sidebar.
 */
export function getSideBarNavigationLinks(): Array<ISideBarNavigationLink> {
	const adjustedSideBarLinks: Array<ISideBarNavigationLink> = cloneDeep(defaultSideBarLinks);

	// Add dev only links
	if (isDev()) {
		adjustedSideBarLinks.splice(0, 0, {
			title: "Demo Page",
			route: "/demo",
			icon: FiCoffee,
		});
	}

	return adjustedSideBarLinks;
}
