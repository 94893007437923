import React, {ReactElement, useEffect, useState} from "react";
import {
    BannedUser, GoFrensApi,
    NftGrouping, UsersApi,
} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading} from "../../redux/meta/MetaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameButton from "../buttons/FrameButton";

interface Props {
    isOpen: boolean;
    grouping: NftGrouping;
    onClose: () => void;
}

function ManageBannedCommunityUsersModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const [bannedUsers, setBannedUsers] = useState<Array<BannedUser>>(undefined);

    useEffect(() => {
        if (props.isOpen) {
            void getBannedUsers();

        } else {
            setBannedUsers(undefined);
        }
    }, [props.isOpen]);

    async function getBannedUsers(): Promise<void> {
        try {
            const res = await new GoFrensApi(getConfig(fullToken)).getUsersBannedFromCommunity({
                id: props.grouping.id
            });
            setBannedUsers(res.bannedUsers);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function unbanUser(userId: string): Promise<void> {
        try {
            await new UsersApi(getConfig(fullToken)).unbanUserFromCommunity({
                banUserFromCommunityBody: {
                    userId: userId,
                    groupingId: props.grouping.id
                }
            });
            props.onClose();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderBannedUsers(): ReactElement | Array<ReactElement> {
        if (!bannedUsers.length) {
            return (
                <p>There are no users banned from this community.</p>
            );
        }
        return bannedUsers.map((user) => {
            return (
                <div key={user.userId}>
                    <p><strong>Nickname:</strong> {user.nickname}</p>
                    <p><strong>Email: </strong>{user.email}</p>
                    <FrameButton
                        color="lightBlue"
                        onClick={async () => await unbanUser(user.userId)}
                    >
                        Unban User
                    </FrameButton>
                </div>
            );
        })
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="contact-modal"
        >
            <FrameModalHeader
                title="Manage Banned Users"
                toggle={props.onClose}
            />
            <FrameModalBody className="contact-modal_body">
                {bannedUsers && renderBannedUsers()}
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default ManageBannedCommunityUsersModal;
