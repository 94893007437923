import React, {ReactElement} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {GoVipApi, GoVipPointMultiplier} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";


interface DeleteGoVipPointMultiplierProps {
    isOpen: boolean;
    onClose: () => void;
    onDone: () => void;
    goVipPointMultiplier: GoVipPointMultiplier;
}

function DeleteGoVipPointMultiplierModal(props: DeleteGoVipPointMultiplierProps): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store:IStore) => store.metaStore.fullToken);

    async function confirmDelete(): Promise<void> {
        dispatch(incrementLoading());

        try {
            await new GoVipApi(getConfig(fullToken)).deleteGoVipPointMultiplier({
                    id: props.goVipPointMultiplier.id
                }
            );

            props.onDone();

        } catch (err) {
            dispatch(await addError(err));
        } finally {
            dispatch(decrementLoading());
        }
    }


    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
        >
            <FrameModalHeader
                toggle={props.onClose}
                title="Deleting GoVIP Point Multiplier"
            />
            <FrameModalBody>
                <p>
                    This action will be permanent. Are you sure you want to delete this GoVIP Point Multiplier?
                </p>
            </FrameModalBody>
            <FrameModalFooter>
                <FrameButton
                    color="lightBlue"
                    onClick={props.onClose}
                >
                    Cancel
                </FrameButton>
                <FrameButton
                    color="danger"
                    onClick={confirmDelete}
                >
                    Delete
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default DeleteGoVipPointMultiplierModal;
