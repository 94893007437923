import React, {ReactElement, useState} from "react";
import {
    ErrorType,
    BusinessPeriod,
    BusinessDays
} from "@devour/client";
import FrameButton from "../buttons/FrameButton";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {PatternFormat} from "react-number-format";
import {NumberFormatValues} from "react-number-format/types/types";
import {IStore} from "../../redux/defaultStore";
import {connect, ConnectedProps} from "react-redux";
import {addError} from "../../redux/meta/MetaActions";
import moment from "moment";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

interface FormValues {
    timeStart: NumberFormatValues;
    timeEnd: NumberFormatValues;
}


const defaultValues: FormValues = {
    timeStart: {
        floatValue: undefined,
        formattedValue: "",
        value: ""
    },
    timeEnd: {
        floatValue: undefined,
        formattedValue: "",
        value: ""
    },
};

interface StateProps {
}

interface Props {
    day?: BusinessDays;
    onClose: () => void;
    onUpdate: (period: BusinessPeriod) => void;
}

function BusinessHoursModal(props: BusinessHoursModalProps): ReactElement {

    const [formValues, setFormValues] = useState<FormValues>(defaultValues);

    /**
     * Handle the time start input onChange.
     *
     * @param timeStart
     */
    function timeStartOnChange(timeStart: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            timeStart,
        });
    }

    /**
     * Handle the time end input onChange.
     *
     * @param timeEnd
     */
    function timeEndOnChange(timeEnd: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            timeEnd,
        });
    }

    async function onFormSubmit(): Promise<void> {
        if (!moment(formValues.timeStart.formattedValue, 'HH:mm', true).isValid()) {
            props.dispatch(await addError({
                type: ErrorType.APP,
                message: "Opening time is invalid. Times are in 24 hour format.",
            }));
            return;
        }
        if (!moment(formValues.timeEnd.formattedValue, 'HH:mm', true).isValid()) {
            props.dispatch(await addError({
                type: ErrorType.APP,
                message: "Closing time is invalid. Times are in 24 hour format.",
            }));
            return;
        }
        if (formValues.timeStart.formattedValue === "24:00" || formValues.timeEnd.formattedValue === "24:00") {
            props.dispatch(await addError({
                type: ErrorType.APP,
                message: "Time can not exceed 23:59",
            }));
            return;
        }

        const period: BusinessPeriod = {
            timeStart: formValues.timeStart.formattedValue,
            timeEnd: formValues.timeEnd.formattedValue,
        };
        props.onUpdate(period);
        setFormValues(defaultValues);
    }

    function onClose() {
        setFormValues(defaultValues);
        props.onClose();
    }

    return (
        <FrameOneModal
            isOpen={!!props.day}
            toggle={onClose}
            size="sm"
            contentClassName="business-hours-modal"
        >
            <FrameModalHeader
                title="Add Business Hours"
                toggle={onClose}
            />

            <FrameModalBody className="business-hours-modal_body">
                <h3>Add time block on {props.day}</h3>

                <div className="business-hours-modal_row">
                    <div className="business-hours-modal_field business-hours-modal_time-start-container">
                        <label>Start Time</label>
                        <PatternFormat
                            value={formValues.timeStart.value}
                            valueIsNumericString={true}
                            type="tel"
                            format="##:##"
                            placeholder="HH:MM"
                            className="business-hours-modal_body_time-start"
                            onValueChange={timeStartOnChange}
                        />
                    </div>

                    <div className="business-hours-modal_field business-hours-modal_time-end-container">
                        <label>End Time</label>
                        <PatternFormat
                            value={formValues.timeEnd.value}
                            valueIsNumericString={true}
                            type="tel"
                            format="##:##"
                            placeholder="HH:MM"
                            className="business-hours-modal_body_time-end"
                            onValueChange={timeEndOnChange}
                        />
                    </div>
                </div>
            </FrameModalBody>
            <FrameModalFooter>
                <FrameButton
                    <React.ButtonHTMLAttributes<HTMLButtonElement>>
                    onClick={onFormSubmit}
                    color="purple"
                    className="business-hours-modal_submit-button"
                    forwardProps={{type: "button"}}
                >
                    Add
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            ...props,
        }
    });
}

type BusinessHoursModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(BusinessHoursModal);
