import React, {ChangeEventHandler, ReactElement, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {ChangeUserPasswordBody, Token, User, UsersApi} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {IStore} from "../../redux/defaultStore";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneModal from "./modalComponents/FrameOneModal";

type ChangeUserPasswordBodyFrontend = Pick<ChangeUserPasswordBody, "password" | "confirmPassword">;

const defaultUpdateUserPasswordForm: ChangeUserPasswordBodyFrontend = {
	password: "",
	confirmPassword: "",
};

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	user: User;
	onClose: () => void;
	onDone: () => void;
}

function ManageUsersUpdatePasswordModal(props: ManageUsersUpdatePasswordModalProps): ReactElement {

	const [form, setForm] = useState<ChangeUserPasswordBodyFrontend>(defaultUpdateUserPasswordForm);

	/**
	 * Reset form & close modal.
	 *
	 */
	function closeHelper(): void {
		setForm(defaultUpdateUserPasswordForm);
		props.onClose();
	}

	/**
	 * Dynamic on change for the form fields.
	 *
	 * @param key
	 */
	function createOnChange(key: keyof ChangeUserPasswordBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Call api to update password,
	 * then reset the form & close the modal.
	 *
	 */
	async function submitUpdateUserPassword(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).changeUserPassword({
				id: props.user?.id,
				changeUserPasswordBody: {
					password: form.password,
					confirmPassword: form.confirmPassword,
				},
			});

			setForm(defaultUpdateUserPasswordForm);
			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={closeHelper}
			contentClassName="manage-users-password-modal"
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Update User Password"
			/>

			<form onSubmit={submitUpdateUserPassword}>
				<FrameModalBody className="manage-users-password-modal_body">
					<div>
						<label>
							New Password*
						</label>
						<input
							type="password"
							value={form.password}
							placeholder="Password"
							onChange={createOnChange("password")}
						/>
					</div>

					<div>
						<label>
							Confirm New Password*
						</label>
						<input
							type="password"
							value={form.confirmPassword}
							placeholder="Confirm Password"
							onChange={createOnChange("confirmPassword")}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="purple"
						forwardProps={{
							type: "submit",
						}}
					>
						Update Password
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageUsersUpdatePasswordModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageUsersUpdatePasswordModal);
