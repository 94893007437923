import React, {ReactElement, useEffect, useState} from "react";
import {Address} from "@devour/client";
import FrameOneModal from "../modalComponents/FrameOneModal";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameOneAddressInputGroup from "../../inputs/FrameOneAddressInputGroup";
import FrameModalFooter from "../modalComponents/FrameModalFooter";
import FrameButton from "../../buttons/FrameButton";


const defaultAddress: Address = {
    line1: "",
    line2: "",
    locality: "",
    administrativeArea: "",
    postalCode: "",
    country: "",
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    address: Address;
    onDone: (newAddress: Address) => void;
}

function CreateBrandMapEditAddressModal(props: Props): ReactElement {

    const [autocompleteMode, setAutocompleteMode] = useState<"autocomplete" | "manual">("autocomplete");
    const [address, setAddress] = useState<Address>(defaultAddress);

    useEffect(() => {
        if (props.isOpen && props.address) {
            setAutocompleteMode("manual");
            setAddress(props.address);
        }
    }, [props.isOpen]);

    function handleOnModeChange(newMode: "autocomplete" | "manual"): void {
        if (newMode === "autocomplete") {
            setAddress(defaultAddress);
        }
    }

    function onDoneHelper(): void {
        props.onDone(address);
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            size="sm"
            toggle={props.onClose}
            contentClassName="create-brand-map-edit-address-modal"
        >
            <FrameModalHeader
                title="Configure Address"
                toggle={props.onClose}
            />

            <FrameModalBody>
                <label>
                    Enter Address
                </label>
                <FrameOneAddressInputGroup
                    value={address}
                    onChange={setAddress}
                    defaultMode={autocompleteMode}
                    onModeChange={handleOnModeChange}
                />
            </FrameModalBody>

            <FrameModalFooter>
                <FrameButton
                    color="purple"
                    size="normal"
                    disabled={!address}
                    onClick={onDoneHelper}
                >
                    Confirm
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default CreateBrandMapEditAddressModal;