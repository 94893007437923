import React, {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {
    ReferralsApi,
    CreateReferralRewardThresholdBody,
    Token,
    ReferralRewardThreshold, TransactionsApi
} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";
import FrameOneDatePicker from "../inputs/FrameOneDatePicker";
import moment from "moment";
import FrameOneSwitchInput from "../inputs/FrameOneSwitchInput";

interface StateProps {
    fullToken: Token;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onDone: () => void;
    referralRewardThreshold: ReferralRewardThreshold;
}

const defaultAddReferralRewardThresholdForm: CreateReferralRewardThresholdBody = {
    dpayRewardForReferrer: 0,
    minPaymentRequired: 0,
    disabled: false,
    hidden: false,
};

function EditReferralRewardsThresholdModal(props: EditReferralRewardsThresholdModalProps): ReactElement {
    const [form, setForm] = useState<CreateReferralRewardThresholdBody>(defaultAddReferralRewardThresholdForm);
    const [rewardInUSD, setRewardInUSD] = useState<number>(0);
    const [rewardWithUSD, setRewardFormat] = useState<boolean>(true);
    const [dpayPricePerUSD, setDpayPricePerUSD] = useState<number>(0);

    useEffect(() => {
        if (props.isOpen) {
            setForm(props.referralRewardThreshold);
            configureDpayPrice().then().catch();
        }
    }, [props.isOpen]);

    async function configureDpayPrice(): Promise<void> {
        const res = await new TransactionsApi().getDpayPrice();
        setDpayPricePerUSD(res.dPayPricePerUsd);
        setRewardInUSD(props.referralRewardThreshold.dpayRewardForReferrer * res.dPayPricePerUsd);
    }

    /**
     * Set the toggle for paying out rewards with DPAY or USD
     * @param value 0 for DPAY, 1 for USD
     */
    function updateRewardFormat(value: 0 | 1): void {
        setRewardFormat(value === 1);
    }

    /**
     * Reset the form & close the modal.
     *
     */
    function closeHelper(): void {
        setForm(defaultAddReferralRewardThresholdForm);
        props.onClose();
    }

    /**
     * Handle enabled/disabled checkbox on change event.
     *
     */
    function toggleDisabledCheckbox(): void {
        setForm({
            ...form,
            disabled: !form.disabled,
        });
    }

    /**
     * Handle expiration date on change event.
     *
     */
    function expiryDateOnChange(expiryDate: Date): void {
        setForm({
            ...form,
            expiration: moment(expiryDate).startOf("day").valueOf(),
        });
    }

    /**
     * Handle DPAY reward on change event.
     *
     */
    function dpayRewardOnChange(values): void {
        setRewardInUSD(values.floatValue * dpayPricePerUSD);
        setForm({
            ...form,
            dpayRewardForReferrer: values.floatValue,
        });
    }

    /**
     * Handle USD reward on change event.
     *
     */
    function usdRewardOnChange(values): void {
        setRewardInUSD(values.floatValue);
        setForm({
            ...form,
            dpayRewardForReferrer: Math.floor(values.floatValue / dpayPricePerUSD),
        });
    }

    /**
     * Handle minimum payment required on change event.
     *
     */
    function minPaymentOnChange(values): void {
        setForm({
            ...form,
            minPaymentRequired: values.floatValue,
        });
    }

    /**
     * Submit form to update the referral reward threshold.
     *
     */
    async function submitNewReferralRewardThreshold(e?: React.FormEvent): Promise<void> {
        e?.preventDefault();
        props.dispatch(incrementLoading());

        try {

            await new ReferralsApi(getConfig(props.fullToken)).updateReferralThreshold({
                id: props.referralRewardThreshold.id,
                createReferralRewardThresholdBody: {
                    ...form
                }
            });

            props.onDone();

        } catch (err) {
            props.dispatch(await addError(err));
        } finally {
            props.dispatch(decrementLoading());
        }

    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="manage-referral-reward-thresholds-add-modal"
        >
            <FrameModalHeader
                toggle={closeHelper}
                title="Edit Referral Reward Threshold"
            />

            <form onSubmit={submitNewReferralRewardThreshold}>
                <FrameModalBody className="manage-referral-reward-thresholds-add-modal_body">

                    <div>
                        <div className="manage-referral-reward-thresholds-add-modal_body_text-field">
                            <label>
                                Reward For Referrer*
                            </label>
                            <FrameOneSwitchInput
                                <0 | 1>
                                name="reward-format"
                                className="manage-referral-reward-thresholds-add-modal_body_switch-input"
                                value={rewardWithUSD ? 1 : 0}
                                onToggle={updateRewardFormat}
                                options={[
                                    {
                                        render: "DPAY",
                                        value: 0,
                                    },
                                    {
                                        render: "USD",
                                        value: 1,
                                    },
                                ]}
                            />
                        </div>
                        {rewardWithUSD ?
                            <NumberFormat
                                suffix=" USD"
                                placeholder="USD reward for referrer..."
                                value={rewardInUSD}
                                allowLeadingZeros={false}
                                allowNegative={false}
                                decimalScale={2}
                                onValueChange={usdRewardOnChange}
                            /> :
                            <NumberFormat
                                suffix=" DPAY"
                                placeholder="DPAY reward for referrer..."
                                value={form.dpayRewardForReferrer}
                                allowLeadingZeros={false}
                                allowNegative={false}
                                decimalScale={2}
                                onValueChange={dpayRewardOnChange}
                            />
                        }
                        <p className="form-tip">
                            {rewardWithUSD ? `${Math.floor(form.dpayRewardForReferrer)} DPAY` : `${rewardInUSD.toFixed(2)} USD`}
                        </p>
                    </div>

                    <div>
                        <label>
                            Minimum Payment Required*
                        </label>
                        <NumberFormat
                            prefix="$"
                            placeholder={"Minimum payment required for this reward..."}
                            value={form.minPaymentRequired}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            decimalScale={2}
                            onValueChange={minPaymentOnChange}
                        />
                    </div>

                    <div>
                        <label>
                            Referral Reward Threshold Expiry Date
                        </label>
                        <FrameOneDatePicker
                            oneTap={true}
                            ranges={[]}
                            onChange={expiryDateOnChange}
                            cleanable={true}
                            caretAs={undefined}
                            value={form.expiration ? moment(form.expiration).startOf("day").toDate() : undefined}
                        />
                        <p className="form-tip">
                            If selected, will set a date at which the referral reward thresholds no longer works. Selected
                            date/time will be midnight (12:00am) of the selected date, in the timezone of the admin
                            who sets it.
                        </p>
                    </div>

                    <div>
                        <FrameOneCheckbox
                            onToggle={toggleDisabledCheckbox}
                            checked={!form.disabled}
                        >
                            Threshold Enabled*
                        </FrameOneCheckbox>
                        <p className="form-tip">
                            By default, the referral reward threshold is enabled. Disabling it will disable referrers from
                            obtaining this reward in the future unless re-enabled.
                        </p>
                    </div>

                </FrameModalBody>

                <FrameModalFooter>
                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        forwardProps={{
                            type: "submit",
                        }}
                    >
                        Update Referral Reward Threshold
                    </FrameButton>
                </FrameModalFooter>
            </form>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.metaStore.fullToken,
            ...props,
        }
    });
}

type EditReferralRewardsThresholdModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(EditReferralRewardsThresholdModal);
