import {OrderDiscountType} from "@devour/client";

export function formatDiscountType(discountType: OrderDiscountType): string {
	switch (discountType) {
		case OrderDiscountType.ORDER:
			return "Order";
		case OrderDiscountType.DELIVERY:
			return "Delivery";
		case OrderDiscountType.PAYMENT:
			return "Payment";
		case OrderDiscountType.CATEGORY:
			return "Category";
		case OrderDiscountType.MENUITEM:
			return "Menu Item";
		default:
			return discountType;
	}
}
