import React, {ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Asset, AssetsApi, Brand, BrandsApi, BusinessTaxonomiesApi, CreateBrandBody, Token} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FileInputButton from "../inputs/FileInputButton";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {defaultCreateBrandBodyForm} from "./AddBrandModal";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {isAsset} from "../../utils/typeGuards";
import getConfig from "../../utils/getConfig";
import {BusinessTaxonomyBodyFrontend} from "./AddTaxonomyModal";

export interface UpdateBrandBodyFrontend extends Omit<CreateBrandBody, "image"> {
	image: Asset | FileWithSRC;
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	brand: Brand;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function EditBrandModal(props: EditBrandModalProps): ReactElement {

	const [editBrandForm, setEditBrandForm] = useState<UpdateBrandBodyFrontend>(defaultCreateBrandBodyForm);

	useEffect(() => {
		if (props.isOpen) {
			setEditBrandForm({
				name: props.brand.name,
				description: props.brand.description,
				image: props.brand.image,
			});
		}
	}, [props.isOpen]);

	/**
	 * Handle onChange event for the icon asset upload input.
	 *
	 * @param e
	 */
	async function onImageChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newAsset = (await addURLsToFiles(e.target.files))[0];
		setEditBrandForm((b) => {
			return {
				...b,
				image: newAsset,
			}
		});
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<BusinessTaxonomyBodyFrontend, "image">): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
		return (e) => {
			setEditBrandForm((b) => {
				return {
					...b,
					[key]: e.target.value,
				}
			});
		}
	}

	async function submitUpdatedBrand(e?: React.FormEvent): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			let image: Asset;
			if (!isAsset(editBrandForm.image)) {
				image = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: editBrandForm.image,
				});
			}

			await new BrandsApi(getConfig(props.fullToken)).updateBrand({
				id: props.brand.id,
				updateBrandBody: {
					image: isAsset(image) ? image.id : (editBrandForm.image as Asset).id,
					name: editBrandForm.name,
					description: editBrandForm.description,
					isEnabled: props.brand.isEnabled,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-brand-edit-modal"
		>
			<FrameModalHeader
				title="Edit Brand"
				toggle={props.onClose}
			/>

			<form onSubmit={submitUpdatedBrand}>
				<FrameModalBody className="manage-brand-edit-modal_body">
					<div>
						<label>
							Icon (suggested 1:1 aspect ratio)
						</label>
						<div className="manage-brand-edit-modal_body_asset-upload_content">
							{editBrandForm?.image && (
								<img
									src={isAsset(editBrandForm.image) ? editBrandForm?.image.url : editBrandForm.image.imageSRC as string}
									alt="Category Icon"
									className="manage-brand-edit-modal_body_asset-upload_content_img"
								/>
							)}

							<FileInputButton
								accept=".png, .jpg, .jpeg, .gif"
								multiple={false}
								onChange={onImageChange}
							>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="purple"
									forwardProps={{
										type: "button",
									}}
								>
									{editBrandForm?.image ? "Change Image" : "Upload Image"}
								</FrameButton>
							</FileInputButton>
						</div>
					</div>

					<div>
						<label>
							Brand Name
						</label>
						<input
							value={editBrandForm.name}
							placeholder="Brand Name..."
							onChange={inputOnChange("name")}
						/>
					</div>

					<div>
						<label>
							Brand Description
						</label>
						<textarea
							value={editBrandForm.description}
							placeholder="Brand Description..."
							onChange={inputOnChange("description")}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Save Changes
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type EditBrandModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(EditBrandModal);
