import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {GetUsersResponse, Token, User, UsersApi, UserType} from "@devour/client";
import getConfig from "../utils/getConfig";
import {parseUserType} from "../utils/parseUserType";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import AddAdminModal from "../components/modals/AddAdminModal";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import ManageAdminsEditPasswordCell from "../components/tables/cells/ManageAdminsEditPasswordCell";
import ManageAdminsToggleEnableCell from "../components/tables/cells/ManageAdminsToggleEnableCell";
import {convertUserDisabledStatusToEnabledString} from "../utils/convertUserDisabledStatusToEnabledString";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import {omit} from "lodash";

interface StateProps {
	fullToken: Token;
}

function ManageAdmins(props: ManageAdminsProps): ReactElement {

	const [showNewAdminModal, setShowNewAdminModal] = useState(false);
	const [admins, setAdmins] = useState<GetUsersResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		readAdmins().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Show or hide the Create Admin Modal.
	 *
	 */
	function toggleNewAdminModal(): void {
		setShowNewAdminModal(!showNewAdminModal);
	}

	/**
	 * Hide the Create Admin Modal when finished,
	 * and call the api to get the updated list.
	 *
	 */
	function onDoneNewAdminModal(): void {
		setShowNewAdminModal(false);
		readAdmins().then().catch();
	}

	/**
	 * Call api to get & save the list of admins.
	 *
	 */
	async function readAdmins(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getUsers({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				type: [UserType.GODADMIN, UserType.STANDARDADMIN],
			});

			setAdmins(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}


	/**
	 * Renderer for the Edit Password Cells.
	 *
	 * @param admin
	 */
	function makeEditPasswordCell(admin: User): ReactNode {
		return (
			<ManageAdminsEditPasswordCell
				admin={admin}
				onDone={readAdmins}
			/>
		);
	}

	/**
	 * Renderer for the Toggle Enabled Cells.
	 *
	 * @param admin
	 */
	function makeToggleEnableCell(admin: User): ReactNode {
		return (
			<ManageAdminsToggleEnableCell
				admin={admin}
				onDone={readAdmins}
			/>
		);
	}

	return (
		<div className="manage-admins">
			<AddAdminModal
				isOpen={showNewAdminModal}
				onClose={toggleNewAdminModal}
				onDone={onDoneNewAdminModal}
			/>

			<PageHeader>
				<div>
					<h3>
						Manage Admins
					</h3>

					<p>
						On this page you can see a list of all the current admins in the system, as well as toggle
						their status
						between enabled & disabled, or update their passwords. You can also add a new admin with the
						button below.
					</p>

					<FrameButton
						color="lightBlue"
						onClick={toggleNewAdminModal}
					>
						Create New Admin
					</FrameButton>
				</div>
			</PageHeader>

			<div className="manage-admins_table">
				<FrameOneTableContainer
					data={admins?.users}
					pagination={{
						...admins?.paginationInfo,
						...omit(frontendPagination, "frontendRenderKey"),
					}}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "username",
							headerValue: "Username",
						},
						{
							key: "type",
							headerValue: "Type",
							valueFormatter: parseUserType,
						},
						{
							key: "firstName",
							headerValue: "First Name",
						},
						{
							key: "lastName",
							headerValue: "Last Name",
						},
						{
							key: "disabled",
							headerValue: "Enabled",
							valueFormatter: convertUserDisabledStatusToEnabledString,
						},
						// {
						// 	key: "email",
						// 	headerValue: "Email",
						// },
						{
							key: undefined,
							headerValue: "Edit Password",
							cellRender: makeEditPasswordCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							key: undefined,
							headerValue: "Enable/Disable Admin",
							cellRender: makeToggleEnableCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type ManageAdminsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageAdmins);
