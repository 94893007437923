import {Configuration, Token} from "@devour/client";

export default function getConfig(fullToken?: Token, basePath?: string): Configuration {
	const headers = fullToken?.token ? {headers: {authorization: `Bearer ${fullToken?.token}`}} : {};
	const base = basePath ? {basePath} : {};
	return new Configuration({
		...headers,
		...base,
	});
}
