import React, {ChangeEventHandler, ReactElement} from "react";
import {EditBrandMapBodyFrontend} from "../../pages/brandMaps/BrandMapFormValues";

interface Props {
	formValues: EditBrandMapBodyFrontend;
	inputOnChange: (key: keyof EditBrandMapBodyFrontend) => ChangeEventHandler<HTMLInputElement>;
}

function EditBrandMapMainPromoDescription(props: Props): ReactElement {

	return (
		<div className="create-map-page_container create-map-page_main-promo">
			<div className="create-map-page_container_inner create-map-page_main-promo_title">
				<h5>
					Main Promotion Description
				</h5>
				<p>
					Write a description for the main promotion. This will only appear if there is a main promotion set.
				</p>
				<input
					placeholder="Eg. This promotion only applies to a specific target group and will be only available for the first 100 users."
					value={props.formValues.promoDescription}
					onChange={props.inputOnChange("promoDescription")}
				/>
			</div>
		</div>
	);
}

export default EditBrandMapMainPromoDescription;
