import {ReactElement, useState} from "react";
import {GoVipPointMultiplier} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import EditGoVipPointMultiplierModal from "../../modals/EditGoVipPointMultiplierModal";

interface Props {
    goVipPointMultiplier: GoVipPointMultiplier;
    onDone: () => Promise<void>;
}

function GoVipPointMultiplierEditCell(props: Props): ReactElement {

    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    function toggleEditModal(): void {
        setShowEditModal(s => !s);
    }

    function onDoneEdit(): void {
        setShowEditModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <EditGoVipPointMultiplierModal
                isOpen={showEditModal}
                goVipPointMultiplier={props.goVipPointMultiplier}
                onClose={toggleEditModal}
                onDone={onDoneEdit}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightBlue"
                    onClick={toggleEditModal}
                >
                    Edit Multiplier
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default GoVipPointMultiplierEditCell;
