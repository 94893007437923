import {PhoneNumberBody} from "@devour/client";

/**
 * For "pruning" an optional phone number input on a form if the user does not enter the nationNumber field.
 * Since on the frontend we will essentially always default the countryCode to something, and the user can just ignore
 * the input entirely if it's marked as optional, we'll return undefined if the nationNumber doesn't exist.
 *
 * @param phoneNumber
 */
export function formatOptionalPhoneNumberForApiSubmission(phoneNumber: PhoneNumberBody | undefined): PhoneNumberBody | undefined {
	if (!phoneNumber) {
		return undefined;
	}

	if (!phoneNumber.nationalNumber || phoneNumber.nationalNumber === "") {
		return undefined;
	}

	return phoneNumber;
}
