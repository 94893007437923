import React, {ReactElement} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Business, BusinessesApi, BusinessUserFull} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {IStore} from "../../redux/defaultStore";
import {useDispatch, useSelector} from "react-redux";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    business: Business;
    user: BusinessUserFull;
}

function DeleteMerchantUserConfirmationModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

    async function confirmDelete(): Promise<void> {
        await removeBusinessUser(props.user.user)
        props.onClose();
    }

    async function removeBusinessUser(userId: string): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new BusinessesApi(getConfig(fullToken)).deleteBusinessUser({
                id: props.business?.id,
                businessDeleteUserBody: {userId},
            });
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }


    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
        >
            <FrameModalHeader
                toggle={props.onClose}
                title={`Deleting User from ${props.business?.name}`}
            />
            <FrameModalBody>
                <p>
                    Are you sure you want to delete {props.user?.firstName} {props.user?.lastName} from {props.business?.name}?
                </p>
            </FrameModalBody>
            <FrameModalFooter>
                <FrameButton
                    color="lightBlue"
                    onClick={props.onClose}
                >
                    Cancel
                </FrameButton>
                <FrameButton
                    color="danger"
                    onClick={confirmDelete}
                >
                    Delete
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default DeleteMerchantUserConfirmationModal;
