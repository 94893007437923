import React, {ReactElement, useState} from "react";
import {
	Business,
	BusinessServiceAvailability
} from "@devour/client";
import FrameButton from "../buttons/FrameButton";
import {isRestaurant} from "../../typeguards/isRestaurant";
import EditBusinessHoursModal from "../modals/EditBusinessHoursModal";

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function MerchantHours(props: Props): ReactElement {

	const [showUpdateHoursModal, setShowUpdateHoursModal] = useState(false);

	function renderBusinessService(service: BusinessServiceAvailability): JSX.Element {
		return (
			<li
				key={service.dayOfWeek}
			>
				<strong>{service.dayOfWeek[0].toUpperCase() + service.dayOfWeek.slice(1).toLowerCase()}</strong><br/>
				{(service.timePeriods?.length) ?
					service.timePeriods.map((period, index) => (
						<div key={`${service.dayOfWeek}-${index}`}>
							{period.timeStart} - {period.timeEnd}
						</div>
					)) :
					"Closed"}
			</li>
		);
	}

	function onDone() {
		setShowUpdateHoursModal(false);
		void props.onDone();
	}

	return (
		<React.Fragment>

			{isRestaurant(props.business) && (
				<EditBusinessHoursModal
					isOpen={showUpdateHoursModal}
					onDone={onDone}
					onClose={() => setShowUpdateHoursModal(false)}
					business={props.business}
				/>
			)}

			<div className="merchant-hours">
				<h4>
					Hours of Operation
				</h4>

				<p>
					Time zone: {props.business.timeZone}
				</p>

				{(isRestaurant(props.business) && (
					<React.Fragment>
						<ul>
							{(props.business.serviceAvailabilities.map(renderBusinessService))}
						</ul>
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="lightBlue"
							onClick={() => setShowUpdateHoursModal(true)}
							forwardProps={{
								type: "button"
							}}
						>
							Update Hours
						</FrameButton>
					</React.Fragment>
				))}

			</div>
		</React.Fragment>
	);
}

export default MerchantHours;
