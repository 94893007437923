import {GetUserCommunityMembershipResponse, User, UsersApi} from "@devour/client";
import React, {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import SectionLabel from "../SectionLabel";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

interface UserCommunityInfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    user: User;
    onDone: () => void;
}

function UserCommunityInfoModal(props: UserCommunityInfoModalProps): ReactElement {
    const [communityInfo, setCommunityInfo] = useState<GetUserCommunityMembershipResponse>();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.isOpen) {
            setCommunityInfo(undefined);
            void getCommunityInfo();
        }
    }, [props.isOpen]);

    async function getCommunityInfo() {
        try {
            const res = await new UsersApi(getConfig(fullToken)).getUserCommunityMembership({id: props.user.id});
            setCommunityInfo(res);
        } catch (err) {
            props.onDone();
            dispatch(await addError(err));
        }
    }
    async function removeUserFromCommunity(): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new UsersApi(getConfig(fullToken)).removeUserCommunityMembership({id: props.user.id});
            props.onDone();
        } catch (err) {
            dispatch(await addError(err));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function banUserFromCommunity(): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new UsersApi(getConfig(fullToken)).banUserFromCommunity({
                banUserFromCommunityBody: {
                    userId: props.user.id,
                    groupingId: communityInfo.nftGroupingId
                }
            });
            props.onDone();
        } catch (err) {
            dispatch(await addError(err));
        } finally {
            dispatch(decrementLoading());
        }
    }

    return (
        <>
            {communityInfo && (
                <FrameOneModal
                    isOpen={props.isOpen}
                    toggle={props.onClose}
                    size="sm"
                    contentClassName="user-community-membership-modal"
                >
                    <FrameModalHeader
                        toggle={props.onClose}
                        title="User Community Membership"
                    />
                    <FrameModalBody className="user-community-membership-modal_body">
                        <SectionLabel
                            title="Community"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{communityInfo.community}</p>
                        </SectionLabel>
                        <SectionLabel
                            title="Current rank"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{communityInfo.pointsEarnedForCommunity ? communityInfo.rankWithinCommunity : "Unranked"}</p>
                        </SectionLabel>
                        <SectionLabel
                            title="Total of points earned"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{communityInfo.pointsEarnedForCommunity}</p>
                        </SectionLabel>
                    </FrameModalBody>

                    <FrameModalFooter>
                        <FrameButton
                            color="danger"
                            narrow={true}
                            onClick={async () => await banUserFromCommunity()}
                        >
                            Ban From Community
                        </FrameButton>
                        <FrameButton
                            color="warning"
                            narrow={true}
                            onClick={async () => await removeUserFromCommunity()}
                        >
                            Kick from Community
                        </FrameButton>
                    </FrameModalFooter>

                </FrameOneModal>
            )}
        </>
    )
}
export default UserCommunityInfoModal;