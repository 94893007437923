import {ReactElement, useState} from "react";
import {ReferralRewardThreshold} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import DeleteReferralRewardsThresholdModal from "../../modals/DeleteReferralRewardsThresholdModal";

interface Props {
    referralRewardThreshold: ReferralRewardThreshold;
    onDone: () => Promise<void>;
}

function ReferralRewardsThresholdDeleteCell(props: Props): ReactElement {

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    function toggleDeleteModal(): void {
        setShowDeleteModal(s => !s);
    }

    function onDoneDelete(): void {
        setShowDeleteModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <DeleteReferralRewardsThresholdModal
                isOpen={showDeleteModal}
                referralRewardThreshold={props.referralRewardThreshold}
                onClose={toggleDeleteModal}
                onDone={onDoneDelete}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="danger"
                    onClick={toggleDeleteModal}
                >
                    Delete Threshold
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ReferralRewardsThresholdDeleteCell;
