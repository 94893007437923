import React, {PropsWithChildren, ReactElement} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Navigate} from "react-router-dom";
import {ErrorType, Token} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {addError, incrementLoading} from "../../redux/meta/MetaActions";

interface StateProps {
	fullToken: Token;
}

function AuthenticatedRoute(props: AuthenticatedRouteProps): ReactElement {

	/**
	 * Async function added so we can leverage "await" on addError passed to the Redux dispatch action.
	 *
	 */
	async function handleErrorDispatch(): Promise<void> {
		props.dispatch(await addError({
			type: ErrorType.APP,
			message: "You must have a token to access that page. If you were previously logged in, your access token may have expired and you must log in again to get a new one.",
		}));
	}

	if (!props.fullToken?.token) {
		props.dispatch(incrementLoading());
		handleErrorDispatch().then().catch();

		return (
			<Navigate
				to="/login"
				replace={true}
			/>
		);
	}

	return (
		<React.Fragment>
			{props.children}
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: PropsWithChildren<{}>): StateProps & PropsWithChildren<{}> => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AuthenticatedRouteProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AuthenticatedRoute);
