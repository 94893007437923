import React, {ReactElement, useState} from "react";
import Select, {GroupBase, Props} from "react-select";
import {omit} from "lodash";
import classNames from "classnames";

function FrameOneReactSelect<T, U, V>(props: any): ReactElement {
// function FrameOneReactSelect<T, U extends boolean, V extends GroupBase<T>>(props: Partial<Props<T, U, V>>): ReactElement {

	// This dynamically adds a className to the component to help us more consistently match styling of our inputs
	const [focus, setFocus] = useState(false);

	function onFocus(e): void {
		setFocus(true);
		props.onFocus?.(e);
	}

	function onBlur(e): void {
		setFocus(false);
		props.onBlur?.(e);
	}

	return (
		<Select
			{...omit(props, "className")}
			className={classNames(props.className, "frame-one-react-select", {
				"frame-one-react-select_focused": focus,
			})}
			onFocus={onFocus}
			onBlur={onBlur}
		/>
	);
}

export default FrameOneReactSelect;
