import React, {ReactElement, useState} from "react";
import {
    BusinessDays,
    BusinessPeriod,
    BusinessServiceAvailability,
} from "@devour/client";
import FrameButton from "../../components/buttons/FrameButton";
import {cloneDeep} from "lodash";
import BusinessHoursModal from "../modals/BusinessHoursModal";

interface Props {
    onUpdateServiceAvailabilities: (newAvailabilities: Array<BusinessServiceAvailability>) => void;
    serviceAvailabilities: Array<BusinessServiceAvailability>;
    disabled?: boolean;
    noHoursText?: string;
}

function ServiceAvailabilitiesInput(props: Props): ReactElement {
    const [newTimeBlock, setNewTimeBlock] = useState<BusinessDays>();

    function onAddTimeBlock(period: BusinessPeriod) {
        const newServiceHours = cloneDeep(props.serviceAvailabilities);
        const dayIndex = newServiceHours.findIndex((service) => service.dayOfWeek === newTimeBlock);
        const dayService = newServiceHours[dayIndex];

        // Push to add element to array
        dayService.timePeriods.push(period);
        newServiceHours[dayIndex] = dayService;
        props.onUpdateServiceAvailabilities(newServiceHours);
        setNewTimeBlock(undefined);

    }

    function onRemoveTimeBlock(day: BusinessDays, deleteIndex: number) {
        const newServiceHours = cloneDeep(props.serviceAvailabilities);
        const dayIndex = newServiceHours.findIndex((service) => service.dayOfWeek === day);
        const dayService = newServiceHours[dayIndex];

        // Splice to remove element from array
        dayService.timePeriods.splice(deleteIndex, 1);
        newServiceHours[dayIndex] = dayService;

        props.onUpdateServiceAvailabilities(newServiceHours);

    }

    function renderDayGroup(day: BusinessDays): JSX.Element {
        let serviceBlock: BusinessServiceAvailability = props.serviceAvailabilities.find((service) => service.dayOfWeek === day);
        if (!serviceBlock) {
            serviceBlock = {
                dayOfWeek: day,
                timePeriods: [],
            }
        }
        return (
            <div
                key={day}
                className="service-availabilities-input_days-container_block"
            >
                <p>
                    <strong>{day}</strong>
                </p>
                <div>
                    {(!props.disabled) && (
                        <FrameButton
                            <React.ButtonHTMLAttributes<HTMLButtonElement>>
                            color="lightGray"
                            narrow={true}
                            className="service-availabilities-input_days-container_block_add"
                            onClick={() => setNewTimeBlock(day)}
                            forwardProps={{type: "button"}}
                        >
                            Add time block to {day}
                        </FrameButton>
                    )}
                </div>
                {(serviceBlock.timePeriods?.length) ? (
                    <ul>
                        {serviceBlock.timePeriods.map((period, index) => (
                            <li
                                key={`period-${day}-${index}`}
                            >
                                <div>
                                    Open: {period.timeStart}<br/>
                                    Close: {period.timeEnd}
                                </div>
                                {(!props.disabled) && (
                                    <FrameButton
                                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                                        color="danger"
                                        narrow={true}
                                        className="service-availabilities-input_days-container_block_remove"
                                        onClick={() => onRemoveTimeBlock(day, index)}
                                        forwardProps={{type: "button"}}
                                    >
                                        Remove {day} {period.timeStart} - {period.timeEnd}
                                    </FrameButton>
                                )}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="service-availabilities-input_days-container_block_no-hours">
                        {props.noHoursText}
                    </div>
                )}
            </div>
        );
    }

    return (
        <React.Fragment>
            <BusinessHoursModal
                day={newTimeBlock}
                onUpdate={onAddTimeBlock}
                onClose={() => setNewTimeBlock(undefined)}
            />
            <div className="service-availabilities-input">
                {Object.values(BusinessDays).map(renderDayGroup)}
            </div>
        </React.Fragment>
    );
}

ServiceAvailabilitiesInput.defaultProps = {
    noHoursText: "Closed",
}

export default ServiceAvailabilitiesInput;
