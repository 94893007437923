import React, {ReactElement} from "react";
import {DatePicker} from "rsuite";
import {DatePickerProps} from "rsuite/esm/DatePicker/DatePicker";
import classNames from "classnames";

function FrameOneDatePicker(props: DatePickerProps): ReactElement {

	return (
		<DatePicker
			block={true}
			{...props}
			className={classNames("frame-one-date-picker", props.className)}
		/>
	);
}

export default FrameOneDatePicker;
