import React, {ReactElement, useState} from "react";
import {User} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import ManageUsersUpdatePasswordModal from "../../modals/ManageUsersUpdatePasswordModal";

interface Props {
	user: User;
	onDone: () => Promise<void>;
}

function ManageUsersEditPasswordCell(props: Props): ReactElement {

	const [showModal, setShowModal] = useState(false);

	/**
	 * Hide or Show the toggle modal.
	 *
	 */
	function toggleShowPasswordModal(): void {
		setShowModal(!showModal);
	}

	/**
	 * Hide the password modal and call the props.onDone function to update the table.
	 *
	 */
	function onDonePasswordModal(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageUsersUpdatePasswordModal
				isOpen={showModal}
				user={props.user}
				onClose={toggleShowPasswordModal}
				onDone={onDonePasswordModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="lightGray"
					onClick={toggleShowPasswordModal}
				>
					Edit Password
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default ManageUsersEditPasswordCell;
