import variables from "./variables.module.scss";

interface ICSSVariables {
	"f1-sidebar-width": string,
	"f1-sidebar-width-docked": string;
	"f1-sidebar-docked-at": string;

	"frame-one-modal-transition-timing": string;
}

export default variables as unknown as ICSSVariables;
