import React, {ReactNode} from "react";
import classNames from "classnames";
import {IconType} from "react-icons";

interface IProps {
	children: string;
	icon?: IconType;
	className?: string;
	active?: boolean;
	onClick?: () => void;
}

const SideBarButton: React.FC<IProps> = (props) => {

	const Icon: any = props.icon;

	function onClickHelper(): void {
		if (props.onClick) {
			props.onClick();
		}
	}

	return (
		<button
			className={classNames("side-bar-button", props.className, {
				"side-bar-button_active": props.active,
			})}
			onClick={onClickHelper}
		>
			{Icon && (
				<Icon/>
			)}

			<span>
				{props.children}
			</span>
		</button>
	);
};

export default SideBarButton;
