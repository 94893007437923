import React, {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames";

interface Props {
	className?: string;
}

function PageHeader(props: PropsWithChildren<Props>): ReactElement {

	return (
		<div className={classNames("page-header", props.className)}>
			<div className="page-header_container">
				{props.children}
			</div>
		</div>
	);
}

export default PageHeader;
