import {
	Address,
	Business,
	BusinessesApi,
	BusinessType,
	UpdateBusinessAdmin,
	GeoJsonType,
	PhoneNumberBody,
	HandoffOptions,
	Token,
	BusinessPermission,
	RestaurantBrandSize,
} from "@devour/client";
import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {formatOptionalPhoneNumberForApiSubmission} from "../../utils/formatOptionalPhoneNumberForApiSubmission";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOnePhoneNumberInput from "../inputs/FrameOnePhoneNumberInput";
import FrameOneAddressInputGroup from "../inputs/FrameOneAddressInputGroup";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import {isRestaurant} from "../../typeguards/isRestaurant";
import {cloneDeep} from "lodash";
import FrameOneSwitchInput from "../inputs/FrameOneSwitchInput";

const defaultAddRestaurantForm: UpdateBusinessAdmin = {
	type: BusinessType.CORPORATE,
	parent: undefined,
	name: "",
	timeZone: undefined,
	currency: "USD",
	description: "",
	email: "",
	prepTime: 30,
	phoneNumber: {
		countryCode: "US",
		nationalNumber: "",
	},
	address: {
		line1: "",
		line2: "",
		locality: "",
		administrativeArea: "",
		postalCode: "",
		country: "",
		placeId: undefined,
		location: {
			type: GeoJsonType.Point,
			coordinates: [],
		},
	},
	taxonomies: [],
	handoffOptions: [],
	firstDeliveryId: "",
	distanceDeliveryMax: 0,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	business: Business;
	isOpen: boolean;
	onClose: () => void;
	onDone: (e?: boolean) => void;
}

function EditRestaurantModal(props: EditRestaurantModalProps): ReactElement {
	const [specialInstructions, setSpecialInstructions] = useState(false);
	const [permissions, setPermissions] = useState<Array<BusinessPermission>>([]);
	const [restaurantForm, setRestaurantForm] = useState<UpdateBusinessAdmin>({...defaultAddRestaurantForm, ...props.business});

	useEffect(() => {
		setRestaurantForm({
			...defaultAddRestaurantForm,
			...props.business,
		})
	}, [props.business]);

	useEffect(() => {
		if (props.isOpen) {
			fetchBusinessPermissions().then().catch();
			if (isRestaurant(props.business)) {
				setSpecialInstructions(props.business?.specialInstructions)
			}
		}
	}, [props.isOpen]);

	async function updateSpecialInstructions(value: 0 | 1): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			setSpecialInstructions(value === 1)
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Get the details for this business from our api.
	 */
	async function fetchBusinessPermissions(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessPermissions({
				id: props.business?.id,
				limit: Number.MAX_SAFE_INTEGER,
			});
			setPermissions(res.authUser);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<UpdateBusinessAdmin, "parent" | "isEnabled" | "phoneNumber" | "address" | "taxonomies" | "merchantId">): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setRestaurantForm({
				...restaurantForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Handle all handoff input onChange events.
	 *
	 * @param handoff
	 */
	function handoffOnToggle(handoff: HandoffOptions): void {
		// Attempt to find this option in the checked items array
		const newHandoff: Array<HandoffOptions> = cloneDeep(restaurantForm.handoffOptions);


		const thisIndex = newHandoff.indexOf(handoff);

		if (thisIndex > -1) {
			// Remove this option from list of selected items
			newHandoff.splice(thisIndex, 1);
		} else {
			newHandoff.push(handoff);
		}
		setRestaurantForm({
			...restaurantForm,
			handoffOptions: newHandoff,
		});

	}

	/**
	 * Handle the phone number input onChange.
	 *
	 * @param phoneNumber
	 */
	function phoneNumberOnChange(phoneNumber: PhoneNumberBody): void {
		setRestaurantForm({
			...restaurantForm,
			phoneNumber,
		});
	}

	/**
	 * Handle the address inputs onChange.
	 *
	 * @param address
	 */
	function addressOnChange(address: Address): void {
		setRestaurantForm({
			...restaurantForm,
			address,
		});
	}

	/**
	 * Submit the updated restaurant/business into the system & close modal.
	 *
	 * @param e
	 */
	async function submitEditRestaurant(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {

			await new BusinessesApi(getConfig(props.fullToken)).updateBusinessAdmin({
				id: props.business.id,
				updateBusinessAdmin: {
					...restaurantForm,
					taxonomies: undefined,
					phoneNumber: formatOptionalPhoneNumberForApiSubmission(restaurantForm.phoneNumber),
					prepTime: (restaurantForm.prepTime) ? Number(restaurantForm.prepTime) : undefined,
				},
			});

			if (isRestaurant(props.business)) {
				await new BusinessesApi(getConfig(props.fullToken)).updateBusinessSpecialInstructions({
					id: props?.business?.id,
					updateBusinessSpecialInstructionsBody: {
						specialInstructions: specialInstructions,
					}
				});
			}

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<React.Fragment>
			<FrameOneModal
				isOpen={props.isOpen}
				toggle={props.onClose}
				contentClassName="manage-restaurants-edit-modal"
			>
				<FrameModalHeader
					title="Edit Business"
					toggle={props.onClose}
				/>

				<form onSubmit={submitEditRestaurant}>
					<FrameModalBody className="manage-restaurants-edit-modal_body">
						<div>
							<label>
								Business Name
							</label>
							<input
								value={restaurantForm.name}
								placeholder="Business Name..."
								onChange={inputOnChange("name")}
							/>
						</div>

						<div>
							<label>
								Internal Name
							</label>
							<input
								value={restaurantForm.internalName}
								placeholder="Internal Name..."
								onChange={inputOnChange("internalName")}
							/>
						</div>

						{(isRestaurant(props.business)) && (
							<React.Fragment>
								<div>
									<label>Handoff Options *</label>
									{Object.values(HandoffOptions).map((option) => (
										<FrameOneCheckbox
											key={option}
											onToggle={() => handoffOnToggle(option)}
											checked={restaurantForm.handoffOptions.includes(option)}
										>
											{option}
										</FrameOneCheckbox>
									))}
								</div>
								<div className="manage-restaurants-edit-modal_field">
									<label>Special instructions allowed</label>
									<FrameOneSwitchInput
										<0 | 1>
										name="tip-delivery"
										disabled={!permissions.includes(BusinessPermission.CONTACT)}
										value={specialInstructions ? 1 : 0}
										onToggle={updateSpecialInstructions}
										options={[
											{
												render: "Forbid",
												value: 0,
											},
											{
												render: "Allow",
												value: 1,
											},
										]}
									/>
								</div>
								<div>
									<label>Brand Size</label>
									<select
										value={restaurantForm.brandSize}
										onChange={inputOnChange("brandSize")}
									>
										<option value="">None</option>
										<option value={RestaurantBrandSize.LOCAL}>Local</option>
										<option value={RestaurantBrandSize.REGIONAL}>Regional</option>
										<option value={RestaurantBrandSize.NATIONAL}>National</option>
									</select>
								</div>
								<div>
									<label>
										Average Prep Time
									</label>
									<input
										type="number"
										value={restaurantForm.prepTime}
										placeholder="Average Order Prep Time..."
										onChange={inputOnChange("prepTime")}
									/>
								</div>
							</React.Fragment>
						)}

						<div>
							<label>
								Business Description
							</label>
							<input
								value={restaurantForm.description}
								placeholder="Business Description..."
								onChange={inputOnChange("description")}
								type="textarea"
							/>
						</div>

						<div>
							<label>
								Business Email
							</label>
							<input
								value={restaurantForm.email}
								placeholder="Business Email..."
								onChange={inputOnChange("email")}
								type="email"
							/>
						</div>

						<div>
							<label>Phone Number</label>
							<FrameOnePhoneNumberInput
								value={restaurantForm.phoneNumber}
								onChange={phoneNumberOnChange}
							/>
						</div>

						<div>
							<label>Address</label>

							<FrameOneAddressInputGroup
								defaultMode="manual"
								value={restaurantForm.address}
								onChange={addressOnChange}
							/>
						</div>

					</FrameModalBody>

					<FrameModalFooter>
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="lightBlue"
							forwardProps={{
								type: "submit"
							}}
						>
							Save Business
						</FrameButton>
					</FrameModalFooter>
				</form>
			</FrameOneModal>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type EditRestaurantModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(EditRestaurantModal);
