import React, {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Brand, BrandsApi, Token} from "@devour/client";
import {useParams} from "react-router";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import PageHeader from "../components/PageHeader";
import ManageBrandMerchants from "../components/ManageBrandMerchants";
import ManageBrandBusinesses from "../components/ManageBrandBusinesses";
import ManageBrandPromos from "../components/ManageBrandPromos";

interface StateProps {
	fullToken: Token;
}

function ManageSingleBrand(props: ManageSingleBrandProps): ReactElement {

	const {brandId} = useParams<{ brandId: string }>();
	const [brand, setBrand] = useState<Brand>();

	useEffect(() => {
		void getBrandDetails();
	}, [brandId]);

	async function getBrandDetails(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BrandsApi(getConfig(props.fullToken)).getBrand({
				id: brandId,
			});
			setBrand(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	if (!brand) {
		return null;
	}

	return (
		<div className="manage-brand">
			<PageHeader>
				<div className="manage-brand_header">
					<h3>
						Viewing details for brand...
					</h3>

					<div className="manage-brand_header_details">
						{brand.image && (
							<div className="manage-brand_header_details_img">
								<img
									src={brand.image.url}
									alt={brand.name}
								/>
							</div>
						)}
						<h2 className="manage-brand_header_details_name">
							{brand.name}
						</h2>
					</div>
				</div>
			</PageHeader>

			<div className="manage-brand_merchants">
				<ManageBrandMerchants brand={brand}/>
			</div>

			<div className="manage-brand_businesses">
				<ManageBrandBusinesses brand={brand}/>
			</div>

			<div className="manage-brand_promos">
				<ManageBrandPromos
					onDone={getBrandDetails}
					brand={brand}
				/>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type ManageSingleBrandProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageSingleBrand);
