import {ReactElement, useState} from "react";
import {ReferralRewardThreshold} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import EditReferralRewardsThresholdModal from "../../modals/EditReferralRewardsThresholdModal";

interface Props {
    referralRewardThreshold: ReferralRewardThreshold;
    onDone: () => Promise<void>;
}

function ReferralRewardsThresholdEditCell(props: Props): ReactElement {

    const [showEditModal, setShowEditModal] = useState(false);

    function toggleEditModal(): void {
        setShowEditModal(s => !s);
    }

    function onDoneEdit(): void {
        setShowEditModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <EditReferralRewardsThresholdModal
                isOpen={showEditModal}
                referralRewardThreshold={props.referralRewardThreshold}
                onClose={toggleEditModal}
                onDone={onDoneEdit}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightBlue"
                    onClick={toggleEditModal}
                >
                    Edit Threshold
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ReferralRewardsThresholdEditCell;
