import {Brand, BrandsApi, Business, OrderDiscount} from "@devour/client";
import React, {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import FrameButton from "../../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";
import ManagePromoAvailabilityToBrandBusinessesModal from "../../modals/ManagePromoAvailabilityToBrandBusinessesModal";
import managePromoAvailabilityToBrandBusinessesModal from "../../modals/ManagePromoAvailabilityToBrandBusinessesModal";


interface Props {
    brand: Brand;
    promo: OrderDiscount;
    businesses: Array<Business>;
    onDone: () => Promise<void>;
}

function ManageBrandPromoAvailabilityCell(props: Props): ReactElement {
    const [showManagePromoAvailabilityModal, setShowManagePromoAvailabilityModal] = useState<boolean>(false);

    function toggleManagePromoAvailabilityModal(): void {
        setShowManagePromoAvailabilityModal(s => !s);
    }

    function onDone(): void {
        setShowManagePromoAvailabilityModal(false);
        props.onDone().then().catch();
    }
    return (
        <div className="frame-one-table-cell">
            <ManagePromoAvailabilityToBrandBusinessesModal
                isOpen={showManagePromoAvailabilityModal}
                businesses={props.businesses}
                promo={props.promo}
                onClose={toggleManagePromoAvailabilityModal}
                onDone={onDone}
                />
            <FrameButton
                color="lightBlue"
                onClick={toggleManagePromoAvailabilityModal}
            >
                Manage Promo Availability
            </FrameButton>
        </div>
    )
}

export default ManageBrandPromoAvailabilityCell;