import {ReactElement, useState} from "react";
import {NftGrouping, NftTracker} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import ManageNftTrackersEditModal from "../../modals/ManageNftTrackersEditModal";
import ManageNftGroupingsEditModal from "../../modals/ManageNftGroupingsEditModal";

interface Props {
	grouping: NftGrouping;
	onDone: () => Promise<void>;
}

function ManageNftGroupingsEditCell(props: Props): ReactElement {

	const [showEditModal, setShowEditModal] = useState(false);

	function toggleShowEditModal(): void {
		setShowEditModal(s => !s);
	}

	function onDoneEdit(): void {
		setShowEditModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageNftGroupingsEditModal
				isOpen={showEditModal}
				grouping={props.grouping}
				onClose={toggleShowEditModal}
				onDone={onDoneEdit}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="darkBlue"
					narrow={true}
					onClick={toggleShowEditModal}
				>
					Edit Grouping
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default ManageNftGroupingsEditCell;