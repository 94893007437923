import React, {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	AddBrandMerchantBody,
	Brand,
	BrandsApi,
	BusinessTaxonomiesApi,
	GetUsersResponse, Merchant,
	Token,
	UsersApi,
	UserType, UtilsApi
} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";

const defaultAddBrandMerchantBody: AddBrandMerchantBody = {
	brand: undefined,
	merchant: undefined,
	createMaps: true,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	brand: Brand;
	onClose: () => void;
	onDone: () => void;
}

function AddMerchantToBrandModal(props: AddMerchantToBrandModalProps): ReactElement {

	const [permissionsForm, setPermissionsForm] = useState<AddBrandMerchantBody>(defaultAddBrandMerchantBody);
	const [merchantList, setMerchantList] = useState<GetUsersResponse>(undefined);

	/**
	 * When modal opens reset the form to defaults & current brand.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setPermissionsForm({
				...defaultAddBrandMerchantBody,
				brand: props.brand.id,
			});

			if (!merchantList) {
				void getMerchantsForDropDown();
			}
		}
	}, [props.isOpen]);

	/**
	 * Get the list of merchant accounts for inflating the drop-down/select input.
	 *
	 */
	async function getMerchantsForDropDown(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const merchantsRes = await new UsersApi(getConfig(props.fullToken)).getUsers({
				type: [UserType.MERCHANT],
				limit: 1000000,
				offset: 0,
			});

			setMerchantList(merchantsRes);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle the merchant id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleMerchantIdOnChange(v: IReactSelectOption): void {
		setPermissionsForm((p) => {
			return {
				...p,
				merchant: v?.value,
			}
		});
	}

	/**
	 * Handle checkbox on change events.
	 *
	 */
	function togglePermission(permission: keyof Pick<AddBrandMerchantBody, "createMaps">): () => void {
		return () => {
			setPermissionsForm((p) => {
				return {
					...p,
					[permission]: !p[permission],
				}
			});
		}
	}

	async function submitNewPermissions(e: React.FormEvent): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new BrandsApi(getConfig(props.fullToken)).addBrandMerchant({
				addBrandMerchantBody: {
					brand: props.brand.id,
					merchant: permissionsForm.merchant,
					createMaps: permissionsForm.createMaps,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	const merchantIdOptions = makeReactSelectOptions<Merchant>(merchantList?.users as Array<Merchant>, "id", (merchant) => merchant.firstName + " " + merchant.lastName + " - " + merchant.email);

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-brand-add-merchant-modal"
		>
			<FrameModalHeader
				title={`Add Merchant User Permissions for the "${props.brand.name}" Brand`}
				toggle={props.onClose}
			/>

			<form onSubmit={submitNewPermissions}>
				<FrameModalBody className="manage-brand-add-merchant-modal_body">
					<div>
						<label>
							Merchant User
						</label>
						<FrameOneReactSelect
							name="Merchant Account"
							placeholder="Select Merchant User Account..."
							isClearable={true}
							isLoading={merchantList === undefined}
							isDisabled={merchantList === undefined}
							value={merchantIdOptions.find(m => m.value === permissionsForm.merchant)}
							onChange={handleMerchantIdOnChange}
							options={merchantIdOptions}
						/>
					</div>

					<div className="manage-brand-add-merchant-modal_body_permissions">
						<h5>Permissions</h5>
						<div className="manage-brand-add-merchant-modal_body_permissions_list">
							<div>
								<label>
									Create Maps
								</label>
								<FrameOneCheckbox
									onToggle={togglePermission("createMaps")}
									checked={permissionsForm.createMaps}
								>
									{`Selected Merchant User can create maps on behalf of ${props.brand.name}`}
								</FrameOneCheckbox>
							</div>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit",
							disabled: !permissionsForm.merchant,
						}}
					>
						Save Permissions
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddMerchantToBrandModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddMerchantToBrandModal);
