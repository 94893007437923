import React, {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    GoVipPointMultiplier,
    CreateGoVipPointMultiplierBody,
    GoVipActionType,
    GoVipApi
} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameOneDatePicker from "../inputs/FrameOneDatePicker";
import moment from "moment";
import {MULTIPLIER_VALUES} from "../../pages/ManageGoVipPointMultipliers";

interface EditGoVipPointMultiplierProps {
    isOpen: boolean;
    onClose: () => void;
    onDone: () => void;
    goVipPointMultiplier: GoVipPointMultiplier;
}

const defaultAddGoVipPointMultiplierForm: CreateGoVipPointMultiplierBody = {
    action: undefined,
    startDate: Date.now(),
    endDate: undefined,
    multiplier: undefined
};

function EditGoVipPointMultiplierModal(props: EditGoVipPointMultiplierProps): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const [form, setForm] = useState<CreateGoVipPointMultiplierBody>(defaultAddGoVipPointMultiplierForm);

    useEffect(() => {
        if (props.isOpen) {
            setForm(props.goVipPointMultiplier);
        }
    }, [props.isOpen]);


    function actionOnChange(action: GoVipActionType): void {
        setForm({
            ...form,
            action: action
        })
    }

    function startDateOnChange(startDate: Date): void {
        setForm({
            ...form,
            startDate: moment(startDate).startOf("day").valueOf()
        })
    }

    function endDateOnChange(endDate: Date): void {
        setForm({
            ...form,
            endDate: moment(endDate).endOf("day").valueOf()
        })
    }

    function multiplierOnChange(multiplier: number): void {
        setForm({
            ...form,
            multiplier: multiplier
        })
    }

    /**
     * Reset the form & close the modal.
     *
     */
    function closeHelper(): void {
        setForm(defaultAddGoVipPointMultiplierForm);
        props.onClose();
    }
    /**
     * Submit form to update the current GoVipPointMultiplier.
     *
     */
    async function submitUpdatedGoVipPointMultiplier(e?: React.FormEvent): Promise<void> {
        e?.preventDefault();
        dispatch(incrementLoading());

        try {

            await new GoVipApi(getConfig(fullToken)).updateGoVipPointMultiplier({
                id: props.goVipPointMultiplier.id,
                createGoVipPointMultiplierBody: {
                    ...form
                }
            });

            props.onDone();

        } catch (err) {
            dispatch(await addError(err));
        } finally {
            dispatch(decrementLoading());
        }

    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="manage-vip-point-mmuliplier-add-modal"
        >
            <FrameModalHeader
                toggle={closeHelper}
                title="Edit GoVIP Point Multiplier"
            />

            <form onSubmit={submitUpdatedGoVipPointMultiplier}>
                <FrameModalBody className="manage-referral-reward-thresholds-add-modal_body">
                    <div>
                        <label>
                            GoVIP Earning Action*
                        </label>
                        <select
                            value={form.action}
                            onChange={e => actionOnChange(e.target.value as GoVipActionType)}
                        >
                            {Object.values(GoVipActionType).map((type:string) => (
                                <option value={type}>{type}</option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label>
                            Multiplier Start Date*
                        </label>
                        <FrameOneDatePicker
                            oneTap={true}
                            ranges={[]}
                            onChange={startDateOnChange}
                            cleanable={true}
                            caretAs={undefined}
                            value={form.startDate ? moment(form.startDate).startOf("day").toDate() : undefined}
                        />
                        <p className="form-tip">
                            If selected, will set a date at which the GoVIP Point Multiplier becomes effective. Selected
                            date/time will be midnight (12:00am) of the selected date, in the timezone of the admin
                            who sets it.
                        </p>
                    </div>

                    <div>
                        <label>
                            Multiplier End Date
                        </label>
                        <FrameOneDatePicker
                            oneTap={true}
                            ranges={[]}
                            onChange={endDateOnChange}
                            cleanable={true}
                            caretAs={undefined}
                            value={form.endDate ? moment(form.endDate).endOf("day").toDate() : undefined}
                        />
                        <p className="form-tip">
                            If selected, will set a date at which the GoVIP Point Multiplier becomes ineffective. Selected
                            date/time will be the end (23:59:59.999pm) of the selected date, in the timezone of the admin
                            who sets it.
                        </p>
                    </div>

                    <div>
                        <label>
                            Multiplier*
                        </label>
                        <select
                            value={form.multiplier}
                            onChange={e => multiplierOnChange(parseFloat(e.target.value))}
                        >
                            {MULTIPLIER_VALUES.map(val => (
                                <option value={val}>{val}</option>
                            ))}
                        </select>
                    </div>

                </FrameModalBody>

                <FrameModalFooter>
                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        forwardProps={{
                            type: "submit",
                        }}
                    >
                        Update GoVIP Point Multiplier
                    </FrameButton>
                </FrameModalFooter>
            </form>
        </FrameOneModal>
    );
}

export default EditGoVipPointMultiplierModal;
