import React, {ChangeEventHandler, ReactElement, ReactNode, useEffect, useState} from "react";
import {
	BonusDpayOption,
	Business,
	BusinessesApi,
	GetBusinessesOwnResponse,
	GetNftGroupingsResponse,
	GetUsersResponse,
	Merchant,
	SiteConfiguration,
	SiteConfigurationBody,
	SiteConfigurationKey,
	SiteConfigurationsApi,
	UsersApi,
	UserType
} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import ImageUploadCard from "../cards/ImageUploadCard";
import FrameButton from "../buttons/FrameButton";
import AddDiscountSelectGroupingsModal from "../modals/AddDiscountSelectGroupingsModal";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";
import FrameOneDatePicker from "../inputs/FrameOneDatePicker";
import moment from "moment";


const defaultFormValues: SiteConfigurationBody = {
	configKey: undefined,
	asset: undefined,
	url: "",
	description: "",
	data: undefined,
	nftGroupings: [],
	percentage: undefined,
	startDate: Date.now(),
	endDate: undefined,
	bonusDpayType: BonusDpayOption.FLATAMOUNT
}

interface Props {
	description?: ReactNode;
	configKey: SiteConfigurationKey;
	fields: Array<keyof SiteConfigurationBody>;
	nftGroupingsRes: GetNftGroupingsResponse;
}

function SiteConfigurationForm(props: Props): ReactElement {

	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const loadingIncrement = useSelector((store: IStore) => store.metaStore.loadingIncrement);
	const [formValues, setFormValues] = useState<SiteConfigurationBody>(defaultFormValues);
	const [siteConfiguration, setSiteConfiguration] = useState<SiteConfiguration>(undefined);
	const [showGroupingsModal, setShowGroupingsModal] = useState(false);
	const [merchantList, setMerchantList] = useState<GetUsersResponse>(undefined);
	const [selectedMerchantBusinesses, setSelectedMerchantBusinesses] = useState<{
		merchant: string,
		businesses: GetBusinessesOwnResponse
	}>(undefined);

	useEffect(() => {
		void getSiteConfigurationApi();
		void getDataForDropDowns();
	}, [props.configKey]);

	useEffect(() => {
		// On new image upload
		if (formValues?.asset && !siteConfiguration?.asset) {
			void submitUpdatedSiteConfiguration();
		}
	}, [formValues?.asset]);

	useEffect(() => {
		if (formValues.user) {
			void getBusinessesBelongingToSelectedMerchant();
		}
	}, [formValues.user]);

	async function getSiteConfigurationApi(): Promise<void> {
		setSiteConfiguration(undefined);
		setFormValues(defaultFormValues);

		dispatch(incrementLoading());

		try {
			const res = await new SiteConfigurationsApi(getConfig(fullToken)).getSiteConfiguration({
				configKey: props.configKey,
			});

			if (res) {
				setSiteConfiguration(res);
				setFormValues({
					...res,
					asset: res.asset?.id,
				});
			}
		} catch (e) {
			// Configuration might not exist yet. Will upsert on submission.
			// props.dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	/**
	 * Get the list of merchant accounts for inflating the drop-down/select input.
	 *
	 */
	async function getDataForDropDowns(): Promise<void> {
		dispatch(incrementLoading());

		try {
			if (props.fields.includes("user")) {
				const merchantsRes = await new UsersApi(getConfig(fullToken)).getUsers({
					type: [UserType.MERCHANT],
					limit: 1000000,
					offset: 0,
				});

				setMerchantList(merchantsRes);
			}
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	async function getBusinessesBelongingToSelectedMerchant(): Promise<void> {
		dispatch(incrementLoading());
		try {
			const res = await new BusinessesApi(getConfig(fullToken)).getBusinessesOwn({
				limit: 100000,
				offset: 0,
				merchant: formValues.user,
			});

			setSelectedMerchantBusinesses({
				merchant: formValues.user,
				businesses: res,
			});
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	/**
	 * Handle the merchant id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleMerchantIdOnChange(v: IReactSelectOption): void {
		setFormValues({
			...formValues,
			user: v?.value,
		});
	}

	/**
	 * Handle the merchant id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleBusinessIdOnChange(v: IReactSelectOption): void {
		setFormValues({
			...formValues,
			business: v?.value,
		});
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof SiteConfigurationBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setFormValues({
				...formValues,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Handle all date input onChange events.
	 *
	 * @param key
	 */
	function dateInputOnChange(key: "startDate" | "endDate") {
		return (date: Date) => {
			const value = (key === "startDate")
				? moment(date).startOf("day").valueOf()
				: moment(date).endOf("day").valueOf();
			setFormValues({
				...formValues,
				[key]: value
			});
		}
	}

	async function submitUpdatedSiteConfiguration(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		dispatch(incrementLoading());

		try {
			await new SiteConfigurationsApi(getConfig(fullToken)).updateSiteConfiguration({
				siteConfigurationBody: {
					...formValues,
					percentage: (formValues.percentage) ? Number(formValues.percentage) : undefined,
					amount: (formValues.amount) ? Number(formValues.amount) : undefined,
					configKey: props.configKey,
				},
			});
			void getSiteConfigurationApi();
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	function onNewImage(assetId: string): void {
		setFormValues({
			...formValues,
			asset: assetId,
		});
	}


	function onUnsetImage(): void {
		setFormValues({
			...formValues,
			asset: undefined,
		});
		setSiteConfiguration({
			...siteConfiguration,
			asset: undefined,
		});
	}

	/**
	 * For when the admin selects an nft grouping from the modal designed for such.
	 * If the grouping is already selected, it will be removed from the selected list,
	 * if the grouping is not already selected then it will be added to the list.
	 *
	 * @param id
	 */
	function handleSelectGrouping(id: string): void {
		const currentlySelectedGroupings = formValues.nftGroupings;
		const foundIndex = currentlySelectedGroupings.indexOf(id);
		if (foundIndex > -1) {
			currentlySelectedGroupings.splice(foundIndex, 1);
		} else {
			currentlySelectedGroupings.push(id);
		}

		setFormValues(r => {
			return {
				...r,
				nftGroupings: currentlySelectedGroupings,
			}
		});
	}

	function toggleGroupingsModal(): void {
		setShowGroupingsModal(s => !s);
	}

	const merchantIdOptions = makeReactSelectOptions<Merchant>(merchantList?.users as Array<Merchant>, "id", (merchant) => merchant.firstName + " " + merchant.lastName);
	const parentBusinessOptions = makeReactSelectOptions<Business>(selectedMerchantBusinesses?.businesses?.businesses, "id", "name");

	return (
		<div className="site-configuration-form">
			<AddDiscountSelectGroupingsModal
				isOpen={showGroupingsModal}
				nftGroupings={props.nftGroupingsRes?.nftGroupings}
				selectedGroupings={formValues?.nftGroupings}
				onSelect={handleSelectGrouping}
				onClose={toggleGroupingsModal}
			/>

			{props.description}

			<form onSubmit={submitUpdatedSiteConfiguration}>
				{(props.fields.includes("user")) && (
					<div className="site-configuration-form_field">
						<label>
							User
						</label>
						<FrameOneReactSelect
							name="User Account"
							placeholder="Select User Account..."
							isClearable={false}
							isLoading={!merchantList}
							isDisabled={!merchantList}
							value={merchantIdOptions.find(m => m.value === formValues.user)}
							onChange={handleMerchantIdOnChange}
							options={merchantIdOptions}
						/>
					</div>
				)}

				{(props.fields.includes("business")) && (
					<div className="site-configuration-form_field">
						<label>
							Business
						</label>
						<FrameOneReactSelect
							key={formValues.business}
							name="Business"
							placeholder="Select Business..."
							isClearable={false}
							isLoading={loadingIncrement > 0}
							isDisabled={selectedMerchantBusinesses?.businesses?.businesses?.length < 1 || !selectedMerchantBusinesses?.businesses}
							value={parentBusinessOptions.find(b => b.value === formValues.business)}
							onChange={handleBusinessIdOnChange}
							options={parentBusinessOptions}
						/>
					</div>
				)}

				{(props.fields.includes("asset")) && (
					<div className="site-configuration-form_field">
						<ImageUploadCard
							asset={siteConfiguration?.asset}
							assetName="Asset"
							assetDescription={props.configKey}
							label="Asset"
							description="Ideal Image Aspect Ratio: 10:3"
							onNew={onNewImage}
							onUnsetImage={onUnsetImage}
						/>
					</div>
				)}

				{(props.fields.includes("title")) && (
					<div className="site-configuration-form_field">
						<label>
							Title
						</label>
						<input
							value={formValues?.title}
							placeholder="Title..."
							onChange={inputOnChange("title")}
						/>
					</div>
				)}

				{(props.fields.includes("percentage")) && (
					<div className="site-configuration-form_field">
						<label>
							Percentage
						</label>
						<input
							type="number"
							value={formValues?.percentage}
							placeholder="Percentage..."
							onChange={inputOnChange("percentage")}
							min={0}
						/>
					</div>
				)}

				{(props.fields.includes("bonusDpayType")) && (
					<div className="site-configuration-form_field">
						<label>
							Type of bonus DPAY
						</label>
						<label className="radio-input-label">
							<input
								type="radio"
								value={BonusDpayOption.FLATAMOUNT}
								onChange={inputOnChange("bonusDpayType")}
								checked={formValues?.bonusDpayType === BonusDpayOption.FLATAMOUNT}
							/>
							Flat amount
						</label>
						<label className="radio-input-label">
							<input
								type="radio"
								value={BonusDpayOption.MULTIPLEAMOUNT}
								onChange={inputOnChange("bonusDpayType")}
								checked={formValues?.bonusDpayType === BonusDpayOption.MULTIPLEAMOUNT}
							/>
							Multiple amount x GoVIP level
						</label>
					</div>
				)}

				{(props.fields.includes("amount")) && (
					<div className="site-configuration-form_field">
						<label>
							Amount
						</label>
						<input
							type="number"
							value={formValues?.amount}
							placeholder="Amount..."
							onChange={inputOnChange("amount")}
							min={0}
							step={0.01}
						/>
					</div>
				)}

				{(props.fields.includes("startDate")) && (
					<div className="site-configuration-form_field">
						<label>
							Start Date*
						</label>
						<FrameOneDatePicker
							oneTap={true}
							ranges={[]}
							onChange={dateInputOnChange("startDate")}
							cleanable={true}
							value={formValues.startDate ? moment(formValues.startDate).startOf("day").toDate() : undefined}
						/>
					</div>
				)}

				{(props.fields.includes("endDate")) && (
					<div className="site-configuration-form_field">
						<label>
							End Date
						</label>
						<FrameOneDatePicker
							oneTap={true}
							ranges={[]}
							onChange={dateInputOnChange("endDate")}
							cleanable={true}
							value={formValues.endDate ? moment(formValues.endDate).endOf("day").toDate() : undefined}
						/>
						<p className="form-tip">If end date is not selected, the promotion does not expire.</p>
					</div>
				)}

				{(props.fields.includes("description")) && (
					<div className="site-configuration-form_field">
						<label>
							Description
						</label>
						<input
							value={formValues?.description}
							placeholder="Description..."
							onChange={inputOnChange("description")}
						/>
					</div>
				)}

				{(props.fields.includes("buttonText")) && (
					<div className="site-configuration-form_field">
						<label>
							Button Text
						</label>
						<input
							value={formValues?.buttonText}
							placeholder="Button Text..."
							onChange={inputOnChange("buttonText")}
						/>
					</div>
				)}

				{(props.fields.includes("url")) && (
					<div className="site-configuration-form_field">
						<label>
							URL
						</label>
						<input
							type="url"
							value={formValues?.url}
							placeholder="URL..."
							onChange={inputOnChange("url")}
						/>
					</div>
				)}

				{(props.fields.includes("nftGroupings")) && (
					<div className="site-configuration-form_field">
						<label>
							NFT Groupings
						</label>
						{formValues?.nftGroupings?.length < 1 ? (
							<p>
								No nft groupings selected.
							</p>
						) : (
							<ul>
								{formValues?.nftGroupings?.map((g, i) => {
									return (
										<li key={`taxonomy_${i}`}>
											{props.nftGroupingsRes?.nftGroupings?.find(bt => bt.id === g).name}
										</li>
									);
								})}
							</ul>
						)}

						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="lightBlue"
							onClick={toggleGroupingsModal}
							forwardProps={{
								type: "button"
							}}
						>
							Select NFT Groupings
						</FrameButton>
					</div>
				)}

				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="lightBlue"
					forwardProps={{
						type: "submit"
					}}
				>
					Save
				</FrameButton>

			</form>
		</div>
	);
}

export default SiteConfigurationForm;
