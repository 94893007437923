import React, {ReactElement} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {NftGrouping} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {NftsApi} from "@devour/client";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    grouping: NftGrouping;
}

function ManageNftGroupingsDeleteModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

    async function deleteNftGrouping() {
        dispatch(incrementLoading());
        try {
            await new NftsApi(getConfig(fullToken)).deleteNftGrouping({
                id: props.grouping.id
            });
            props.toggle();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="contact-modal"
        >
            <FrameModalHeader
                title="Delete Grouping"
                toggle={props.toggle}
            />

            <FrameModalBody className="contact-modal_body">
                <p>
                    Are you sure you really want to delete this grouping? It will also delete all NFT trackers, NFT
                    ownerships and webhooks associated with the tracker, as well as all other references to it in the system. Hidden menu items referencing this grouping will now be visible.
                    This cannot be undone!
                </p>
            </FrameModalBody>

            <FrameModalFooter>
                <FrameButton
                    color="danger"
                    onClick={deleteNftGrouping}
                >
                    Confirm
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default ManageNftGroupingsDeleteModal;
