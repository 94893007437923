import React, {ReactElement, useState} from "react";
import {Brand} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import EditBrandModal from "../../modals/EditBrandModal";
import BrandToggleEnabledModal from "../../modals/BrandToggleEnabledModal";

interface Props {
	brand: Brand;
	onDone: () => Promise<void>;
}

function BrandToggleEnabledCell(props: Props): ReactElement {

	const [showToggleModal, setShowToggleModal] = useState(false);

	function toggleToggleModal(): void {
		setShowToggleModal(s => !s);
	}

	function onDoneEdit(): void {
		setShowToggleModal(false);
		void props.onDone();
	}

	return (
		<React.Fragment>
			<BrandToggleEnabledModal
				isOpen={showToggleModal}
				brand={props.brand}
				onClose={toggleToggleModal}
				onDone={onDoneEdit}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color={props.brand.isEnabled ? "warning" : "lightBlue"}
					onClick={toggleToggleModal}
				>
					{props.brand.isEnabled ? "Disable Brand" : "Enable Brand"}
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default BrandToggleEnabledCell;