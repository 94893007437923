import React, {ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Asset,
	AssetsApi, BrandsApi,
	CreateBrandBody,
	Token,
} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import FileInputButton from "../inputs/FileInputButton";
import getConfig from "../../utils/getConfig";

export interface CreateBrandBodyFrontend extends Omit<CreateBrandBody, "image"> {
	image: FileWithSRC;
}

export const defaultCreateBrandBodyForm: CreateBrandBodyFrontend = {
	name: "",
	description: "",
	image: undefined,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function AddBrandModal(props: AddBrandModalProps): ReactElement {

	const [brandForm, setBrandForm] = useState<CreateBrandBodyFrontend>(defaultCreateBrandBodyForm);

	useEffect(() => {
		if (props.isOpen) {
			setBrandForm(defaultCreateBrandBodyForm);
		}
	}, [props.isOpen]);

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<CreateBrandBodyFrontend, "image">): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
		return (e) => {
			setBrandForm((b) => {
				return {
					...b,
					[key]: e.target.value,
				}
			});
		}
	}

	/**
	 * Handle onChange event for the image asset upload input.
	 *
	 * @param e
	 */
	async function imageOnChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newAsset = (await addURLsToFiles(e.target.files))[0];
		setBrandForm((b) => {
			return {
				...b,
				image: newAsset,
			}
		});
	}

	/**
	 * Submit the new brand into the system & close modal.
	 *
	 * @param e
	 */
	async function submitNewBrand(e: React.FormEvent): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			let image: Asset;
			if (brandForm.image) {
				image = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: brandForm.image,
				});
			}

			await new BrandsApi(getConfig(props.fullToken)).createBrand({
				createBrandBody: {
					name: brandForm.name,
					description: brandForm.description,
					image: image ? image.id : undefined,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-brands-add-modal"
		>
			<FrameModalHeader
				title="Add New Brand"
				toggle={props.onClose}
			/>

			<form onSubmit={submitNewBrand}>
				<FrameModalBody className="manage-brands-add-modal_body">
					<div>
						<label>
							Icon (suggested 1:1 aspect ratio)
						</label>
						<div className="manage-brands-add-modal_body_asset-upload_content">
							{brandForm.image && (
								<img
									src={brandForm.image.imageSRC as string}
									alt="New Brand Icon"
									className="manage-brands-add-modal_body_asset-upload_content_img"
								/>
							)}

							<FileInputButton
								accept=".png, .jpg, .jpeg, .gif"
								multiple={false}
								onChange={imageOnChange}
							>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="purple"
									forwardProps={{
										type: "button",
									}}
								>
									{brandForm.image ? "Change Image" : "Upload Image"}
								</FrameButton>
							</FileInputButton>
						</div>
					</div>

					<div>
						<label>
							Brand Name
						</label>
						<input
							value={brandForm.name}
							placeholder="Brand Name..."
							onChange={inputOnChange("name")}
						/>
					</div>

					<div>
						<label>
							Brand Description
						</label>
						<textarea
							value={brandForm.description}
							placeholder="Brand Description..."
							onChange={inputOnChange("description")}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Add Brand
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddBrandModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddBrandModal);
