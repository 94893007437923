import React, {ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import {
	Asset,
	Business,
	DiscountMenuItem,
	MenusApi,
	OrderDiscountType,
	MenuCategory,
} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import FrameOneTableCellImagePreview from "../tables/cells/FrameOneTableCellImagePreview";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import SelectPromotionMenuCategoriesModal from "./SelectPromotionMenuCategoriesModal";
import {useDispatch} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {CreateOrderDiscountBodyFrontEnd} from "./AddOrderDiscountModal";
import SelectPromotionMenuItemsModal from "./SelectPromotionMenuItemsModal";
import _ from 'lodash';


interface Props {
	isOpen: boolean;
	businesses?: Array<Business>;
	selectedBusinesses: string[];
	selectedCategories: Array<MenuCategory>;
	selectedItems: Array<DiscountMenuItem>;
	onSelect: (id: string) => void;
	onClose: () => void;
	discountType: OrderDiscountType;
	onUpdateForm: Dispatch<SetStateAction<CreateOrderDiscountBodyFrontEnd>>;
}

function AddDiscountSelectGroupingsModal(props: Props): ReactElement {
	const dispatch = useDispatch();
	const [searchString, setSearchString] = useState("");
	const [selectedBusiness, setSelectedBusiness] = useState<Business>(undefined);
	const [selectedBusinessMenu, setSelectedBusinessMenu] = useState<Array<MenuCategory>>(undefined);
	const [flattenedCategories, setFlattenedCategories] = useState<Array<MenuCategory>>(undefined);

	const [showSelectCategoriesModal, setShowSelectCategoriesModal] = useState<boolean>(false);
	const [showSelectMenuItemsModal, setShowSelectMenuItemsModal] = useState<boolean>(false);

	useEffect(() => {
		if (selectedBusiness?.id) {
			void fetchRestaurantMenu();
		}
	}, [selectedBusiness?.id])

	async function fetchRestaurantMenu(): Promise<void> {
		dispatch(incrementLoading());
		try {
			const res = await new MenusApi(getConfig()).getMenu({
				id: selectedBusiness?.id,
			});
			setSelectedBusinessMenu(res.menus);
			setFlattenedCategories(flattenCategories(res.menus));
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}
	
	function flattenCategories(categories: Array<MenuCategory>): Array<MenuCategory> {
		return _.flattenDeep(categories.map(category => [category, ...flattenCategories(category.menuSubcategories || [])]));
	}

	function onSearchInputChange(e: ChangeEvent<HTMLInputElement>): void {
		setSearchString(e.target.value);
	}

	function onSelectCategories(categoriesIds: Array<string>) {
		props.onUpdateForm(prevState => ({
			...prevState,
			menuCategories: [
				...props.selectedCategories.filter(c => c.business !== selectedBusiness?.id),
				...flattenedCategories.filter(c => categoriesIds.includes(c.id))
			]
		}))
		onCloseModal();
	}

	function onSelectItems(items: Array<DiscountMenuItem>) {
		props.onUpdateForm(prevState => ({
			...prevState,
			menuItems: [
				...props.selectedItems.filter(item => item.business !== selectedBusiness?.id),
				...items
			]
		}));
		onCloseModal();
	}

	function onCloseModal() {
		setShowSelectCategoriesModal(false);
		setShowSelectMenuItemsModal(false);
		setSelectedBusiness(undefined);
		setSelectedBusinessMenu(undefined);
	}

	return (
		<>
			<SelectPromotionMenuCategoriesModal
				isOpen={showSelectCategoriesModal && !!selectedBusinessMenu}
				menuCategories={selectedBusinessMenu}
				selectedCategories={props.selectedCategories?.map(c => c.id)}
				onClose={onCloseModal}
				onDone={onSelectCategories}
			/>
			<SelectPromotionMenuItemsModal
				isOpen={showSelectMenuItemsModal && !! selectedBusinessMenu}
				selectedItems={props.selectedItems?.map(item => item.id)}
				menuCategories={selectedBusinessMenu}
				menuItems={flattenedCategories?.map(category => category.menuItems)
					.reduce((acc, menuItems) => acc.concat(menuItems),[])
				}
				businessId={selectedBusiness?.id}
				onClose={onCloseModal}
				onDone={onSelectItems}
			/>
			<FrameOneModal
				isOpen={props.isOpen}
				toggle={props.onClose}
				size="lg"
				contentClassName="select-businesses-modal"
			>
				<FrameModalHeader
					title="Select Businesses"
					toggle={props.onClose}
				/>

				<FrameModalBody className="select-businesses-modal_body">
					<div className="select-businesses-modal_body_input-container">
						<label>
							Search businesses
						</label>
						<input
							placeholder="Search Businesses..."
							value={searchString}
							onChange={onSearchInputChange}
						/>
					</div>
					<p className="form-tip">
						To set up promotions by menu categories or items, please add the business first then select the categories or items.
					</p>
					<FrameOneTableContainer
						data={props.businesses?.filter((t) => {
							return t.name?.toLowerCase().includes(searchString.toLowerCase())
								|| t.description?.toLowerCase().includes(searchString.toLowerCase())
								|| t.internalName?.toLowerCase().includes(searchString.toLowerCase())
						})}
						columnOptions={[
							{
								key: "icon",
								headerValue: "Icon",
								cellRender: (asset: Asset) => {
									return (
										<FrameOneTableCellImagePreview asset={asset}/>
									);
								}
							},
							{
								key: "name",
								headerValue: "Name",
							},
							{
								key: "internalName",
								headerValue: "Internal Name",
							},
							{
								key: undefined,
								headerValue: "Select/De-select",
								cellRender: (t: Business) => {
									const isSelected: boolean = props.selectedBusinesses.indexOf(t.id) > -1;

									function clickHelper(): void {
										props.onSelect(t.id);
									}

									return (
										<div className="frame-one-table-cell">
											<FrameButton
												color={isSelected ? "danger" : "success"}
												onClick={clickHelper}
											>
												{isSelected ? "Remove" : "Add"}
											</FrameButton>
										</div>
									);
								}
							},
							{
								key: "description",
								headerValue: "Description",
								cellRender: (d: string) => {
									return (
										<div className="table-description-field">
											{d}
										</div>
									);
								}
							},
							(props.discountType === OrderDiscountType.CATEGORY) ?
							{
								key: undefined,
								headerValue: "Selected Categories",
								cellRender: (t: Business) => {
									return (
										<ul>
											{props.selectedCategories?.filter(c => c.business === t.id).map(c => (
												<li key={c.id}>{c.name}</li>
											))}
										</ul>
									)
								}
							} : null,
							(props.discountType === OrderDiscountType.CATEGORY) ?
								{
									key: undefined,
									headerValue: "Select Categories",
									cellRender: (business: Business) => {
										const isBusinessSelected: boolean = props.selectedBusinesses.indexOf(business.id) > -1;
										return (
											<div className="frame-one-table-cell">
												<FrameButton
													color={isBusinessSelected ? "lightBlue" : "lightGray"}
													onClick={() => {
														setSelectedBusiness(business);
														setShowSelectCategoriesModal(true);
													}}
													forwardProps={{
														disabled: !isBusinessSelected
													}}
												>
													Select Categories
												</FrameButton>
											</div>
										)
									}
								} : null,
							(props.discountType === OrderDiscountType.MENUITEM) ?
								{
									key: undefined,
									headerValue: "Selected Menu Items",
									cellRender: (t: Business) => {
										return (
											<ul>
												{props.selectedItems?.filter(item => item.business === t.id).map(c => (
													<li key={c.id}>{c.name}</li>
												))}
											</ul>
										)
									}
								} : null,
							(props.discountType === OrderDiscountType.MENUITEM) ?
								{
									key: undefined,
									headerValue: "Select Menu Items",
									cellRender: (business: Business) => {
										const isBusinessSelected: boolean = props.selectedBusinesses.indexOf(business.id) > -1;
										return (
											<div className="frame-one-table-cell">
												<FrameButton
													color={isBusinessSelected ? "lightBlue" : "lightGray"}
													onClick={() => {
														setSelectedBusiness(business);
														setShowSelectMenuItemsModal(true);
													}}
													forwardProps={{
														disabled: !isBusinessSelected
													}}
												>
													Select Items
												</FrameButton>
											</div>
										)
									}
								} : null
						].filter(Boolean)}
					/>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						color="darkBlue"
						onClick={props.onClose}
					>
						Done
					</FrameButton>
				</FrameModalFooter>
			</FrameOneModal>
		</>
	);
}

export default AddDiscountSelectGroupingsModal;
