import React, {ReactElement, useEffect, useState} from "react";
import {
	Business,
	BusinessesApi,
	Token
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import PageHeader from "../components/PageHeader";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {useParams} from "react-router";
import MerchantInfo from "../components/merchants/MerchantInfo";
import MerchantPayment from "../components/merchants/MerchantPayment";
import ManageRestaurantsParentCell from "../components/tables/cells/ManageRestaurantsParentCell";
import BusinessesEditCell from "../components/tables/cells/BusinessesEditCell";
import MerchantTaxonomies from "../components/merchants/MerchantTaxonomies";
import MerchantActions from "../components/merchants/MerchantActions";
import ManageRestaurantsViewPosKeyCell from "../components/tables/cells/ManageRestaurantsViewPosKeyCell";
import MerchantImages from "../components/merchants/MerchantImages";
import MerchantHours from "../components/merchants/MerchantHours";
import MerchantUsers from "../components/merchants/MerchantUsers";
import ManageBusinessMarketplaceFeeCell from "../components/tables/cells/ManageBusinessMarketplaceFeeCell";
import MerchantDeliveryDistance from "../components/merchants/MerchantDelivery";


interface StateProps {
	fullToken: Token;
}

function ManageMerchantDetails(props: ManageMerchantDetailsProps): ReactElement {

	const {businessId} = useParams<{ businessId: string; }>();
	const [business, setBusiness] = useState<Business>();

	useEffect(() => {
		fetchBusiness().then();
	}, [businessId]);

	/**
	 * Get the details for this menu order from our api.
	 */
	async function fetchBusiness(): Promise<void> {
		setBusiness(undefined);
		if (!businessId) {
			return;
		}

		props.dispatch(incrementLoading());

		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusiness({
				id: businessId,
			});
			setBusiness(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}

	}

	if (!business) {
		return null;
	}

	return (
		<div className="manage-merchant-details">
			<PageHeader>
				<div>
					<h3>
						{businessId}: {business.internalName || business.name}
					</h3>

					<p>
						Here you can view and manage the business in the system.
					</p>

				</div>
			</PageHeader>

			<div className="manage-merchant-details_body">
				<div className="manage-merchant-details_row">
					<div className="manage-merchant-details_info">
						<MerchantInfo business={business}/>
						<BusinessesEditCell
							business={business}
							onDone={fetchBusiness}
						/>
					</div>
					<div className="manage-merchant-details_actions">
						<MerchantActions
							business={business}
							onDone={fetchBusiness}
						/>
					</div>
				</div>
				<div className="manage-merchant-details_row">
					<div className="manage-merchant-details_taxonomies">
						<MerchantTaxonomies
							business={business}
							onDone={fetchBusiness}
						/>
					</div>
					<div className="manage-merchant-details_pos">
						<h4>
							POS
						</h4>
						<ManageRestaurantsViewPosKeyCell
							business={business}
							onDone={fetchBusiness}
						/>
					</div>
				</div>
				<div className="manage-merchant-details_row">
					<div className="manage-merchant-details_images">
						<MerchantImages
							business={business}
							onDone={fetchBusiness}
						/>
					</div>
					<div className="manage-merchant-details_hours">
						<MerchantHours
							business={business}
							onDone={fetchBusiness}
						/>
					</div>
				</div>
				<div className="manage-merchant-details_row">
					<div className="manage-merchant-details_payment">
						<MerchantPayment business={business}/>
					</div>
					<div className="manage-merchant-details_parent">
						<ManageRestaurantsParentCell
							business={business}
							onDone={fetchBusiness}
						/>
					</div>
				</div>
				<div className="manage-merchant-details_row">
					<div className="manage-merchant-details_delivery-distance">
						<MerchantDeliveryDistance
							business={business}
							onDone={fetchBusiness}
						/>
					</div>
					<div className="manage-merchant-details_marketplace-fee">
						<ManageBusinessMarketplaceFeeCell
							business={business}
							onDone={fetchBusiness}
						/>
					</div>
				</div>
				<div className="manage-merchant-details_users">
					<MerchantUsers
						business={business}
						onDone={fetchBusiness}
					/>
				</div>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type ManageMerchantDetailsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageMerchantDetails);
