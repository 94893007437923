import {ReactElement, useState} from "react";
import {NftGrouping} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import ManageCommunityModal from "../../modals/ManageCommunityModal";

interface Props {
    grouping: NftGrouping;
    onDone: () => Promise<void>;
}

function ManageCommunityCell(props: Props): ReactElement {

    const [showManageModal, setShowManageModal] = useState<boolean>(false);

    function toggleShowManageModal(): void {
        setShowManageModal(!showManageModal);
    }

    function onDone(): void {
        setShowManageModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <ManageCommunityModal
                isOpen={showManageModal}
                toggle={onDone}
                grouping={props.grouping}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightBlue"
                    narrow={true}
                    onClick={toggleShowManageModal}
                >
                    Manage Community
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ManageCommunityCell;