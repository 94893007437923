import {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {Business, BusinessesApi, BusinessKeys, Restaurant, Token, UpdateBusinessChowlyBody, User} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameOneModal from "./modalComponents/FrameOneModal";
import React from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	business: Restaurant;
	apiKeys: BusinessKeys;
	onClose: () => void;
	onDone: () => void;
}

function ManageRestaurantsUpdateChowlyApiKeyModal(props: ManageRestaurantsUpdateChowlyApiKeyModalProps): ReactElement {

	const [updateBusinessChowlyForm, setUpdateBusinessChowlyForm] = useState<UpdateBusinessChowlyBody>(undefined);

	useEffect(() => {
		if (props.isOpen && props.apiKeys !== undefined) {
			setUpdateBusinessChowlyForm({
				chowlyApi: props.apiKeys.chowlyApi,
			});
		}
	}, [props.isOpen]);

	function inputOnChange(key: keyof UpdateBusinessChowlyBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setUpdateBusinessChowlyForm({
				...updateBusinessChowlyForm,
				[key]: e.target.value,
			})
		}
	}

	async function submitUpdatedKey(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new BusinessesApi(getConfig(props.fullToken, process.env.REACT_APP_BACKEND_REST_POWERFUL_URL)).updateBusinessChowly({
				id: props.business.id,
				updateBusinessChowlyBody: {
					chowlyApi: updateBusinessChowlyForm.chowlyApi,
				}
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-restaurants-update-chowly-api-key-modal"
		>
			<FrameModalHeader
				title={`Update Chowly api key for ${props.business.name}`}
				toggle={props.onClose}
			/>

			<form onSubmit={submitUpdatedKey}>
				<FrameModalBody className="manage-restaurants-update-chowly-api-key-modal_body">
					<div>
						<label>
							Chowly API Key
						</label>
						<input
							value={updateBusinessChowlyForm?.chowlyApi}
							placeholder="Chowly API Key..."
							onChange={inputOnChange("chowlyApi")}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color={(updateBusinessChowlyForm?.chowlyApi) ? "lightBlue" : "danger"}
						forwardProps={{
							type: "submit"
						}}
					>
						{(updateBusinessChowlyForm?.chowlyApi) ?
							"Update Api Key" :
							"Remove Api Key"}
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageRestaurantsUpdateChowlyApiKeyModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageRestaurantsUpdateChowlyApiKeyModal);
