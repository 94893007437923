import React, {ReactElement} from "react";
import {connect, ConnectedProps} from "react-redux";
import {FiMenu} from "react-icons/fi";
import {IStore} from "../../redux/defaultStore";
import {toggleSidebar} from "../../redux/meta/MetaActions";

interface StateProps {
	sidebarOpen: boolean;
}

function HamburgerHelper(props: HamburgerHelperProps): ReactElement {

	/**
	 * Toggle the sidebar open state.
	 *
	 */
	function toggleSidebarHelper(): void {
		props.dispatch(toggleSidebar(!props.sidebarOpen));
	}

	return (
		<div className="hamburger-helper">
			<div className="hamburger-helper_content">
				<FiMenu
					className="hamburger-helper_content_icon"
					onClick={toggleSidebarHelper}
				/>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			sidebarOpen: store.metaStore.sidebarOpen,
		}
	});
}

type HamburgerHelperProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(HamburgerHelper);

