import React, {ReactElement, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {User, UserType} from "@devour/client";
import ManageUsersUpdateToMerchantModal from "../../modals/ManageUsersUpdateToMerchantModal";

interface Props {
	user: User;
	onDone: () => void;
}

function ManageUsersUpdateToMerchantCell(props: Props): ReactElement {

	const [showModal, setShowModal] = useState(false);

	/**
	 * Hide or Show the toggle modal.
	 *
	 */
	function toggleShowToggleModal(): void {
		setShowModal(!showModal);
	}

	function onDoneModal(): void {
		setShowModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<ManageUsersUpdateToMerchantModal
				isOpen={showModal}
				user={props.user}
				onClose={toggleShowToggleModal}
				onDone={onDoneModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color={(props.user.type === UserType.STANDARDUSER) ? "lightBlue" : "danger"}
					onClick={toggleShowToggleModal}
				>
					{(props.user.type === UserType.STANDARDUSER) ?
						"Switch to Merchant" :
						"Downgrade to customer"}
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default ManageUsersUpdateToMerchantCell;
