import React, {useEffect, useState} from "react";
import {ReactElement} from "react";
import {connect, ConnectedProps} from "react-redux";
import {addError} from "../../../redux/meta/MetaActions";
import {Business, BusinessesApi, Token} from "@devour/client";
import {IStore} from "../../../redux/defaultStore";
import getConfig from "../../../utils/getConfig";
import FrameButton from "../../buttons/FrameButton";
import ManageRestaurantsUpdateParentModal from "../../modals/ManageRestaurantsUpdateParentModal";
import {Link} from "react-router-dom";

interface StateProps {
	fullToken: Token;
}

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function ManageRestaurantsParentCell(props: ManageRestaurantsParentCellProps): ReactElement {

	const [parent, setParent] = useState<Business>(undefined);
	const [showModal, setShowModal] = useState<boolean>(false);

	useEffect(() => {
		void getParent();
	}, [props.business]);

	async function getParent(): Promise<void> {
		setParent(undefined);
		if (!props.business.parent) {
			return;
		}
		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusiness({
				id: props.business.parent,
			});

			setParent(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
		}
	}

	function renderParent(): JSX.Element {
		if (parent) {
			return (
				<div>
					Parent:
					<Link to={`/merchants/${parent.id}`}>
						{parent.name}
					</Link>
				</div>
			);
		}
		if (props.business.parent) {
			return (
				<div>
					Loading...
				</div>
			);
		}
		return (
			<div>
				Parent: -
			</div>
		);
	}

	function toggleModal(): void {
		setShowModal(s => !s);
	}

	function onDoneUpdateModal(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageRestaurantsUpdateParentModal
				isOpen={showModal}
				business={props.business}
				onClose={toggleModal}
				onDone={onDoneUpdateModal}
			/>
			<div>
				<h4>
					Parent Business
				</h4>
				{renderParent()}
				<FrameButton
					color="lightBlue"
					onClick={toggleModal}
				>
					Edit Parent
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props
		}
	});
}

type ManageRestaurantsParentCellProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageRestaurantsParentCell);
