import React, {ReactElement, useState} from "react";
import {Pos} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import ManagePosEditModal from "../../modals/ManagePosEditModal";

interface Props {
	pos: Pos;
	onDone: () => void;
}

function ManagePosEditCell(props: Props): ReactElement {

	const [showModal, setShowModal] = useState(false);

	function toggleShowToggleModal(): void {
		setShowModal(!showModal);
	}

	function onDoneModal(): void {
		setShowModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<ManagePosEditModal
				isOpen={showModal}
				pos={props.pos}
				onClose={toggleShowToggleModal}
				onDone={onDoneModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="warning"
					onClick={toggleShowToggleModal}
				>
					Edit Point of Sale
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default ManagePosEditCell;
