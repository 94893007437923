import React, {FormEvent, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {AgreementTermsApi, AgreementTermType, CreateAgreementTermBody, DocumentsApi, Token} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FileInputButton from "../inputs/FileInputButton";
import uploadToSignedURL from "../../utils/uploadToSignedUrl";

interface CreateAgreementTermBodyFrontend extends Omit<CreateAgreementTermBody, "file"> {
	file: File;
}

const defaultUploadAgreementTermForm: CreateAgreementTermBodyFrontend = {
	type: AgreementTermType.MERCHANT,
	file: undefined,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function AddAgreementTermModal(props: AddAgreementTermModalProps): ReactElement {

	const [agreementForm, setAgreementForm] = useState<CreateAgreementTermBodyFrontend>(defaultUploadAgreementTermForm);

	useEffect(() => {
		if (props.isOpen) {
			setAgreementForm(defaultUploadAgreementTermForm);
		}
	}, [props.isOpen]);

	function onPdfChange(e: React.ChangeEvent<HTMLInputElement>): void {
		if (e.target.files && e.target.files.length > 0) {
			setAgreementForm((form) => {
				return {
					...form,
					file: e.target.files?.[0],
				}
			});
		}
	}

	async function updateMerchantAgreements(e: FormEvent<HTMLFormElement>): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const uploadDocRes = await new DocumentsApi(getConfig(props.fullToken)).getUploadDocumentUrl({
				getUploadDocumentUrlBody: {
					contentType: agreementForm.file?.type,
				},
			});

			await uploadToSignedURL(uploadDocRes.url, agreementForm.file);

			const createDocRes = await new DocumentsApi(getConfig(props.fullToken)).createDocumentUploaded({
				createAssetUploadedBody: {
					fileName: uploadDocRes.fileName,
					name: agreementForm?.file?.name,
				},
			});

			await new AgreementTermsApi(getConfig(props.fullToken)).createAgreementTerm({
				createAgreementTermBody: {
					type: AgreementTermType.MERCHANT,
					file: createDocRes.id,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="add-agreement-term-modal"
		>
			<FrameModalHeader
				title="Update Merchant Agreement Terms"
				toggle={props.onClose}
			/>

			<form onSubmit={updateMerchantAgreements}>
				<FrameModalBody className="add-agreement-term-modal_body">
					<div>
						<label>Selected File:</label>
						<p>
							{agreementForm.file ? agreementForm.file.name : "No File Selected"}
						</p>

						<FileInputButton
							accept=".pdf"
							onChange={onPdfChange}
						>
							<FrameButton
								color="darkBlue"
								forwardProps={{
									type: "button",
								}}
							>
								Select File (.pdf)
							</FrameButton>
						</FileInputButton>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit",
							disabled: agreementForm.file == undefined,
						}}
					>
						Upload New Agreement
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddAgreementTermModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddAgreementTermModal);