import {
	Address,
	Business,
	BusinessesApi,
	BusinessTaxonomiesApi,
	BusinessType,
	UpdateBusinessAdmin,
	GeoJsonType,
	GetBusinessTaxonomiesResponse,
	PhoneNumberBody,
	HandoffOptions,
	Token,
	ConfigResponse,
	UtilsApi, OnboardingApi, OnboardingThirdParty,
} from "@devour/client";
import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {formatOptionalPhoneNumberForApiSubmission} from "../../utils/formatOptionalPhoneNumberForApiSubmission";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOnePhoneNumberInput from "../inputs/FrameOnePhoneNumberInput";
import FrameOneAddressInputGroup from "../inputs/FrameOneAddressInputGroup";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import SelectTaxonomiesModal from "./SelectTaxonomiesModal";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import {isRestaurant} from "../../typeguards/isRestaurant";
import {cloneDeep} from "lodash";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	onboardingThirdParty: OnboardingThirdParty;
	onClose: () => void;
	onDone: () => void;
}

function ManageThirdPartiesDeleteModal(props: ManageThirdPartiesDeleteModalProps): ReactElement {

	async function onConfirmDelete(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new OnboardingApi(getConfig(props.fullToken)).deleteOnboardingThirdParty({
				id: props.onboardingThirdParty.id,
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				title="Confirm Deletion"
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<div>
					<h5>
						Are your sure you want to delete this Third Party?
					</h5>

					<p>
						Their public & private keys will be revoked.
					</p>
				</div>

				<div>
					<label>
						Third Party
					</label>
					<div>
						{props.onboardingThirdParty.title}
					</div>
				</div>

			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="lightGray"
					onClick={props.onClose}
				>
					Cancel
				</FrameButton>

				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="danger"
					onClick={onConfirmDelete}
				>
					Yes, Delete
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageThirdPartiesDeleteModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageThirdPartiesDeleteModal);
