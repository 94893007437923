import {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {
	BusinessKeys,
	GetPosResponse,
	MealmeApi,
	Restaurant,
	UpdateBusinessMealmeBody,
	PosApi,
	Pos
} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameOneModal from "./modalComponents/FrameOneModal";
import React from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";

const defaultForm: UpdateBusinessMealmeBody = {
	mealmeStoreId: "",
	mealmeMenuId: "",
	menuSource: "",
}

interface Props {
	isOpen: boolean;
	business: Restaurant;
	apiKeys: BusinessKeys;
	onClose: () => void;
	onDone: () => void;
}

function ManageRestaurantsUpdateMealmeModal(props: Props): ReactElement {

	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [updateBusinessMealmeForm, setUpdateBusinessMealmeForm] = useState<UpdateBusinessMealmeBody>(defaultForm);
	const [posRes, setPosRes] = useState<GetPosResponse>();
	const posIdOptions = makeReactSelectOptions<Pos>(posRes?.pos as Array<Pos>, "id", (pos) => pos.name);

	useEffect(() => {
		getPos().then().catch();
	}, []);

	async function getPos(): Promise<void> {
		dispatch(incrementLoading());

		try {
			const res = await new PosApi(getConfig(fullToken)).getPos();
			setPosRes(res);
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	useEffect(() => {
		if (props.isOpen && props.apiKeys) {
			setUpdateBusinessMealmeForm({
				...updateBusinessMealmeForm,
				mealmeStoreId: props.apiKeys.mealmeStoreId,
				mealmeMenuId: props.apiKeys.mealmeMenuId,
				menuSource: props.business.menuSource?.id,
			});
		}
	}, [props.isOpen]);

	function inputOnChange(key: keyof UpdateBusinessMealmeBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setUpdateBusinessMealmeForm({
				...updateBusinessMealmeForm,
				[key]: e.target.value,
			})
		}
	}

	/**
	 * Handle the menu source react-select input onChange event.
	 *
	 * @param v
	 */
	function handleMenuSourceOnChange(v: IReactSelectOption): void {
		setUpdateBusinessMealmeForm({
			...updateBusinessMealmeForm,
			menuSource: v?.value,
		});
	}

	async function submitUpdatedKey(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		dispatch(incrementLoading());

		try {
			await new MealmeApi(getConfig(fullToken)).updateMealmeStoreId({
				id: props.business.id,
				updateBusinessMealmeBody: {
					mealmeStoreId: updateBusinessMealmeForm.mealmeStoreId,
					mealmeMenuId: updateBusinessMealmeForm.mealmeMenuId,
					menuSource: updateBusinessMealmeForm.menuSource,
				}
			});

			props.onDone();
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-restaurants-update-mealme-modal"
		>
			<FrameModalHeader
				title={`Update Mealme store ID for ${props.business.name}`}
				toggle={props.onClose}
			/>

			<form onSubmit={submitUpdatedKey}>
				<FrameModalBody className="manage-restaurants-update-mealme-modal_body">
					<div>
						<label>
							Mealme Store ID
						</label>
						<input
							value={updateBusinessMealmeForm?.mealmeStoreId}
							placeholder="Mealme Store ID..."
							onChange={inputOnChange("mealmeStoreId")}
						/>
					</div>

					{(updateBusinessMealmeForm?.mealmeStoreId) && (
						<React.Fragment>
						<div>
							<label>
								Mealme Menu ID (Optional, will get from API if not provided)
							</label>
							<input
								value={updateBusinessMealmeForm?.mealmeMenuId}
								placeholder="Mealme Menu ID..."
								onChange={inputOnChange("mealmeMenuId")}
							/>
						</div>
							<div>
								<label>
									Menu Source
								</label>
								<FrameOneReactSelect
									name="Menu Source"
									placeholder="Select Menu Source..."
									isClearable={true}
									isLoading={!posRes}
									isDisabled={!posRes}
									value={posIdOptions.find(m => m.value === updateBusinessMealmeForm.menuSource)}
									onChange={handleMenuSourceOnChange}
									options={posIdOptions}
								/>
							</div>
						</React.Fragment>
					)}

				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color={(updateBusinessMealmeForm?.mealmeStoreId) ? "lightBlue" : "danger"}
						forwardProps={{
							type: "submit"
						}}
					>
						{(updateBusinessMealmeForm?.mealmeStoreId) ?
							"Update Mealme store ID" :
							"Remove Mealme store ID"}
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

export default ManageRestaurantsUpdateMealmeModal;
