import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {
	Address, Restaurant,
	GetMenuOrdersResponse,
	MenuOrder,
	MenuOrdersApi,
	PaginationInfo,
	Token
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import PageHeader from "../components/PageHeader";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment";
import {Link} from "react-router-dom";
import FilterMenuOrdersForm, {MenuOrderFilters} from "../components/menuOrders/FilterMenuOrdersForm";
import {menuOrderFinalizedStatuses} from "../components/menuOrders/MenuOrderRefund";
import FrameButton from "../components/buttons/FrameButton";
import DpayCsvFilterModal from "../components/modals/DpayCsvFilterModal";

const monthMilliseconds = 1000 * 60 * 60 * 24 * 30;

const defaultFilters: MenuOrderFilters = {
	status: menuOrderFinalizedStatuses,
	business: "",
	customer: "",
	orderId: "",
	recipient: "",
	dateStart: Date.now() - monthMilliseconds,
	dateEnd: Date.now(),
}

interface StateProps {
	fullToken: Token;
}

function MenuOrdersPage(props: MenuOrdersPageProps): ReactElement {

	const [menuOrdersRes, setMenuOrdersRes] = useState<GetMenuOrdersResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [filters, setFilters] = useState<MenuOrderFilters>(defaultFilters);
	const [showCsvExportModal, setShowCsvExportModal] = useState<boolean>(false);

	useEffect(() => {
		getMenuOrders().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	useEffect(() => {
		// Filtered data might be outside of range. Go back to page 1.
		setFrontendPagination(defaultFrontendPagination);

		// If it's already on page 1, the pagination useEffect above won't retrigger. Manually grab new data.
		if (JSON.stringify(frontendPagination) === JSON.stringify(defaultFrontendPagination)) {
			getMenuOrders().then().catch();
		}
	}, [filters]);

	/**
	 * Renderer for the Recipient Cells.
	 *
	 * @param order
	 */
	function makeRecipientCell(order: MenuOrder): ReactNode {
		return (
			<div>
				{order.firstName} {order.lastName}<br/>
				{order.email}<br/>
				{order.phoneNumber?.nationalNumber}
			</div>
		);
	}

	async function getMenuOrders(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MenuOrdersApi(getConfig(props.fullToken)).getMenuOrdersAll({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				status: (filters.status?.length) ? filters.status : undefined,
				dateStart: filters.dateStart.toString(),
				dateEnd: filters.dateEnd.toString(),
				business: filters.business,
				customer: filters.customer,
				discount: filters.discount,
				orderId: filters.orderId,
				recipient: filters.recipient,
			});

			setMenuOrdersRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (

		<div className="manage-menu-orders">
			<PageHeader>
				<div>
					<h3>
						Manage Menu Orders
					</h3>

					<p>
						Here you can view and manage customer's menu orders in the system.
					</p>

				</div>
			</PageHeader>
			<DpayCsvFilterModal
				dateStart={filters.dateStart}
				dateEnd={filters.dateEnd}
				isOpen={showCsvExportModal}
				toggle={() => setShowCsvExportModal(false)}
			/>
			<div className="manage-menu-orders_table">
				<FilterMenuOrdersForm
					filters={filters}
					onUpdate={setFilters}
				/>
				<div className="manage-menu-orders_dpay-button-container">
					<FrameButton
						color="lightBlue"
						onClick={() => setShowCsvExportModal(true)}
					>
						Export DPAY data in period to CSV
					</FrameButton>
				</div>

				<FrameOneTableContainer
					data={menuOrdersRes?.menuOrders}
					pagination={{
						...menuOrdersRes?.paginationInfo,
						...frontendPagination,
					} as PaginationInfo}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: undefined,
							headerValue: "Order ID / Pickup Code",
							cellRender: (order: MenuOrder) => {
								return (
									<Link to={`/menu-orders/${order.id}`}>
										{order.id}<br/>
										{order.pickupCode}
									</Link>
								);
							}
						},
						{
							key: "business",
							headerValue: "Business",
							cellRender: (business: Restaurant) => {
								return (
									<Link to={`/merchants/${business.id}`}>
										{business.internalName || business.name}
									</Link>
								);
							}
						},
						{
							key: "status",
							headerValue: "Order Status",
						},
						{
							key: "subtotal",
							headerValue: "Subtotal",
							valueFormatter: (value: number) => `$${value.toFixed(2)}`,
						},
						{
							key: "paidToDevour",
							headerValue: "Paid to Devour",
							valueFormatter: (value: boolean) => (value) ? "Yes" : "No",
						},
						{
							key: "stripeCheckout",
							headerValue: "Stripe Checkout",
							valueFormatter: (value: boolean) => (value) ? "Yes" : "No",
						},
						{
							key: undefined,
							headerValue: "Recipient",
							cellRender: makeRecipientCell,
						},
						{
							key: "handoff",
							headerValue: "Handoff",
						},
						{
							key: "address",
							headerValue: "Address",
							valueFormatter: (addy: Address) => {
								function addPotentiallyEmptyAddressSection(_address: Address, key: keyof Address): string {
									if (typeof _address[key] === "string") {
										return " " + _address[key];
									}

									return "";
								}

								const addressString = addy?.line1
									+ addPotentiallyEmptyAddressSection(addy, "line2")
									+ addPotentiallyEmptyAddressSection(addy, "locality")
									+ addPotentiallyEmptyAddressSection(addy, "administrativeArea")
									+ addPotentiallyEmptyAddressSection(addy, "postalCode")
									+ addPotentiallyEmptyAddressSection(addy, "country");

								return (
									<a
										href={addy?.placeId ? `https://www.google.com/maps/place/?q=place_id:${addy?.placeId}` : `https://www.google.com/maps?q=${addressString}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{addressString}
									</a>
								);
							}
						},
						// {
						// 	key: "createdAt",
						// 	headerValue: "Created",
						// 	valueFormatter: (created: number) => moment(created).format("MMM DD YYYY HH:mm"),
						// },
						{
							key: "updatedAt",
							headerValue: "Last Updated",
							valueFormatter: (updated: number) => moment(updated).format("MMM DD YYYY HH:mm"),
						},
					]}
				/>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type MenuOrdersPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(MenuOrdersPage);
