export function removePlaceIDFromURL(url: string): string {
    let searchValue = url;

    // First 2 items should be http(s), devourgo.io
    // The 3rd item should be the route (empty for root)
    const urls: string[] = url.split("/").filter(Boolean);
    if (urls.length > 2 && urls[2] === "restaurants") {
        let length = urls.length - 2;
        if (
            (urls[3] === "search" && urls.length === 5) || // "/restaurants/search/:placeId"
            urls.length === 5 || // "/restaurants/:restaurantId/:placeId"
            urls.length === 9 // "/restaurants/:state/:cityName/:address/:restaurantName/:restaurantId/:placeId",
        ) {
            length--;
        }
        searchValue = `${process.env.REACT_APP_WEB_URL}/${urls.splice(2, length).join("/")}`;
    }

    return searchValue;
}