import React, {ReactElement} from "react";
import {FaUserAlt} from "react-icons/fa";
import classNames from "classnames";

interface Props {
	src?: string;
	alt?: string;
	className?: string;
	onClick?: () => void;
}

function ProfilePicture(props: Props): ReactElement {

	return (
		<div
			className={classNames("profile-picture", props.className, {
				"profile-picture_clicker": props.onClick != undefined,
			})}
			onClick={props.onClick && props.onClick}
		>
			{props.src ? (
				<img
					src={props.src}
					alt={props.alt}
					className="profile-picture_image"
				/>
			) : (
				<FaUserAlt className="profile-picture_placeholder"/>
			)}
		</div>
	);
}

export default ProfilePicture;
