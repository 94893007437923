import React, {ReactElement} from "react";
import {MenuOrder} from "@devour/client";

interface Props {
	menuOrder: MenuOrder;
}

function MenuOrderMeta(props: Props): ReactElement {

	return (
		<div className="menu-order-meta">
			<h3>
				Order Info
			</h3>
			<p>
				Status: {props.menuOrder.status}
			</p>
			<p>
				Handoff: {props.menuOrder.handoff}
			</p>
			<p>
				DPAY used: {props.menuOrder.dpay}
			</p>
			<p>
				Restaurant pickup code: {props.menuOrder.pickupCode || "N/A"}
			</p>
			<p>
				DPAY rewarded for having industry collection: {props.menuOrder.dpayRewardsIndustry}
			</p>
			<p>
				DPAY rewarded for paying with DPAY: {props.menuOrder.dpayRewardsPayment}
			</p>
			<p>
				Checkout through Stripe: {(props.menuOrder.stripeCheckout) ? "Yes" : "No"}
			</p>
			<p>
				Paid to Devour's account: {(props.menuOrder.paidToDevour) ? "Yes" : "No"}
			</p>
			<p>
				Devour order ID: {props.menuOrder.id}
			</p>
			<p>
				Chowly order ID: {props.menuOrder.chowlyId || "N/A"}
			</p>
			<p>
				First Delivery order ID:
				{(props.menuOrder.deliveryId) ? (
					<a
						href={`https://tracker.firstdelivery.com/${props.menuOrder.deliveryId}`}
						rel="noopener noreferrer"
					>
						{props.menuOrder.deliveryId}
					</a>
				) : " N/A"}
			</p>
			<p>
				Mealme order ID:
				{(props.menuOrder.mealmeTrackingLink) ? (
					<a
						href={props.menuOrder.mealmeTrackingLink}
						rel="noopener noreferrer"
					>
						{props.menuOrder.mealmeId}
					</a>
				) : " N/A"}
			</p>
			<p>
				Restaurant notes: {props.menuOrder.restaurantNotes || "N/A"}
			</p>
			<p>
				Delivery notes: {props.menuOrder.deliveryNotes || "N/A"}
			</p>
			<p>
				Experience rewards: {props.menuOrder.experienceRewards}
			</p>
		</div>
	);
}

export default MenuOrderMeta;
