import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {AddNftTrackerBody, EVMChain, NftGrouping, NftsApi, Token} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {parseChain} from "../../utils/parseChain";

const defaultAddNftTrackerBody: AddNftTrackerBody = {
	groupingId: undefined,
	contractAddress: "",
	evmChain: undefined,
	name: "",
	openSea: "",
	overrideSupportsInterface: false,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function AddNftTrackerModal(props: AddNftTrackerModalProps): ReactElement {

	const [nftGroupings, setNftGroupings] = useState<Array<NftGrouping>>(undefined);
	const [createTrackerBody, setCreateTrackerBody] = useState<AddNftTrackerBody>(defaultAddNftTrackerBody);

	/**
	 * Clean the form on open.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setCreateTrackerBody(defaultAddNftTrackerBody);
			getNftGroupings().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Call the get nft groupings to populate the react-select input.
	 *
	 */
	async function getNftGroupings(): Promise<void> {
		try {
			const res = await new NftsApi(getConfig(props.fullToken)).getNftGroupings({
				limit: 100000,
				offset: 0,
			});

			setNftGroupings(res.nftGroupings);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
		}
	}

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setCreateTrackerBody(defaultAddNftTrackerBody);
		props.onClose();
	}

	/**
	 * Handle the grouping id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleGroupingIdOnChange(v: IReactSelectOption): void {
		setCreateTrackerBody({
			...createTrackerBody,
			groupingId: v?.value,
		});
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<AddNftTrackerBody, "groupingId">): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setCreateTrackerBody({
				...createTrackerBody,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Submit new tracker to our api, reset modal & close modal on success.
	 *
	 * @param e
	 */
	async function submitNewTracker(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new NftsApi(getConfig(props.fullToken)).addNftTracker({
				addNftTrackerBody: {
					groupingId: createTrackerBody.groupingId,
					contractAddress: createTrackerBody.contractAddress || undefined,
					evmChain: createTrackerBody.evmChain,
					name: createTrackerBody.name,
					openSea: createTrackerBody.openSea || undefined,
					overrideSupportsInterface: createTrackerBody.overrideSupportsInterface,
				},
			});

			setCreateTrackerBody(defaultAddNftTrackerBody);
			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	const groupingIdOptions = makeReactSelectOptions<NftGrouping>(nftGroupings, "id", "name");

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={closeHelper}
			size="sm"
			contentClassName="manage-trackers-add-modal"
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Add New NFT Tracker"
			/>

			<form onSubmit={submitNewTracker}>
				<FrameModalBody className="manage-trackers-add-modal_body">
					<div>
						<label>
							NFT Grouping*
						</label>
						<FrameOneReactSelect
							name="NFT Grouping"
							placeholder="Select NFT Grouping..."
							isClearable={true}
							isLoading={nftGroupings === undefined}
							value={groupingIdOptions.find(o => o.value === createTrackerBody.groupingId)}
							onChange={handleGroupingIdOnChange}
							options={groupingIdOptions}
						/>
					</div>

					<div>
						<label>
							Tracker Name*
						</label>
						<input
							value={createTrackerBody.name}
							placeholder="Tracker Name..."
							onChange={inputOnChange("name")}
						/>
					</div>

					<div>
						<label>
							Chain*
						</label>
						<select
							value={createTrackerBody.evmChain}
							onChange={inputOnChange("evmChain")}
						>
							<option value={undefined} selected={true} disabled={true}>Select...</option>
							<option value={EVMChain.ETHEREUMMAINNET}>{parseChain(EVMChain.ETHEREUMMAINNET)}</option>
							<option value={EVMChain.AVALANCHE}>{parseChain(EVMChain.AVALANCHE)}</option>
							<option value={EVMChain.POLYGON}>{parseChain(EVMChain.POLYGON)}</option>
							<option value={EVMChain.GOERLI}>{parseChain(EVMChain.GOERLI)}</option>
							<option value={EVMChain.MUMBAI}>{parseChain(EVMChain.MUMBAI)}</option>
							<option value={EVMChain.BASE}>{parseChain(EVMChain.BASE)}</option>
							<option value={EVMChain.SOLANA}>{parseChain(EVMChain.SOLANA)}</option>
						</select>
					</div>

					<div>
						<label>
							{createTrackerBody.evmChain === EVMChain.SOLANA ? "Any Solana NFT Token Address*" : "Contract Address*"}
						</label>
						<input
							value={createTrackerBody.contractAddress}
							placeholder="Tracker Contract Address..."
							onChange={inputOnChange("contractAddress")}
						/>
						{createTrackerBody.evmChain === EVMChain.SOLANA && (
							<>
								<br />
								<p>
									<strong>
										IMPORTANT: for Solana only, you must enter the Token Address of any Solana NFT instead of the Program ID.
									</strong>
								</p>
								<p>
									For example, to track the Okay Bears collection, find the Token Address of <strong>any</strong> Okay Bears NFT. For Okay Bears #100
									this is <em>8AVTf...zKJZJ</em>. Entering the Program ID <em>Token...VQ5DA</em> is unacceptable and will fail.
								</p>
								<p>
									This will still track the whole collection, not just the individual NFT.
								</p>
							</>
						)}
					</div>

					<div>
						<label>
							OpenSea Link
						</label>
						<input
							value={createTrackerBody.openSea}
							placeholder="Tracker OpenSea Link..."
							onChange={inputOnChange("openSea")}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Save Tracker
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddNftTrackerModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddNftTrackerModal);
