import React, {ReactElement} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PosApi, Pos} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";


interface Props {
	isOpen: boolean;
	pos: Pos;
	onClose: () => void;
	onDone: () => void;
}

function ManagePosDeleteModal(props: Props): ReactElement {
	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

	/**
	 * Dynamically call the enable or disable apis based on the admin's "disabled" status.
	 *
	 */
	async function deleteCategory(): Promise<void> {
		dispatch(incrementLoading());

		try {
			await new PosApi(getConfig(fullToken)).deletePos({
				id: props.pos?.id,
			});

			props.onDone();
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				toggle={props.onClose}
				title="Confirm Point of Sale Deletion"
			/>

			<FrameModalBody>
				<p>
					Are you sure you want to remove this point of sale from the system?
					Any restaurants that were using it will have it removed from their point of sale list.
				</p>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="lightGray"
					onClick={props.onClose}
				>
					Cancel
				</FrameButton>

				<FrameButton
					color="danger"
					onClick={deleteCategory}
				>
					Yes, Delete
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

export default ManagePosDeleteModal;
