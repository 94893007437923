import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {
	Brand,
	BrandsApi,
	BrandToMerchantPermissions,
	GetBrandMerchantsResponse,
	Merchant,
	PaginationInfo,
	Token
} from "@devour/client";
import FrameOneTableContainer from "./tables/FrameOneTableContainer";
import {omit} from "lodash";
import moment from "moment/moment";
import {defaultFrontendPagination, FrontendPagination} from "./tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import AddMerchantToBrandModal from "./modals/AddMerchantToBrandModal";
import FrameButton from "./buttons/FrameButton";
import {getUserFullName} from "../utils/getUserFullName";
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";
import BrandEditCell from "./tables/cells/BrandEditCell";
import BrandMerchantPermissionsEditCell from "./tables/cells/BrandMerchantPermissionsEditCell";

interface StateProps {
	fullToken: Token;
}

interface Props {
	brand: Brand;
}

function ManageBrandMerchants(props: ManageBrandMerchantsProps): ReactElement {

	const [merchantsRes, setMerchantsRes] = useState<GetBrandMerchantsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddModal, setShowAddModal] = useState(false);

	useEffect(() => {
		void getBrandMerchants();
	}, [JSON.stringify(props.brand), JSON.stringify(frontendPagination)]);

	/**
	 * Get list of the merchants associated with this Brand.
	 *
	 */
	async function getBrandMerchants(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BrandsApi(getConfig(props.fullToken)).getBrandMerchants({
				id: props.brand.id,
				...frontendPagination,
			});

			setMerchantsRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Show or hide the Add Modal.
	 *
	 */
	function toggleAddMerchantModal(): void {
		setShowAddModal(s => !s);
	}

	/**
	 * When a new merchant permission is added successfully, close the "Add Modal" & reset the pagination to prompt the useEffect to run again.
	 *
	 */
	function onDoneAddMerchant(): void {
		setShowAddModal(false);
		setFrontendPagination((p) => {
			return {
				...defaultFrontendPagination,
				frontendRenderKey: p.frontendRenderKey + 1,
			}
		});
	}

	/**
	 * Dynamic render function for any of the permission columns.
	 *
	 * @param allowed
	 */
	function renderPermissionCell(allowed: boolean): ReactNode {
		if (allowed) {
			return (
				<AiFillCheckCircle className="table-icon table-text-success"/>
			);
		}

		return (
			<AiFillCloseCircle className="table-icon  table-text-danger"/>
		);
	}

	/**
	 * Render the cells for editing the brands. On done, just call the getBrands function again without changing the pagination.
	 *
	 * @param permissions
	 */
	function renderEditCell(permissions: BrandToMerchantPermissions): ReactNode {
		return (
			<BrandMerchantPermissionsEditCell
				permissions={permissions}
				onDone={getBrandMerchants}
			/>
		);
	}

	return (
		<React.Fragment>
			<AddMerchantToBrandModal
				isOpen={showAddModal}
				brand={props.brand}
				onClose={toggleAddMerchantModal}
				onDone={onDoneAddMerchant}
			/>

			<div className="manage-brand-merchants">
				<div className="manage-brand-merchants_header">
					<h4>
						Associated Merchant Users & Permissions
					</h4>

					<p>
						Use the add-button below to associate a new merchant user with this brand & select the permission
						that merchant user should have. Permissions can be edited after creating the relationship with the
						"Edit Permissions" button in the table for that merchant user.
					</p>

					<FrameButton
						color="lightBlue"
						onClick={toggleAddMerchantModal}
					>
						Add New Merchant User Permissions
					</FrameButton>
				</div>

				<div className="manage-brand-merchants_table">
					<FrameOneTableContainer
						data={merchantsRes?.brandMerchants}
						pagination={{
							...merchantsRes?.paginationInfo,
							...omit(frontendPagination, "frontendRenderKey"),
						} as PaginationInfo}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "merchant",
								headerValue: "Merchant User",
								valueFormatter: (merchant: Merchant) => getUserFullName(merchant),
							},
							{
								key: "merchant",
								headerValue: "Merchant User Email",
								valueFormatter: (merchant: Merchant) => merchant.email,
							},
							{
								key: "createMaps",
								headerValue: "Create Maps",
								cellRender: renderPermissionCell,
							},
							{
								key: undefined,
								headerValue: "Edit Permissions",
								cellRender: renderEditCell,
							},
							{
								key: "createdAt",
								headerValue: "Created",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: "updatedAt",
								headerValue: "Last Updated",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: "id",
								headerValue: "Relationship ID",
							},
							{
								key: "merchant",
								headerValue: "Merchant User ID",
								valueFormatter: (merchant: Merchant) => merchant.id,
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageBrandMerchantsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageBrandMerchants);
