import {ReactElement, useState} from "react";
import {GoVipPointMultiplier} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import DeleteGoVipPointMultiplierModal from "../../modals/DeleteGoVipPointMultiplierModal";

interface Props {
    goVipPointMultiplier: GoVipPointMultiplier;
    onDone: () => Promise<void>;
}

function GoVipPointMultiplierDeleteCell(props: Props): ReactElement {

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    function toggleDeleteModal(): void {
        setShowDeleteModal(s => !s);
    }

    function onDoneDelete(): void {
        setShowDeleteModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <DeleteGoVipPointMultiplierModal
                isOpen={showDeleteModal}
                goVipPointMultiplier={props.goVipPointMultiplier}
                onClose={toggleDeleteModal}
                onDone={onDoneDelete}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="danger"
                    onClick={toggleDeleteModal}
                >
                    Delete Multiplier
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default GoVipPointMultiplierDeleteCell;
