import React, {ReactElement, ReactNode, useState} from "react";
import FrameOneAccordionsItem from "./FrameOneAccordionsItem";

export interface FrameOneAccordionItem {
	key: string;
	title: string;
	body: ReactNode;
}

interface Props {
	defaultKey?: string;
	items: Array<FrameOneAccordionItem>;
}

function FrameOneAccordions(props: Props): ReactElement {
	const [activeKey, setActiveKey] = useState<string>(props.defaultKey);

	return (
		<div className="frame-one-accordions">
			{props.items.map((item: FrameOneAccordionItem) => (
				<FrameOneAccordionsItem
					key={item.key}
					body={item.body}
					title={item.title}
					isOpen={(activeKey === item.key)}
					handleTitleClick={() => {
						if (activeKey === item.key) {
							setActiveKey("");
						} else {
							setActiveKey(item.key);
						}
					}}
				/>
			))}
		</div>
	);
}

export default FrameOneAccordions;
