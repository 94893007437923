import {ReactElement, useState} from "react";
import {NftGrouping} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import ManageNftGroupingsEditEligibilityModal from "../../modals/ManageNftGroupingsEditEligibilityModal";

interface Props {
    grouping: NftGrouping;
    onDone: () => Promise<void>;
}

function ManageNftGroupingsEditEligibilityCell(props: Props): ReactElement {

    const [showEditModal, setShowEditModal] = useState(false);

    function toggleShowEditModal(): void {
        setShowEditModal(s => !s);
    }

    function onDoneEdit(): void {
        setShowEditModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <ManageNftGroupingsEditEligibilityModal
                isOpen={showEditModal}
                grouping={props.grouping}
                onClose={toggleShowEditModal}
                onDone={onDoneEdit}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightBlue"
                    narrow={true}
                    onClick={toggleShowEditModal}
                >
                    Edit Leaderboard Eligibility
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ManageNftGroupingsEditEligibilityCell;