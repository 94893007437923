import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {AddNftTrackerBody, EVMChain, NftGrouping, NftsApi, NftTracker, Token, UpdateNftTrackerBody} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {parseChain} from "../../utils/parseChain";

const defaultUpdateNftTrackerBody: UpdateNftTrackerBody = {
	trackerId: undefined,
	groupingId: undefined,
	contractAddress: "",
	evmChain: undefined,
	name: "",
	openSea: "",
	overrideSupportsInterface: false,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	tracker: NftTracker;
	onClose: () => void;
	onDone: () => void;
}

function ManageNftTrackersEditModal(props: ManageNftTrackersEditModalProps): ReactElement {

	const [nftGroupings, setNftGroupings] = useState<Array<NftGrouping>>(undefined);
	const [editTrackerBody, setEditTrackerBody] = useState<UpdateNftTrackerBody>(defaultUpdateNftTrackerBody);

	/**
	 * Clean the form on open.
	 *
	 */
	useEffect(() => {
		if (props.isOpen && props.tracker) {
			setEditTrackerBody({
				trackerId: props.tracker.id,
				groupingId: props.tracker.groupingId,
				contractAddress: props.tracker.contractAddress,
				evmChain: props.tracker.evmChain,
				name: props.tracker.name,
				openSea: props.tracker.openSea,
				overrideSupportsInterface: props.tracker.overrideSupportsInterface,
			});
			getNftGroupings().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Call the get nft groupings to populate the react-select input.
	 *
	 */
	async function getNftGroupings(): Promise<void> {
		try {
			const res = await new NftsApi(getConfig(props.fullToken)).getNftGroupings({
				limit: 100000,
				offset: 0,
			});

			setNftGroupings(res.nftGroupings);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
		}
	}

	/**
	 * Handle the grouping id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleGroupingIdOnChange(v: IReactSelectOption): void {
		setEditTrackerBody({
			...editTrackerBody,
			groupingId: v?.value,
		});
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<UpdateNftTrackerBody, "groupingId">): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setEditTrackerBody({
				...editTrackerBody,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Submit new tracker to our api, reset modal & close modal on success.
	 *
	 * @param e
	 */
	async function submitNewTracker(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new NftsApi(getConfig(props.fullToken)).updateNftTracker({
				updateNftTrackerBody: {
					trackerId: props.tracker.id,
					groupingId: editTrackerBody.groupingId,
					contractAddress: editTrackerBody.contractAddress || undefined,
					evmChain: editTrackerBody.evmChain,
					name: editTrackerBody.name,
					openSea: editTrackerBody.openSea || undefined,
					overrideSupportsInterface: editTrackerBody.overrideSupportsInterface,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	const groupingIdOptions = makeReactSelectOptions<NftGrouping>(nftGroupings, "id", "name");

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="sm"
			contentClassName="manage-trackers-add-modal"
		>
			<FrameModalHeader
				toggle={props.onClose}
				title={`Editing Tracker "${props.tracker?.name}"`}
			/>

			<form onSubmit={submitNewTracker}>
				<FrameModalBody className="manage-trackers-add-modal_body">
					<div>
						<label>
							NFT Grouping*
						</label>
						<FrameOneReactSelect
							name="NFT Grouping"
							placeholder="Select NFT Grouping..."
							isClearable={true}
							isLoading={nftGroupings === undefined}
							value={groupingIdOptions.find(o => o.value === editTrackerBody.groupingId)}
							onChange={handleGroupingIdOnChange}
							options={groupingIdOptions}
						/>
					</div>

					<div>
						<label>
							Tracker Name*
						</label>
						<input
							value={editTrackerBody.name}
							placeholder="Tracker Name..."
							onChange={inputOnChange("name")}
						/>
					</div>

					<div>
						<label>
							Chain*
						</label>
						<select
							value={editTrackerBody.evmChain}
							onChange={inputOnChange("evmChain")}
						>
							<option value={undefined} selected={true} disabled={true}>Select...</option>
							<option value={EVMChain.ETHEREUMMAINNET}>{parseChain(EVMChain.ETHEREUMMAINNET)}</option>
							<option value={EVMChain.AVALANCHE}>{parseChain(EVMChain.AVALANCHE)}</option>
							<option value={EVMChain.POLYGON}>{parseChain(EVMChain.POLYGON)}</option>
							<option value={EVMChain.GOERLI}>{parseChain(EVMChain.GOERLI)}</option>
							<option value={EVMChain.MUMBAI}>{parseChain(EVMChain.MUMBAI)}</option>
							<option value={EVMChain.BASE}>{parseChain(EVMChain.BASE)}</option>
							<option value={EVMChain.SOLANA}>{parseChain(EVMChain.SOLANA)}</option>
						</select>
					</div>

					<div>
						<label>
							{editTrackerBody.evmChain === EVMChain.SOLANA ? "Any Solana NFT Token Address*" : "Contract Address*"}
						</label>
						<input
							value={editTrackerBody.contractAddress}
							placeholder="Tracker Contract Address..."
							onChange={inputOnChange("contractAddress")}
						/>
						{editTrackerBody.evmChain === EVMChain.SOLANA && (
							<>
								<br />
								<p>
									<strong>
										IMPORTANT: for Solana only, you must enter the Token Address of any Solana NFT instead of the Program ID.
									</strong>
								</p>
								<p>
									For example, to track the Okay Bears collection, find the Token Address of <strong>any</strong> Okay Bears NFT. For Okay Bears #100
									this is <em>8AVTf...zKJZJ</em>. Entering the Program ID <em>Token...VQ5DA</em> is unacceptable and will fail.
								</p>
								<p>
									This will still track the whole collection, not just the individual NFT.
								</p>
							</>
						)}
					</div>

					<div>
						<label>
							OpenSea Link
						</label>
						<input
							value={editTrackerBody.openSea}
							placeholder="Tracker OpenSea Link..."
							onChange={inputOnChange("openSea")}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Update Tracker
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageNftTrackersEditModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageNftTrackersEditModal);
