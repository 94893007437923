import React, {ReactElement} from "react";
import {MenuOrder, Address} from "@devour/client";

interface Props {
	menuOrder: MenuOrder;
}

function addPotentiallyEmptyAddressSection(_address: Address, key: keyof Address): string {
	if (typeof _address[key] === "string") {
		return " " + _address[key];
	}

	return "";
}

function MenuOrderCustomer(props: Props): ReactElement {

	const addressString = props.menuOrder.address?.line1
		+ addPotentiallyEmptyAddressSection(props.menuOrder.address, "line2")
		+ addPotentiallyEmptyAddressSection(props.menuOrder.address, "locality")
		+ addPotentiallyEmptyAddressSection(props.menuOrder.address, "administrativeArea")
		+ addPotentiallyEmptyAddressSection(props.menuOrder.address, "postalCode")
		+ addPotentiallyEmptyAddressSection(props.menuOrder.address, "country");

	return (
		<div className="menu-order-customer">
			<h3>
				Customer
			</h3>
			<p>
				User account ID: {props.menuOrder.customer || "N/A"}
			</p>
			<p>
				Recipient first name: {props.menuOrder.firstName || "N/A"}
			</p>
			<p>
				Recipient last name: {props.menuOrder.lastName || "N/A"}
			</p>
			<p>
				Recipient phone: {props.menuOrder.phoneNumber?.nationalNumber || "N/A"}
			</p>
			<p>
				Recipient email: {props.menuOrder.email || "N/A"}
			</p>
			<p>
				Recipient address:
				<a
					href={props.menuOrder.address?.placeId ? `https://www.google.com/maps/place/?q=place_id:${props.menuOrder.address?.placeId}` : `https://www.google.com/maps?q=${addressString}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{addressString}
				</a>

			</p>
		</div>
	);
}

export default MenuOrderCustomer;
