import React, {ReactElement, useState} from "react";
import {OnboardingThirdParty} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {BsClipboardCheck} from "react-icons/bs";
import {FiCheck} from "react-icons/fi";

interface Props {
	isOpen: boolean;
	createdThirdParty: OnboardingThirdParty;
	onDismiss: () => void;
}

function OnBoardThirdPartyConfirmModal(props: Props): ReactElement {

	const [copied, setCopied] = useState(false);

	/**
	 * Concat & copy relevant instructions to clipboard so Devour Admin can easily relay to new partner.
	 *
	 */
	async function copyToClipboard(): Promise<void> {
		await navigator.clipboard.writeText(
			`This is the information necessary to use any of the onboarding APIs. The "Public Key" is safe to be exposed to the public, however the Secret Key should never be exposed to any users. It is recommended to keep the Secret Key in an environment variable on the third party's backend.\n\n` +
			`Public Key: ${props.createdThirdParty?.publicKey}\n` +
			`Secret Key: ${props.createdThirdParty?.secretKey}\n\n` +
			`Any APIs for onboarding third parties will need to pass these credentials in using Basic HTTP Authentication (https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization#basic_authentication). They will need to combine their Public and Secret keys together and pass them in the Authorization header with "Basic " as the prefix. An example of what an Authorization header would look like is...\n\n` +
			`${props.createdThirdParty?.exampleHeader}`
		);
		setCopied(true);
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			contentClassName="on-board-third-party-confirmation-modal"
		>
			<FrameModalHeader title="Third Party On Boarded!"/>

			<FrameModalBody className="on-board-third-party-confirmation-modal_body">
				<div>
					<label>New Third Party</label>
					<div>
						{props.createdThirdParty?.title}
					</div>
				</div>

				<div className="on-board-third-party-confirmation-modal_body_warning">
					WARNING! For security purposes, you will not be able to view the secret key again after closing this
					modal. Please save the secret key before closing this modal.
				</div>

				<div>
					This is the information necessary to use any of the onboarding APIs. The "Public Key" is safe to be
					exposed to the public, however the Secret Key should never be exposed to any users. It is
					recommended to keep the Secret Key in an environment variable on the third party's backend.
				</div>

				<div className="on-board-third-party-confirmation-modal_body_keys">
					<div>
						<label>
							Public Key
						</label>
						<div className="on-board-third-party-confirmation-modal_body_code">
							<code>
								{props.createdThirdParty?.publicKey}
							</code>
						</div>
					</div>

					<div>
						<label>
							Secret Key
						</label>
						<div className="on-board-third-party-confirmation-modal_body_code">
							<code>
								{props.createdThirdParty?.secretKey}
							</code>
						</div>
					</div>
				</div>

				<div>
					Any APIs for onboarding third parties will need to pass these credentials in using Basic HTTP
					Authentication{" "}
					<a
						href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization#basic_authentication"
						target="_blank"
						rel="noopener noreferrer"

					>
						(https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization#basic_authentication)
					</a>.
					They will need to combine their Public and Secret keys together and pass them in the Authorization
					header with "Basic " as the prefix. An example of what an Authorization header would look like is...
				</div>

				<div className="on-board-third-party-confirmation-modal_body_code">
					<code>
						{props.createdThirdParty?.exampleHeader}
					</code>
				</div>

				<div className="on-board-third-party-confirmation-modal_body_copy-instructions">
					<FrameButton
						color="lightBlue"
						leftIcon={BsClipboardCheck}
						onClick={copyToClipboard}
					>
						Copy Instructions to Clipboard
					</FrameButton>
				</div>

				{copied && (
					<div className="on-board-third-party-confirmation-modal_body_copied-confirmation">
						<FiCheck/>
						<p>
							Instructions copied to clipboard successfully.
						</p>
					</div>
				)}
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="danger"
					onClick={props.onDismiss}
					forwardProps={{
						type: "submit"
					}}
				>
					Dismiss
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

export default OnBoardThirdPartyConfirmModal;