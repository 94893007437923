import {ReactElement, useState} from "react";
import {OrderDiscount, GetBusinessesOwnResponse, Brand} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import EditOrderDiscountModal from "../../modals/EditOrderDiscountModal";

interface Props {
	businessesRes: GetBusinessesOwnResponse;
	orderDiscount: OrderDiscount;
	onDone: () => Promise<void>;
	brands: Array<Brand>;
}

function OrderDiscountsEditCell(props: Props): ReactElement {

	const [showEditModal, setShowEditModal] = useState(false);

	function toggleEditModal(): void {
		setShowEditModal(s => !s);
	}

	function onDoneEdit(): void {
		setShowEditModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<EditOrderDiscountModal
				businessesRes={props.businessesRes}
				isOpen={showEditModal}
				orderDiscount={props.orderDiscount}
				onClose={toggleEditModal}
				onDone={onDoneEdit}
				brands={props.brands}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="lightBlue"
					onClick={toggleEditModal}
				>
					Edit Discount
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default OrderDiscountsEditCell;
