import React, {ChangeEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {
    GetTotalDpayResponse,
    GetUsersResponse,
    ReferralsApi,
    Token,
    TransactionsApi,
    User,
    UsersApi,
    UserType
} from "@devour/client";
import getConfig from "../utils/getConfig";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {convertUserDisabledStatusToEnabledString} from "../utils/convertUserDisabledStatusToEnabledString";
import ManageUsersEditPasswordCell from "../components/tables/cells/ManageUsersEditPasswordCell";
import ManageUsersToggleEnableCell from "../components/tables/cells/ManageUsersToggleEnableCell";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import PageHeader from "../components/PageHeader";
import ManageUsersExperienceCell from "../components/tables/cells/ManageUsersUserExperienceCell";
import FrameButton from "../components/buttons/FrameButton";
import {omit} from "lodash";
import ManageUsersUpdateToMerchantCell from "../components/tables/cells/ManageUsersUpdateToMerchantCell";
import DebitOrCreditDpayCell from "../components/tables/cells/DebitOrCreditDpayCell";
import DebitOrCreditExperienceCell from "../components/tables/cells/DebitOrCreditExperienceCell";
import ManageUsersCommunityMembershipCell from "../components/tables/cells/ManageUsersCommunityMembershipCell";
import ManageReferredUsersCell from "../components/tables/cells/ManageReferredUsersCell";
import {csvExport} from "../utils/csvExport";
import ManageBannedCommunitiesCell from "../components/tables/cells/ManageBannedCommunitiesCell";
import ManageViewDpayCell from "../components/tables/cells/ManageViewDpayCell";
import ViewTotalVdpayModal from "../components/modals/ViewTotalVdpayModal";

function ManageUsers(): ReactElement {
    const [users, setUsers] = useState<GetUsersResponse>(undefined);
    const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
    const [search, setSearch] = useState<string>();
    const fullToken = useSelector<IStore, Token | undefined>(state => state.metaStore.fullToken);
    const dispatch = useDispatch();
    const [toggleTotalVdpay, setToggleTotalVdpay] = useState<boolean>(false);
    const [totalDpayData, setTotalDpayData] = useState<GetTotalDpayResponse>();

    useEffect(() => {
        readUsers().then().catch();
    }, [JSON.stringify(frontendPagination)]);

    /**
     * Call api to get & save the list of users.
     *
     */
    async function readUsers(): Promise<void> {
        dispatch(incrementLoading());

        try {
            const res = await new UsersApi(getConfig(fullToken)).getUsers({
                limit: frontendPagination?.limit,
                offset: frontendPagination?.offset,
                type: [UserType.STANDARDUSER, UserType.MERCHANT],
                search
            });

            setUsers(res);

        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    // Fetch referrals data then export to CSV
    async function exportAllReferralsToCsv() {
        dispatch(incrementLoading());
        try {
            const referralsData = await new ReferralsApi(getConfig(fullToken)).generateAllReferrals();

            const headers = [
                "Referrer Id",
                "Number of Referrals",
                "Referrer Email",
                "Referred Users",
            ];

            const date = new Date();
            const formattedDate = date.toLocaleDateString('en-US'); // 'en-US' locale formats the date as 'MM/DD/YYYY'

            const filename: string =
                `all-referrals-report-${formattedDate}.csv`;

            const formattedContent = [];

            // Need to generate extra rows of just a referred user's email if # of referrals is > 1
            referralsData.referrals.forEach((referral) => {

                // Array of full details
                if (referral.count > 0) {
                    formattedContent.push([
                        referral.referrerId[0],
                        referral.count,
                        referral.referrerEmail[0],
                        referral.referredUsers[0]
                    ]);
                }

                // Subsequent arrays with only referred user's email
                for (let i = 1; i < referral.count; i++) {
                    formattedContent.push([
                        "",  // blank referrerId
                        "",  // blank count
                        "",  // blank referrerEmail
                        referral.referredUsers[i]  // referred user's email
                    ]);
                }
            });

            csvExport(formattedContent, headers, filename);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    // Fetch total VDPAY data to display when view total vdpay button is clicked
    async function viewTotalVdpayAllUsers() {
        dispatch(incrementLoading());
        try {
            const response = await new TransactionsApi(getConfig(fullToken)).getTotalDpayAllUsers();
            setTotalDpayData(response);
            setToggleTotalVdpay(!toggleTotalVdpay);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    //
    function toggleTotalVdpayModal() {
        setToggleTotalVdpay(!toggleTotalVdpay);
    }


    /**
     * Renderer for the User Experience Cell.
     *
     * @param user
     */
    function makeUserExperienceCell(user: User): ReactNode {
        return (
            <ManageUsersExperienceCell user={user}/>
        );
    }

    /**
     * Renderer for the cells for admin to manually debit/credit dpay to user.
     *
     * @param user
     */
    function makeDebitOrCreditDpayCell(user: User): ReactNode {
        return (
            <DebitOrCreditDpayCell user={user}/>
        );
    }

    /**
     * Renderer for the cells for admin to manually debit/credit user experience.
     *
     * @param user
     */
    function makeDebitOrCreditExperienceCell(user: User): ReactNode {
        return (
            <DebitOrCreditExperienceCell
                user={user}
                onDone={readUsers}
            />
        );
    }

    /**
     * Renderer for the cell for admin to manage user's community.
     *
     * @param user
     */
    function makeManageCommunityMembershipCell(user: User): ReactNode {
        return (
            <ManageUsersCommunityMembershipCell
                user={user}
                onDone={readUsers}
            />
        );
    }

    /**
     * Renderer for the Edit Password Cells.
     *
     * @param user
     */
    function makeEditPasswordCell(user: User): ReactNode {
        return (
            <ManageUsersEditPasswordCell
                user={user}
                onDone={readUsers}
            />
        );
    }

    /**
     * Renderer for the Edit Password Cells.
     *
     * @param user
     */
    function makeToggleEnableCell(user: User): ReactNode {
        return (
            <ManageUsersToggleEnableCell
                user={user}
                onDone={readUsers}
            />
        );
    }

    /**
     * Renderer for the button for updating the user to a merchant type.
     *
     * @param user
     */
    function makeUpgradeUserTypeCell(user: User): ReactNode {
        return (
            <ManageUsersUpdateToMerchantCell
                user={user}
                onDone={handleSubmitSearch}
            />
        );
    }

    /**
     * Renderer for the User Referrals Cell.
     *
     * @param user
     */
    function makeUserReferralsCell(user: User): ReactNode {
        return (
            <ManageReferredUsersCell user={user}/>
        );
    }

    /**
     * Renderer for the Banned Communities Cell.
     *
     * @param user
     */
    function makeBannedCommunitiesCell(user: User): ReactNode {
        return (
            <ManageBannedCommunitiesCell user={user}/>
        );
    }

    /**
     * Renderer for the View Dpay Cell.
     *
     * @param user
     */
    function makeViewDpayCell(user: User): ReactNode {
        return (
            <ManageViewDpayCell user={user}/>
        );
    }

    function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
        setSearch(e.target.value);
    }

    function handleSubmitSearch(): void {
        setFrontendPagination(p => {
            return {
                ...defaultFrontendPagination,
                frontendRenderKey: p.frontendRenderKey + 1,
            }
        });
    }

    return (
        <div className="manage-users">
            <PageHeader className="manage-users_page-header">

                <h3>
                    Manage Users
                </h3>

                <p className="manage-users_page-header_description">
                    On this page you can see a list of all the current users on the platform, edit their
                    passwords, and enable/disable them.
                </p>

                <div className="manage-users_header-buttons">
                    <FrameButton
                        className="manage-user-referrals_all-referrals-csv-button"
                        color="lightBlue"
                        onClick={async () => await exportAllReferralsToCsv()}
                    >
                        Export All Referrals
                    </FrameButton>

                    <FrameButton
                        className="manage-user-vdpay_viewDpay-button"
                        color="lightBlue"
                        onClick={async () => await viewTotalVdpayAllUsers()}
                    >
                        View Total VDPAY of All Users
                    </FrameButton>

                </div>
            </PageHeader>

            {toggleTotalVdpay &&
                <ViewTotalVdpayModal
                    isOpen={toggleTotalVdpay}
                    onClose={toggleTotalVdpayModal}
                    data={totalDpayData}
                />
            }

            <div className="manage-users_table">
                <div>
                    <label>
                        Search
                        <input
                            value={search}
                            placeholder="Search for name, email, or nickname..."
                            onChange={searchOnChange}
                        />
                    </label>
                    <br/>
                    <FrameButton
                        color="lightBlue"
                        onClick={handleSubmitSearch}
                    >
                        Search
                    </FrameButton>
                    <br/>
                </div>

                <FrameOneTableContainer
                    data={users?.users}
                    pagination={{
                        ...users?.paginationInfo,
                        ...omit(frontendPagination, "frontendRenderKey"),
                    }}
                    onPaginationChange={setFrontendPagination}
                    columnOptions={[
                        {
                            key: "email",
                            headerValue: "Email",
                        },
                        {
                            key: "type",
                            headerValue: "Type",
                        },
                        {
                            key: "firstName",
                            headerValue: "First Name",
                        },
                        {
                            key: "lastName",
                            headerValue: "Last Name",
                        },
                        {
                            key: "nickname",
                            headerValue: "Nickname",
                        },
                        {
                            key: "disabled",
                            headerValue: "Enabled",
                            valueFormatter: convertUserDisabledStatusToEnabledString,
                        },
                        {
                            key: undefined,
                            headerValue: "User Experience",
                            cellRender: makeUserExperienceCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center",
                        },
                        {
                            key: undefined,
                            headerValue: "Debit/Credit Dpay",
                            cellRender: makeDebitOrCreditDpayCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center",
                        },
                        {
                            key: undefined,
                            headerValue: "Debit/Credit Experience",
                            cellRender: makeDebitOrCreditExperienceCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center",
                        },
                        {
                            key: undefined,
                            headerValue: "Community Membership",
                            cellRender: makeManageCommunityMembershipCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center"
                        },
                        {
                            key: undefined,
                            headerValue: "Edit Password",
                            cellRender: makeEditPasswordCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center",
                        },
                        {
                            key: undefined,
                            headerValue: "Enable/Disable User",
                            cellRender: makeToggleEnableCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center",
                        },
                        {
                            key: undefined,
                            headerValue: "Upgrade to Merchant Account",
                            cellRender: makeUpgradeUserTypeCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center",
                        },
                        {
                            key: undefined,
                            headerValue: "User Referrals",
                            cellRender: makeUserReferralsCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center",
                        },
                        {
                            key: undefined,
                            headerValue: "Banned Communities",
                            cellRender: makeBannedCommunitiesCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center",
                        },
                        {
                            key: undefined,
                            headerValue: "View VDPAY",
                            cellRender: makeViewDpayCell,
                            headerCellClassName: "justify-content-center",
                            rowCellClassName: "justify-content-center",
                        },
                    ]}
                />
            </div>
        </div>
    )
        ;
}


export default ManageUsers;
