import {User} from "@devour/client";

export function getUserFullName(user: User): string {
	if (user) {
		if (user.firstName && user.lastName) {
			return user.firstName + " " + user.lastName;
		} else if (user.firstName) {
			return user.firstName
		} else if (user.lastName) {
			return user.lastName;
		}
	}

	return "User";
}
