import React, {ReactElement} from "react";
import {FaCaretDown, FaCaretUp} from "react-icons/fa";
import {CellContextDetails} from "frame-one-table/build/contextTypes";

function FrameOneTableHeaderCell<T extends ReactElement>(value: T, data: CellContextDetails<T>): ReactElement {

	return (
		<div className="frame-one-table-header-cell">
			<span>
				{value}
			</span>

			{data.column?.sortable && (
				<div className="frame-one-sorting-icons-container">
					<FaCaretUp
						className="frame-one-sorting-icons-container__icon__up"
					/>

					<FaCaretDown
						className="frame-one-sorting-icons-container__icon__down"
					/>
				</div>
			)}
		</div>
	);
}

export default FrameOneTableHeaderCell;
