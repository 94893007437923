import {ReactElement, useState} from "react";
import {NftTracker} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import ManageNftTrackersDeleteModal from "../../modals/ManageNftTrackersDeleteModal";
import ManageNftTrackersForceRefreshSingleOwnershipModal
    from "../../modals/ManageNftTrackersForceRefreshSingleOwnershipModal";
import {validateEnvForSimpleHash} from "../../../utils/validateEnvForSimpleHash";

interface Props {
    isUpdateAll: boolean;
    tracker: NftTracker;
    onDone: () => Promise<void>;
}

function ManageNftTrackersDeleteCell(props: Props): ReactElement {

    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false);

    function toggleShowRefreshModal(): void {
        setShowRefreshModal(s => !s);
    }

    function onDoneRefresh(): void {
        setShowRefreshModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <ManageNftTrackersForceRefreshSingleOwnershipModal
                isUpdateAll={props.isUpdateAll}
                isOpen={showRefreshModal}
                toggle={onDoneRefresh}
                tracker={props.tracker}
            />
            <div className="frame-one-table-cell">
                <FrameButton
                    color="danger"
                    narrow={true}
                    forwardProps={{
                        disabled: validateEnvForSimpleHash(),
                    }}
                    onClick={toggleShowRefreshModal}
                >
                    Force Refresh Ownerships
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ManageNftTrackersDeleteCell;