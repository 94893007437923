import {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {GetNftGroupingResponse, NftGrouping, NftsApi, Token} from "@devour/client";
import React from "react";
import getConfig from "../../../utils/getConfig";

interface StateProps {
	fullToken: Token;
}

interface Props {
	nftGroupings: string[]
}

function OrderDiscountsNftGroupingsCell(props: OrderDiscountsNftGroupingsCellProps): ReactElement {

	const [groupings, setGroupings] = useState<Array<GetNftGroupingResponse>>(undefined);

	useEffect(() => {
		if (props.nftGroupings && props.nftGroupings.length > 0) {
			getNftGroupings().then().catch();
		}
	}, [props.nftGroupings]);

	async function getNftGroupings(): Promise<void> {
		try {
			const res = await Promise.all(props.nftGroupings.map(async (id: string) => {
				return await new NftsApi(getConfig(props.fullToken)).getNftGrouping({id});
			}));

			setGroupings(res);
		} catch (e) {

		}

	}

	return (
		<div className="frame-one-table-cell">
			{(!props.nftGroupings || props.nftGroupings.length === 0) ? (
				"-"
			) : (
				groupings === undefined ? "Loading..." : (
					<ul>
						{groupings.map((g, i) => {
							return (
								<li key={`grouping_${i}`}>
									{g.nftGrouping.name}
								</li>
							)
						})}
					</ul>
				)
			)}
		</div>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type OrderDiscountsNftGroupingsCellProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(OrderDiscountsNftGroupingsCell);
