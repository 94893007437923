import {ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Asset, AssetsApi,
	BusinessTaxonomiesApi,
	BusinessTaxonomyBody,
	Token
} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import React from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import FileInputButton from "../inputs/FileInputButton";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";

export interface BusinessTaxonomyBodyFrontend extends Omit<BusinessTaxonomyBody, "image"> {
	image: FileWithSRC;
}

const defaultAddTaxonomyBodyForm: BusinessTaxonomyBodyFrontend = {
	image: undefined,
	name: "",
	description: "",
	priority: 0,
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

interface StateProps {
	fullToken: Token;
}

function AddTaxonomyModal(props: AddTaxonomyModalProps): ReactElement {

	const [catForm, setCatForm] = useState<BusinessTaxonomyBodyFrontend>(defaultAddTaxonomyBodyForm);

	useEffect(() => {
		if (props.isOpen) {
			setCatForm(defaultAddTaxonomyBodyForm);
		}
	}, [props.isOpen]);

	/**
	 * Handle onChange event for the icon asset upload input.
	 *
	 * @param e
	 */
	async function onIconChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newAsset = (await addURLsToFiles(e.target.files))[0];
		setCatForm((_catForm): BusinessTaxonomyBodyFrontend => {
			return {
				..._catForm,
				image: newAsset,
			}
		});
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<BusinessTaxonomyBodyFrontend, "image">): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setCatForm({
				...catForm,
				[key]: e.target.value,
			});
		}
	}

	async function submitNewTaxonomy(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			let image: Asset;
			if (catForm.image) {
				image = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: catForm.image,
				});
			}

			await new BusinessTaxonomiesApi(getConfig(props.fullToken)).createBusinessTaxonomy({
				businessTaxonomyBody: {
					image: image ? image.id : undefined,
					name: catForm.name,
					description: catForm.description,
					priority: Number(catForm.priority),
				},
			});

			setCatForm(defaultAddTaxonomyBodyForm);
			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle the number format input for the amount field on change.
	 *
	 * @param values
	 */
	function priorityOnChange(values): void {
		setCatForm({
			...catForm,
			priority: values.floatValue,
		});
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-taxonomies-add-modal"
		>
			<FrameModalHeader
				title="Add New Category"
				toggle={props.onClose}
			/>

			<form onSubmit={submitNewTaxonomy}>
				<FrameModalBody className="manage-taxonomies-add-modal_body">
					<div>
						<label>
							Icon (suggested 1:1 aspect ratio)
						</label>
						<div className="manage-taxonomies-add-modal_body_asset-upload_content">
							{catForm.image && (
								<img
									src={catForm.image.imageSRC as string}
									alt="New Category Icon"
									className="manage-taxonomies-add-modal_body_asset-upload_content_img"
								/>
							)}

							<FileInputButton
								accept=".png, .jpg, .jpeg, .gif"
								multiple={false}
								onChange={onIconChange}
							>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="purple"
									forwardProps={{
										type: "button",
									}}
								>
									{catForm.image ? "Change Icon" : "Upload Icon"}
								</FrameButton>
							</FileInputButton>
						</div>
					</div>

					<div>
						<label>
							Category Name
						</label>
						<input
							value={catForm.name}
							placeholder="Category Name..."
							onChange={inputOnChange("name")}
						/>
					</div>

					<div>
						<label>
							Category Description
						</label>
						<input
							value={catForm.description}
							placeholder="Category Description..."
							onChange={inputOnChange("description")}
							type="textarea"
						/>
					</div>

					<div>
						<label>
							Priority*
						</label>
						<NumberFormat
							placeholder="Priority..."
							value={catForm.priority}
							allowLeadingZeros={false}
							allowNegative={true}
							decimalScale={1}
							onValueChange={priorityOnChange}
						/>
						<p className="form-tip">
							Categories are sorted by priority in descending order.
						</p>
					</div>

				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Add Category
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddTaxonomyModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddTaxonomyModal);
