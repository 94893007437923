import React, {ReactElement, useEffect, useState} from "react";
import {
	Business,
	Token,
	BusinessesApi,
	UtilsApi,
	HandoffOptions,
	BusinessKeys
} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {isRestaurant} from "../../typeguards/isRestaurant";
import EditRestaurantDeliveryModal from "../modals/EditRestaurantDeliveryModal";
import FrameButton from "../buttons/FrameButton";

interface StateProps {
	fullToken: Token;
}

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

const kmInMile = 1.609;

function MerchantDelivery(props: MerchantDeliveryProps): ReactElement {

	const [showEditRestaurantDeliveryModal, setShowEditRestaurantDeliveryModal] = useState(false);
	const [systemDeliveryMax, setSystemDeliveryMax] = useState<number>(0);
	const [apiKeys, setApiKeys] = useState<BusinessKeys>(undefined);

	useEffect(() => {
		void getSystemConfig();
	}, []);

	useEffect(() => {
		void getApiKeys();
	}, [props.business]);

	async function getSystemConfig(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const config = await new UtilsApi().getConfig();

			setSystemDeliveryMax(config.distanceDeliveryMax);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	async function getApiKeys(): Promise<void> {
		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessKeys({
				id: props.business.id,
			});

			setApiKeys(res);
		} catch (e) {
			props.dispatch(await addError(e));
		}
	}

	function onDone() {
		setShowEditRestaurantDeliveryModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<EditRestaurantDeliveryModal
				isOpen={showEditRestaurantDeliveryModal}
				onClose={() => setShowEditRestaurantDeliveryModal(false)}
				business={props.business}
				onDone={onDone}
				distanceDeliveryMax={systemDeliveryMax}
			/>

			<div className="merchant-hours">
				<h4>
					Delivery
				</h4>

				<p>
					First Delivery ID: {apiKeys?.firstDeliveryId}
				</p>

				{(!isRestaurant(props.business) ? (
					<p>
						Max Delivery Distance: N/A (Not a restaurant)
					</p>
				) : (!props.business.handoffOptions.includes(HandoffOptions.DELIVERY)) ? (
					<p>
						Max Delivery Distance: Delivery not enabled for this restaurant
					</p>
				) : (props.business.distanceDeliveryMax) ? (
					<p>
						Max Delivery Distance: {(props.business.distanceDeliveryMax / kmInMile / 1000).toFixed(1)} miles
					</p>
				) : (
					<p>
						Max Delivery Distance: {(systemDeliveryMax / kmInMile / 1000).toFixed(1)} miles (default)
					</p>
				))}

				{isRestaurant(props.business) && (
					<FrameButton
						color="lightBlue"
						onClick={() => setShowEditRestaurantDeliveryModal(true)}
					>
						Edit Delivery
					</FrameButton>
				)}

			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props
		}
	});
}

type MerchantDeliveryProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(MerchantDelivery);
