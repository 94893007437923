import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {
	GetOnboardingThirdPartiesResponse,
	GetOnboardingThirdPartiesSort,
	OnboardingApi, OnboardingThirdParty,
	PaginationInfo,
	SortOrder,
	Token
} from "@devour/client";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import {omit} from "lodash";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import AddThirdPartyOnBoardModal from "../components/modals/AddThirdPartyOnBoardModal";
import ManageThirdPartiesDeleteCell from "../components/tables/cells/renderDeleteCell";

interface StateProps {
	fullToken: Token;

}

function OnBoardingThirdParties(props: OnBoardingThirdPartiesProps): ReactElement {

	const [thirdPartiesRes, setThirdPartiesRes] = useState<GetOnboardingThirdPartiesResponse>();
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddModal, setShowAddModal] = useState(false);

	useEffect(() => {
		void getOnBoardings();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Get list of on-boarded third parties.
	 *
	 */
	async function getOnBoardings(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new OnboardingApi(getConfig(props.fullToken)).getOnboardingThirdParties({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				sort: GetOnboardingThirdPartiesSort.CreatedAt,
				order: SortOrder.Desc,
			});

			setThirdPartiesRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function toggleAddModal(): void {
		setShowAddModal(s => !s);
	}

	/**
	 * When finished adding, call api to update list.
	 *
	 */
	function onDoneAdd(): void {
		setShowAddModal(false);
		setFrontendPagination((_frontendPagination) => {
			return {
				...defaultFrontendPagination,
				frontendRenderKey: _frontendPagination.frontendRenderKey + 1,
			}
		});
	}

	function renderDeleteCell(onboardingThirdParty: OnboardingThirdParty): ReactNode {
		return (
			<ManageThirdPartiesDeleteCell
				onboardingThirdParty={onboardingThirdParty}
				onDone={onDoneAdd}
			/>
		);
	}

	return (
		<React.Fragment>
			<AddThirdPartyOnBoardModal
				isOpen={showAddModal}
				onClose={toggleAddModal}
				onDone={onDoneAdd}
			/>

			<div className="manage-third-parties">
				<PageHeader>
					<div>
						<h3>
							On Board Third Parties
						</h3>

						<p>
							Here you can add, view, and delete third party on-boardings.
						</p>

						<FrameButton
							color="lightBlue"
							onClick={toggleAddModal}
						>
							On Board New Third Party
						</FrameButton>
					</div>
				</PageHeader>

				<div className="manage-third-parties_table">
					<FrameOneTableContainer
						data={thirdPartiesRes?.onboardingThirdParties}
						pagination={{
							...thirdPartiesRes?.paginationInfo,
							...omit(frontendPagination, "frontendRenderKey"),
						} as PaginationInfo}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "publicKey",
								headerValue: "Public Key",
							},
							{
								key: "title",
								headerValue: "Title",
							},
							{
								key: "description",
								headerValue: "Description",
								cellRender: (d: string) => {
									return (
										<div className="table-description-field">
											{d}
										</div>
									);
								}
							},
							{
								key: undefined,
								headerValue: "Delete",
								cellRender: renderDeleteCell,
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type OnBoardingThirdPartiesProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(OnBoardingThirdParties);
