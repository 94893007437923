import {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {PosApi, PosBody} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import React from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";


const defaultAddPosBodyForm: PosBody = {
	name: "",
	description: "",
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function AddPosModal(props: Props): ReactElement {
	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [posForm, setPosForm] = useState<PosBody>(defaultAddPosBodyForm);

	useEffect(() => {
		if (props.isOpen) {
			setPosForm(defaultAddPosBodyForm);
		}
	}, [props.isOpen]);

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof PosBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setPosForm({
				...posForm,
				[key]: e.target.value,
			});
		}
	}

	async function submitNewPos(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		dispatch(incrementLoading());

		try {
			await new PosApi(getConfig(fullToken)).createPos({
				posBody: {
					name: posForm.name,
					description: posForm.description,
					itsaCheckmateLabel: posForm.itsaCheckmateLabel,
				},
			});

			setPosForm(defaultAddPosBodyForm);
			props.onDone();
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-pos-add-modal"
		>
			<FrameModalHeader
				title="Add New Point of Sale"
				toggle={props.onClose}
			/>

			<form onSubmit={submitNewPos}>
				<FrameModalBody className="manage-pos-add-modal_body">
					<div>
						<label>
							Point of Sale Name
						</label>
						<input
							value={posForm.name}
							placeholder="Point of Sale Name..."
							onChange={inputOnChange("name")}
						/>
					</div>

					<div>
						<label>
							Point of Sale Description
						</label>
						<input
							value={posForm.description}
							placeholder="Point of Sale Description..."
							onChange={inputOnChange("description")}
							type="textarea"
						/>
					</div>

					<div>
						<label>
							ItsaCheckmate Label (For auto-population, has to match the `pos` key from ItsaCheckmate's
							Get Location API)
						</label>
						<input
							value={posForm.itsaCheckmateLabel}
							placeholder="ItsaCheckmate Label..."
							onChange={inputOnChange("itsaCheckmateLabel")}
						/>
					</div>

				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Add Point of Sale
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

export default AddPosModal;
