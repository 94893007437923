import React, {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BannedCommunity, GetBannedCommunitiesResponse, Token, User, UsersApi} from "@devour/client";
import {IStore} from "../../../redux/defaultStore";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameOneModal from "../modalComponents/FrameOneModal";
import getConfig from "../../../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import FrameButton from "../../buttons/FrameButton";
import ConfirmUnbanUser from "./ConfirmUnbanUser";
import ConfirmBanOrUnbanFromAllCommunities from "./ConfirmBanOrUnbanFromAllCommunities";

interface BannedCommunitiesModalProps {
    isOpen: boolean;
    onClose: () => void;
    user: User;
}

function ManageBannedCommunitiesModal(props: BannedCommunitiesModalProps): ReactElement {

    const [bannedCommunitiesData, setBannedCommunitiesData] = useState<GetBannedCommunitiesResponse>();
    const [unbanConfirmation, setUnbanConfirmation] = useState<number | null>(null);
    const [banOrUnbanAllConfirmation, setBanOrUnbanAllConfirmation] = useState<boolean>(false);
    const [isBan, setIsBan] = useState<boolean>(false);
    const fullToken = useSelector<IStore, Token | undefined>(state => state.metaStore.fullToken);
    const dispatch = useDispatch();

    useEffect(() => {
        void fetchBannedCommunities();
    }, [props.isOpen]);

    async function fetchBannedCommunities() {
        if (props.isOpen) {
            try {
                dispatch(incrementLoading());
                const response = await new UsersApi(getConfig(fullToken)).getBannedCommunities({id: props.user.id});
                setBannedCommunitiesData(response);
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }
    }

    async function unBanUser(groupingId: string) {
        try {
            dispatch(incrementLoading());
            await new UsersApi(getConfig(fullToken)).unbanUserFromCommunity({
                banUserFromCommunityBody: {
                    userId: props.user.id,
                    groupingId: groupingId
                }
            });
            void fetchBannedCommunities();
            toggleUnbanConfirmation(null);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function banOrUnbanUserFromAllCommunities(ban: boolean) {
        try {
            dispatch(incrementLoading());
            await new UsersApi(getConfig(fullToken)).banOrUnbanUserFromAllCommunities({
                banOrUnbanUserFromAllCommunitiesBody: {
                    userId: props.user.id,
                    ban: ban
                }
            });
            void fetchBannedCommunities();
            toggleBanOrUnbanAllConfirmation(false);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function toggleUnbanConfirmation(index: number | null) {
        setUnbanConfirmation(index);
    }

    function toggleBanOrUnbanAllConfirmation(ban: boolean) {
        setBanOrUnbanAllConfirmation(!banOrUnbanAllConfirmation);

        if (ban) {
            setIsBan(true);
        } else {
            setIsBan(false);
        }
    }

    function closeHelper(): void {
        props.onClose();
        setBannedCommunitiesData(undefined);
    }

    // Render the ban or unban user from all communities buttons and confirmation modal
    function renderBanOrUnbanAllButtons(): ReactElement {
        return (
            <div className="manage-user-referrals_confirm-buttons">

                {(bannedCommunitiesData.bannedCommunities.length > 0) && (
                    <FrameButton
                        className="manage-user-referrals_referral-button manage-banned-communities_margin-right"
                        color="success"
                        onClick={() => toggleBanOrUnbanAllConfirmation(false)}
                    >
                        Unban User From All Communities
                    </FrameButton>)}

                <FrameButton
                    className="manage-user-referrals_referral-button"
                    color="danger"
                    onClick={() => toggleBanOrUnbanAllConfirmation(true)}
                >
                    Ban User From All Communities
                </FrameButton>

                {(banOrUnbanAllConfirmation) && (
                    <ConfirmBanOrUnbanFromAllCommunities
                        isOpen={banOrUnbanAllConfirmation}
                        onConfirmation={() => banOrUnbanUserFromAllCommunities(isBan)}
                        onClose={() => toggleBanOrUnbanAllConfirmation(false)}
                        ban={isBan}
                    />
                )}
            </div>
        )
    }

    //Render each banned community from the bannedCommunitiesData and confirmation modal for unban button
    function renderBannedCommunitiesData(): ReactElement {
        const data: Array<BannedCommunity> = bannedCommunitiesData.bannedCommunities;

        return (<>
            {data.map((community: BannedCommunity, index: number) => (

                <div className="manage-banned-communities_communities">

                    <div className="manage-banned-communities_community">
                        <img
                            className="manage-banned-communities_logo"
                            alt="community_image"
                            src={community.imgUrl}
                        />

                        <div className="manage-banned-communities_title">
                            {community.name}
                        </div>

                        <FrameButton
                            className="manage-banned-communities_unban-button"
                            color="danger"
                            onClick={() => toggleUnbanConfirmation(index)}
                        >
                            Unban User
                        </FrameButton>
                    </div>

                    {(unbanConfirmation === index) && (
                        <ConfirmUnbanUser
                            isOpen={unbanConfirmation === index}
                            onConfirmation={() => unBanUser(community.id)}
                            onClose={() => setUnbanConfirmation(null)}
                            community={community}
                        />
                    )}
                </div>
            ))}
        </>)
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={closeHelper}
            size="sm"
            contentClassName="user-experience-modal"
        >
            <FrameModalHeader
                toggle={closeHelper}
                title="Managed Banned Communities"
            />
            <FrameModalBody className="manage-user-referrals_modal">
                {!bannedCommunitiesData ? (
                    <div className="spinner"/>
                ) : (
                    bannedCommunitiesData.bannedCommunities.length > 0 ? (
                        <>
                            <div className="manage-user-referrals_total-referred-users">
                                <p>
                                    <b>
                                        Number of Banned Communities:{" "}
                                    </b>
                                    {bannedCommunitiesData.bannedCommunities.length}
                                </p>
                            </div>

                            <div className="manage-user-referrals_line"></div>

                            <h2 className="manage-user-referrals_title">Banned Communities</h2>

                            {renderBannedCommunitiesData()}
                            {renderBanOrUnbanAllButtons()}
                        </>
                    ) : (
                        <div className="manage-banned-communities_no-community">
                            This user is not banned from any community.
                            {renderBanOrUnbanAllButtons()}
                        </div>
                    )
                )}
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default ManageBannedCommunitiesModal;
