import React, {ChangeEvent, ChangeEventHandler, ReactElement} from "react";
import {MenuOrderStatus} from "@devour/client";
import FrameButton from "../../components/buttons/FrameButton";
import moment from "moment";
import {cloneDeep} from "lodash";
import FrameOneDatePicker from "../../components/inputs/FrameOneDatePicker";
import {menuOrderFinalizedStatuses} from "./MenuOrderRefund";

export interface MenuOrderFilters {
	status?: Array<MenuOrderStatus>;
	business?: string;
	customer?: string;
	recipient?: string;
	discount?: string;
	orderId?: string;
	dateStart?: number;
	dateEnd?: number;
}

interface Props {
	filters: MenuOrderFilters,
	onUpdate: (filters: MenuOrderFilters) => void;
}

function FilterMenuOrdersForm(props: Props): ReactElement {

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof MenuOrderFilters): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			props.onUpdate({
				...props.filters,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Submit filters to query.
	 *
	 * @param e
	 */
	function submitFilters(e?: React.FormEvent): void {
		e?.preventDefault();
		props.onUpdate(props.filters);
	}

	/**
	 * Handle order status change.
	 *
	 * @param e
	 */
	function statusOnChange(e: ChangeEvent<HTMLSelectElement>): void {
		const status: MenuOrderStatus = e.target.value as MenuOrderStatus;
		const newStatuses: Array<MenuOrderStatus> = cloneDeep(props.filters.status);

		const thisIndex = newStatuses.indexOf(status);

		if (thisIndex > -1) {
			// Remove this option from list of selected items
			newStatuses.splice(thisIndex, 1);
		} else {
			newStatuses.push(status);
		}
		props.onUpdate({
			...props.filters,
			status: newStatuses,
		});
	}

	function statusClear() {
		props.onUpdate({
			...props.filters,
			status: [],
		});
	}

	function statusAll() {
		props.onUpdate({
			...props.filters,
			status: Object.values(MenuOrderStatus),
		});
	}

	function statusPostPaid() {
		props.onUpdate({
			...props.filters,
			status: menuOrderFinalizedStatuses,
		});
	}

	function dateStartOnChange(dateStart: Date): void {
		props.onUpdate({
			...props.filters,
			dateStart: (dateStart) ? moment(dateStart).startOf("day").valueOf() : undefined,
		});
	}

	function dateEndOnChange(dateEnd: Date): void {
		props.onUpdate({
			...props.filters,
			dateEnd: (dateEnd) ? moment(dateEnd).startOf("day").valueOf() : undefined,
		});
	}

	return (
		<div className="filter-menu-orders-form">
			<form onSubmit={submitFilters}>
				<div className="filter-menu-orders-form_body">
					<div className="filter-menu-orders-form_inputs">
						<div className="filter-menu-orders-form_inputs_dates">
							<div className="filter-menu-orders-form_inputs_dates_item">
								<label>Start Date</label>
								<FrameOneDatePicker
									oneTap={true}
									ranges={[]}
									onChange={dateStartOnChange}
									cleanable={true}
									caretAs={undefined}
									value={props.filters.dateStart ? moment(props.filters.dateStart).startOf("day").toDate() : undefined}
								/>
							</div>
							<div className="filter-menu-orders-form_inputs_dates_item">
								<label>End Date</label>
								<FrameOneDatePicker
									oneTap={true}
									ranges={[]}
									onChange={dateEndOnChange}
									cleanable={true}
									caretAs={undefined}
									value={props.filters.dateEnd ? moment(props.filters.dateEnd).startOf("day").toDate() : undefined}
								/>
							</div>
						</div>
						<div className="filter-menu-orders-form_inputs_item">
							<label>Order ID / Pickup Code</label>
							<input
								value={props.filters.orderId}
								placeholder="Order ID / Pickup Code..."
								onChange={inputOnChange("orderId")}
							/>
						</div>
						<div className="filter-menu-orders-form_inputs_item">
							<label>Business</label>
							<input
								value={props.filters.business}
								placeholder="Business..."
								onChange={inputOnChange("business")}
							/>
						</div>
						<div className="filter-menu-orders-form_inputs_item">
							<label>Account Holder</label>
							<input
								value={props.filters.customer}
								placeholder="Account Holder..."
								onChange={inputOnChange("customer")}
							/>
						</div>
						<div className="filter-menu-orders-form_inputs_item">
							<label>Order Recipient</label>
							<input
								value={props.filters.recipient}
								placeholder="Order Recipient..."
								onChange={inputOnChange("recipient")}
							/>
						</div>

					</div>
					<div className="filter-menu-orders-form_status">
						<label>Statuses</label>
						<select
							value={props.filters.status}
							onChange={statusOnChange}
							multiple={true}
						>
							{Object.values(MenuOrderStatus).map((status) => (
								<option key={status} value={status}>
									{status}
								</option>
							))}
						</select>

						<div className="filter-menu-orders-form_status_buttons">

							<FrameButton
								color="lightGray"
								forwardProps={{type: "button"}}
								onClick={statusClear}
							>
								Clear statuses
							</FrameButton>
							<FrameButton
								color="lightGray"
								forwardProps={{type: "button"}}
								onClick={statusPostPaid}
							>
								Select post-paid statuses
							</FrameButton>
							<FrameButton
								color="lightGray"
								forwardProps={{type: "button"}}
								onClick={statusAll}
							>
								Select all statuses
							</FrameButton>
						</div>

					</div>
				</div>
			</form>
		</div>

	);
}

export default FilterMenuOrdersForm;
