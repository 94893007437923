import React, {ChangeEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import PageHeader from "../components/PageHeader";
import {GetUsersResponse, Merchant, PaginationInfo, PhoneNumber, Token, User, UsersApi, UserType} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment/moment";
import AddMerchantModal from "../components/modals/AddMerchantModal";
import FrameButton from "../components/buttons/FrameButton";
import {getUserFullName} from "../utils/getUserFullName";
import {CellContextDetails} from "frame-one-table/build/contextTypes";
import ManageUsersEditPasswordCell from "../components/tables/cells/ManageUsersEditPasswordCell";
import ManageUsersToggleEnableCell from "../components/tables/cells/ManageUsersToggleEnableCell";
import {omit} from "lodash";

interface StateProps {
	fullToken: Token;
}

function ManageMerchants(props: ManageMerchantsProps): ReactElement {

	const [merchants, setMerchants] = useState<GetUsersResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddMerchantModal, setShowAddMerchantModal] = useState(false);
	const [search, setSearch] = useState<string>();

	useEffect(() => {
		getMerchantAccounts().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	async function getMerchantAccounts(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getUsers({
				type: [UserType.MERCHANT],
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				search,
			});

			setMerchants(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function toggleAddMerchantModal(): void {
		setShowAddMerchantModal(s => !s);
	}

	function onDoneAddMerchant(): void {
		setShowAddMerchantModal(false);
		getMerchantAccounts().then().catch();
	}

	/**
	 * Renderer for the Edit Password Cells.
	 *
	 * @param user
	 */
	function makeEditPasswordCell(user: User): ReactNode {
		return (
			<ManageUsersEditPasswordCell
				user={user}
				onDone={getMerchantAccounts}
			/>
		);
	}

	/**
	 * Renderer for the Edit Password Cells.
	 *
	 * @param user
	 */
	function makeToggleEnableCell(user: User): ReactNode {
		return (
			<ManageUsersToggleEnableCell
				user={user}
				onDone={getMerchantAccounts}
			/>
		);
	}

	function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
		setSearch(e.target.value);
	}

	function handleSubmitSearch(): void {
		setFrontendPagination(p => {
			return {
				...defaultFrontendPagination,
				frontendRenderKey: p.frontendRenderKey + 1,
			}
		});
	}

	return (
		<React.Fragment>
			<AddMerchantModal
				isOpen={showAddMerchantModal}
				onClose={toggleAddMerchantModal}
				onDone={onDoneAddMerchant}
			/>

			<div className="manage-merchants">
				<PageHeader>
					<div>
						<h3>
							Manage Merchant Users
						</h3>

						<p>
							Use this page to manage merchant users.
						</p>

						<FrameButton
							color="lightBlue"
							onClick={toggleAddMerchantModal}
						>
							Create New Merchant User
						</FrameButton>
					</div>
				</PageHeader>

				<div className="manage-merchants_table">
					<div>
						<label>
							Search
						</label>
						<input
							value={search}
							placeholder="Search for name or email..."
							onChange={searchOnChange}
						/>
						<br/>
						<FrameButton
							color="lightBlue"
							onClick={handleSubmitSearch}
						>
							Search
						</FrameButton>
						<br/>
					</div>
					<FrameOneTableContainer
						data={merchants?.users}
						pagination={{
							...merchants?.paginationInfo,
							...omit(frontendPagination, "frontendRenderKey"),
						} as PaginationInfo}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "email",
								headerValue: "Email",
								cellRender: (email: string) => {
									return (
										<a
											href={`mailto:${email}?subject=Your new merchant account on DevourGO!`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{email}
										</a>
									);
								}
							},
							{
								key: undefined,
								headerValue: "Name",
								valueFormatter: (v: any, c: CellContextDetails<Merchant>) => {
									return getUserFullName(c.value);
								},
							},
							{
								key: "phoneNumber",
								headerValue: "Phone Number",
								valueFormatter: (p: PhoneNumber) => {
									if (!p) {
										return "-";
									}

									return p.countryCode + " " + p.nationalNumber;
								}
							},
							{
								key: "createdAt",
								headerValue: "Created",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: "updatedAt",
								headerValue: "Updated",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: undefined,
								headerValue: "Edit Password",
								cellRender: makeEditPasswordCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
							{
								key: undefined,
								headerValue: "Enable/Disable User",
								cellRender: makeToggleEnableCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type ManageMerchantsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageMerchants);
