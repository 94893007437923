import React, {ReactElement, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {LoginBody, LoginType, Token, UsersApi} from "@devour/client";
import FrameOneRoundPanel from "./FrameOneRoundPanel";
import FrameButton from "./buttons/FrameButton";
import {ReactComponent as UserSettings} from "../svgs/user-settings.svg";
import {IStore} from "../redux/defaultStore";
import {
	addError,
	decrementLoading,
	incrementLoading,
	login,
	toggleGlobalContactModalVisible
} from "../redux/meta/MetaActions";
import {genericOnChangeHelper} from "../utils/genericOnChangeHelper";

const defaultLoginBody: LoginBody = {
	email: "",
	password: "",
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	className?: string;
}

function LoginCard(props: LoginCardProps): ReactElement {

	const history = useNavigate();
	const [loginForm, setLoginForm] = useState<LoginBody>(defaultLoginBody);

	/**
	 * Submit credentials to try and log the user in.
	 * If successful, save token to redux & send to dashboard.
	 *
	 */
	async function submitLogin(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi().login({
				type: LoginType.ADMIN,
				loginBody: {
					email: loginForm?.email,
					password: loginForm?.password,
				},
			});

			props.dispatch(login(res));
			history("/dashboard");
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function onContact(e): void {
		e?.preventDefault();
		props.dispatch(toggleGlobalContactModalVisible(true));
	}

	return (
		<FrameOneRoundPanel className={classNames("login-card", props.className)}>
			<div className="login-card_form">
				<UserSettings className="login-card_form_user-icon"/>

				<h3 className="login-card_form_title">
					Admin login portal
				</h3>

				<form onSubmit={submitLogin}>
					<label>Email</label>
					<input
						placeholder="Email..."
						value={loginForm?.email}
						onChange={genericOnChangeHelper(loginForm, setLoginForm, "email")}
					/>

					<label>Password</label>
					<input
						placeholder="Password..."
						value={loginForm?.password}
						onChange={genericOnChangeHelper(loginForm, setLoginForm, "password")}
						type="password"
					/>

					<div className="login-card_form_button-container">
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							forwardProps={{
								type: "submit",
							}}
							color="purple"
						>
							Log In
						</FrameButton>
					</div>
				</form>

				<p className="login-card_form_contact">
					Having problems? <a href="#" onClick={onContact}>Contact Support</a>
				</p>
			</div>

			<div className="login-card_graphic-container">
				<img
					src={process.env.PUBLIC_URL + "/images/login-bubbles.svg"}
					className="login-card_graphic-container_bubbles"
				/>

				<div className="login-card_graphic-container_computer-container">
					<img
						src={process.env.PUBLIC_URL + "/images/login-computer.svg"}
						className="login-card_graphic-container_computer-container_computer"
					/>
				</div>
			</div>
		</FrameOneRoundPanel>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type LoginCardProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(LoginCard);
