import {UserType} from "@devour/client";
import {valueOrBust} from "./valueOrBust";

export function parseUserType(type: UserType): string {
	switch (type) {
		case UserType.GODADMIN:
			return "God Admin";
		case UserType.STANDARDADMIN:
			return "Standard Admin";
		case UserType.STANDARDUSER:
			return "Standard User";
		default:
			return valueOrBust(type);
	}
}
