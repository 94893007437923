import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {CreateUserBody, Token, UsersApi} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import getConfig from "../../utils/getConfig";
import generateCrytoSecureString from "../../utils/generateCryptoSecureString";

const defaultRegisterMerchantForm: CreateUserBody = {
    password: "",
    confirmPassword: "",
    email: "",
    firstName: "",
    lastName: "",
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onDone: () => void;
}

interface StateProps {
    fullToken: Token;
}

const VERIFICATION_EXPIRATION_TIME = 172800000;

function AddMerchantModal(props: AddMerchantModalProps): ReactElement {

    const [registerForm, setRegisterForm] = useState<CreateUserBody>(defaultRegisterMerchantForm);

    useEffect(() => {
        if (props.isOpen) {
            setRegisterForm(defaultRegisterMerchantForm);
        }
    }, [props.isOpen]);

    async function submitNewMerchant(e?: React.FormEvent): Promise<void> {
        e?.preventDefault();
        props.dispatch(incrementLoading());

        try {
            const generatedPassword = generateCrytoSecureString(["symbols", "numbers", "lowercase", "uppercase"], 16);
            await new UsersApi(getConfig(props.fullToken)).registerMerchant({
                createUserBody: {
                    password: generatedPassword,
                    confirmPassword: generatedPassword,
                    email: registerForm.email,
                    firstName: registerForm.firstName,
                    lastName: registerForm.lastName,
                },
            });

            props.onDone();
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    /**
     * Dynamic onChange for the form fields.
     *
     * @param key
     */
    function createOnChange(key: keyof CreateUserBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
        return (e) => {
            setRegisterForm({
                ...registerForm,
                [key]: e.target.value,
            });
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="manage-merchants-add-modal"
        >
            <FrameModalHeader
                title="Add New Merchant"
                toggle={props.onClose}
            />

            <form onSubmit={submitNewMerchant}>
                <FrameModalBody className="manage-merchants-add-modal_body">
                    <div>
                        <label>
                            Email*
                        </label>
                        <input
                            value={registerForm.email}
                            placeholder="Email..."
                            onChange={createOnChange("email")}
                        />
                    </div>

                    <div>
                        <label>
                            First Name*
                        </label>
                        <input
                            value={registerForm.firstName}
                            placeholder="First Name..."
                            onChange={createOnChange("firstName")}
                        />
                    </div>

                    <div>
                        <label>
                            Last Name*
                        </label>
                        <input
                            value={registerForm.lastName}
                            placeholder="Last Name..."
                            onChange={createOnChange("lastName")}
                        />
                    </div>

                    <p>
                        Don't forget to tell the merchant partner they
                        have {VERIFICATION_EXPIRATION_TIME / (60000 * 60)} hours to reset their password!
                    </p>
                </FrameModalBody>

                <FrameModalFooter>
                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        forwardProps={{
                            type: "submit",
                        }}
                    >
                        Add Merchant
                    </FrameButton>
                </FrameModalFooter>
            </form>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): Props & StateProps => {
        return {
            fullToken: store.metaStore.fullToken,
            ...props,
        }
    });
}

type AddMerchantModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddMerchantModal);
