import React, {ReactElement} from "react";
import {EditBrandMapBodyFrontend} from "../BrandMapFormValues";
import {OrderDiscount} from "@devour/client";
import {isDesktop, isTablet} from "react-device-detect";
import FrameButton from "../../../components/buttons/FrameButton";

interface Props {
    promos: Array<OrderDiscount>;
    formValues: EditBrandMapBodyFrontend;
    activeBusinessId: string;
}

function PreviewSecondaryPromos(props: Props): ReactElement {

    const selectedPromos = props.promos.filter(p => props.formValues.promos.includes(p?.id) &&
        p.businesses.includes(props.activeBusinessId));

    function formatDate(date: number) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
        }).format(date);
    }

    if (!selectedPromos.length) {
        return null;
    }

    return (
        <div className="restaurant-map-landing_other-rewards">
            <div className="restaurant-map-landing_other-rewards_header">
                <h3>REWARDS AVAILABLE</h3>
                {(isDesktop || isTablet) &&  <p>{selectedPromos.length} promotions available</p>}
            </div>
            <ul className="restaurant-map-landing_other-rewards_list">
                {selectedPromos.map(reward => (
                    <li
                        key={reward.id}
                        className="restaurant-map-landing_other-rewards_list_item"
                    >
                        <div className="restaurant-map-landing_other-rewards_list_item_inner">
                            <h4>{reward.label}</h4>
                            <p className="restaurant-map-landing_other-rewards_list_item_inner_expiry">
                                {reward.expiresAt ? formatDate(reward.expiresAt)
                                    : reward.maxRedemptions ? `${reward.maxRedemptions - reward.timesRedeemed} Available`
                                        : ""}
                            </p>
                            <p className="restaurant-map-landing_other-rewards_list_item_inner_description">{reward.description}</p>
                        </div>
                        <FrameButton
                            color="purple"
                            size="narrow"
                            className="restaurant-map-landing_other-rewards_list_item_button"

                        >
                            Redeem Now
                        </FrameButton>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default PreviewSecondaryPromos;
