import React, {ReactElement} from "react";
import {Business, BusinessesApi, Token} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";

interface StateProps {
	fullToken: Token;
}

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function ManageRestaurantsToggleEnabledStatusCell(props: ManageRestaurantsToggleEnabledStatusCellProps): ReactElement {

	async function toggleStatus(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new BusinessesApi(getConfig(props.fullToken)).toggleDisableBusiness({
				id: props.business.id,
			});

			void props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<div className="frame-one-table-cell">
			{props.business.isEnabled ? (
				<FrameButton
					color="warning"
					onClick={toggleStatus}
				>
					Disable Business
				</FrameButton>
			) : (
				<FrameButton
					color="lightBlue"
					onClick={toggleStatus}
				>
					Enable Business
				</FrameButton>
			)}
		</div>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageRestaurantsToggleEnabledStatusCellProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageRestaurantsToggleEnabledStatusCell);
