import React, {ReactElement} from "react";
import {ScrollRestoration} from "react-router-dom";
import classNames from "classnames";
import GlobalContactModalManager from "../modals/GlobalContactModalManager";
import LoadingManager from "../modals/LoadingManager";
import TokenManager from "../auth/TokenManager";
import AuthenticatedRoute from "./AuthenticatedRoute";
import ErrorModalManager from "../modals/errorManager/ErrorModalManager";
import FrameOneSidebar from "../sideBar/FrameOneSidebar";
import HamburgerHelper from "../sideBar/HamburgerHelper";

interface IProps {
	page: ReactElement;
	hasSidebar: boolean; // set to `true` for pages where you want the sidebar to be rendered. Don't forget to also configure the sidebar in _f1-sidebar.scss!
	authRequired?: boolean; // true -> user will be redirected to login page if no token is present & error message shown. For more complicated auth at this routing level, change this type to an interface of your choice and implement accordingly in the AuthenticatedRoute component.
}

function PageWrapper(props: IProps): ReactElement {

	return (
		<main className="app-main">
			<ScrollRestoration/>
			<ErrorModalManager/>
			<GlobalContactModalManager/>
			<LoadingManager/>
			<TokenManager/>

			<FrameOneSidebar dockingEnabled={props.hasSidebar}/>

			<div
				className={classNames("page-padding-adjuster", {
					"page-padding-adjuster_active": props.hasSidebar,
				})}
			>
				{props.hasSidebar && (
					<HamburgerHelper/>
				)}

				{props.authRequired ? (
					<AuthenticatedRoute>
						{props.page}
					</AuthenticatedRoute>
				) : props.page}
			</div>
		</main>
	);
}

export default PageWrapper;
