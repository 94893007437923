import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {
	Asset,
	Brand,
	BrandsApi,
	Business,
	GetBrandBusinessesResponse,
	GetBrandMerchantsResponse,
	Merchant,
	PaginationInfo,
	Token
} from "@devour/client";
import {defaultFrontendPagination, FrontendPagination} from "./tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import FrameButton from "./buttons/FrameButton";
import FrameOneTableContainer from "./tables/FrameOneTableContainer";
import {omit} from "lodash";
import {getUserFullName} from "../utils/getUserFullName";
import moment from "moment/moment";
import AddBusinessToBrandModal from "./modals/AddBusinessToBrandModal";
import FrameOneTableCellImagePreview from "./tables/cells/FrameOneTableCellImagePreview";
import RemoveBrandBusinessCell from "./tables/cells/RemoveBrandBusinessCell";
import {convertBooleanToString} from "../utils/convertBooleanToString";

interface StateProps {
	fullToken: Token;
}

interface Props {
	brand: Brand;
}

function ManageBrandBusinesses(props: ManageBrandBusinessesProps): ReactElement {

	const [businessesRes, setBusinessesRes] = useState<GetBrandBusinessesResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddModal, setShowAddModal] = useState(false);

	useEffect(() => {
		void getBrandBusinesses();
	}, [JSON.stringify(props.brand), JSON.stringify(frontendPagination)]);

	async function getBrandBusinesses(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BrandsApi(getConfig(props.fullToken)).getBrandBusinesses({
				id: props.brand.id,
				...frontendPagination,
			});

			setBusinessesRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Show or hide the Add Modal.
	 *
	 */
	function toggleAddBusinessModal(): void {
		setShowAddModal(s => !s);
	}

	/**
	 * When a new merchant permission is added successfully, close the "Add Modal" & reset the pagination to prompt the useEffect to run again.
	 *
	 */
	function onDoneAddBusiness(): void {
		setShowAddModal(false);
		setFrontendPagination((p) => {
			return {
				...defaultFrontendPagination,
				frontendRenderKey: p.frontendRenderKey + 1,
			}
		});
	}

	function renderRemoveCell(business: Business): ReactNode {
		return (
			<RemoveBrandBusinessCell
				business={business}
				brand={props.brand}
				onDone={onDoneAddBusiness}
			/>
		);
	}

	return (
		<React.Fragment>
			<AddBusinessToBrandModal
				isOpen={showAddModal}
				brand={props.brand}
				onClose={toggleAddBusinessModal}
				onDone={onDoneAddBusiness}
			/>

			<div className="manage-brand-businesses">
				<div className="manage-brand-businesses_header">
					<h4>
						Associated Merchants
					</h4>

					<p>
						Use the add-button below to add a new merchants to this brand entity. You can remove the merchant
						afterwards at any time with the "Remove from Brand" button in the table for that merchant.
						Removing a merchant from this brand would remove it from any existing maps & make it no longer
						possible to add to new maps being created.
					</p>

					<FrameButton
						color="lightBlue"
						onClick={toggleAddBusinessModal}
					>
						Add New Merchant to this Brand
					</FrameButton>
				</div>

				<div className="manage-brand-businesses_table">
					<FrameOneTableContainer
						data={businessesRes?.brandBusinesses}
						pagination={{
							...businessesRes?.paginationInfo,
							...omit(frontendPagination, "frontendRenderKey"),
						} as PaginationInfo}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "icon",
								headerValue: "Merchant Image",
								cellRender: (asset: Asset) => {
									return (
										<FrameOneTableCellImagePreview asset={asset}/>
									);
								}
							},
							{
								key: "name",
								headerValue: "Merchant",
							},
							{
								key: "isActive",
								headerValue: "Active",
								valueFormatter: convertBooleanToString,
							},
							{
								key: "id",
								headerValue: "Business ID",
							},
							{
								key: undefined,
								headerValue: "Remove Merchant from Brand",
								cellRender: renderRemoveCell,
							}
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageBrandBusinessesProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageBrandBusinesses);
