import {ReactElement, useState} from "react";
import {NftTracker} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import ManageNftTrackersDeleteModal from "../../modals/ManageNftTrackersDeleteModal";

interface Props {
    tracker: NftTracker;
    onDone: () => Promise<void>;
}

function ManageNftTrackersDeleteCell(props: Props): ReactElement {

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    function toggleShowDeleteModal(): void {
        setShowDeleteModal(s => !s);
    }

    function onDoneDelete(): void {
        setShowDeleteModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <ManageNftTrackersDeleteModal
                isOpen={showDeleteModal}
                toggle={onDoneDelete}
                tracker={props.tracker}
            />
            <div className="frame-one-table-cell">
                <FrameButton
                    color="danger"
                    narrow={true}
                    onClick={toggleShowDeleteModal}
                >
                    Delete Tracker
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ManageNftTrackersDeleteCell;