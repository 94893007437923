import React, {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	AddBrandPromoBody,
	Brand,
	BrandsApi,
	GetOrderDiscountsResponse,
	OrderDiscount,
	OrderDiscountsApi,
} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";

const defaultAddBrandPromoBody: AddBrandPromoBody = {
	brand: undefined,
	promo: undefined,
}

interface Props {
	isOpen: boolean;
	brand: Brand;
	onClose: () => void;
	onDone: () => void;
}

function AddPromoToBrandModal(props: Props): ReactElement {

	const dispatch = useDispatch();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [promoForm, setPromoForm] = useState<AddBrandPromoBody>(defaultAddBrandPromoBody);
	const [promoList, setPromoList] = useState<GetOrderDiscountsResponse>(undefined);

	/**
	 * When modal opens reset the form to defaults & current brand.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setPromoForm({
				...defaultAddBrandPromoBody,
				brand: props.brand.id,
			});

			if (!promoList) {
				void getPromosForDropDown();
			}
		}
	}, [props.isOpen]);

	/**
	 * Get the list of promos for inflating the drop-down/select input.
	 *
	 */
	async function getPromosForDropDown(): Promise<void> {
		dispatch(incrementLoading());

		try {
			const res = await new OrderDiscountsApi(getConfig(fullToken)).getOrderDiscounts({
				limit: 1000000,
				offset: 0,
			});

			setPromoList(res);
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	/**
	 * Handle the promo id react-select input onChange event.
	 *
	 * @param v
	 */
	function handlePromoIdOnChange(v: IReactSelectOption): void {
		setPromoForm((p) => {
			return {
				...p,
				promo: v?.value,
			}
		});
	}

	async function submitNewBrandPromo(e: React.FormEvent): Promise<void> {
		e.preventDefault();
		dispatch(incrementLoading());

		try {
			await new BrandsApi(getConfig(fullToken)).addBrandPromo({
				addBrandPromoBody: {
					brand: props.brand.id,
					promo: promoForm.promo,
				},
			});

			props.onDone();
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	const promoIdOptions = makeReactSelectOptions<OrderDiscount>(promoList?.orderDiscounts as Array<OrderDiscount>, "id", (promo) => promo.internalName + " - " + promo.label);

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-brand-add-promo-modal"
		>
			<FrameModalHeader
				title={`Add Promo for the "${props.brand.name}" Brand`}
				toggle={props.onClose}
			/>

			<form onSubmit={submitNewBrandPromo}>
				<FrameModalBody className="manage-brand-add-promo-modal_body">
					<div>
						<label>
							Promo
						</label>
						<FrameOneReactSelect
							name="promo"
							placeholder="Select Promo..."
							isClearable={true}
							isLoading={promoList === undefined}
							isDisabled={promoList === undefined}
							value={promoIdOptions.find(m => m.value === promoForm.promo)}
							onChange={handlePromoIdOnChange}
							options={promoIdOptions}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit",
							disabled: !promoForm.promo,
						}}
					>
						Add Promo to Brand
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

export default AddPromoToBrandModal;
