import React, {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {AgreementTermsApi, AgreementTermType, Document, GetAgreementTermsResponse, PaginationInfo, Token} from "@devour/client";
import {IStore} from "../redux/defaultStore";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import AddAgreementTermModal from "../components/modals/AddAgreementTermModal";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment/moment";
import {omit} from "lodash";

interface StateProps {
	fullToken: Token;
}

function MerchantAgreementTermsPage(props: MerchantAgreementTermsPageProps): ReactElement {

	const [agreementTermsRes, setAgreementTermsRes] = useState<GetAgreementTermsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddAgreementTermModal, setShowAddAgreementTermModal] = useState(false);

	useEffect(() => {
		getMerchantAgreements().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	async function getMerchantAgreements(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new AgreementTermsApi(getConfig(props.fullToken)).getAgreementTerms({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				type: [AgreementTermType.MERCHANT],
			});

			setAgreementTermsRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function toggleShowAddModal(): void {
		setShowAddAgreementTermModal(s => !s);
	}

	function onDoneAddModal(): void {
		setShowAddAgreementTermModal(false);
		setFrontendPagination(p => {
			return {
				...defaultFrontendPagination,
				frontendRenderKey: p.frontendRenderKey + 1,
			}
		});
	}

	return (
		<React.Fragment>
			<AddAgreementTermModal
				isOpen={showAddAgreementTermModal}
				onClose={toggleShowAddModal}
				onDone={onDoneAddModal}
			/>

			<div className="merchant-agreement-terms-page">
				<PageHeader>
					<div>
						<h3>
							Manage Merchant Agreement Terms
						</h3>

						<p>
							Here you can view and manage the merchant agreement terms documents that have been uplaoded
							in the system.
							<br/>
							<br/>
							You can see a history of documents uploaded to the system; any time a new document is
							uploaded, merchant accounts will be prompted to agree to the newest document before
							continuing to use the merchant portal.
						</p>

						<p>
							The File the at the top most of the list (should have the latest "Uploaded" date), is the document that merchants must agree to.
						</p>

						<FrameButton
							color="lightBlue"
							onClick={toggleShowAddModal}
						>
							Upload New Agreement Term
						</FrameButton>
					</div>
				</PageHeader>

				<div className="merchant-agreement-terms-page_table">
					<FrameOneTableContainer
						data={agreementTermsRes?.agreementTerms}
						pagination={{
							...agreementTermsRes?.paginationInfo,
							...omit(frontendPagination, "frontendRenderKey"),
						} as PaginationInfo}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "file",
								headerValue: "File",
								cellRender: (f: Document) => {
									return (
										<a
											href={f.redirectUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											{f.name}
										</a>
									);
								}
							},
							{
								key: "createdAt",
								headerValue: "Uploaded",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type MerchantAgreementTermsPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(MerchantAgreementTermsPage);