import {
	Business,
	BusinessesApi,
	UpdateBusinessAdmin,
	Token,
} from "@devour/client";
import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {isRestaurant} from "../../typeguards/isRestaurant";

const kmInMile = 1.609;

interface StateProps {
	fullToken: Token;
}

interface Props {
	business: Business;
	isOpen: boolean;
	onClose: () => void;
	distanceDeliveryMax: number;
	onDone: (e?: boolean) => void;
}

function EditRestaurantDeliveryModal(props: EditRestaurantDeliveryModalProps): ReactElement {
	const [restaurantForm, setRestaurantForm] = useState<UpdateBusinessAdmin>({...props.business});

	useEffect(() => {
		void getApiKeys();
	}, [props.business]);

	async function getApiKeys(): Promise<void> {
		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessKeys({
				id: props.business.id,
			});

			setRestaurantForm({
				...props.business,
				distanceDeliveryMax: (isRestaurant(props.business) && props.business.distanceDeliveryMax) ? Number((props.business.distanceDeliveryMax / kmInMile / 1000).toFixed(1)) : 0,
				firstDeliveryId: res.firstDeliveryId,
			});
		} catch (e) {
			props.dispatch(await addError(e));
		}
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<UpdateBusinessAdmin, "parent" | "isEnabled" | "phoneNumber" | "address" | "taxonomies" | "merchantId">): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setRestaurantForm({
				...restaurantForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Submit the updated restaurant/business into the system & close modal.
	 *
	 * @param e
	 */
	async function submitEditRestaurant(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {

			await new BusinessesApi(getConfig(props.fullToken)).updateBusinessAdmin({
				id: props.business.id,
				updateBusinessAdmin: {
					...props.business,
					taxonomies: undefined,
					firstDeliveryId: restaurantForm.firstDeliveryId,
					distanceDeliveryMax: (restaurantForm.distanceDeliveryMax) ? Number(restaurantForm.distanceDeliveryMax) * kmInMile * 1000 : 0,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<React.Fragment>
			<FrameOneModal
				isOpen={props.isOpen}
				toggle={props.onClose}
				contentClassName="manage-restaurants-edit-modal"
			>
				<FrameModalHeader
					title="Edit Business"
					toggle={props.onClose}
				/>

				<form onSubmit={submitEditRestaurant}>
					<FrameModalBody className="manage-restaurants-edit-modal_body">
						{isRestaurant(props.business) && (
							<div>
								<label>
									Max Delivery Distance
								</label>
								<input
									type="number"
									value={restaurantForm.distanceDeliveryMax}
									placeholder="Max Delivery Distance..."
									onChange={inputOnChange("distanceDeliveryMax")}
									min={0}
									max={props.distanceDeliveryMax / kmInMile / 1000}
									step={0.1}
								/>
								<p className="form-tip">
									Distance is in miles.
									Maximum is {props.distanceDeliveryMax / kmInMile / 1000} miles.
									Leave at "0" to default to the maximum distance.
								</p>
							</div>
						)}

						<div>
							<label>
								First Delivery Vendor ID
							</label>
							<input
								value={restaurantForm.firstDeliveryId}
								placeholder="First Delivery Vendor ID..."
								onChange={inputOnChange("firstDeliveryId")}
								type="text"
							/>
						</div>

					</FrameModalBody>

					<FrameModalFooter>
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="lightBlue"
							forwardProps={{
								type: "submit"
							}}
						>
							Save Delivery Distance
						</FrameButton>
					</FrameModalFooter>
				</form>
			</FrameOneModal>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type EditRestaurantDeliveryModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(EditRestaurantDeliveryModal);
