import React, {ReactElement, ReactNode} from "react";
import {Link} from "react-router-dom";
import {FiChevronRight} from "react-icons/fi";
import classNames from "classnames";

export interface BreadCrumbItemProps {
	label: string;
	route: string;
}

interface Props {
	crumbs: Array<BreadCrumbItemProps>;
}

function BreadCrumbs(props: Props): ReactElement {

	/**
	 * Render each crumb.
	 *
	 * @param item
	 * @param i
	 */
	function makeCrumb(item: BreadCrumbItemProps, i: number): ReactNode {
		return (
			<div
				key={`bread-crumb-item_${item.label}_${i}`}
				className="bread-crumb-container_item"
			>
				{i > 0 && (
					<FiChevronRight className="bread-crumb-container_item_icon"/>
				)}

				<Link
					className={classNames("bread-crumb-container_item_link")}
					to={item.route}
				>
					{item.label}
				</Link>
			</div>
		);
	}

	return (
		<div className="bread-crumb-container">
			{props.crumbs?.map(makeCrumb)}
		</div>
	);
}

BreadCrumbs.defaultProps = {
	crumbs: [],
};

export default BreadCrumbs;
