import {ReactElement, ReactNode, useEffect, useState} from "react";
import {GetApiResponsesResponse, APIResponsesApi, PaginationInfo, ModelApiResponse} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import React from "react";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment/moment";
import PageHeader from "../components/PageHeader";
import FilterApiResponsesForm, {ApiResponsesFilters} from "../components/FilterApiResponsesForm";
import {Link, useSearchParams} from "react-router-dom";

const monthMilliseconds = 1000 * 60 * 60 * 24 * 30;

const defaultFilters: ApiResponsesFilters = {
	types: [],
	business: "",
	orderId: "",
	dateStart: Date.now() - monthMilliseconds,
	dateEnd: Date.now(),
}

function ViewApiResponses(): ReactElement {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const menuOrderId = searchParams.get("menuOrder");
	const businessId = searchParams.get("business");
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [apiResponsesRes, setApiResponsesRes] = useState<GetApiResponsesResponse>();
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [filters, setFilters] = useState<ApiResponsesFilters>({
		...defaultFilters,
		business: businessId || undefined,
		orderId: menuOrderId || undefined,
	});

	useEffect(() => {
		void getApiResponses();
	}, [JSON.stringify(frontendPagination)]);

	useEffect(() => {
		// Filtered data might be outside of range. Go back to page 1.
		setFrontendPagination(defaultFrontendPagination);

		// If it's already on page 1, the pagination useEffect above won't retrigger. Manually grab new data.
		if (JSON.stringify(frontendPagination) === JSON.stringify(defaultFrontendPagination)) {
			void getApiResponses();
		}
	}, [filters]);

	async function getApiResponses(): Promise<void> {
		dispatch(incrementLoading());

		try {
			const res = await new APIResponsesApi(getConfig(fullToken)).getApiResponses({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				dateStart: filters.dateStart.toString(),
				dateEnd: filters.dateEnd.toString(),
				types: (filters.types.length) ? filters.types : undefined,
				orderId: filters.orderId,
				business: filters.business,
			});

			setApiResponsesRes(res);
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer for the Business Cells.
	 *
	 * @param apiResponse
	 */
	function makeBusinessCell(apiResponse: ModelApiResponse): ReactNode {
		if (!apiResponse.business) {
			return "-";
		}
		return (
			<Link to={`/merchants/${apiResponse.business}`}>
				{apiResponse.business}
			</Link>
		);
	}

	/**
	 * Renderer for the Menu Order Cells.
	 *
	 * @param apiResponse
	 */
	function makeMenuOrderCell(apiResponse: ModelApiResponse): ReactNode {
		if (!apiResponse.menuOrder) {
			return "-";
		}
		return (
			<Link to={`/menu-orders/${apiResponse.menuOrder}`}>
				{apiResponse.menuOrder}
			</Link>
		);
	}

	return (
		<div className="view-api-responses">
			<PageHeader className="view-api-responses_header">
				<div>
					<h3>
						View API Logs
					</h3>

					<p>
						Here you can view all the third party events that have been logged in our system.
					</p>

				</div>
			</PageHeader>

			<div className="view-api-responses_content">
				<FilterApiResponsesForm
					filters={filters}
					onUpdate={setFilters}
				/>

				<FrameOneTableContainer
					data={apiResponsesRes?.apiResponses}
					pagination={{
						...apiResponsesRes?.paginationInfo,
						...frontendPagination,
					} as PaginationInfo}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "id",
							headerValue: "Response ID",
						},
						{
							key: "type",
							headerValue: "API Type",
						},
						{
							key: "updatedAt",
							headerValue: "Last Updated",
							valueFormatter: (updated: number) => moment(updated).format("MMM DD YYYY HH:mm"),
						},
						{
							key: undefined,
							headerValue: "Business",
							cellRender: makeBusinessCell,
						},
						{
							key: undefined,
							headerValue: "Menu Order",
							cellRender: makeMenuOrderCell,
						},
						{
							key: "insertId",
							headerValue: "Log Explorer Insert ID",
						},
						{
							key: "url",
							headerValue: "URL",
						},
					]}
				/>
			</div>
		</div>
	);
}


export default ViewApiResponses;
