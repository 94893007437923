import React, {ReactElement} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface StateProps {
	loadingIncrement: number;
}

function LoadingManager(props: LoadingManagerProps): ReactElement {

	if (!props.loadingIncrement || props.loadingIncrement < 1) {
		return null;
	}

	return (
		<div className="loading-manager">
			<object
				data="/svg/frame-one-loader.svg"
				className="loading-manager_svg"
				aria-label="Loading Spinner"
			/>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			loadingIncrement: store.metaStore.loadingIncrement,
		}
	});
}

type LoadingManagerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(LoadingManager);
