import {
    Asset,
    Business,
    MenuCategory,
    MenusApi,
    OrderDiscount,
    OrderDiscountsApi,
    OrderDiscountType,
    DiscountMenuItem,
} from "@devour/client";
import React, {ChangeEvent, useEffect, useState} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneTableCellImagePreview from "../tables/cells/FrameOneTableCellImagePreview";
import FrameButton from "../buttons/FrameButton";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import SelectPromotionMenuCategoriesModal from "./SelectPromotionMenuCategoriesModal";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {useDispatch, useSelector} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {IStore} from "../../redux/defaultStore";
import getConfig from "../../utils/getConfig";
import SelectPromotionMenuItemsModal from "./SelectPromotionMenuItemsModal";
import _ from "lodash";

interface Props {
    isOpen: boolean;
    businesses: Array<Business>;
    promo: OrderDiscount;
    onClose: () => void;
    onDone: () => void;
}

function ManagePromoAvailabilityToBrandBusinessesModal(props: Props) {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

    const [searchString, setSearchString] = useState<string>("");
    const [selectedBusinesses, setSelectedBusinesses] = useState<Array<string>>(undefined);
    const [selectedCategories, setSelectedCategories] = useState<Array<MenuCategory>>(undefined);
    const [selectedItems, setSelectedItems] = useState<Array<DiscountMenuItem>>(undefined);
    const [selectedBusiness, setSelectedBusiness] = useState<Business>(undefined);
    const [selectedBusinessMenu, setSelectedBusinessMenu] = useState<Array<MenuCategory>>(undefined);
    const [flattenedCategories, setFlattenedCategories] = useState<Array<MenuCategory>>(undefined);

    const [showSelectCategoriesModal, setShowSelectCategoriesModal] = useState<boolean>(false);
    const [showSelectMenuItemsModal, setShowSelectMenuItemsModal] = useState<boolean>(false);

    useEffect(() => {
        if (props.isOpen) {
            setSearchString("");
            setSelectedBusinesses(props.promo?.businesses);
            setSelectedCategories(props.promo?.menuCategories);
            setSelectedItems(props.promo?.menuItems);
        }
    }, [props.isOpen, JSON.stringify(props.promo)]);

    useEffect(() => {
        if (selectedBusiness?.id) {
            void fetchRestaurantMenu();
        }
    }, [selectedBusiness?.id]);

    async function fetchRestaurantMenu(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new MenusApi(getConfig()).getMenu({
                id: selectedBusiness?.id,
            });
            setSelectedBusinessMenu(res.menus);
            setFlattenedCategories(flattenCategories(res.menus));
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function flattenCategories(categories: Array<MenuCategory>): Array<MenuCategory> {
        return _.flattenDeep(categories.map(category => [category, ...flattenCategories(category.menuSubcategories || [])]));
    }

    function onSearchInputChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchString(e.target.value);
    }

    function onUpdateSelectedBusinesses(business: Business) {
        const updatedBusinesses = [...selectedBusinesses];

        const foundIndex = selectedBusinesses.indexOf(business.id);

        if (foundIndex > -1) {
            updatedBusinesses.splice(foundIndex, 1);

            // remove all the menu categories of the deselected business from the promo
            if (props.promo?.type === OrderDiscountType.CATEGORY) {
                setSelectedCategories([...selectedCategories].filter((c) => c.business !== business.id));
            }
        } else {
            updatedBusinesses.push(business.id);
        }
        setSelectedBusinesses(updatedBusinesses);
    }

    function onSelectCategories(categoriesIds: Array<string>) {
        setSelectedCategories([
            ...selectedCategories.filter(c => c.business !== selectedBusiness?.id),
            ...flattenedCategories.filter(c => categoriesIds.includes(c.id))
        ]);
        onCloseModal();
    }

    function onSelectItems(items: Array<DiscountMenuItem>) {
        setSelectedItems([
            ...selectedItems.filter(item => item.business !== selectedBusiness?.id),
            ...items
        ]);
        onCloseModal();
    }

    async function submitUpdatedOrderDiscount(): Promise<void> {
        if (!props.promo) {
            return;
        }

        dispatch(incrementLoading());
        try {
            await new OrderDiscountsApi(getConfig(fullToken)).updateOrderDiscount({
                id: props.promo.id,
                createOrderDiscountBody: {
                    ...props.promo,
                    menuCategories: (props.promo.type === OrderDiscountType.CATEGORY) ? selectedCategories.map(c => c.id) : undefined,
                    menuItems: (props.promo.type === OrderDiscountType.MENUITEM) ? selectedItems.map(c => c.id) : undefined,
                    businesses: selectedBusinesses
                },
            });
            props.onDone();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function handleSelectAllBrandBusinesses() {
        const updatedBusinesses = [...selectedBusinesses];
        for (const business of props.businesses) {
            const foundIndex = selectedBusinesses.indexOf(business.id);

            if (foundIndex === -1) {
                updatedBusinesses.push(business.id);
            }
        }
        setSelectedBusinesses(updatedBusinesses);
    }

    function onCloseModal() {
        setShowSelectCategoriesModal(false);
        setShowSelectMenuItemsModal(false);
        setSelectedBusiness(undefined);
        setSelectedBusinessMenu(undefined);
    }

    return (
        <>
            <SelectPromotionMenuCategoriesModal
                isOpen={showSelectCategoriesModal && !!selectedBusinessMenu}
                menuCategories={selectedBusinessMenu}
                selectedCategories={selectedCategories?.map(c => c.id)}
                onClose={onCloseModal}
                onDone={onSelectCategories}
            />
            <SelectPromotionMenuItemsModal
                isOpen={showSelectMenuItemsModal && !! selectedBusinessMenu}
                selectedItems={selectedItems?.map(item => item.id)}
                menuCategories={selectedBusinessMenu}
                menuItems={flattenedCategories?.map(category => category.menuItems)
                    .reduce((acc, menuItems) => acc.concat(menuItems),[])
                }
                businessId={selectedBusiness?.id}
                onClose={onCloseModal}
                onDone={onSelectItems}
            />
            <FrameOneModal
                isOpen={props.isOpen}
                toggle={props.onClose}
                size="lg"
            >
                <FrameModalHeader
                    title="Select Brand Businesses"
                    toggle={props.onClose}
                />
                <FrameModalBody>
                    <div>
                        <label>Search brand businesses</label>
                        <input
                            placeholder="Search Businesses..."
                            value={searchString}
                            onChange={onSearchInputChange}
                        />
                    </div>
                    <p className="form-tip">To set up promotions by menu categories, please add the business first then select the categories.</p>
                    <FrameOneTableContainer
                        data={props.businesses?.filter((t) => {
                            return t.name?.toLowerCase().includes(searchString.toLowerCase())
                                || t.description?.toLowerCase().includes(searchString.toLowerCase())
                                || t.internalName?.toLowerCase().includes(searchString.toLowerCase())
                        })}
                        columnOptions={[
                            {
                                key: "icon",
                                headerValue: "Icon",
                                cellRender: (asset: Asset) => {
                                    return (
                                        <FrameOneTableCellImagePreview asset={asset}/>
                                    );
                                }
                            },
                            {
                                key: "name",
                                headerValue: "Name",
                            },
                            {
                                key: "internalName",
                                headerValue: "Internal Name",
                            },
                            {
                                key: undefined,
                                headerValue: "Select/De-select",
                                cellRender: (t: Business) => {
                                    const isSelected: boolean = selectedBusinesses.indexOf(t.id) > -1;

                                    return (
                                        <div className="frame-one-table-cell">
                                            <FrameButton
                                                color={isSelected ? "danger" : "success"}
                                                onClick={() => onUpdateSelectedBusinesses(t)}
                                            >
                                                {isSelected ? "Remove" : "Add"}
                                            </FrameButton>
                                        </div>
                                    );
                                }
                            },
                            (props.promo?.type === OrderDiscountType.CATEGORY) ?
                            {
                                key: undefined,
                                headerValue: "Selected Categories",
                                cellRender: (t: Business) => {
                                    return (
                                        <ul>
                                            {selectedCategories?.filter(c => c.business === t.id).map(c => (
                                                <li key={c.id}>{c.name}</li>
                                            ))}
                                        </ul>
                                    )
                                }
                            } : null,
                            (props.promo?.type === OrderDiscountType.CATEGORY) ?
                                {
                                    key: undefined,
                                    headerValue: "Select Categories",
                                    cellRender: (business: Business) => {
                                        const isBusinessSelected: boolean = selectedBusinesses.indexOf(business.id) > -1;
                                        return (
                                            <div className="frame-one-table-cell">
                                                <FrameButton
                                                    color={isBusinessSelected ? "lightBlue" : "lightGray"}
                                                    onClick={() => {
                                                        setSelectedBusiness(business);
                                                        setShowSelectCategoriesModal(true);
                                                    }}
                                                    forwardProps={{
                                                        disabled: !isBusinessSelected
                                                    }}
                                                >
                                                    Select Categories
                                                </FrameButton>
                                            </div>
                                        )
                                    }
                                } : null,
                            (props.promo?.type === OrderDiscountType.MENUITEM) ?
                                {
                                    key: undefined,
                                    headerValue: "Selected Menu Items",
                                    cellRender: (t: Business) => {
                                        return (
                                            <ul>
                                                {selectedItems?.filter(item => item.business === t.id).map(c => (
                                                    <li key={c.id}>{c.name}</li>
                                                ))}
                                            </ul>
                                        )
                                    }
                                } : null,
                            (props.promo?.type === OrderDiscountType.MENUITEM) ?
                                {
                                    key: undefined,
                                    headerValue: "Select Menu Items",
                                    cellRender: (business: Business) => {
                                        const isBusinessSelected: boolean = selectedBusinesses.indexOf(business.id) > -1;
                                        return (
                                            <div className="frame-one-table-cell">
                                                <FrameButton
                                                    color={isBusinessSelected ? "lightBlue" : "lightGray"}
                                                    onClick={() => {
                                                        setSelectedBusiness(business);
                                                        setShowSelectMenuItemsModal(true);
                                                    }}
                                                    forwardProps={{
                                                        disabled: !isBusinessSelected
                                                    }}
                                                >
                                                    Select Categories
                                                </FrameButton>
                                            </div>
                                        )
                                    }
                                } : null
                        ].filter(Boolean)}
                    />
                </FrameModalBody>
                <FrameModalFooter>
                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="success"
                        onClick={handleSelectAllBrandBusinesses}
                    >
                        Select all
                    </FrameButton>
                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="lightBlue"
                        onClick={submitUpdatedOrderDiscount}
                    >
                        Save changes
                    </FrameButton>
                </FrameModalFooter>
            </FrameOneModal>
        </>
    )
}

export default ManagePromoAvailabilityToBrandBusinessesModal;