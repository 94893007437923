import React, {ReactElement, useEffect} from "react";
import {Token, Withdraw, WithdrawsApi} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {getUserFullName} from "../../utils/getUserFullName";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {add} from "lodash";
import getConfig from "../../utils/getConfig";
import {useContractWrite} from "@web3modal/react";
import dpayAbi from "../../config/dpayAbi";
import {chains} from "@web3modal/ethereum";
import {ethers} from "ethers";

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	withdraw: Withdraw
	onClose: () => void;
	onDone: () => Promise<void>;
}

function WithdrawRequestsConfirmAcceptModal(props: WithdrawRequestsConfirmAcceptModalProps): ReactElement {

	const {
		write: approveWithdraw,
		data,
		isLoading,
		error,
	} = useContractWrite({
		address: "0xE5A733681bbE6cd8c764Bb8078ef8E13a576Dd78",
		abi: dpayAbi,
		functionName: "transfer",
		chainId: chains.mainnet.id,
		args: [props.withdraw.wallet, ethers.utils.parseEther(props.withdraw.amount.toString())],
	});

	useEffect(() => {
		if (data && data.hash) {
			onApproveWithdraw().then().catch();
		}
	}, [JSON.stringify(data)]);

	async function callApproveWithdraw(): Promise<void> {
		props.dispatch(incrementLoading());
		const res = await approveWithdraw();
		props.dispatch(decrementLoading());
	}

	async function onApproveWithdraw(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new WithdrawsApi(getConfig(props.fullToken)).completeWithdrawRequest({
				id: props.withdraw.id,
				completeWithdrawBody: {
					notes: "",
					hash: data.hash,
					wallet: props.withdraw.wallet,
				},
			});

			await props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				title="Confirm Withdraw Request Approval"
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<p>
					Are you sure you want to accept the withdraw requested by{" "}
					<b>{getUserFullName(props.withdraw.user)} ({props.withdraw.user.email})</b>{" "}
					for the amount of{" "}
					<b>{props.withdraw.amount} DPAY</b>{" "}
					to the wallet{" "}
					<b>{props.withdraw.wallet}</b>?
				</p>

				{error && error.hasOwnProperty("reason") && (
					<div>
						<p>
							{error["reason"]}
						</p>
					</div>
				)}
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="lightGray"
					onClick={props.onClose}
				>
					Back
				</FrameButton>

				<FrameButton
					color="success"
					onClick={callApproveWithdraw}
				>
					Yes, Approve
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type WithdrawRequestsConfirmAcceptModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(WithdrawRequestsConfirmAcceptModal);
