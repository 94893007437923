import {ReactElement} from "react";
import {Asset} from "@devour/client";
import React from "react";

interface Props {
	asset?: Asset;
}

function FrameOneTableCellImagePreview(props: Props): ReactElement {

	return (
		<div className="frame-one-table-cell frame-one-table-cell-image-preview">
			{props.asset && (
				<img
					src={props.asset.url}
					alt="thumbnail"
				/>
			)}
		</div>
	);
}

export default FrameOneTableCellImagePreview;
