export interface IReactSelectOption<T = string> {
	value: T;
	label: string;
}

interface IListBase {
	id: string;
	name?: string;
}

/**
 * Util to generate the options for the React Select component in the correct format.
 *
 * @param arr
 * @param valueKey
 * @param labelKey
 */
export function makeReactSelectOptions<T extends IListBase>(arr: Array<T> = [], valueKey: keyof T = "id", labelKey: keyof T | ((item: T) => string)): Array<IReactSelectOption> {
	return arr.map((item): IReactSelectOption => {
		let label;
		if (typeof labelKey === "string") {
			label = item[labelKey];
		} else if (typeof labelKey === "function") {
			label = labelKey(item);
		}

		return {
			value: item[valueKey] as unknown as string, // Technically a cheat cast. basically valueKey should point to a string on the object.
			label,
		}
	});
}

/**
 * When using the FrameOneSelect for single-selection, to pass the value field back into the select component in your JSX,
 * the value prop should look something like this:
 *
 * const groupingIdOptions = makeReactSelectOptions<T>(yourArray, "desired-value-key", "desired-label-key");
 *
 * value={groupingIdOptions.find(o => o.value === createTrackerBody.groupingId)}
 * options={groupingIdOptions}
 */
