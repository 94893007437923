import React, {ReactElement, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {Withdraw} from "@devour/client";
import ManageUsersToggleEnableModal from "../../modals/ManageUsersToggleEnableModal";
import UserDpayModal from "../../modals/UserDpayModal";

interface Props {
    withdraw: Withdraw;
}

function ManageUsersDpayCell(props: Props): ReactElement {

    const [showModal, setShowModal] = useState(false);

    /**
     * Hide or Show the toggle modal.
     *
     */
    function toggleShowToggleModal(): void {
        setShowModal(!showModal);
    }

    /**
     * Hide the toggle modal and call the props.onDone function to update the table.
     *
     */
    function onDoneToggleModal(): void {
        setShowModal(false);
    }

    return (
        <React.Fragment>
            <UserDpayModal
                isOpen={showModal}
                withdraw={props.withdraw}
                onClose={toggleShowToggleModal}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightGray"
                    onClick={toggleShowToggleModal}
                >
                    View DPay
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ManageUsersDpayCell;
