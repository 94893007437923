import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Token, User, UsersApi} from "@devour/client";
import getConfig from "../utils/getConfig";
import {parseUserType} from "../utils/parseUserType";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import FrameOneCard from "../components/FrameOneCard";
import SectionLabel from "../components/SectionLabel";
import PageHeader from "../components/PageHeader";

interface StateProps {
	fullToken: Token;
}

function Dashboard(props: DashboardProps): ReactElement {

	const [currentAdmin, setCurrentAdmin] = useState<User>();

	useEffect(() => {
		getCurrentAdmin().then().catch();
	}, []);

	async function getCurrentAdmin(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getProfile();

			setCurrentAdmin(res.user as User);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function makeAdminInfoColumn(label: string, value: string): ReactNode {
		return (
			<div className="col col-12 col-xl-6 mb-2 mb-xl-0">
				<label>{label}</label>
				<input
					value={value ? value : "Not Available"}
					readOnly={true}
					disabled={true}
				/>
			</div>
		);
	}

	if (!currentAdmin) {
		return (
			<div className="dashboard-loading">
				<h4 className="dashboard-loading_title">
					Loading...
				</h4>
			</div>
		);
	}

	return (
		<div className="dashboard">
			<PageHeader className="dashboard_page-header">
				<h3 className="dashboard_page-header_title">
					Admin Dashboard
				</h3>
			</PageHeader>

			<div className="dashboard_content">
				<FrameOneCard>
					<SectionLabel
						title="Dashboard & Admin Info"
						contentClassName="dashboard_content_section-label-content"
					>
						{makeAdminInfoColumn("Admin Role", parseUserType(currentAdmin.type))}
						{makeAdminInfoColumn("Email", currentAdmin.email)}
						{makeAdminInfoColumn("First Name", currentAdmin.firstName)}
						{makeAdminInfoColumn("Last Name", currentAdmin.lastName)}
					</SectionLabel>
				</FrameOneCard>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type DashboardProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(Dashboard);
