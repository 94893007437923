import React, {ReactElement, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";
import {Token, TokensApi, UsersApi} from "@devour/client";
import getConfig from "../utils/getConfig";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading, logout, updateCurrentUser} from "../redux/meta/MetaActions";
import LoginCard from "../components/LoginCard";
import LoginFooter from "../components/LoginFooter";
import {ReactComponent as BackgroundGraphic} from "../svgs/login-gradient_shapes.svg";

interface StateProps {
	fullToken: Token;
}

function Login(props: LoginProps): ReactElement {

	const history = useNavigate();
	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => {
		if (props.fullToken && firstRender) {
			verifyTokenExpiry(props.fullToken).then().catch();
		}
	}, [firstRender]);

	/**
	 * Checks if the existing token is expired.
	 * If it is, dispatch the redux logout action but don't
	 * do anything from the user's perspective - they must log in again.
	 *
	 * If their token ISN'T expired, replace current index in history with the contests route.
	 * If this logic needs to be recycled on multiple pages, it could be moved into a nwe component,
	 * and that component could be rendered in each required spot instead of repeating this block of code.
	 *
	 * @param fullToken
	 */
	async function verifyTokenExpiry(fullToken: Token): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TokensApi(getConfig(fullToken)).checkTokenExpiration({
				tokenBody: {
					token: fullToken?.token,
				},
			});

			if (res.expired) {
				await props.dispatch(logout());
				setFirstRender(false);
			} else {
				const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
				props.dispatch(updateCurrentUser(userRes.user));
				history("/dashboard", {replace: true});
			}
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	// Render nothing if the token exists & we haven't checked if it's expired yet.
	if (props.fullToken && firstRender) {
		return null;
	}

	return (
		<div className="login-page">
			<div className="login-page_background-container">
				<div className="login-page_background-container_graphic-container">
					<BackgroundGraphic className="login-page_background-container_graphic-container_graphic"/>
				</div>

				<div className="login-page_background-container_solid"/>
			</div>

			<div className="login-page_content">
				<div className="login-page_content_spacer"/>

				<div className="login-page_content_container">
					<LoginCard/>

					<div className="login-page_content_spacer"/>

					<LoginFooter/>
				</div>

				<div className="login-page_content_spacer"/>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type LoginProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(Login);
