import React, {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames";

interface Props {
	active: boolean;
	onClick?: () => void;
}

function FrameOnePaginatorButton(props: PropsWithChildren<Props>): ReactElement {

	return (
		<button
			disabled={props.onClick === undefined || props.active}
			onClick={props.onClick !== undefined ? props.onClick : undefined}
			className={classNames("frame-one-paginator_buttons-container_buttons_button", {
				"frame-one-paginator_buttons-container_buttons_button_clickable": props.onClick !== undefined,
				"frame-one-paginator_buttons-container_buttons_button_active": props.active,
			})}
		>
			{props.children}
		</button>
	);
}

export default FrameOnePaginatorButton;
