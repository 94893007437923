import {User} from "@devour/client";
import React, {ReactElement, useState} from "react";
import UserCommunityInfoModal from "../../modals/UserCommunityInfoModal";
import FrameButton from "../../buttons/FrameButton";

interface Props {
    user: User;
    onDone: () => Promise<void>;
}

function ManageUsersCommunityMembershipCell(props: Props): ReactElement {
    const [showModal, setShowModal] = useState<boolean>(false);
    
    function toggleShowModal(): void {
        setShowModal(!showModal);
    }
    
    function onDoneModal(): void {
        setShowModal(false);
        props.onDone().then().catch();
    }
    
    return (
        <>
            <UserCommunityInfoModal 
                isOpen={showModal} 
                onClose={toggleShowModal}
                onDone={onDoneModal}
                user={props.user}
            />
            <div className="frame-one-table-cell">
                <FrameButton
                    color={!props.user?.community ? "lightGray" : "lightBlue"}
                    onClick={toggleShowModal}
                    forwardProps={{disabled: !props.user?.community}}
                >
                    Manage Community Membership
                </FrameButton>
            </div>
        </>
    )
}

export default ManageUsersCommunityMembershipCell;