import React, {ReactElement} from "react";
import {connect, ConnectedProps} from "react-redux";
import {BusinessTaxonomiesApi, BusinessTaxonomy, Token} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	taxonomy: BusinessTaxonomy;
	onClose: () => void;
	onDone: () => void;
}

function ManageTaxonomiesDeleteModal(props: ManageTaxonomiesDeleteCellProps): ReactElement {

	/**
	 * Dynamically call the enable or disable apis based on the admin's "disabled" status.
	 *
	 */
	async function deleteCategory(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new BusinessTaxonomiesApi(getConfig(props.fullToken)).deleteBusinessTaxonomy({
				id: props.taxonomy?.id,
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				toggle={props.onClose}
				title="Confirm Category Deletion"
			/>

			<FrameModalBody>
				<p>
					Are you sure you want to remove this category from the system? Any restaurants that were using it will have it removed from their category list.
				</p>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="lightGray"
					onClick={props.onClose}
				>
					Cancel
				</FrameButton>

				<FrameButton
					color="danger"
					onClick={deleteCategory}
				>
					Yes, Delete
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageTaxonomiesDeleteCellProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageTaxonomiesDeleteModal);
