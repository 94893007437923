import {EVMChain} from "@devour/client";

export function parseChain(chain: EVMChain): string {
	switch (chain) {
		case EVMChain.ETHEREUMMAINNET:
			return "Ethereum Mainnet";
		case EVMChain.POLYGON:
			return "Polygon";
		case EVMChain.GOERLI:
			return "Goerli";
		case EVMChain.AVALANCHE:
			return "Avalanche";
		case EVMChain.MUMBAI:
			return "Mumbai";
		case EVMChain.BASE:
			return "Base";
		case EVMChain.SOLANA:
			return "Solana";
		default:
			return chain;
	}
}
