import React, {ReactElement, useState} from "react";
import {User} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import ManageAdminsToggleEnableModal from "../../modals/ManageAdminsToggleEnableModal";

interface Props {
	admin: User;
	onDone: () => Promise<void>;
}

function ManageAdminsToggleEnableCell(props: Props): ReactElement {

	const [showModal, setShowModal] = useState(false);

	/**
	 * Hide or Show the toggle modal.
	 *
	 */
	function toggleShowToggleModal(): void {
		setShowModal(!showModal);
	}

	/**
	 * Hide the toggle modal and call the props.onDone function to update the table.
	 *
	 */
	function onDoneToggleModal(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageAdminsToggleEnableModal
				isOpen={showModal}
				admin={props.admin}
				onClose={toggleShowToggleModal}
				onDone={onDoneToggleModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color={props.admin?.disabled ? "lightBlue" : "purple"}
					onClick={toggleShowToggleModal}
				>
					{`${props.admin?.disabled ? "Enable" : "Disable"} Admin`}
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default ManageAdminsToggleEnableCell;
