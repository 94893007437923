import React, {ReactElement, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {User} from "@devour/client";
import ManageBannedCommunitiesModal from "../../modals/bannedCommunities/ManageBannedCommunitiesModal";

interface Props {
    user: User;
}

function ManageBannedCommunitiesCell(props: Props): ReactElement {

    const [showModal, setShowModal] = useState(false);

    /**
     * Hide or Show the toggle modal.
     *
     */
    function toggleShowToggleModal()  {
        setShowModal(!showModal);
    }

    return (
        <React.Fragment>
            <ManageBannedCommunitiesModal
                isOpen={showModal}
                user={props.user}
                onClose={toggleShowToggleModal}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightGray"
                    onClick={() => toggleShowToggleModal()}
                >
                    Manage Banned Communities
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ManageBannedCommunitiesCell;
