import React, {ReactElement, useState} from "react";
import {Brand} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import EditBrandModal from "../../modals/EditBrandModal";

interface Props {
	brand: Brand;
	onDone: () => Promise<void>;
}

function BrandEditCell(props: Props): ReactElement {

	const [showEditModal, setShowEditModal] = useState(false);

	function toggleEditModal(): void {
		setShowEditModal(s => !s);
	}

	function onDoneEdit(): void {
		setShowEditModal(false);
		void props.onDone();
	}

	return (
		<React.Fragment>
			<EditBrandModal
				isOpen={showEditModal}
				brand={props.brand}
				onClose={toggleEditModal}
				onDone={onDoneEdit}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="lightBlue"
					onClick={toggleEditModal}
				>
					Edit Brand
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default BrandEditCell;