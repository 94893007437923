import React, {ReactElement} from "react";
import {Token, User, UsersApi} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	user: User;
	onClose: () => void;
	onDone: () => void;
}

function ManageUsersToggleEnableModal(props: ManageUsersToggleEnableModalProps): ReactElement {

	/**
	 * Dynamically call the enable or disable apis based on the admin's "disabled" status.
	 *
	 */
	async function toggleUser(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const apiBase = new UsersApi(getConfig(props.fullToken));

			if (props.user?.disabled) {
				await apiBase.enableUser({
					id: props.user?.id,
				});
			} else {
				await apiBase.disableUser({
					id: props.user?.id,
				});
			}

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				toggle={props.onClose}
				title={props.user?.disabled ? "Enable User" : "Disable User"}
			/>

			<FrameModalBody>
				<p>
					{props.user?.disabled ? (
						"Enabling this user will grant them all the ability to perform all tasks allowed by their current User Role."
					) : (
						"Disabling this user will revoke their access to the system and they will not be allowed to log in or perform any of the actions they were previously able to."
					)}
				</p>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color={props.user?.disabled ? "darkBlue" : "purple"}
					onClick={toggleUser}
				>
					{props.user?.disabled ? "Confirm, Enable User" : "Confirm, Disable User"}
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageUsersToggleEnableModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageUsersToggleEnableModal);
