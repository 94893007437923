import React, {ReactElement} from "react";
import {Business} from "@devour/client";
import {convertBooleanToString} from "../../utils/convertBooleanToString";
import ManageRestaurantsToggleActiveStatusCell from "../tables/cells/ManageRestaurantsToggleActiveStatusCell";
import ManageRestaurantsToggleEnabledStatusCell from "../tables/cells/ManageRestaurantsToggleEnabledStatusCell";
import {isRestaurant} from "../../typeguards/isRestaurant";
import classNames from "classnames";
import moment from "moment/moment";

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function MerchantActions(props: Props): ReactElement {

	return (
		<div className="merchant-actions">
			<h4>
				Controls
			</h4>

			<div
				className={classNames("merchant-actions_field", {
					"is-true": props.business.isActive,
				})}
			>
				<p>
					<strong>Active: </strong> {convertBooleanToString(props.business.isActive)}
				</p>
				<p>
					Control allowing admins to disable the business. If false, do not show location to customers or
					merchants.
				</p>
				<ManageRestaurantsToggleActiveStatusCell
					business={props.business}
					onDone={props.onDone}
				/>
			</div>

			<div
				className={classNames("merchant-actions_field", {
					"is-true": props.business.isEnabled,
				})}
			>
				<p>
					<strong>Enabled: </strong> {convertBooleanToString(props.business.isEnabled)}
				</p>
				<p>
					Control allowing merchants to permanently close the business. If false, do not show location to
					customers or merchants.
				</p>
				<ManageRestaurantsToggleEnabledStatusCell
					business={props.business}
					onDone={props.onDone}
				/>
			</div>

			<div
				className={classNames("merchant-actions_field", {
					"is-true": !props.business.suspendUntil || Date.now() > props.business.suspendUntil,
				})}
			>
				<p>
					<strong>Operating: </strong> {convertBooleanToString(!props.business.suspendUntil || Date.now() > props.business.suspendUntil)}
				</p>
				{(props.business.suspendUntil && Date.now() < props.business.suspendUntil) && (
				<p>
					<strong>Closed Until: </strong> {moment(props.business.suspendUntil).format("MMM DD YYYY HH:mm")}
				</p>
				)}
				<p>
					Control allowing merchants to temporarily close the business until the specified time.
					If false, show location as closed to customers.
				</p>
			</div>

			{(isRestaurant(props.business)) && (
				<div
					className={classNames("merchant-actions_field", {
						"is-true": props.business.isMenuActive,
					})}
				>
					<p>
						<strong>Menu Active: </strong> {convertBooleanToString(props.business.isMenuActive)}
					</p>
					<p>
						Check if the menu import completed successfully. If false, do not show location to customers.
					</p>
				</div>
			)}
		</div>
	);
}

export default MerchantActions;
