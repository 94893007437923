import React, {ChangeEvent, ChangeEventHandler, ReactElement, ReactNode, useEffect, useState} from "react";
import {
	Asset, Brand,
	BrandsApi,
	GetBrandsEnabledStatus,
	GetBrandsRequest,
	GetBrandsResponse,
	GetBrandsSort, GetBusinessesOwnRequest, GetBusinessOwnEnabledStatus, GetBusinessOwnSort,
	PaginationInfo,
	SortOrder,
	Token
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {omit} from "lodash";
import {convertBooleanToString} from "../utils/convertBooleanToString";
import moment from "moment/moment";
import FrameOneTableCellImagePreview from "../components/tables/cells/FrameOneTableCellImagePreview";
import AddBrandModal from "../components/modals/AddBrandModal";
import BrandEditCell from "../components/tables/cells/BrandEditCell";
import {Link} from "react-router-dom";
import BrandToggleEnabledCell from "../components/tables/cells/BrandToggleEnabledCell";

const defaultGetBrandsRequest: GetBrandsRequest = {
	isEnabled: GetBrandsEnabledStatus.ENABLED,
	sort: GetBrandsSort.CreatedAt,
	order: SortOrder.Asc,
}

interface StateProps {
	fullToken: Token;
}

function ManageBrands(props: ManageBrandsProps): ReactElement {

	const [brandsRes, setBrandsRes] = useState<GetBrandsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [showAddModal, setShowAddModal] = useState(false);
	const [getBrandsFilter, setGetBrandsFilter] = useState<GetBrandsRequest>(defaultGetBrandsRequest);
	const [search, setSearch] = useState<string>("");

	useEffect(() => {
		void getBrands();
	}, [JSON.stringify(frontendPagination), JSON.stringify(getBrandsFilter)]);

	async function getBrands(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BrandsApi(getConfig(props.fullToken)).getBrands({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				isEnabled: getBrandsFilter.isEnabled === GetBrandsEnabledStatus.ENABLED ? GetBrandsEnabledStatus.ENABLED : getBrandsFilter.isEnabled === GetBrandsEnabledStatus.DISABLED ? GetBrandsEnabledStatus.DISABLED : undefined,
				sort: getBrandsFilter.sort,
				order: getBrandsFilter.order,
				search: search,
			});

			setBrandsRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof GetBrandsRequest): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setFrontendPagination((_frontendPagination) => {
				return {
					...defaultFrontendPagination,
					frontendRenderKey: _frontendPagination.frontendRenderKey + 1,
				}
			});
			setGetBrandsFilter((_getBrandsFilter) => {
				return {
					..._getBrandsFilter,
					[key]: e.target.value,
				}
			});
		}
	}

	function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
		setSearch(e.target.value);
	}

	function handleSubmitSearch(key: keyof GetBusinessesOwnRequest): () => void {
		return () => {
			setFrontendPagination((_frontendPagination) => {
				return {
					...defaultFrontendPagination,
					frontendRenderKey: _frontendPagination.frontendRenderKey + 1,
				}
			});
			setGetBrandsFilter((_getBrandsFilter) => {
				return {
					..._getBrandsFilter,
					[key]: search,
				}
			});
		}
	}

	function toggleAddBrandModal(): void {
		setShowAddModal(s => !s);
	}

	/**
	 * When a new brand is added successfully, close the "Add Modal" & reset the pagination to prompt the useEffect to run again.
	 *
	 */
	function onDoneAddBrand(): void {
		setShowAddModal(false);
		setFrontendPagination((p) => {
			return {
				...defaultFrontendPagination,
				frontendRenderKey: p.frontendRenderKey + 1,
			}
		});
	}

	/**
	 * Render the cells for flipping the "isEnabled" status on the Brands.
	 *
	 */
	function renderToggleEnabledCell(brand: Brand): ReactNode {
		return (
			<BrandToggleEnabledCell
				brand={brand}
				onDone={getBrands}
			/>
		);
	}

	/**
	 * Render the cells for editing the brands. On done, just call the getBrands function again without changing the pagination.
	 *
	 * @param brand
	 */
	function renderEditCell(brand: Brand): ReactNode {
		return (
			<BrandEditCell
				brand={brand}
				onDone={getBrands}
			/>
		);
	}

	function renderManageMapsCell(brand: Brand): ReactNode {
		return (
			<div className="frame-one-table-cell">
				<FrameButton
					color="lightBlue"
					to={`/manage-brands/${brand.id}/maps`}
				>
					Manage Maps
				</FrameButton>
			</div>
		)
	}

	return (
		<React.Fragment>
			<AddBrandModal
				isOpen={showAddModal}
				onClose={toggleAddBrandModal}
				onDone={onDoneAddBrand}
			/>

			<div className="manage-brands">
				<PageHeader>
					<div>
						<h3>
							Manage Brands
						</h3>

						<p>
							Here you can view, add, and edit Brands on the platform & manage Brand ↔ Merchant associations.
						</p>
						<p>
							Click on a brand name to see details about associated Merchants, Businesses, and Maps.
						</p>

						<FrameButton
							color="lightBlue"
							onClick={toggleAddBrandModal}
						>
							Add New Brand
						</FrameButton>
					</div>
				</PageHeader>

				<div className="manage-brands_filtering">
					<div className="manage-brands_filtering_row">
						<div className="manage-brands_filtering_row_col">
							<label>Enabled Status</label>
							<select
								value={getBrandsFilter.isEnabled}
								onChange={inputOnChange("isEnabled")}
							>
								<option value="">All</option>
								<option value={GetBusinessOwnEnabledStatus.ENABLED}>Enabled</option>
								<option value={GetBusinessOwnEnabledStatus.DISABLED}>Disabled</option>
							</select>
						</div>
					</div>

					<hr/>

					<div className="manage-brands_filtering_row">
						<div className="manage-brands_filtering_row_col">
							<label>Sort By...</label>
							<select
								value={getBrandsFilter.sort}
								onChange={inputOnChange("sort")}
							>
								<option value={GetBusinessOwnSort.Name}>Name</option>
								<option value={GetBusinessOwnSort.CreatedAt}>Creation Date</option>
							</select>
						</div>

						<div className="manage-brands_filtering_row_col">
							<label>Sort Order...</label>
							<select
								value={getBrandsFilter.order}
								onChange={inputOnChange("order")}
							>
								<option value={SortOrder.Asc}>Ascending</option>
								<option value={SortOrder.Desc}>Descending</option>
							</select>
						</div>
					</div>

					<hr/>

					<div className="manage-brands_filtering_col">
						<label>
							Search
						</label>
						<input
							value={search}
							placeholder="Search for name of the brand..."
							onChange={searchOnChange}
						/>
						<br/>
						<FrameButton
							color="lightBlue"
							onClick={handleSubmitSearch("search")}
						>
							Search
						</FrameButton>
						<br/>
					</div>
				</div>

				<div className="manage-brands_table">
					<FrameOneTableContainer
						data={brandsRes?.brands}
						pagination={{
							...brandsRes?.paginationInfo,
							...omit(frontendPagination, "frontendRenderKey"),
						} as PaginationInfo}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "image",
								headerValue: "Brand Image",
								cellRender: (asset: Asset) => {
									return (
										<FrameOneTableCellImagePreview asset={asset}/>
									);
								}
							},
							{
								key: undefined,
								headerValue: "Name",
								cellRender: (brand: Brand) => {
									return (
										<Link to={`/manage-brands/${brand.id}`}>
											{brand.name}
										</Link>
									);
								}
							},
							{
								key: "description",
								headerValue: "Description",
								cellRender: (d: string) => {
									return (
										<div className="table-description-field">
											{d || "-"}
										</div>
									);
								}
							},
							{
								key: "isEnabled",
								headerValue: "Enabled",
								valueFormatter: convertBooleanToString,
							},
							{
								key: undefined,
								headerValue: "Toggle Enabled",
								cellRender: renderToggleEnabledCell,
							},
							{
								key: undefined,
								headerValue: "Edit Brand",
								cellRender: renderEditCell,
							},
							{
								key: undefined,
								headerValue: "Manage Brand Maps",
								cellRender: renderManageMapsCell,
							},
							{
								key: "createdAt",
								headerValue: "Created",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: "updatedAt",
								headerValue: "Last Updated",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: "id",
								headerValue: "Brand ID",
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type ManageBrandsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageBrands);
