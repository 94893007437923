import React, {ReactElement} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {toggleGlobalContactModalVisible} from "../../redux/meta/MetaActions";
import ContactModal from "./ContactModal";

interface StateProps {
	globalContactModalVisible: boolean;
}

function GlobalContactModalManager(props: SideBarManagerProps): ReactElement {

	function dismissHelper(): void {
		props.dispatch(toggleGlobalContactModalVisible(false));
	}

	return (
		<ContactModal
			isOpen={props.globalContactModalVisible}
			toggle={dismissHelper}
		/>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			globalContactModalVisible: store.metaStore.globalContactModalVisible,
		}
	});
}

type SideBarManagerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(GlobalContactModalManager);
