import {Asset} from "@devour/client";
import {FileWithSRC} from "./renderAssetsHelper";
import {isAsset} from "./typeGuards";

export function generateBackgroundUrl(image: Asset | FileWithSRC, placeholder?: string): string {
    if (image) {
        if (isAsset(image)) {
            return image.url;
        }
        return image.imageSRC as string;
    }
    if (placeholder) {
        return placeholder;
    }
    return null;
}