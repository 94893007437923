import React, {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	AddBrandMerchantBody, Brand,
	BrandsApi, BrandToMerchantPermissions, Business,
	Token, UpdateBrandMerchantBody,
} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import {getUserFullName} from "../../utils/getUserFullName";

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	business: Business;
	brand: Brand;
	onClose: () => void;
	onDone: () => void;
}

function ConfirmRemoveBrandBusinessModal(props: ConfirmRemoveBrandBusinessModalProps): ReactElement {

	/**
	 * Call api to disassociate the brand & business.
	 *
	 */
	async function confirmRemoval(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new BrandsApi(getConfig(props.fullToken)).deleteBrandBusiness({
				removeBrandBusinessBody: {
					brand: props.brand.id,
					business: props.business.id,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				title="Confirm removal of merchant from brand"
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<p>
					Are you sure you want to remove <b>{props.business.name}</b> from
					the <b>{props.brand.name}</b> brand?
				</p>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="lightGray"
					onClick={props.onClose}
				>
					Cancel
				</FrameButton>

				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="danger"
					onClick={confirmRemoval}
				>
					Yes, Remove
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ConfirmRemoveBrandBusinessModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ConfirmRemoveBrandBusinessModal);
