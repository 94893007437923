import React, {ChangeEventHandler, FormEvent, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	AdminDebitOrCreditLevelPointRequestBody,
	Token,
	TransactionsApi,
	User, UsersApi,
} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {getUserFullName} from "../../utils/getUserFullName";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";

const defaultAdminDebitOrCreditLevelPointRequestBody: AdminDebitOrCreditLevelPointRequestBody = {
	userId: undefined,
	amount: undefined,
	debit: true,
	note: "",
	adminNote: "",
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	user: User;
	onClose: () => void;
	onDone: () => void;
}

function ManageUserDebitCreditExperienceModal(props: ManageUserDebitCreditExperienceModalProps): ReactElement {

	const [experienceForm, setExperienceForm] = useState<AdminDebitOrCreditLevelPointRequestBody>(defaultAdminDebitOrCreditLevelPointRequestBody);

	useEffect(() => {
		if (props.isOpen) {
			setExperienceForm(defaultAdminDebitOrCreditLevelPointRequestBody);
		}
	}, [props.isOpen]);

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Pick<AdminDebitOrCreditLevelPointRequestBody, "note" | "adminNote">): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setExperienceForm((_experienceForm) => {
				return {
					..._experienceForm,
					[key]: e.target.value,
				}
			});
		}
	}

	/**
	 * Handle the number format input for the amount field on change.
	 *
	 * @param values
	 */
	function experienceAmountOnChange(values): void {
		setExperienceForm((_experienceForm) => {
			return {
				..._experienceForm,
				amount: values.floatValue,
			}
		});
	}

	/**
	 * Handle toggling the transaction type.
	 *
	 * @param debit
	 */
	function toggleDebit(debit: boolean): () => void {
		return () => {
			setExperienceForm((_experienceForm) => {
				return {
					..._experienceForm,
					debit,
				}
			});
		}
	}

	async function submitPointsChange(e: FormEvent<HTMLFormElement>): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).adminDebitOrCreditLevelPoints({
				adminDebitOrCreditLevelPointRequestBody: {
					userId: props.user.id,
					amount: experienceForm.amount,
					debit: experienceForm.debit,
					note: experienceForm.note || undefined,
					adminNote: experienceForm.adminNote || undefined,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-users-debit-credit-dpay-modal"
		>
			<FrameModalHeader
				title="Debit/Credit Points"
				toggle={props.onClose}
			/>

			<form onSubmit={submitPointsChange}>
				<FrameModalBody className="manage-users-debit-credit-dpay-modal_body">
					<h5 className="manage-users-debit-credit-dpay-modal_body_user-label">
						For user {getUserFullName(props.user)}
					</h5>

					<div>
						<label>
							Point Amount
						</label>
						<NumberFormat
							placeholder="Point Amount..."
							value={experienceForm.amount}
							allowLeadingZeros={false}
							allowNegative={false}
							onValueChange={experienceAmountOnChange}
						/>
					</div>

					<div>
						<FrameOneCheckbox
							onToggle={toggleDebit(true)}
							checked={experienceForm.debit}
						>
							This is a DEBIT
						</FrameOneCheckbox>
					</div>

					<div>
						<FrameOneCheckbox
							onToggle={toggleDebit(false)}
							checked={!experienceForm.debit}
						>
							This is a CREDIT
						</FrameOneCheckbox>
					</div>

					<div>
						<label>
							Note (visible to user)
						</label>
						<input
							value={experienceForm?.note}
							placeholder="Add notes..."
							onChange={inputOnChange("note")}
							type="textarea"
						/>
					</div>

					<div>
						<label>
							Admin Note (not visible to user)
						</label>
						<input
							value={experienceForm?.adminNote}
							placeholder="Add admin notes..."
							onChange={inputOnChange("adminNote")}
							type="textarea"
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Submit Points Transaction
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageUserDebitCreditExperienceModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageUserDebitCreditExperienceModal);