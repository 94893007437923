import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {GetReferralRewardThresholdsResponse, Token, ReferralsApi, ReferralRewardThreshold} from "@devour/client";
import getConfig from "../utils/getConfig";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import AddReferralRewardsThresholdModal from "../components/modals/AddReferralRewardsThresholdModal";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {convertUserDisabledStatusToEnabledString} from "../utils/convertUserDisabledStatusToEnabledString";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import {omit} from "lodash";
import moment from "moment/moment";
import ReferralRewardsThresholdEditCell from "../components/tables/cells/ReferralRewardsThresholdEditCell";
import EditReferralLinkTextModal from "../components/modals/EditReferralLinkTextModal";
import ReferralRewardsThresholdDeleteCell from "../components/tables/cells/ReferralrewardsThresholdDeleteCell";

interface StateProps {
    fullToken: Token;
}

function ManageReferralRewardThresholds(props: ManageAdminsProps): ReactElement {

    const [showNewReferralRewardThresholdsModal, setShowNewReferralRewardThresholdsModal] = useState(false);
    const [showReferralLinkTextModal, setShowReferralLinkTextModal] = useState(false);

    const [referralRewardThresholds, setReferralRewardThresholds] = useState<GetReferralRewardThresholdsResponse>(undefined);
    const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

    useEffect(() => {
        readReferralRewardThresholds().then().catch();
    }, [JSON.stringify(frontendPagination)]);

    /**
     * Show or hide the New Referral Reward Thresholds Modal.
     *
     */
    function toggleNewReferralRewardThresholdsModal(): void {
        setShowNewReferralRewardThresholdsModal(!showNewReferralRewardThresholdsModal);
    }

    /**
     * Show or hide the Referral Link Text Modal.
     *
     */
    function toggleReferralLinkTextModal(): void {
        setShowReferralLinkTextModal(!showReferralLinkTextModal);
    }

    /**
     * Hide the New Referral Reward Thresholds Modal when finished,
     * and call the api to get the updated list.
     *
     */
    function onDoneNewReferralRewardThresholdsModal(): void {
        setShowNewReferralRewardThresholdsModal(false);
        readReferralRewardThresholds().then().catch();
    }

    /**
     * Hide the Referral Link Text Modal when finished,
     *
     */
    function onDoneReferralLinkTextModal(): void {
        setShowReferralLinkTextModal(false);
    }

    /**
     * Call api to get & save the list of referralRewardThresholds.
     *
     */
    async function readReferralRewardThresholds(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            const res = await new ReferralsApi(getConfig(props.fullToken)).getReferralRewardThresholds({
                limit: frontendPagination.limit,
                offset: frontendPagination.offset
            });

            setReferralRewardThresholds(res);
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    function renderEditCell(referralRewardThreshold: ReferralRewardThreshold): ReactNode {
        return (
            <ReferralRewardsThresholdEditCell
                referralRewardThreshold={referralRewardThreshold}
                onDone={readReferralRewardThresholds}
            />
        );
    }

    function renderDeleteCell(referralRewardThreshold: ReferralRewardThreshold): ReactNode {
        return (
            <ReferralRewardsThresholdDeleteCell
                referralRewardThreshold={referralRewardThreshold}
                onDone={readReferralRewardThresholds}
            />
        );
    }

    return (
        <div className="manage-referral-reward-thresholds">
            <AddReferralRewardsThresholdModal
                isOpen={showNewReferralRewardThresholdsModal}
                onClose={toggleNewReferralRewardThresholdsModal}
                onDone={onDoneNewReferralRewardThresholdsModal}
            />

            <EditReferralLinkTextModal
                isOpen={showReferralLinkTextModal}
                onClose={toggleReferralLinkTextModal}
                onDone={onDoneReferralLinkTextModal}
            />

            <PageHeader>
                <div>
                    <h3>
                        Manage Referral Reward Thresholds
                    </h3>

                    <p>
                        On this page you can see a list of all the referral reward thresholds, when they expire if an
                        expiry date was set, their created and updated dates, the set DPAY amount for the referrer
                        when a user they referred spends over the minimum amount required for them to obtain
                        the reward, the minimum amount that must be spent to achieve the reward, and its
                        enabled/disabled status.
                    </p>
                    <p>
                        To create a new referral reward threshold, click on the "Create New referral Reward Threshold" button.
                        Each referral reward threshold can also be edited or disabled once created.
                    </p>

                    <p>
                        To edit the text that displays when a user accesses the Sign Up page via referral link, click the
                        "Edit Referral Link Text" button. Both the header and body can both be edited here.
                    </p>

                    <div className="manage-referral-reward-thresholds_button-container">
                        <FrameButton
                            color="lightBlue"
                            onClick={toggleNewReferralRewardThresholdsModal}
                        >
                            Create New Referral Reward Threshold
                        </FrameButton>

                        <FrameButton
                            color="lightBlue"
                            onClick={toggleReferralLinkTextModal}
                        >
                            Edit Referral Link Text
                        </FrameButton>
                    </div>

                </div>

            </PageHeader>

            <div className="manage-referral-reward-thresholds_table">
                <FrameOneTableContainer
                    data={referralRewardThresholds?.referralRewardThresholds}
                    pagination={{
                        ...referralRewardThresholds?.paginationInfo,
                        ...omit(frontendPagination, "frontendRenderKey"),
                    }}
                    onPaginationChange={setFrontendPagination}
                    columnOptions={[
                        {
                            key: "id",
                            headerValue: "ID",
                        },
                        {
                            key: "expiration",
                            headerValue: "Expiration",
                            valueFormatter: (d: number) => d ? moment(d).format("MMM DD YYYY hh:mma") : "No expiration date",
                        },
                        {
                            key: "createdAt",
                            headerValue: "Created",
                            valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
                        },
                        {
                            key: "updatedAt",
                            headerValue: "Last Updated",
                            valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
                        },
                        {
                            key: "dpayRewardForReferrer",
                            headerValue: "DPAY Reward For Referrer",
                            valueFormatter: (pay: number) => pay + 'DPAY'
                        },
                        {
                            key: "minPaymentRequired",
                            headerValue: "Minimum Payment Required",
                            valueFormatter: (pay: number) => '$' + pay
                        },
                        {
                            key: "disabled",
                            headerValue: "Enabled",
                            valueFormatter: convertUserDisabledStatusToEnabledString,
                        },
                        {
                            key: undefined,
                            headerValue: "Edit Referral Reward Threshold",
                            cellRender: renderEditCell,
                        },
                        {
                            key: undefined,
                            headerValue: "Delete Referral Reward Threshold",
                            cellRender: renderDeleteCell,
                        },
                    ]}
                />
            </div>
        </div>
    );
}

function connector() {
    return connect((store: IStore): StateProps => {
        return {
            fullToken: store.metaStore.fullToken,
        }
    });
}

type ManageAdminsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageReferralRewardThresholds);
