import React, {ChangeEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import {GetWithdrawsResponse, PaginationInfo, Token, User, Withdraw, WithdrawsApi} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import FrameButton from "../components/buttons/FrameButton";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment";
import {parseWithdrawStatus} from "../utils/parseWithdrawStatus";
import {getUserFullName} from "../utils/getUserFullName";
import WithdrawRequestsActionsCell from "../components/tables/cells/WithdrawRequestsActionsCell";
import ManageUsersExperienceCell from "../components/tables/cells/ManageUsersUserExperienceCell";
import ManageUsersDpayCell from "../components/tables/cells/ManageUsersUserDpayCell";
import {omit} from "lodash";

interface StateProps {
	fullToken: Token;
}

function WithdrawRequests(props: WithdrawRequestsProps): ReactElement {

	const [withdrawRequests, setWithdrawRequests] = useState<GetWithdrawsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [search, setSearch] = useState<string>();

	useEffect(() => {
		getWithdrawRequestsData().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Call our api to get the list of withdraw requests.
	 *
	 */
	async function getWithdrawRequestsData(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new WithdrawsApi(getConfig(props.fullToken)).getWithdrawRequests({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
			});

			setWithdrawRequests(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Render cell with accept & reject buttons per request.
	 *
	 * @param withdraw
	 */
	function renderActionsCell(withdraw: Withdraw): ReactElement {
		return (
			<WithdrawRequestsActionsCell
				withdraw={withdraw}
				onDone={getWithdrawRequestsData}
			/>
		);
	}

	function makeUserDPayCell(withdraw: Withdraw): ReactNode {
		return (
			<ManageUsersDpayCell
				withdraw={withdraw}
			/>
		);
	}

	function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
		setSearch(e.target.value);
	}

	function handleSubmitSearch(): void {
		setFrontendPagination(p => {
			return {
				...defaultFrontendPagination,
				frontendRenderKey: p.frontendRenderKey + 1,
			}
		});
	}

	return (
		<div className="withdraw-requests">
			<PageHeader>
				<div>
					<h3>
						Manage DPAY Withdraw Requests
					</h3>

					<p>
						Use this page to manage withdraw requests by users; approve, reject, and view histories.
					</p>
				</div>
			</PageHeader>

			<div className="withdraw-requests_table">
				<div>
					<label>
						Search
					</label>
					<input
						value={search}
						placeholder="Search for name or email..."
						onChange={searchOnChange}
					/>
					<br/>
					<FrameButton
						color="lightBlue"
						onClick={handleSubmitSearch}
					>
						Search
					</FrameButton>
					<br/>
				</div>
				<FrameOneTableContainer
					data={withdrawRequests?.withdraws}
					pagination={{
						...withdrawRequests?.paginationInfo,
						...omit(frontendPagination, "frontendRenderKey"),
					} as PaginationInfo}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "createdAt",
							headerValue: "Created",
							valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
						},
						{
							key: "updatedAt",
							headerValue: "Updated",
							valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
						},
						{
							key: "user",
							headerValue: "User",
							valueFormatter: (u: User) => `${getUserFullName(u)} (${u.email})`,
						},
						{
							key: "wallet",
							headerValue: "Recipient Wallet Address",
						},
						{
							key: "amount",
							headerValue: "Amount",
						},
						{
							key: "dpayFee",
							headerValue: "Fee",
						},
						{
							key: "hash",
							headerValue: "Hash",
						},
						{
							key: undefined,
							headerValue: "User DPay",
							cellRender: makeUserDPayCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							key: "status",
							headerValue: "Request Status",
							valueFormatter: parseWithdrawStatus,
						},
						{
							key: undefined,
							headerValue: "Accept or Reject",
							valueFormatter: renderActionsCell,
						},
						{
							key: "notes",
							headerValue: "Notes",
						},
						{
							key: "id",
							headerValue: "Withdraw Request ID",
						},
					]}
				/>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type WithdrawRequestsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(WithdrawRequests);
