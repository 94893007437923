import React, {ReactElement, useState} from "react";
import {Withdraw, WithdrawStatus} from "@devour/client";
import {useAccount, Web3Button} from "@web3modal/react";
import FrameButton from "../../buttons/FrameButton";
import WithdrawRequestsConfirmAcceptModal from "../../modals/WithdrawRequestsConfirmAcceptModal";
import WithdrawRequestsConfirmRejectModal from "../../modals/WithdrawRequestsConfirmRejectModal";

interface Props {
	withdraw: Withdraw;
	onDone: () => Promise<void>;
}

function WithdrawRequestsActionsCell(props: Props): ReactElement {

	const account = useAccount();
	const accountIsConnected = account.account.isConnected;

	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);

	function toggleShowApproveModal(): void {
		setShowApproveModal((_show) => !_show);
	}

	function toggleShowRejectModal(): void {
		setShowRejectModal((_show) => !_show);
	}

	async function onDoneModal(): Promise<void> {
		setShowApproveModal(false);
		setShowRejectModal(false);
		await props.onDone();
	}

	if (props.withdraw.status !== WithdrawStatus.PENDING) {
		return null;
	}

	return (
		<React.Fragment>
			<WithdrawRequestsConfirmAcceptModal
				isOpen={showApproveModal}
				withdraw={props.withdraw}
				onClose={toggleShowApproveModal}
				onDone={onDoneModal}
			/>

			<WithdrawRequestsConfirmRejectModal
				isOpen={showRejectModal}
				withdraw={props.withdraw}
				onClose={toggleShowRejectModal}
				onDone={onDoneModal}
			/>

			<div className="frame-one-table-cell-btns">
				{!accountIsConnected ? (
					<Web3Button/>
				) : (
					<React.Fragment>
						<FrameButton
							color="success"
							onClick={toggleShowApproveModal}
						>
							Approve
						</FrameButton>

						<FrameButton
							color="danger"
							onClick={toggleShowRejectModal}
						>
							Reject
						</FrameButton>
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
}

export default WithdrawRequestsActionsCell;
