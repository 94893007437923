import React, {ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {
	AddNftGroupingBody,
	Asset,
	AssetsApi,
	NftsApi,
	Token
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FileInputButton from "../inputs/FileInputButton";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import NumberFormat from "react-number-format-legacy/dist/react-number-format";

interface AddNftGroupingBodyFrontend extends Omit<AddNftGroupingBody, "hero" | "icon"> {
	hero: FileWithSRC;
	icon: FileWithSRC;
}

const defaultAddNftGroupingBody: AddNftGroupingBodyFrontend = {
	name: "",
	description: "",
	discordUrl: "",
	twitterUrl: "",
	websiteUrl: "",
	hero: undefined,
	icon: undefined,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function AddNftGroupingModal(props: AddNftGroupingModalProps): ReactElement {

	const [createGroupingBody, setCreateGroupingBody] = useState<AddNftGroupingBodyFrontend>(defaultAddNftGroupingBody);

	/**
	 * Clean the form on open.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setCreateGroupingBody(defaultAddNftGroupingBody);
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setCreateGroupingBody(defaultAddNftGroupingBody);
		props.onClose();
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<AddNftGroupingBodyFrontend, "asset">): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateGroupingBody({
				...createGroupingBody,
				[key]: e.target.value,
			});
		}
	}

	function sortPriorityOnChange(values): void {
		setCreateGroupingBody((_createGroupingBody) => {
			return {
				..._createGroupingBody,
				sortPriority: values.floatValue,
			}
		});
	}

	/**
	 * Handle onChange event for the hero asset upload input.
	 *
	 * @param e
	 */
	async function onHeroChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newAsset = (await addURLsToFiles(e.target.files))[0];
		setCreateGroupingBody((_createGroupingBody) => {
			return {
				..._createGroupingBody,
				hero: newAsset,
			}
		});
	}

	/**
	 * Handle onChange event for the icon asset upload input.
	 *
	 * @param e
	 */
	async function onIconChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newAsset = (await addURLsToFiles(e.target.files))[0];
		setCreateGroupingBody((_createGroupingBody) => {
			return {
				..._createGroupingBody,
				icon: newAsset,
			}
		});
	}

	/**
	 * Remove the asset from the form.
	 *
	 */
	function removeAsset(key: keyof Pick<AddNftGroupingBodyFrontend, "hero" | "icon">): () => void {
		return () => {
			setCreateGroupingBody((_createGroupingBody) => {
				return {
					..._createGroupingBody,
					[key]: undefined,
				}
			});
		}
	}

	/**
	 * Call api to create new NFT grouping, reset form & close modal on success.
	 *
	 */
	async function submitNewGrouping(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			let hero: Asset;
			if (createGroupingBody.hero) {
				hero = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: createGroupingBody.hero,
				});
			}

			let icon: Asset;
			if (createGroupingBody.icon) {
				icon = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: createGroupingBody.icon,
				});
			}


			await new NftsApi(getConfig(props.fullToken)).addNftGrouping({
				addNftGroupingBody: {
					name: createGroupingBody.name || undefined,
					sortPriority: createGroupingBody.sortPriority,
					description: createGroupingBody.description || undefined,
					discordUrl: createGroupingBody.discordUrl || undefined,
					twitterUrl: createGroupingBody.twitterUrl || undefined,
					websiteUrl: createGroupingBody.websiteUrl || undefined,
					hero: hero ? hero.id : undefined,
					icon: icon ? icon.id : undefined,
				},
			});

			setCreateGroupingBody(defaultAddNftGroupingBody);
			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={closeHelper}
			size="sm"
			contentClassName="manage-groupings-add-modal"
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Add New NFT Grouping"
			/>

			<form onSubmit={submitNewGrouping}>
				<FrameModalBody className="manage-groupings-add-modal_body">
					<div>
						<label>
							Name*
						</label>
						<input
							value={createGroupingBody.name}
							placeholder="Grouping Name..."
							onChange={inputOnChange("name")}
						/>
					</div>

					<div>
						<label>
							Sort Priority
						</label>
						<NumberFormat
							placeholder="Sort Priority..."
							value={createGroupingBody.sortPriority}
							allowNegative={false}
							decimalScale={0}
							allowLeadingZeros={false}
							onValueChange={sortPriorityOnChange}
						/>
					</div>

					<div>
						<label>
							Description
						</label>
						<input
							value={createGroupingBody.description}
							placeholder="Grouping Description..."
							onChange={inputOnChange("description")}
						/>
					</div>

					<div>
						<label>
							Discord URL
						</label>
						<input
							value={createGroupingBody.discordUrl}
							placeholder="Grouping Discord URL..."
							onChange={inputOnChange("discordUrl")}
						/>
					</div>

					<div>
						<label>
							Twitter URL
						</label>
						<input
							value={createGroupingBody.twitterUrl}
							placeholder="Grouping Twitter URL..."
							onChange={inputOnChange("twitterUrl")}
						/>
					</div>

					<div>
						<label>
							Website URL
						</label>
						<input
							value={createGroupingBody.websiteUrl}
							placeholder="Grouping Website URL..."
							onChange={inputOnChange("websiteUrl")}
						/>
					</div>

					<div className="manage-groupings-add-modal_body_asset-upload">
						<label>
							Hero Image
						</label>

						<div className="manage-groupings-add-modal_body_asset-upload_content">
							{createGroupingBody.hero && (
								<img
									src={createGroupingBody.hero.imageSRC as string}
									alt="New NFT Grouping Hero"
									className="manage-groupings-add-modal_body_asset-upload_content_img"
								/>
							)}

							<FileInputButton
								accept=".png, .jpg, .jpeg, .gif"
								multiple={false}
								onChange={onHeroChange}
							>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="purple"
									forwardProps={{
										type: "button",
									}}
								>
									{createGroupingBody.hero ? "Change Hero" : "Upload Hero"}
								</FrameButton>
							</FileInputButton>

							{createGroupingBody.hero && (
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="danger"
									onClick={removeAsset("hero")}
									forwardProps={{
										type: "button",
									}}
								>
									Remove Image
								</FrameButton>
							)}
						</div>
					</div>

					<div>
						<label>
							Icon Image
						</label>

						<div className="manage-groupings-add-modal_body_asset-upload_content">
							{createGroupingBody.icon && (
								<img
									src={createGroupingBody.icon.imageSRC as string}
									alt="New NFT Grouping Icon"
									className="manage-groupings-add-modal_body_asset-upload_content_img"
								/>
							)}

							<FileInputButton
								accept=".png, .jpg, .jpeg, .gif"
								multiple={false}
								onChange={onIconChange}
							>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="purple"
									forwardProps={{
										type: "button",
									}}
								>
									{createGroupingBody.icon ? "Change Icon" : "Upload Icon"}
								</FrameButton>
							</FileInputButton>

							{createGroupingBody.icon && (
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="danger"
									onClick={removeAsset("icon")}
									forwardProps={{
										type: "button",
									}}
								>
									Remove Image
								</FrameButton>
							)}
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Save Grouping
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddNftGroupingModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddNftGroupingModal);
