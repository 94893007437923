import React, {ReactElement, useEffect, useState, ReactNode} from "react";
import {
    User,
    Business,
    BusinessesApi,
    Token,
    BusinessPermission,
    BusinessUserFull,
    BusinessUpdateUserBody,
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameButton from "../../components/buttons/FrameButton";
import getConfig from "../../utils/getConfig";
import FrameOneSwitchInput from "../../components/inputs/FrameOneSwitchInput";
import {cloneDeep} from "lodash";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import DeleteMerchantUserConfirmationModal from "./DeleteMerchantUserConfirmationModal";
import BusinessAddUserModal from "./BusinessAddUserModal";

interface PermissionDescription {
    permission: BusinessPermission;
    name: string;
    description: ReactNode;
}

export const permissionDescription: Array<PermissionDescription> = [
    {
        permission: BusinessPermission.OPERATIONS,
        name: "Operations",
        description: "Allowed to shut down the business.",
    },
    {
        permission: BusinessPermission.BILLING,
        name: "Billing",
        description: "Allowed to change the bank account that receives payment. They will also receive notifications on banking changes.",
    },
    {
        permission: BusinessPermission.USERS,
        name: "Users",
        description: "Allowed to add new users, grant permissions to users, and remove users from this business.",
    },
    {
        permission: BusinessPermission.MENUS,
        name: "Menus",
        description: "Allowed to edit the menus for the restaurant.",
    },
    {
        permission: BusinessPermission.CONTACT,
        name: "Contact",
        description: "Allowed to edit the contact information displayed to customers, such as address and hours of operation.",
    },
    {
        permission: BusinessPermission.ORDERSVIEW,
        name: "View Menu Orders",
        description: "Allowed to view the restaurant orders placed by customers.",
    },
];

interface StateProps {
    fullToken: Token;
    currentUser: User;
}

interface Props {
    business: Business;
    isOpen: boolean;
    onClose: () => void;
}

function EditBusinessUsersModal(props: EditBusinessUsersModalProps): ReactElement {
    const [showAddUser, setShowAddUser] = useState<boolean>(false);
    const [showUserDeleteConfimationModal, setShowUserDeleteConfimationModal] = useState<boolean>(false);
    const [deletionUser, setDeletionUser] = useState<BusinessUserFull>(undefined);
    const [businessUsers, setBusinessUsers] = useState<Array<BusinessUserFull>>([]);

    useEffect(() => {
        fetchBusinessPermissions().then();
    }, [props.isOpen]);

    async function submitBusinessPermissions(businessUser: BusinessUpdateUserBody): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            await new BusinessesApi(getConfig(props.fullToken)).updateBusinessUser({
                id: props.business?.id,
                businessUpdateUserBody: businessUser,
            });
            fetchBusinessPermissions().then();
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }

    }

    /**
     * Get the details for this business from our api.
     */
    async function fetchBusinessPermissions(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessPermissions({
                id: props.business?.id,
                limit: Number.MAX_SAFE_INTEGER,
            });
            setBusinessUsers(res.currentUsers);

        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }

    }

    function onPermissionToggle(userId: string, permission: BusinessPermission): void {
        const businessUser: BusinessUserFull = businessUsers.find((user) => user.user === userId);
        const businessUpdateBody: BusinessUpdateUserBody = cloneDeep({
            userId: userId,
            permissions: businessUser.permissions,
        });

        const thisIndex = businessUpdateBody.permissions.indexOf(permission);

        if (thisIndex > -1) {
            // Remove this option from list of selected items
            businessUpdateBody.permissions.splice(thisIndex, 1);
        } else {
            businessUpdateBody.permissions.push(permission);
        }

        submitBusinessPermissions(businessUpdateBody).then();
    }

    function onAddUserModalClose(): void {
        setShowAddUser(false);
        fetchBusinessPermissions().then();
    }

    function handleDeleteUserButtonClick(user: BusinessUserFull): void {
        setDeletionUser(user)
        setShowUserDeleteConfimationModal(true);
    }

    function handleDeleteUserClose(): void {
        setShowUserDeleteConfimationModal(false);
        fetchBusinessPermissions().then();
    }

    return (
        <React.Fragment>
            <BusinessAddUserModal
                business={(showAddUser) ? props.business : undefined}
                onUpdate={onAddUserModalClose}
                onClose={onAddUserModalClose}
            />
            <DeleteMerchantUserConfirmationModal
                isOpen={showUserDeleteConfimationModal}
                onClose={handleDeleteUserClose}
                business={props.business}
                user={deletionUser}
            />
            <FrameOneModal
                isOpen={props.isOpen}
                toggle={props.onClose}
                size={"lg"}
            >
                <FrameModalHeader
                    title={`Manage Users of ${props.business?.name}`}
                    toggle={props.onClose}
                />
                <FrameModalBody>
                    <div className="business-manage-users-modal">
                        <div className="business-manage-users-modal_content">
                            <h4>Permissions</h4>
                            <ul>
                                {permissionDescription.map((item) => (
                                    <li key={item.permission}>
                                        <strong>{item.name}</strong><br/>
                                        <span>{item.description}</span>
                                    </li>
                                ))}
                            </ul>

                            <div className="business-manage-users-modal_add-another">
                                <FrameButton
                                    <React.ButtonHTMLAttributes<HTMLButtonElement>>
                                    forwardProps={{type: "button"}}
                                    color="purple"
                                    onClick={() => setShowAddUser(true)}
                                >
                                    Add Another User to {props.business.name}
                                </FrameButton>
                            </div>
                            <div className="business-manage-users-modal_table">
                                <FrameOneTableContainer
                                    data={businessUsers.filter(u => u.user !== props.currentUser?.id)}
                                    columnOptions={[].concat([
                                        {
                                            key: "firstName",
                                            headerValue: "First Name",
                                        },
                                        {
                                            key: "lastName",
                                            headerValue: "Last Name",
                                        },
                                        {
                                            key: "email",
                                            headerValue: "Email",
                                        }],
                                        permissionDescription.map((p) => (
                                            {
                                                key: undefined,
                                                headerValue: p.name,
                                                cellRender: (businessUser) => (
                                                    <FrameOneSwitchInput
                                                        <number>
                                                        name="permission"
                                                        value={(businessUser.permissions.includes(p.permission)) ? 1 : 0}
                                                        onToggle={() => onPermissionToggle(businessUser.user, p.permission)}
                                                        className="business-manage-users-modal_table_cell"
                                                        options={[
                                                            {
                                                                render: "Forbid",
                                                                value: 0,
                                                            },
                                                            {
                                                                render: "Allow",
                                                                value: 1,
                                                            },
                                                        ]}
                                                    />
                                                )
                                            }
                                        )),
                                        {
                                            key: undefined,
                                            headerValue: "Delete User",
                                            cellRender: (businessUser) => (
                                                <div className="business-manage-users-modal_table_cell">
                                                    <FrameButton
                                                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                                                        forwardProps={{type: "button"}}
                                                        color="danger"
                                                        narrow={true}
                                                        onClick={() => handleDeleteUserButtonClick(businessUser)}
                                                    >
                                                        Remove User
                                                    </FrameButton>
                                                </div>
                                            )
                                        }
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                </FrameModalBody>
                <FrameModalFooter>
                    <FrameButton
                        color="darkBlue"
                        onClick={props.onClose}
                    >
                        Done
                    </FrameButton>
                </FrameModalFooter>
            </FrameOneModal>
        </React.Fragment>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            currentUser: store.metaStore.currentUser,
            fullToken: store.metaStore.fullToken,
            ...props,
        }
    });
}

type EditBusinessUsersModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(EditBusinessUsersModal);
