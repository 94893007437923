import React, {ReactElement, useEffect, useState} from "react";
import {
    CommunityRankingRecord,
    GoFrensApi,
    NftGrouping,
} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";

interface Props {
    isOpen: boolean;
    grouping: NftGrouping;
    onClose: () => void;
}

function HistoricCommunityRankingModal(props: Props): ReactElement {
    const [historicRankingData, setHistoricRankingData] = useState<Array<CommunityRankingRecord>>(undefined);

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

    useEffect(() => {
        if (props.isOpen) {
            void getHistoricalRankingData();
        } else {
            setHistoricRankingData(undefined);
        }
    }, [props.isOpen]);

    async function getHistoricalRankingData(): Promise<void> {
        dispatch(incrementLoading())
        try {
            const res = await new GoFrensApi(getConfig(fullToken)).getAdminCommunityRankingData({
                id: props.grouping.id
            });
            setHistoricRankingData(res.communityRankingRecords);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderHistoricRankingData(): ReactElement | Array<ReactElement> {
        if (!historicRankingData.length) {
            return (
                <p><strong>There are no historic records for this community... yet!</strong></p>
            );
        }
        const date = new Date();
        return historicRankingData.map((record, index) => {
            date.setMonth(record.month - 1);
            const month = date.toLocaleString('default', { month: 'long' });
           return (
               <div>
                   <p><strong>Month/Year: </strong>{`${month} ${record.year}`}</p>
                   <p><strong>Community Rank: </strong>{record.endOfMonthRank}</p>
                   <p><strong>Points accumulated this month: </strong>{record.monthlyTotalPoints}</p>
                   <p><strong>Grand total points: </strong>{record.grandTotalPoints}</p>
                   {index < historicRankingData.length - 1 && <hr />}
               </div>
           )
        });
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="contact-modal"
        >
            <FrameModalHeader
                title="Historic Community Rankings"
                toggle={props.onClose}
            />

            <FrameModalBody className="contact-modal_body">
                <p>
                    These are the end-of-month historic records of this community. At the end of each month, on midnight EST,
                    totals are counted for points.
                </p>
                <hr />
                {historicRankingData && renderHistoricRankingData()}
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default HistoricCommunityRankingModal;
