import {ChangeEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {
	Asset,
	BusinessTaxonomiesApi, BusinessTaxonomy,
	GetBusinessTaxonomiesResponse,
	Token
} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import React from "react";
import FrameButton from "../components/buttons/FrameButton";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment";
import AddTaxonomyModal from "../components/modals/AddTaxonomyModal";
import FrameOneTableCellImagePreview from "../components/tables/cells/FrameOneTableCellImagePreview";
import ManageTaxonomiesDeleteCell from "../components/tables/cells/ManageTaxonomiesDeleteCell";
import ManageTaxonomiesEditCell from "../components/tables/cells/ManageTaxonomiesEditCell";

interface StateProps {
	fullToken: Token;
}

function ManageTaxonomies(props: ManageTaxonomiesProps): ReactElement {

	const [taxonomiesRes, setTaxonomiesRes] = useState<GetBusinessTaxonomiesResponse>(undefined);
	const [showAddModal, setShowAddModal] = useState(false);
	const [search, setSearch] = useState<string>("");

	useEffect(() => {
		getTaxonomies().then().catch();
	}, []);

	function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
		setSearch(e.target.value);
	}

	async function getTaxonomies(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BusinessTaxonomiesApi(getConfig(props.fullToken)).getBusinessTaxonomies();
			setTaxonomiesRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function toggleAddTaxonomyModal(): void {
		setShowAddModal(s => !s);
	}

	function onDoneAddModal(): void {
		setShowAddModal(false);
		getTaxonomies().then().catch();
	}

	function renderDeleteCell(taxonomy: BusinessTaxonomy): ReactNode {
		return (
			<ManageTaxonomiesDeleteCell
				taxonomy={taxonomy}
				onDone={getTaxonomies}
			/>
		);
	}

	function renderEditCell(taxonomy: BusinessTaxonomy): ReactNode {
		return (
			<ManageTaxonomiesEditCell
				taxonomy={taxonomy}
				onDone={getTaxonomies}
			/>
		);
	}

	return (
		<React.Fragment>
			<AddTaxonomyModal
				isOpen={showAddModal}
				onClose={toggleAddTaxonomyModal}
				onDone={onDoneAddModal}
			/>

			<div className="manage-taxonomies">
				<PageHeader>
					<div>
						<h3>
							Merchant Categories
						</h3>

						<p>
							Here you can view, add, and edit categories on the platform.
						</p>

						<FrameButton
							color="lightBlue"
							onClick={toggleAddTaxonomyModal}
						>
							Add Merchant Category
						</FrameButton>
					</div>
				</PageHeader>

				<div className="manage-restaurants_table">
					<div>
						<label>
							Search
						</label>
						<input
							value={search}
							placeholder="Search for name of the category..."
							onChange={searchOnChange}
						/>
					</div>
					<br/>

					<FrameOneTableContainer
						data={taxonomiesRes?.businessTaxonomies.filter((c) => c.name.toLowerCase().includes(search.toLowerCase()))}
						columnOptions={[
							{
								key: "image",
								headerValue: "Image",
								cellRender: (asset: Asset) => {
									return (
										<FrameOneTableCellImagePreview asset={asset}/>
									);
								}
							},
							{
								key: "name",
								headerValue: "Name",
							},
							{
								key: "priority",
								headerValue: "Priority",
							},
							{
								key: "description",
								headerValue: "Description",
								cellRender: (d: string) => {
									return (
										<div className="table-description-field">
											{d}
										</div>
									);
								}
							},
							{
								key: "createdAt",
								headerValue: "Created",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: "updatedAt",
								headerValue: "Last Updated",
								valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
							},
							{
								key: undefined,
								headerValue: "Edit Category",
								cellRender: renderEditCell,
							},
							{
								key: undefined,
								headerValue: "Delete Category",
								cellRender: renderDeleteCell,
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type ManageTaxonomiesProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageTaxonomies);
