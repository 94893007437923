import React, {ChangeEvent, ChangeEventHandler, ReactElement, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {GetIndustryCollectionPayoutsReportRequest, Token, TransactionsApi} from "@devour/client";
import PageHeader from "../components/PageHeader";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import FrameButton from "../components/buttons/FrameButton";
import moment from "moment";
import getConfig from "../utils/getConfig";

const startingYear = 2022; // Start the year dropdown at this value; automatically fills to current year.
const currentYear = moment().get("year");
const currentMonth = moment().get("month");

interface GetIndustryCollectionPayoutsReportRequestFrontend extends Omit<GetIndustryCollectionPayoutsReportRequest, "month" | "year"> {
	month: number;
	year: number;
}

const defaultReportForm = {
	year: currentYear,
	month: currentMonth,
}

interface StateProps {
	fullToken: Token;
}

function IndustryCollectionPayoutsReportPage(props: IndustryCollectionPayoutsReportPageProps): ReactElement {

	const [reportForm, setReportForm] = useState<GetIndustryCollectionPayoutsReportRequestFrontend>(defaultReportForm);

	/**
	 * Year change handler; force type to number because <select> input only gives us string.
	 *
	 * @param e
	 */
	function onYearChange(e: ChangeEvent<HTMLSelectElement>): void {
		setReportForm((_reportForm) => {
			return {
				..._reportForm,
				year: parseInt(e.target.value),
			}
		});
	}

	/**
	 * Month change handler; force type to number because <select> input only gives us string.
	 *
	 * @param e
	 */
	function onMonthChange(e: ChangeEvent<HTMLSelectElement>): void {
		setReportForm((_reportForm) => {
			return {
				..._reportForm,
				month: parseInt(e.target.value),
			}
		});
	}

	/**
	 * Call the api & download the report.
	 *
	 */
	async function requestReport(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new TransactionsApi(getConfig(props.fullToken)).getIndustryCollectionPayoutsReport({
				month: moment({month: reportForm.month}).format("MMMM").toLowerCase(),
				year: reportForm.year.toString(),
			});
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Generate the years for the year dropdown.
	 *
	 */
	function getYears(): Array<number> {
		const difference = currentYear - startingYear;
		const years: Array<number> = [];

		for (let i = 0; i <= difference; i++) {
			years.push(startingYear + i);
		}

		return years;
	}

	return (
		<div className="industry-col-pay-report-page">
			<PageHeader className="industry-col-pay-report-page_header">
				<h3>
					Industry Collection Payouts Report Page
				</h3>

				<div className="industry-col-pay-report-page_header_form">
					<div>
						<label>Year</label>
						<select
							value={reportForm.year}
							onChange={onYearChange}
						>
							{getYears().map((year, i) => {
								return (
									<option
										key={`year_${i}`}
										value={year}
									>
										{year}
									</option>
								);
							})}
						</select>
					</div>

					<div>
						<label>Month</label>
						<select
							value={reportForm.month}
							onChange={onMonthChange}
						>
							<option value="0">January</option>
							<option value="1">February</option>
							<option value="2">March</option>
							<option value="3">April</option>
							<option value="4">May</option>
							<option value="5">June</option>
							<option value="6">July</option>
							<option value="7">August</option>
							<option value="8">September</option>
							<option value="9">October</option>
							<option value="10">November</option>
							<option value="11">December</option>
						</select>
					</div>

					<div>
						<FrameButton
							<React.AnchorHTMLAttributes<HTMLAnchorElement>>
							color="darkBlue"
							href={`${process.env.REACT_APP_BACKEND_REST_URL}/transactions/industry_collection_payouts_report?month=${moment({month: reportForm.month}).format("MMMM").toLowerCase()}&year=${reportForm.year.toString()}`}
							// onClick={requestReport}
							forwardProps={{
								// disabled: reportForm.year == undefined || reportForm.month == undefined || (reportForm.year === currentYear && reportForm.month > currentMonth),
							}}
						>
							DownloadReport
						</FrameButton>
					</div>
				</div>
			</PageHeader>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type IndustryCollectionPayoutsReportPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(IndustryCollectionPayoutsReportPage);