import React, {ReactElement, ReactNode} from "react";
import {Brand, OrderDiscount} from "@devour/client";
import {
	MdCheckBox,
	MdOutlineCheckBoxOutlineBlank,
	MdRadioButtonChecked,
	MdRadioButtonUnchecked
} from "react-icons/md";
import {EditBrandMapBodyFrontend} from "../../pages/brandMaps/BrandMapFormValues";
import classNames from "classnames";

interface Props {
	formValues: EditBrandMapBodyFrontend;
	brand: Brand;
	mainPromoOnChange: (promo: OrderDiscount) => void;
	secondaryPromoOnChange: (promo: OrderDiscount) => void;
}

function EditBrandMapMainPromoSelection(props: Props): ReactElement {

	/**
	 * Render each business of the brand in the list with controls for selecting/deselecting.
	 *
	 * @param promo
	 */
	function renderPromoCard(promo: OrderDiscount): ReactNode {
		const isMainPromo = props.formValues.mainPromo?.length && props.formValues.mainPromo === promo.id;
		const isEnabled = props.formValues.promos.includes(promo.id);

		return (
			<div
				key={promo.id}
				className="create-map-page_main-promo_table_body_row"
			>
				<div className="create-map-page_main-promo_table_body_row_item">
					<button
						onClick={() => {
							props.mainPromoOnChange(promo);
						}}
						className="reset-button"
					>
						{(isMainPromo) ? (
							<MdRadioButtonChecked className="is-selected"/>
						) : (
							<MdRadioButtonUnchecked className="is-not-selected"/>
						)}
					</button>
				</div>
				<div className="create-map-page_main-promo_table_body_row_item">
					<button
						onClick={() => {
							if (!isMainPromo) {
								props.secondaryPromoOnChange(promo);
							}
						}}
						className={classNames("reset-button", {
							"merchant-brand-disable-select": isMainPromo
						})}
					>
						{(isEnabled) ? (
							<MdCheckBox className="is-selected"/>
						) : (
							<MdOutlineCheckBoxOutlineBlank className="is-not-selected"/>
						)}
					</button>
				</div>
				<div className="create-map-page_main-promo_table_body_row_item">
					<div className="create-map-page_main-promo_table_body_row_item_truncate">
						{promo.internalName}
					</div>
				</div>
				<div className="create-map-page_main-promo_table_body_row_item">
					<div className="create-map-page_main-promo_table_body_row_item_truncate">
						{promo.label}
					</div>
				</div>
				<div className="create-map-page_main-promo_table_body_row_item">
					{promo.type}
				</div>
			</div>
		);
	}

	return (
		<div className="create-map-page_container create-map-page_main-promo">

			<h4>
				Set Promotions and Locations
			</h4>

			<div className="create-map-page_container_inner create-map-page_main-promo_selection">

				<h5>
					Choose Promotions
				</h5>
				<p>
					Optionally select the main and regular promotions that you would like to display. The main promotion will be highlighted on your brand map page.
				</p>
				<p>
					*If a main promotion is selected, it must be applicable to <em>all</em> locations, else the location
					will be hidden on the live map. You can leave the main promotion empty if you would like to show all
					locations, regardless of promotional availability.
				</p>

				<div className="create-map-page_main-promo_table">
					<div className="create-map-page_main-promo_table_header">
						<div className="create-map-page_main-promo_table_header_row">
							<div className="create-map-page_main-promo_table_header_row_item">
								Main
							</div>
							<div className="create-map-page_main-promo_table_header_row_item">
								Regular
							</div>
							<div className="create-map-page_main-promo_table_header_row_item">
								Promotion Name
							</div>
							<div className="create-map-page_main-promo_table_header_row_item">
								Promotion Text
							</div>
							<div className="create-map-page_main-promo_table_header_row_item">
								Promotion Type
							</div>
						</div>
					</div>
					<div className="create-map-page_main-promo_table_body">
						{props.brand?.promos?.map(renderPromoCard)}
					</div>
				</div>
				{!props.brand?.promos.length && (
					<p className="create-map-page_main-promo_no-promos">
						No promotions available.
					</p>
				)}
			</div>
		</div>
	);
}

export default EditBrandMapMainPromoSelection;
