import React, {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	AddBrandBusinessBody,
	Brand,
	BrandsApi,
	Business,
	BusinessesApi,
	BusinessType,
	GetBusinessesOwnResponse,
	Token
} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {concatAddressFields} from "../../utils/formatAddress";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";

const defaultAddBrandBusinessBody: AddBrandBusinessBody = {
	brand: undefined,
	business: undefined,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	brand: Brand;
	onClose: () => void;
	onDone: () => void;
}

function AddBusinessToBrandModal(props: AddBusinessToBrandModalProps): ReactElement {

	const [businessForm, setBusinessForm] = useState<AddBrandBusinessBody>(defaultAddBrandBusinessBody);
	const [businessList, setBusinessList] = useState<GetBusinessesOwnResponse>(undefined);

	/**
	 * When modal opens reset the form to defaults & current brand.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			setBusinessForm({
				...defaultAddBrandBusinessBody,
				brand: props.brand.id,
			});

			if (!businessList) {
				void getBusinessesForDropDown();
			}
		}
	}, [props.isOpen]);

	/**
	 * Get the list of businesses for inflating the drop-down/select input.
	 *
	 */
	async function getBusinessesForDropDown(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const businessesRes = await new BusinessesApi(getConfig(props.fullToken)).getBusinessesOwn({
				limit: 1000000,
				offset: 0,
				type: [BusinessType.RESTAURANT],
			});

			setBusinessList(businessesRes);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle the business id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleBusinessIdOnChange(v: IReactSelectOption): void {
		setBusinessForm((p) => {
			return {
				...p,
				business: v?.value,
			}
		});
	}

	async function submitNewBrandBusiness(e: React.FormEvent): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new BrandsApi(getConfig(props.fullToken)).addBrandBusiness({
				addBrandBusinessBody: {
					brand: props.brand.id,
					business: businessForm.business,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	const businessesIdOptions = makeReactSelectOptions<Business>(businessList?.businesses as Array<Business>, "id", (business) => business.name + " - " + concatAddressFields(business.address));

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-brand-add-business-modal"
		>
			<FrameModalHeader
				title={`Add Business for the "${props.brand.name}" Brand`}
				toggle={props.onClose}
			/>

			<form onSubmit={submitNewBrandBusiness}>
				<FrameModalBody className="manage-brand-add-business-modal_body">
					<div>
						<label>
							Business
						</label>
						<FrameOneReactSelect
							name="Business"
							placeholder="Select Business..."
							isClearable={true}
							isLoading={businessList === undefined}
							isDisabled={businessList === undefined}
							value={businessesIdOptions.find(m => m.value === businessForm.business)}
							onChange={handleBusinessIdOnChange}
							options={businessesIdOptions}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit",
							disabled: !businessForm.business,
						}}
					>
						Add Business to Brand
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type AddBusinessToBrandModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AddBusinessToBrandModal);
