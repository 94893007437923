import React, {ReactElement} from "react";
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import {isDev} from "./utils/isDev";
import PageWrapper from "./components/routing/PageWrapper";
import ComponentDemoPage from "./pages/ComponentDemoPage";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ManageAdmins from "./pages/ManageAdmins";
import ManageUsers from "./pages/ManageUsers";
import ManageNftGroupings from "./pages/ManageNftGroupings";
import ManageNftTrackers from "./pages/ManageNftTrackers";
import WithdrawRequests from "./pages/WithdrawRequests";
import ManageMerchants from "./pages/ManageMerchants";
import ManageRestaurants from "./pages/ManageRestaurants";
import ManageTaxonomies from "./pages/ManageTaxonomies";
import OrderDiscountsPage from "./pages/OrderDiscountsPage";
import MerchantAgreementTermsPage from "./pages/MerchantAgreementTermsPage";
import ManageSiteConfigurations from "./pages/ManageSiteConfigurations";
import MenuOrdersPage from "./pages/MenuOrdersPage";
import MenuOrdersDetails from "./pages/MenuOrdersDetails";
import IndustryCollectionPayoutsReportPage from "./pages/IndustryCollectionPayoutsReport";
import ManageBrands from "./pages/ManageBrands";
import ManageSingleBrand from "./pages/ManageSingleBrand";
import ManageReferralRewardThresholds from "./pages/ManageReferralRewardThresholds";
import OnBoardingThirdParties from "./pages/OnBoardingThirdParties";
import ManageServiceFeesPage from "./pages/ManageServiceFeesPage";
import ManageDpayPurchases from "./pages/ManageDpayPurchases";
import SEOConfigurationPage from "./pages/SEOConfigurationPage";
import ManageNftPurchases from "./pages/ManageNftPurchases";
import VelocityReportingPage from "./pages/VelocityReportingPage";
import ManageMerchantDetails from "./pages/ManageMerchantDetails";
import ViewApiResponses from "./pages/ViewApiResponses";
import ManageGoVipPointMultipliers from "./pages/ManageGoVipPointMultipliers";
import ManagePos from "./pages/ManagePos";
import ManageBrandMapsPage from "./pages/ManageBrandMapsPage";
import CreateBrandMapPage from "./pages/brandMaps/CreateBrandMapPage";
import EditBrandMapPage from "./pages/brandMaps/EditBrandMapPage";

function App(): ReactElement {

	return (
		<RouterProvider
			router={createBrowserRouter([
				isDev() && {
					path: "/demo",
					element: (
						<PageWrapper
							page={<ComponentDemoPage/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/",
					element: (
						<PageWrapper
							page={<Login/>}
							hasSidebar={false}
						/>
					),
				},
				{
					path: "/dashboard",
					element: (
						<PageWrapper
							page={<Dashboard/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-admins",
					element: (
						<PageWrapper
							page={<ManageAdmins/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-users",
					element: (
						<PageWrapper
							page={<ManageUsers/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-nft-groupings",
					element: (
						<PageWrapper
							page={<ManageNftGroupings/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-nft-trackers",
					element: (
						<PageWrapper
							page={<ManageNftTrackers/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/withdraw-requests",
					element: (
						<PageWrapper
							page={<WithdrawRequests/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/merchant-users",
					element: (
						<PageWrapper
							page={<ManageMerchants/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/merchants",
					element: (
						<PageWrapper
							page={<ManageRestaurants/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/merchants/:businessId",
					element: (
						<PageWrapper
							page={<ManageMerchantDetails/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/merchant-categories",
					element: (
						<PageWrapper
							page={<ManageTaxonomies/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-pos",
					element: (
						<PageWrapper
							page={<ManagePos/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-brands",
					element: (
						<PageWrapper
							page={<ManageBrands/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-brands/:brandId",
					element: (
						<PageWrapper
							page={<ManageSingleBrand/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-brands/:brandId/maps",
					element: (
						<PageWrapper
							page={<ManageBrandMapsPage />}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-brands/:brandId/maps/create",
					element: (
						<PageWrapper
							page={<CreateBrandMapPage />}
							hasSidebar={false}
						/>
					),
				},
				{
					path: "/manage-brands/maps/:slug/edit",
					element: (
						<PageWrapper
							page={<EditBrandMapPage />}
							hasSidebar={false}
						/>
					),
				},
				{
					path: "/promos",
					element: (
						<PageWrapper
							page={<OrderDiscountsPage/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/service-fees",
					element: (
						<PageWrapper
							page={<ManageServiceFeesPage/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/merchant-agreement-terms",
					element: (
						<PageWrapper
							page={<MerchantAgreementTermsPage/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/menu-orders",
					element: (
						<PageWrapper
							page={<MenuOrdersPage/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/menu-orders/:menuOrderId",
					element: (
						<PageWrapper
							page={<MenuOrdersDetails/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/dpay-purchases",
					element: (
						<PageWrapper
							page={<ManageDpayPurchases/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/industry-pass-minting",
					element: (
						<PageWrapper
							page={<ManageNftPurchases/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/third-party-on-boarding",
					element: (
						<PageWrapper
							page={<OnBoardingThirdParties/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/site-configurations",
					element: (
						<PageWrapper
							page={<ManageSiteConfigurations/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/industry-collection-payouts-report",
					element: (
						<PageWrapper
							page={<IndustryCollectionPayoutsReportPage/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/referral-rewards-thresholds",
					element: (
						<PageWrapper
							page={<ManageReferralRewardThresholds/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/seo-configurations",
					element: (
						<PageWrapper
							page={<SEOConfigurationPage/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/velocity-reporting",
					element: (
						<PageWrapper
							page={<VelocityReportingPage/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/api-responses",
					element: (
						<PageWrapper
							page={<ViewApiResponses/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/manage-go-vip-point-multipliers",
					element: (
						<PageWrapper
							page={<ManageGoVipPointMultipliers/>}
							hasSidebar={true}
						/>
					),
				},
				{
					path: "/*",
					element: (
						<Navigate
							to="/"
							replace={true}
						/>
					),
				},
			])}
		/>
	);
}

export default App;
