import {ReactElement, useState} from "react";
import {NftGrouping} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import ManageBannedCommunityUsersModal from "../../modals/ManageBannedCommunityUsersModal";
import HistoricCommunityRankingModal from "../../modals/HistoricCommunityRankingModal";

interface Props {
    grouping: NftGrouping;
    onDone: () => Promise<void>;
}

function HistoricCommunityRankingCell(props: Props): ReactElement {

    const [showRankingModal, setShowRankingModal] = useState<boolean>(false);

    function toggleShowDeleteModal(): void {
        setShowRankingModal(s => !s);
    }

    function onDone(): void {
        setShowRankingModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <HistoricCommunityRankingModal
                isOpen={showRankingModal}
                onClose={onDone}
                grouping={props.grouping}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightBlue"
                    narrow={true}
                    onClick={toggleShowDeleteModal}
                >
                    View Historic Rankings
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default HistoricCommunityRankingCell;