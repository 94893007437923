import * as React from 'react';
import {ReactElement, ReactNode, useEffect, useRef, useState} from 'react';
import classNames from "classnames";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

interface Props {
	isOpen: boolean;
	handleTitleClick: () => void;
	title: string;
	body: ReactNode;
}

function FrameOneAccordionsItem(props: Props): ReactElement {
	const [height, setHeight] = useState<number | undefined>(props.isOpen ? undefined : 0);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!height || !props.isOpen || !ref.current) return undefined;
		// @ts-ignore
		const resizeObserver = new ResizeObserver((el) => {
			setHeight(el[0].contentRect.height);
		});
		resizeObserver.observe(ref.current);
		return () => {
			resizeObserver.disconnect();
		};
	}, [height, props.isOpen]);
	useEffect(() => {
		if (props.isOpen) {
			setHeight(ref.current?.getBoundingClientRect().height);
		} else {
			setHeight(0);
		}
	}, [props.isOpen]);

	return (
		<div
			className={classNames("frame-one-accordions_item", {
				"is-active": props.isOpen,
			})}
		>
			<button
				onClick={props.handleTitleClick}
				className="frame-one-accordions_item_title"
			>
				<span>{props.title}</span>
				{(props.isOpen) ? (
					<IoIosArrowUp/>
				) : (
					<IoIosArrowDown/>
				)}
			</button>
			<div
				className="frame-one-accordions_item_body"
				style={{height}}
			>
				<div
					className="frame-one-accordions_item_body_inner"
					ref={ref}
				>
					{props.body}
				</div>
			</div>
		</div>
	);
}

export default FrameOneAccordionsItem;
