import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {
    ReferralsApi,
    Token,
    ReferralLinkTextBody
} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";

interface StateProps {
    fullToken: Token;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onDone: () => void;
}

const defaultEditReferralLinkTextForm: ReferralLinkTextBody = {
    header: "",
    description: ""

};

function EditReferralRewardsThresholdModal(props: EditReferralRewardsThresholdModalProps): ReactElement {

    const [form, setForm] = useState<ReferralLinkTextBody>(defaultEditReferralLinkTextForm);

    useEffect(() => {
        /**
         * Get the current referral link text.
         *
         */
        async function getReferralLinkText () {
            try {

                const formData =  await new ReferralsApi(getConfig()).getReferralLinkText();

                setForm({
                    header: formData.header,
                    description: formData.description
                });

            } catch (e) {
                props.dispatch(await addError(e));
            } finally {
                props.dispatch(decrementLoading());
            }
        }

        if (props.isOpen) {
            void getReferralLinkText();
        }
    }, [props.isOpen]);

    /**
     * Reset the form & close the modal.
     *
     */
    function closeHelper(): void {
        setForm(defaultEditReferralLinkTextForm);
        props.onClose();
    }

    /**
     * Dynamic onChange for the form fields.
     *
     * @param key
     */
    function createOnChange(key: keyof ReferralLinkTextBody): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
        return (e) => {
            setForm({
                ...form,
                [key]: e.target.value,
            });
        }
    }

    /**
     * Submit form to update the referral link text.
     *
     */
    async function submitNewReferralLinkText(e?: React.FormEvent): Promise<void> {
        e?.preventDefault();
        props.dispatch(incrementLoading());

        try {

            await new ReferralsApi(getConfig(props.fullToken)).updateReferralLinkText({
                referralLinkTextBody: {
                    header: form.header ? form.header : "",
                    description: form.description ? form.description : ""
                }
            });

            props.onDone();

        } catch (err) {
            props.dispatch(await addError(err));
        } finally {
            props.dispatch(decrementLoading());
        }

    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="manage-referral-reward-thresholds-add-modal"
        >
            <FrameModalHeader
                toggle={closeHelper}
                title="Edit Referral Link Text"
            />

            <form onSubmit={submitNewReferralLinkText}>
                <FrameModalBody className="manage-referral-reward-thresholds-add-modal_body">
                    <div>
                        <label>
                            Header*
                        </label>
                        <input
                            value={form.header}
                            placeholder="Header..."
                            onChange={createOnChange("header")}
                        />
                    </div>

                    <div>
                        <label>
                            Description
                        </label>
                        <input
                            value={form.description}
                            placeholder="Description..."
                            onChange={createOnChange("description")}
                        />
                    </div>

                </FrameModalBody>

                <FrameModalFooter>
                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        forwardProps={{
                            type: "submit",
                        }}
                    >
                        Update Referral Link Text
                    </FrameButton>
                </FrameModalFooter>
            </form>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.metaStore.fullToken,
            ...props,
        }
    });
}

type EditReferralRewardsThresholdModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(EditReferralRewardsThresholdModal);
