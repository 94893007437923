import {OrderDiscountOrigin} from "@devour/client";

export function formatDiscountOrigin(origin: OrderDiscountOrigin): string {
	switch (origin) {
		case OrderDiscountOrigin.DEVOUR:
			return "Devour";
		case OrderDiscountOrigin.MERCHANT:
			return "Merchant";
		case OrderDiscountOrigin.DPAY:
			return "DPAY";
	}
}
