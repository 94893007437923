import React, {ReactElement, useEffect, useState} from "react";
import {
	MenuOrder,
	MenuOrdersApi,
	Token
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import PageHeader from "../components/PageHeader";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {useParams} from "react-router";
import MenuOrderBusiness from "../components/menuOrders/MenuOrderBusiness";
import MenuOrderCustomer from "../components/menuOrders/MenuOrderCustomer";
import MenuOrderMeta from "../components/menuOrders/MenuOrderMeta";
import MenuOrderItems from "../components/menuOrders/MenuOrderItems";
import MenuOrderPricing from "../components/menuOrders/MenuOrderPricing";
import MenuOrderRefund from "../components/menuOrders/MenuOrderRefund";
import MenuOrderLog from "../components/menuOrders/MenuOrderLog";
import MenuOrderChat from "../components/menuOrders/MenuOrderChat";
import FrameButton from "../components/buttons/FrameButton";


interface StateProps {
	fullToken: Token;
}

function MenuOrdersDetails(props: MenuOrdersDetailsProps): ReactElement {

	const {menuOrderId} = useParams<{ menuOrderId: string; }>();
	const [menuOrder, setMenuOrder] = useState<MenuOrder>();

	useEffect(() => {
		fetchMenuOrder().then();
	}, [menuOrderId]);

	/**
	 * Get the details for this menu order from our api.
	 */
	async function fetchMenuOrder(): Promise<void> {
		setMenuOrder(undefined);
		if (!menuOrderId) {
			return;
		}

		props.dispatch(incrementLoading());

		try {
			const menuOrder = await new MenuOrdersApi(getConfig(props.fullToken)).getMenuOrderAdmin({
				id: menuOrderId,
			});
			setMenuOrder(menuOrder);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}

	}

	if (!menuOrder) {
		return null;
	}

	return (
		<div className="menu-order-details">
			<PageHeader>
				<div>
					<h3>
						Menu Order {menuOrderId}
					</h3>

					<p>
						Here you can view and manage customer's menu order in the system.
					</p>

					{(menuOrder.errorMessage) && (
						<p className="menu-order-details_error">
							{menuOrder.errorMessage}
						</p>
					)}

					<div className="d-flex">
						<FrameButton
							color="lightBlue"
							narrow={true}
							to={`/api-responses?menuOrder=${menuOrderId}`}
						>
							View API Logs
						</FrameButton>
					</div>

				</div>
			</PageHeader>

			<div className="menu-order-details_body">
				<div className="menu-order-details_row">
					<div className="menu-order-details_business">
						<MenuOrderBusiness restaurant={menuOrder.business}/>
					</div>
					<div className="menu-order-details_customer">
						<MenuOrderCustomer menuOrder={menuOrder}/>
					</div>
					<div className="menu-order-details_meta">
						<MenuOrderMeta menuOrder={menuOrder}/>
					</div>
				</div>
				<div className="menu-order-details_row">
					<div className="menu-order-details_details">
						<MenuOrderItems menuOrder={menuOrder}/>
					</div>
					<div className="menu-order-details_log">
						<MenuOrderLog menuOrder={menuOrder}/>
					</div>
				</div>
				<div className="menu-order-details_row">
					<div className="menu-order-details_pricing">
						<MenuOrderPricing
							menuOrder={menuOrder}
							onDone={fetchMenuOrder}
						/>
					</div>
					<div className="menu-order-details_pricing">
						<MenuOrderRefund
							menuOrder={menuOrder}
							onDone={fetchMenuOrder}
						/>
					</div>
				</div>
				<div className="menu-order-details_row">
					<div className="menu-order-details_chat">
						<MenuOrderChat menuOrder={menuOrder}/>
					</div>
				</div>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type MenuOrdersDetailsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(MenuOrdersDetails);
