import React, {ReactElement} from "react";

import {BrandMap, BrandMapStatus} from "@devour/client";

import {LinkProps} from "react-router-dom";
import FrameOneModal from "../modalComponents/FrameOneModal";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameModalFooter from "../modalComponents/FrameModalFooter";
import FrameButton from "../../buttons/FrameButton";

interface Props {
    isOpen: boolean;
    brandMap: BrandMap;
    new: boolean;
    onContinueEditing?: () => void;
    originalBrandMap?: BrandMap;
}

function BrandMapSavePublishedSuccessModal(props: Props): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            size="sm"
            contentClassName="brand-map-save-published-success-modal"
        >
            <FrameModalHeader title="Map published successfully!"/>

            <FrameModalBody>
                {props.new ? (
                    <p>
                        {`Your new map, '${props.brandMap?.name}' was successfully published!`}
                    </p>
                ) : (
                    <React.Fragment>
                        {props.originalBrandMap?.status === BrandMapStatus.DRAFT ? (
                            <p>
                                {`Your map, '${props.brandMap?.name}' has been successfully updated & published!`}
                            </p>
                        ) : (
                            <p>
                                {`Your map, '${props.brandMap?.name}' has been successfully updated!`}
                            </p>
                        )}
                    </React.Fragment>
                )}
            </FrameModalBody>

            <FrameModalFooter>
                <FrameButton
                    <React.AnchorHTMLAttributes<HTMLAnchorElement>>
                    color="purple"
                    size="normal"
                    href={`${process.env.REACT_APP_WEB_URL}/brands/${props.brandMap?.slug}`}
                    forwardProps={{
                        target: "_blank",
                        rel: "noopener noreferrer",
                    }}
                >
                    View Live Map
                </FrameButton>

                {props.new ? (
                    <FrameButton
                        <LinkProps>
                        color="purple"
                        size="normal"
                        forwardProps={{
                            to: `/manage-brands/maps/${props.brandMap?.slug}/edit`,
                            replace: true
                        }}
                    >
                        Continue Editing
                    </FrameButton>
                ) : (
                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={props.onContinueEditing}
                    >
                        Continue Editing
                    </FrameButton>
                )}

                <FrameButton
                    color="purple-outline"
                    size="normal"
                    to={`/manage-brands/${props.brandMap?.brand?.id}/maps`}
                >
                    Return to Browse Maps
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default BrandMapSavePublishedSuccessModal;