import {EVMChain} from "@devour/client";

export function getBlockExplorerUrl(chain: EVMChain): string {
	switch (chain) {
		case EVMChain.ETHEREUMMAINNET:
			return "https://etherscan.io";
		case EVMChain.POLYGON:
			return "https://polygonscan.com";
		case EVMChain.GOERLI:
			return "https://goerli.etherscan.io";
		case EVMChain.AVALANCHE:
			return "https://snowtrace.io";
		case EVMChain.MUMBAI:
			return "https://mumbai.polygonscan.com";
		case EVMChain.BASE:
			return "https://basescan.org"
	}
}
