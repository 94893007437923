import React, {ReactElement} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    ReferralsApi,
    Token,
} from "@devour/client";
import {IStore} from "../../../redux/defaultStore";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameOneModal from "../modalComponents/FrameOneModal";
import {ReferralInfo} from "./ManageReferredUsersModal";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";
import FrameButton from "../../buttons/FrameButton";

interface ConfirmRemoveReferralModalProps {
    isOpen: boolean;
    onConfirmation: () => Promise<void>;
    onClose: () => void;
    referralInfo: ReferralInfo;
}

function ConfirmRemoveReferralModal(props: ConfirmRemoveReferralModalProps): ReactElement {

    const fullToken = useSelector<IStore, Token | undefined>(state => state.metaStore.fullToken);
    const dispatch = useDispatch();

    async function removeReferral(): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new ReferralsApi(getConfig(fullToken)).removeReferral({
                removeReferralRequestBody: {
                    referralId: props.referralInfo.referralId
                },
            });
            await props.onConfirmation();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
            props.onClose();
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="user-experience-modal"
        >
            <FrameModalHeader
                toggle={props.onClose}
                title="Confirm Referral Removal"
            />

            <FrameModalBody className="manage-user-referrals_modal">
                Are you sure you would like to remove this referral? <br/>

                <div className="manage-user-referrals_referral-info">
                    <b> Name: </b>{props.referralInfo.firstName} {props.referralInfo.lastName}<br/>
                    <b> Email: </b>{props.referralInfo.email}<br/>
                    <b> Referral Date:</b> {props.referralInfo.referralDate}<br/>
                    referral id: {props.referralInfo.referralId}
                </div>

                <div className="manage-user-referrals_confirm-buttons">

                    <FrameButton
                        className="manage-user-referrals_confirm-button"
                        color="success"
                        onClick={removeReferral}
                    >
                        Confirm
                    </FrameButton>

                    <FrameButton
                        className="manage-user-referrals_cancel-button"
                        color="danger"
                        onClick={props.onClose}
                    >
                        Cancel
                    </FrameButton>

                </div>

            </FrameModalBody>
        </FrameOneModal>
    );
}


export default ConfirmRemoveReferralModal;
