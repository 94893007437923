import React, {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {
	GetDpayPurchasesResponse,
	GetUserResponse,
	Token,
	TransactionsApi,
	UsersApi,
	UtilsApi
} from "@devour/client";
import getConfig from "../utils/getConfig";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import PageHeader from "../components/PageHeader";
import {omit} from "lodash";
import moment from "moment";
import FrameButton from "../components/buttons/FrameButton";

interface StateProps {
	fullToken: Token;
}

function ManageDpayPurchases(props: ManageDpayPurchasesProps): ReactElement {

	const [dpayPurchases, setDpayPurchases] = useState<GetDpayPurchasesResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [stripeTestMode, setStripeTestMode] = useState<boolean>(false);
	const [search, setSearch] = useState<string>("");

	useEffect(() => {
		getVersion().then().catch(console.error);
	}, []);


	useEffect(() => {
		void readDpayPurchases();
	}, [JSON.stringify(frontendPagination)]);

	async function getVersion() {
		const version = await new UtilsApi().version();
		let testMode: boolean = false;

		if (version.version.includes("Local") ||
			version.version.includes("develop") ||
			version.version.includes("staging1") ||
			version.version.includes("staging2")) {
			testMode = true;
		}
		setStripeTestMode(testMode);
	}

	/**
	 * Call api to get & save the list of admins.
	 *
	 */
	async function readDpayPurchases(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TransactionsApi(getConfig(props.fullToken)).getDpayPurchaseTransactions({
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				search: search,
			});

			setDpayPurchases(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
		setSearch(e.target.value);
	}

	async function handleSubmitSearch(): Promise<void> {
		setFrontendPagination(p => {
			return {
				...defaultFrontendPagination,
				frontendRenderKey: p.frontendRenderKey + 1,
			}
		});
	}

	return (
		<div className="manage-dpay-purchases">

			<PageHeader>
				<div>
					<h3>
						Manage DPAY Purchases
					</h3>

					<p>
						On this page you can see a list of all the attempts by customers to purchase DPAY using USD.
					</p>
				</div>
			</PageHeader>

			<div className="manage-admins_table">
				<div>
					<label>
						Search
					</label>
					<input
						value={search}
						placeholder="Search for name or email of the user..."
						onChange={searchOnChange}
					/>
					<br/>
					<FrameButton
						color="lightBlue"
						onClick={handleSubmitSearch}
					>
						Search
					</FrameButton>
					<br/>
				</div>

				<FrameOneTableContainer
					data={dpayPurchases?.dpayPurchases}
					pagination={{
						...dpayPurchases?.paginationInfo,
						...omit(frontendPagination, "frontendRenderKey"),
					}}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "user",
							headerValue: "User ID",
						},
						{
							key: "isComplete",
							headerValue: "Completed?",
							valueFormatter: (isComplete) => isComplete ? "Yes" : "No",
						},
						{
							key: "price",
							headerValue: "Price",
						},
						{
							key: "fiat",
							headerValue: "USD Amount",
						},
						{
							key: "dpay",
							headerValue: "DPAY Amount",
						},
						{
							key: "paymentIntentId",
							headerValue: "Stripe Payment",
							valueFormatter: (paymentIntentId) => {
								if (!paymentIntentId) {
									return "";
								}
								return (
									<a
										href={(stripeTestMode) ?
											`https://dashboard.stripe.com/test/payments/${paymentIntentId}` :
											`https://dashboard.stripe.com/payments/${paymentIntentId}`
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										{paymentIntentId}
									</a>
								);
							}
						},
						{
							key: "dpayTransaction",
							headerValue: "DPAY Transaction ID",
						},
						{
							key: "errorMessage",
							headerValue: "Error",
						},
						{
							key: "updatedAt",
							headerValue: "Updated",
							valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
						},
						{
							key: "expiresAt",
							headerValue: "Expiry",
							valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
						},
					]}
				/>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
		}
	});
}

type ManageDpayPurchasesProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageDpayPurchases);
