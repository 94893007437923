import {ChangeEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import {GetNftTrackersResponse, NftGrouping, NftsApi, NftTracker, PaginationInfo, Token} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import React from "react";
import FrameButton from "../components/buttons/FrameButton";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment/moment";
import AddNftTrackerModal from "../components/modals/AddNftTrackerModal";
import FrameOneTableCellLink from "../components/tables/cells/FrameOneTableCellLink";
import {parseChain} from "../utils/parseChain";
import {getBlockExplorerUrl} from "../utils/getBlockExplorerUrl";
import {CellContextDetails} from "frame-one-table/build/contextTypes";
import {omit} from "lodash";
import ManageNftTrackersEditCell from "../components/tables/cells/ManageNftTrackersEditCell";
import ManageNftTrackersDeleteCell from "../components/tables/cells/ManageNftTrackersDeleteCell";
import ManageNftTrackersRefreshOwnershipsCell from "../components/tables/cells/ManageNftTrackersRefreshOwnershipsCell";
import ManageNftTrackersForceRefreshSingleOwnershipModal
    from "../components/modals/ManageNftTrackersForceRefreshSingleOwnershipModal";
import {validateEnvForSimpleHash} from "../utils/validateEnvForSimpleHash";

interface StateProps {
    fullToken: Token;
}

function ManageNftTrackers(props: ManageNftTrackersProps): ReactElement {

    const [showNewTrackerModal, setShowNewTrackerModal] = useState(false);
    const [trackers, setTrackers] = useState<GetNftTrackersResponse>(undefined);
    const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
    const [search, setSearch] = useState<string>();
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false);


    useEffect(() => {
        readNftTrackers().then().catch();
    }, [JSON.stringify(frontendPagination)]);

    /**
     * Show or hide the Add NFT Tracker Modal.
     *
     */
    function toggleNewTrackerModal(): void {
        setShowNewTrackerModal(!showNewTrackerModal);
    }

    /**
     * Hide the Add NFT Tracker Modal when finished,
     * and call the api to get the updated list.
     *
     */
    function onDoneNewNftTrackerModal(): void {
        setShowNewTrackerModal(false);
        readNftTrackers().then().catch();
    }

    /**
     * Call our api to get the list of trackers and save to state.
     *
     */
    async function readNftTrackers(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            const res = await new NftsApi(getConfig(props.fullToken)).getNftTrackers({
                limit: frontendPagination.limit,
                offset: frontendPagination.offset,
                search
            });
            setTrackers(res);
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    /**
     * Render anchor tags for links.
     *
     * @param value
     * @param data
     */
    function renderLinkCell(value: string, data: CellContextDetails<NftTracker>): ReactElement {
        return (
            <FrameOneTableCellLink
                link={`${getBlockExplorerUrl((data.row as NftTracker).evmChain)}/address/${value}`}
                display={value}
            />
        );
    }

    function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
        setSearch(e.target.value);
    }

    function handleSubmitSearch(): void {
        setFrontendPagination(p => {
            return {
                ...defaultFrontendPagination,
                frontendRenderKey: p.frontendRenderKey + 1,
            }
        });
    }

    function renderEditCell(tracker: NftTracker): ReactNode {
        return (
            <ManageNftTrackersEditCell
                tracker={tracker}
                onDone={readNftTrackers}
            />
        );
    }

    function renderRefreshOwnershipsCell(tracker: NftTracker): ReactNode {
        return (
            <ManageNftTrackersRefreshOwnershipsCell
                isUpdateAll={false}
                tracker={tracker}
                onDone={readNftTrackers}
            />
        );
    }


    function renderDeleteCell(tracker: NftTracker): ReactNode {
        return (
            <ManageNftTrackersDeleteCell
                tracker={tracker}
                onDone={readNftTrackers}
            />
        );
    }

    function toggleShowRefreshModal(): void {
        setShowRefreshModal(s => !s);
    }

    function onDoneRefresh(): void {
        setShowRefreshModal(false);
        readNftTrackers().then().catch();
    }

    return (
        <div className="manage-trackers">
            <ManageNftTrackersForceRefreshSingleOwnershipModal
                isUpdateAll={true}
                isOpen={showRefreshModal}
                toggle={onDoneRefresh}
            />
            <AddNftTrackerModal
                isOpen={showNewTrackerModal}
                onClose={toggleNewTrackerModal}
                onDone={onDoneNewNftTrackerModal}
            />

            <PageHeader>
                <div>
                    <h3>
                        Manage NFT Trackers
                    </h3>

                    <p>
                        Manage NFT Trackers on this page!
                        <span
                            style={{display: process.env.REACT_APP_ENVIRONMENT !== "production" ? "inline" : "none"}}
                        >
                          {" "}Please note that the force refresh all ownerships functionality is disabled in the testing environment by default. To force refresh all ownerships in the testing environment, please contact developers.
                        </span>
                    </p>
                    <div className="manage-trackers_button-container">
                        <FrameButton
                            color="lightBlue"
                            onClick={toggleNewTrackerModal}
                        >
                            Add New Tracker
                        </FrameButton>

                        <FrameButton
                            color="danger"
                            forwardProps={{
                                disabled: validateEnvForSimpleHash(),
                            }}
                            onClick={toggleShowRefreshModal}
                        >
                            Force Refresh All Ownerships
                        </FrameButton>
                    </div>
                </div>
            </PageHeader>

            <div className="manage-admins_table">
                <div>
                    <label>
                        Search
                    </label>
                    <input
                        value={search}
                        placeholder="Search over name and addresses."
                        onChange={searchOnChange}
                    />
                    <br/>
                    <FrameButton
                        color="lightBlue"
                        onClick={handleSubmitSearch}
                    >
                        Search
                    </FrameButton>
                    <br/>
                </div>

                <FrameOneTableContainer
                    data={trackers?.nftTrackers}
                    pagination={{
                        ...trackers?.paginationInfo,
                        ...omit(frontendPagination, "frontendRenderKey"),
                    } as PaginationInfo}
                    onPaginationChange={setFrontendPagination}
                    columnOptions={[
                        {
                            key: "contractAddress",
                            headerValue: "Contract Address",
                            cellRender: renderLinkCell,
                        },
                        {
                            key: "name",
                            headerValue: "Tracker Name",
                        },
                        {
                            key: "openSea",
                            headerValue: "OpenSea Link",
                            cellRender: (op: string) => {
                                if (op) {
                                    return (
                                        <a
                                            href={op}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {op}
                                        </a>
                                    );
                                }

                                return "-";
                            }
                        },
                        {
                            key: "grouping",
                            headerValue: "NFT Grouping",
                            valueFormatter: (g: NftGrouping) => g.name,
                        },
                        {
                            key: "evmChain",
                            headerValue: "EVM Chain",
                            valueFormatter: parseChain,
                        },
                        {
                            key: "createdAt",
                            headerValue: "Created",
                            valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
                        },
                        {
                            key: "updatedAt",
                            headerValue: "Last Updated",
                            valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
                        },
                        {
                            key: "id",
                            headerValue: "Tracker ID",
                        },
                        {
                            key: undefined,
                            headerValue: "Edit NFT Tracking",
                            cellRender: renderEditCell,
                        },
                        {
                            key: undefined,
                            headerValue: "Force Refresh NFT Ownerships",
                            cellRender: renderRefreshOwnershipsCell,
                        },
                        {
                            key: undefined,
                            headerValue: "Delete NFT Tracking",
                            cellRender: renderDeleteCell,
                        },
                    ]}
                />
            </div>
        </div>
    );
}

function connector() {
    return connect((store: IStore): StateProps => {
        return {
            fullToken: store.metaStore.fullToken,
        }
    });
}

type ManageNftTrackersProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageNftTrackers);
