import React, {ReactElement} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    url: string;
    tags: string[];
}

function SEOUpdateSuccessModal(props: Props): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
        >
            <FrameModalHeader
                toggle={props.onClose}
                title="SEO Updated Successfully"
            />
            <FrameModalBody>
                <p className="seo-update-success-modal_text">
                    SEO tags has been updated for {props.url}
                </p>
                <p>
                    Tags: {props.tags?.join(", ")}
                </p>
            </FrameModalBody>
            <FrameModalFooter>
                <FrameButton
                    color="success"
                    onClick={props.onClose}
                >
                    Ok
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
}

export default SEOUpdateSuccessModal;
