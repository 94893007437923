import {
	UpdateBusinessParentBody,
	Business,
	BusinessesApi,
	GetBusinessesOwnResponse,
	Token,
} from "@devour/client";
import React, {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import {IReactSelectOption, makeReactSelectOptions} from "../../utils/reactSelectHelpers";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";

const defaultFormValues: UpdateBusinessParentBody = {
	parentId: undefined,
	purgeUsers: false,
	purgeStripe: false,
}

interface StateProps {
	fullToken: Token;
}

interface Props {
	business: Business;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function ManageRestaurantsUpdateParentModal(props: ManageRestaurantsUpdateParentModalProps): ReactElement {

	const [formValues, setFormValues] = useState<UpdateBusinessParentBody>(defaultFormValues);
	const [selectedMerchantBusinesses, setSelectedMerchantBusinesses] = useState<{ merchant: string, businesses: GetBusinessesOwnResponse }>(undefined);

	useEffect(() => {
		if (props.isOpen) {
			void getDataForDropDowns();
			setFormValues({
				parentId: props.business.parent,
			});
		}
	}, [props.isOpen]);

	/**
	 * Get the list of merchant accounts for inflating the drop-down/select input.
	 *
	 */
	async function getDataForDropDowns(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BusinessesApi(getConfig(props.fullToken)).getBusinessesOwn({
				limit: 100000,
				offset: 0,
			});

			setSelectedMerchantBusinesses({
				merchant: undefined,
				businesses: res,
			});
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Handle checkbox on change event.
	 *
	 */
	function togglePurgeUsers(): void {
		setFormValues({
			...formValues,
			purgeUsers: !formValues.purgeUsers,
		});
	}

	/**
	 * Handle checkbox on change event.
	 *
	 */
	function togglePurgeStripe(): void {
		setFormValues({
			...formValues,
			purgeStripe: !formValues.purgeStripe,
		});
	}

	/**
	 * Handle the parent business id react-select input onChange event.
	 *
	 * @param v
	 */
	function handleParentBusinessIdOnChange(v: IReactSelectOption): void {
		setFormValues({
			...formValues,
			parentId: v?.value,
		});
	}

	/**
	 * Submit the new restaurant/business into the system & close modal.
	 *
	 * @param e
	 */
	async function submitUpdateBusiness(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new BusinessesApi(getConfig(props.fullToken)).updateBusinessParent({
				id: props.business.id,
				updateBusinessParentBody: formValues,
			});

			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	const parentBusinessOptions = makeReactSelectOptions<Business>(selectedMerchantBusinesses?.businesses?.businesses, "id", "name");

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="manage-restaurants-update-parent-modal"
		>
			<FrameModalHeader
				title="Update Business Parent"
				toggle={props.onClose}
			/>

			<form onSubmit={submitUpdateBusiness}>
				<FrameModalBody className="manage-restaurants-update-parent-modal_body">
					<div>
						<label>
							Parent Business
						</label>
						<FrameOneReactSelect
							key="business-parent"
							name="Parent Business"
							placeholder="Select Parent Business..."
							isClearable={true}
							value={parentBusinessOptions.find(b => b.value === formValues.parentId)}
							onChange={handleParentBusinessIdOnChange}
							options={parentBusinessOptions}
						/>
					</div>

					<div>
						<FrameOneCheckbox
							onToggle={togglePurgeUsers}
							checked={formValues.purgeUsers}
						>
							Purge user permissions
						</FrameOneCheckbox>
						<p className="form-tip">
							Remove access for all merchant users directly linked to {props.business.name} and all
							children / descendent businesses.
							Merchant users of the parent business and all ancestor businesses will still have access,
							and can re-add other merchant users as needed.
						</p>
					</div>

					<div>
						<FrameOneCheckbox
							onToggle={togglePurgeStripe}
							checked={formValues.purgeStripe}
						>
							Purge Stripe link
						</FrameOneCheckbox>
						<p className="form-tip">
							Remove Stripe account link for {props.business.name} and all children / descendent
							businesses.
							Stripe payments will be paid to DevourGO's Stripe account until new Stripe accounts are
							linked.
						</p>
					</div>

				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="lightBlue"
						forwardProps={{
							type: "submit"
						}}
					>
						Confirm
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props & StateProps => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type ManageRestaurantsUpdateParentModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageRestaurantsUpdateParentModal);
