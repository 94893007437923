import React, {ChangeEvent, ChangeEventHandler, ReactElement} from "react";
import {ApiResponseType} from "@devour/client";
import FrameButton from "./buttons/FrameButton";
import moment from "moment";
import {cloneDeep} from "lodash";
import FrameOneDatePicker from "./inputs/FrameOneDatePicker";

const apiResponsesErrorTypes: Array<ApiResponseType> = [
	ApiResponseType.CHOWLYERRORMENU,
	ApiResponseType.ITSACHECKMATEERRORMENU,
	ApiResponseType.ITSACHECKMATEERRORACCESS,
	ApiResponseType.ITSACHECKMATEERRORACTIVATE,
	ApiResponseType.ITSACHECKMATEERRORLOCATION,
	ApiResponseType.ITSACHECKMATEERRORREFRESH,
	ApiResponseType.MEALMEERRORINVENTORY,
	ApiResponseType.MEALMEERRORPRODUCTDETAILS,
	ApiResponseType.MEALMEERRORINVENTORYQUOTE,
];

export interface ApiResponsesFilters {
	types: Array<ApiResponseType>;
	business: string;
	orderId: string;
	dateStart: number;
	dateEnd: number;
}

interface Props {
	filters: ApiResponsesFilters,
	onUpdate: (filters: ApiResponsesFilters) => void;
}

function FilterApiResponsesForm(props: Props): ReactElement {

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof ApiResponsesFilters): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			props.onUpdate({
				...props.filters,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Submit filters to query.
	 *
	 * @param e
	 */
	function submitFilters(e?: React.FormEvent): void {
		e?.preventDefault();
		props.onUpdate(props.filters);
	}

	/**
	 * Handle API type change.
	 *
	 * @param e
	 */
	function typesOnChange(e: ChangeEvent<HTMLSelectElement>): void {
		const type: ApiResponseType = e.target.value as ApiResponseType;
		const newTypes: Array<ApiResponseType> = cloneDeep(props.filters.types);

		const thisIndex = newTypes.indexOf(type);

		if (thisIndex > -1) {
			// Remove this option from list of selected items
			newTypes.splice(thisIndex, 1);
		} else {
			newTypes.push(type);
		}
		props.onUpdate({
			...props.filters,
			types: newTypes,
		});
	}

	function statusClear() {
		props.onUpdate({
			...props.filters,
			types: [],
		});
	}

	function statusErrors() {
		props.onUpdate({
			...props.filters,
			types: apiResponsesErrorTypes,
		});
	}

	function statusAll() {
		props.onUpdate({
			...props.filters,
			types: Object.values(ApiResponseType),
		});
	}

	function dateStartOnChange(dateStart: Date): void {
		props.onUpdate({
			...props.filters,
			dateStart: (dateStart) ? moment(dateStart).startOf("day").valueOf() : undefined,
		});
	}

	function dateEndOnChange(dateEnd: Date): void {
		props.onUpdate({
			...props.filters,
			dateEnd: (dateEnd) ? moment(dateEnd).startOf("day").valueOf() : undefined,
		});
	}

	return (
		<div className="filter-api-responses-form">
			<form onSubmit={submitFilters}>
				<div className="filter-api-responses-form_body">
					<div className="filter-api-responses-form_inputs">
						<div className="filter-api-responses-form_inputs_dates">
							<div className="filter-api-responses-form_inputs_dates_item">
								<label>Start Date</label>
								<FrameOneDatePicker
									oneTap={true}
									ranges={[]}
									onChange={dateStartOnChange}
									cleanable={true}
									caretAs={undefined}
									value={props.filters.dateStart ? moment(props.filters.dateStart).startOf("day").toDate() : undefined}
								/>
							</div>
							<div className="filter-api-responses-form_inputs_dates_item">
								<label>End Date</label>
								<FrameOneDatePicker
									oneTap={true}
									ranges={[]}
									onChange={dateEndOnChange}
									cleanable={true}
									caretAs={undefined}
									value={props.filters.dateEnd ? moment(props.filters.dateEnd).startOf("day").toDate() : undefined}
								/>
							</div>
						</div>
						<div className="filter-api-responses-form_inputs_item">
							<label>Order ID</label>
							<input
								value={props.filters.orderId}
								placeholder="Order ID..."
								onChange={inputOnChange("orderId")}
							/>
						</div>
						<div className="filter-api-responses-form_inputs_item">
							<label>Business</label>
							<input
								value={props.filters.business}
								placeholder="Business..."
								onChange={inputOnChange("business")}
							/>
						</div>
					</div>
					<div className="filter-api-responses-form_status">
						<label>Types</label>
						<select
							value={props.filters.types}
							onChange={typesOnChange}
							multiple={true}
						>
							{Object.values(ApiResponseType).map((type) => (
								<option key={type} value={type}>
									{type}
								</option>
							))}
						</select>

						<div className="filter-api-responses-form_types_buttons">

							<FrameButton
								color="lightGray"
								forwardProps={{type: "button"}}
								onClick={statusClear}
							>
								Clear types
							</FrameButton>
							<FrameButton
								color="lightGray"
								forwardProps={{type: "button"}}
								onClick={statusErrors}
							>
								Select errors
							</FrameButton>
							<FrameButton
								color="lightGray"
								forwardProps={{type: "button"}}
								onClick={statusAll}
							>
								Select all types
							</FrameButton>
						</div>

					</div>
				</div>
			</form>
		</div>
	);
}

export default FilterApiResponsesForm;
