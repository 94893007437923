import React, {ChangeEventHandler, ReactElement, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {MenuOrdersApi, OrderRefundBody, Token, MenuOrder, OrderRefundReason, OrderRefundResponsibility} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";
import MenuOrderPricing from "../menuOrders/MenuOrderPricing";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";

const defaultFormValues: OrderRefundBody = {
	reason: undefined,
	responsibility: undefined,
	notes: "",
	fiat: 0,
	dpay: 0,
	rewardsClawback: 0,
	responsiblePartyAmount: 0,
	transferReverseAmount: 0,
	discountNullify: false,
};

interface StateProps {
	fullToken: Token;
}

interface Props {
	menuOrder: MenuOrder;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

function RefundMenuOrderModal(props: RefundMenuOrderModalProps): ReactElement {

	const [formValues, setFormValues] = useState<OrderRefundBody>(defaultFormValues);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setFormValues(defaultFormValues);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof OrderRefundBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
		return (e) => {
			setFormValues({
				...formValues,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * On full amount refunds.
	 */
	function toggleDiscountNullify(): void {
		setFormValues({
			...formValues,
			discountNullify: !formValues.discountNullify,
		});
	}

	/**
	 * On full amount refunds.
	 */
	function onFullAmount(): void {
		setFormValues({
			...formValues,
			discountNullify: true,
			fiat: props.menuOrder.stripeTotal,
			dpay: props.menuOrder.dpay,
			rewardsClawback: Math.round(props.menuOrder.dpayRewardsIndustry + props.menuOrder.dpayRewardsPayment),
			transferReverseAmount: (props.menuOrder.paymentTransferId) ? props.menuOrder.transferAmount : 0,
		});
	}

	/**
	 * Call api to create new admin, reset form & close modal on success.
	 *
	 */
	async function submitAddNewAdmin(e?: React.FormEvent): Promise<void> {
		e?.preventDefault();

		// Do some soft checks to warn of any obvious issues with refunds
		if (Number(formValues.transferReverseAmount) > 0) {
			if (formValues.responsibility !== OrderRefundResponsibility.MERCHANT &&
				!window.confirm("The indicated responsible party is not a merchant, but you are reversing a payment given to the merchant. Are you sure?")) {
				return;
			} else if (Number(formValues.transferReverseAmount) > Number(formValues.responsiblePartyAmount) &&
				!window.confirm("The transfer reversal is exceeding the indicated responsible party amount. Are you sure?")) {
				return;
			}
		}

		props.dispatch(incrementLoading());

		try {
			await new MenuOrdersApi(getConfig(props.fullToken)).refundMenuOrder({
				id: props.menuOrder.id,
				orderRefundBody: {
					...formValues,
					fiat: Number(formValues.fiat),
					dpay: Number(formValues.dpay),
					rewardsClawback: Number(formValues.rewardsClawback),
					responsiblePartyAmount: Number(formValues.responsiblePartyAmount),
					transferReverseAmount: Number(formValues.transferReverseAmount),
				},
			});

			setFormValues(defaultFormValues);
			props.onDone();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={closeHelper}
			size="xl"
			contentClassName="refund-menu-order-modal"
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Refund Menu Order"
			/>

			<form onSubmit={submitAddNewAdmin}>
				<FrameModalBody className="refund-menu-order-modal_body">
					<div className="refund-menu-order-modal_row">
						<div className="refund-menu-order-modal_row_pricing">
							<MenuOrderPricing menuOrder={props.menuOrder}/>
						</div>
						<div className="refund-menu-order-modal_row_form">
							<div className="refund-menu-order-modal_body_full-button">
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="purple"
									onClick={onFullAmount}
									forwardProps={{
										type: "button",
									}}
								>
									Use full order amount
								</FrameButton>
							</div>

							<div className="refund-menu-order-modal_body_field">
								<label>
									Refund Reason *
								</label>
								<select
									value={formValues.reason}
									onChange={inputOnChange("reason")}
									required={true}
								>
									<option value="" selected disabled>Select Refund Reason</option>
									<option value={OrderRefundReason.WRONGORDERRECEIVED}>{OrderRefundReason.WRONGORDERRECEIVED}</option>
									<option value={OrderRefundReason.ORDERNEVERARRIVED}>{OrderRefundReason.ORDERNEVERARRIVED}</option>
									<option value={OrderRefundReason.ITEMQUALITYISSUES}>{OrderRefundReason.ITEMQUALITYISSUES}</option>
									<option value={OrderRefundReason.MISSINGORINCORRECTITEMS}>{OrderRefundReason.MISSINGORINCORRECTITEMS}</option>
									<option value={OrderRefundReason.ORDERARRIVEDLATE}>{OrderRefundReason.ORDERARRIVEDLATE}</option>
									<option
										value={OrderRefundReason.THIRDPARTYISSUED}>{OrderRefundReason.THIRDPARTYISSUED}</option>
									<option value={OrderRefundReason.OTHER}>{OrderRefundReason.OTHER}</option>
								</select>
							</div>

							<div className="refund-menu-order-modal_body_field">
								<label>
									Responsible Party *
								</label>
								<select
									value={formValues.responsibility}
									onChange={inputOnChange("responsibility")}
									required={true}
								>
									<option value="" selected disabled>Select Responsible Party</option>
									<option
										value={OrderRefundResponsibility.DEVOUR}>{OrderRefundResponsibility.DEVOUR}</option>
									<option
										value={OrderRefundResponsibility.MERCHANT}>{OrderRefundResponsibility.MERCHANT}</option>
									<option
										value={OrderRefundResponsibility.DELIVERYPROVIDER}>{OrderRefundResponsibility.DELIVERYPROVIDER}</option>
								</select>
							</div>

							<div className="refund-menu-order-modal_body_field">
								<label>
									Responsible Party Amount *
								</label>
								<input
									value={formValues.responsiblePartyAmount}
									placeholder="Responsible Party Amount..."
									onChange={inputOnChange("responsiblePartyAmount")}
									type="number"
									required={true}
									min={0}
									step={0.01}
									max={props.menuOrder.subtotal +
										props.menuOrder.tax +
										props.menuOrder.deliveryCharge +
										props.menuOrder.tipRestaurant +
										props.menuOrder.tipDelivery +
										Number.EPSILON}
								/>
							</div>

							<div className="refund-menu-order-modal_body_field">
								<label>
									Notes (may become available to customer)
								</label>
								<input
									value={formValues.notes}
									placeholder="Add notes..."
									onChange={inputOnChange("notes")}
									type="text"
								/>
							</div>

							<div className="refund-menu-order-modal_body_field">
								<label>
									Fiat Refund * (Max ${props.menuOrder.stripeTotal.toFixed(2)})
								</label>
								<input
									value={formValues.fiat}
									placeholder="Fiat Refund..."
									onChange={inputOnChange("fiat")}
									type="number"
									required={true}
									min={0}
									step={0.01}
									max={props.menuOrder.stripeTotal}
								/>
							</div>

							<div className="refund-menu-order-modal_body_field">
								<label>
									DPAY Refund * (Max {props.menuOrder.dpay} DPAY)
								</label>
								<input
									value={formValues.dpay}
									placeholder="DPAY Refund..."
									onChange={inputOnChange("dpay")}
									type="number"
									required={true}
									min={0}
									step={0.01}
									max={props.menuOrder.dpay}
								/>
							</div>

							<div className="refund-menu-order-modal_body_field">
								<label>
									DPAY Rewards Clawback *
									(Max {Math.round(props.menuOrder.dpayRewardsIndustry + props.menuOrder.dpayRewardsPayment)} DPAY)
								</label>
								<input
									value={formValues.rewardsClawback}
									placeholder="DPAY Rewards Clawback..."
									onChange={inputOnChange("rewardsClawback")}
									type="number"
									required={true}
									min={0}
									step={1}
									max={Math.round(props.menuOrder.dpayRewardsIndustry + props.menuOrder.dpayRewardsPayment)}
								/>
							</div>

							<div className="refund-menu-order-modal_body_field">
								<label>
									Transfer Reverse Amount *
									(Max
									${(props.menuOrder.paymentTransferId) ? props.menuOrder.transferAmount.toFixed(2) : 0})
								</label>
								<input
									value={formValues.transferReverseAmount}
									placeholder="Transfer Reverse Amount..."
									onChange={inputOnChange("transferReverseAmount")}
									type="number"
									required={true}
									min={0}
									step={0.01}
									max={(props.menuOrder.paymentTransferId) ? props.menuOrder.transferAmount : 0}
								/>
								<p className="form-tip">
									Take back money that Devour has transferred to the <strong>merchant
									(restaurant)</strong> on behalf of the customer.
								</p>
							</div>

							<div className="refund-menu-order-modal_body_field">
								<FrameOneCheckbox
									onToggle={toggleDiscountNullify}
									checked={formValues.discountNullify}
								>
									Nullify promos
								</FrameOneCheckbox>
								<p className="form-tip">
									Nullify the promos used in this order to allow the customer to reuse their "first
									order" and "single use" promos.
								</p>
							</div>

						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="danger"
						forwardProps={{
							type: "submit",
						}}
					>
						Refund Order
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type RefundMenuOrderModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(RefundMenuOrderModal);
