import {ReactElement, useState} from "react";
import {NftGrouping} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import ManageBannedCommunityUsersModal from "../../modals/ManageBannedCommunityUsersModal";

interface Props {
    grouping: NftGrouping;
    onDone: () => Promise<void>;
}

function ManageBannedCommunityUsersCell(props: Props): ReactElement {

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    function toggleShowDeleteModal(): void {
        setShowDeleteModal(s => !s);
    }

    function onDoneDelete(): void {
        setShowDeleteModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <ManageBannedCommunityUsersModal
                isOpen={showDeleteModal}
                onClose={onDoneDelete}
                grouping={props.grouping}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightBlue"
                    narrow={true}
                    onClick={toggleShowDeleteModal}
                >
                    Manage Banned Users
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default ManageBannedCommunityUsersCell;