import React, {ChangeEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import {Asset, GetNftGroupingsResponse, NftGrouping, NftsApi, PaginationInfo, Token} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import FrameButton from "../components/buttons/FrameButton";
import PageHeader from "../components/PageHeader";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import AddNftGroupingModal from "../components/modals/AddNftGroupingModal";
import moment from "moment";
import FrameOneTableCellLink from "../components/tables/cells/FrameOneTableCellLink";
import FrameOneTableCellImagePreview from "../components/tables/cells/FrameOneTableCellImagePreview";
import {omit} from "lodash";
import ManageNftGroupingsEditCell from "../components/tables/cells/ManageNftGroupingsEditCell";
import ManageNftGroupingsDeleteCell from "../components/tables/cells/ManageNftGroupingsDeleteCell";
import ManageNftGroupingsEditEligibilityCell from "../components/tables/cells/ManageNftGroupingsEditEligibilityCell";
import ManageCommunityCell from "../components/tables/cells/ManageCommunityCell";
import ManageBannedCommunityUsersCell from "../components/tables/cells/ManageBannedCommunityUsers";
import HistoricCommunityRankingCell from "../components/tables/cells/HistoricCommunityRankingCell";

interface StateProps {
    fullToken: Token;
}

function ManageNftGroupings(props: ManageNftGroupingsProps): ReactElement {

    const [showNewGroupingModal, setShowNewGroupingModal] = useState(false);
    const [groupings, setGroupings] = useState<GetNftGroupingsResponse>(undefined);
    const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
    const [search, setSearch] = useState<string>();

    useEffect(() => {
        readNftGroupings().then().catch();
    }, [JSON.stringify(frontendPagination)]);

    /**
     * Show or hide the Add NFT Grouping Modal.
     *
     */
    function toggleNewGroupingModal(): void {
        setShowNewGroupingModal(!showNewGroupingModal);
    }

    /**
     * Hide the Add NFT Grouping Modal when finished,
     * and call the api to get the updated list.
     *
     */
    function onDoneNewNftGroupingModal(): void {
        setShowNewGroupingModal(false);
        readNftGroupings().then().catch();
    }

    /**
     * Call the api to get the list of nft groupings and save to state.
     *
     */
    async function readNftGroupings(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            const res = await new NftsApi(getConfig(props.fullToken)).getNftGroupings({
                limit: frontendPagination.limit,
                offset: frontendPagination.offset,
                search,
            });

            setGroupings(res);
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    /**
     * Render asset cells for image preview.
     *
     * @param asset
     */
    function renderAssetCell(asset?: Asset): ReactElement {
        return (
            <FrameOneTableCellImagePreview
                asset={asset}
            />
        );
    }

    /**
     * Render anchor tags for links.
     *
     * @param value
     */
    function renderLinkCell(value: string): ReactElement {
        return (
            <FrameOneTableCellLink link={value}/>
        );
    }

    function searchOnChange(e: ChangeEvent<HTMLInputElement>) {
        setSearch(e.target.value);
    }

    function handleSubmitSearch(): void {
        setFrontendPagination(p => {
            return {
                ...defaultFrontendPagination,
                frontendRenderKey: p.frontendRenderKey + 1,
            }
        });
    }

    function renderEditCell(grouping: NftGrouping): ReactElement {
        return (
            <ManageNftGroupingsEditCell
                grouping={grouping}
                onDone={readNftGroupings}
            />
        );
    }

    function renderEditEligibilityCell(grouping: NftGrouping): ReactElement {
        return (
            <ManageNftGroupingsEditEligibilityCell
                grouping={grouping}
                onDone={readNftGroupings}
            />
        );
    }

    function renderBannedCommunityUsersCell(grouping: NftGrouping): ReactElement {
        return (
            <ManageBannedCommunityUsersCell
                grouping={grouping}
                onDone={readNftGroupings}
            />
        )
    }

    function renderHistoricCommunityRankingCell(grouping: NftGrouping): ReactElement {
        return (
            <HistoricCommunityRankingCell
                grouping={grouping}
                onDone={readNftGroupings}
            />
        )
    }

    function renderDeleteCell(grouping: NftGrouping): ReactElement {
        return (
            <ManageNftGroupingsDeleteCell
                grouping={grouping}
                onDone={readNftGroupings}
            />
        );
    }

    function renderManageCommunityCell(grouping: NftGrouping): ReactElement {
        return (
            <ManageCommunityCell
                grouping={grouping}
                onDone={readNftGroupings}
            />
        );
    }

    return (
        <div className="manage-groupings">
            <AddNftGroupingModal
                isOpen={showNewGroupingModal}
                onClose={toggleNewGroupingModal}
                onDone={onDoneNewNftGroupingModal}
            />

            <PageHeader>
                <div>
                    <h3>
                        Manage NFT Groupings & Communities
                    </h3>

                    <p>
                        Manage NFT Groupings and communities on this page
                    </p>

                    <FrameButton
                        color="lightBlue"
                        onClick={toggleNewGroupingModal}
                    >
                        Add New Grouping
                    </FrameButton>
                </div>
            </PageHeader>

            <div className="manage-admins_table">
                <div>
                    <label>
                        Search
                    </label>
                    <input
                        value={search}
                        placeholder="Search over name, description and urls."
                        onChange={searchOnChange}
                    />
                    <br/>
                    <FrameButton
                        color="lightBlue"
                        onClick={handleSubmitSearch}
                    >
                        Search
                    </FrameButton>
                    <br/>
                </div>

                <FrameOneTableContainer
                    data={groupings?.nftGroupings}
                    pagination={{
                        ...groupings?.paginationInfo,
                        ...omit(frontendPagination, "frontendRenderKey"),
                    } as PaginationInfo}
                    onPaginationChange={setFrontendPagination}
                    columnOptions={[
                        {
                            key: "hero",
                            headerValue: "Image",
                            cellRender: renderAssetCell,
                        },
                        {
                            key: "name",
                            headerValue: "Grouping Name",
                        },
                        {
                            key: "sortPriority",
                            headerValue: "Sort Priority"
                        },
                        {
                            key: "description",
                            headerValue: "Description",
                            cellRender: (d: string) => {
                                return (
                                    <div className="table-description-field">
                                        {d}
                                    </div>
                                );
                            }
                        },
                        {
                            key: "discordUrl",
                            headerValue: "Discord URL",
                            cellRender: renderLinkCell,
                        },
                        {
                            key: "twitterUrl",
                            headerValue: "Twitter URL",
                            cellRender: renderLinkCell,
                        },
                        {
                            key: "websiteUrl",
                            headerValue: "Website URL",
                            cellRender: renderLinkCell,
                        },
                        {
                            key: "createdAt",
                            headerValue: "Created",
                            valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
                        },
                        {
                            key: "updatedAt",
                            headerValue: "Last Updated",
                            valueFormatter: (d: number) => moment(d).format("MMM DD YYYY hh:mma"),
                        },
                        {
                            key: "id",
                            headerValue: "Grouping ID",
                        },
                        {
                            key: undefined,
                            headerValue: "Edit NFT Grouping",
                            cellRender: renderEditCell,
                        },
                        {
                            key: undefined,
                            headerValue: "Manage Community",
                            cellRender: renderManageCommunityCell,
                        },
                        {
                            key: undefined,
                            headerValue: "Community Historic Rankings",
                            cellRender: renderHistoricCommunityRankingCell,
                        },
                        {
                            key: undefined,
                            headerValue: "Edit Leaderboard Eligibility",
                            cellRender: renderEditEligibilityCell,
                        },
                        {
                            key: undefined,
                            headerValue: "Users Banned From Community",
                            cellRender: renderBannedCommunityUsersCell,
                        },
                        {
                            key: undefined,
                            headerValue: "Delete NFT Grouping",
                            cellRender: renderDeleteCell,
                        },
                    ]}
                />
            </div>
        </div>
    );
}

function connector() {
    return connect((store: IStore): StateProps => {
        return {
            fullToken: store.metaStore.fullToken,
        }
    });
}

type ManageNftGroupingsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageNftGroupings);
