import React, {ReactElement, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {GetTransactionsResponse, Token, Transaction, TransactionsApi, TransactionType, Withdraw} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import getConfig from "../../utils/getConfig";
import {addError} from "../../redux/meta/MetaActions";
import SectionLabel from "../SectionLabel";
import FrameOneCard from "../FrameOneCard";

interface StateProps {
    fullToken: Token;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    withdraw: Withdraw;
}

function UserDPayModal(props: UserDPayModalProps): ReactElement {

    const [transactionsResponse, setTransactionsResponse] = useState<GetTransactionsResponse>();

    function closeHelper(): void {
        props.onClose();
        setTransactionsResponse(undefined);
    }

    useEffect(() => {
        if (props.isOpen) {
            new TransactionsApi(getConfig(props.fullToken)).getTransactions({id: props.withdraw.user.id})
                .then(setTransactionsResponse)
                .catch(async (err) => {
                    props.dispatch(await addError(err));
                });
        }
    }, [props.isOpen])

    function renderTransaction(transaction: Transaction) {

        const date = new Date(transaction.createdAt);

        return (
            <FrameOneCard key={transaction.id} className="user-experience-modal_transaction-entry">
                <div>
                    <h6>{date.toLocaleString()}</h6>
                    <p>{transaction.subject}</p>
                </div>
                <div>
                    <h6>{transaction.type === TransactionType.DEBIT ? "+" : "-"} {transaction.amount} DPAY</h6>
                    <p>{transaction.notes}</p>
                </div>
            </FrameOneCard>
        )
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={closeHelper}
            size="sm"
            contentClassName="user-dpay-modal"
        >
            <FrameModalHeader
                toggle={closeHelper}
                title="User DPay"
            />
            <FrameModalBody className="user-dpay-modal_body">
                {
                    transactionsResponse == null ?
                        <div className="spinner"/> :
                        (
                            <React.Fragment>
                                <SectionLabel title="Balance"
                                              titleContainerClassName="manage-users-password-modal_section-title-container">
                                    <p>{transactionsResponse.balance}</p>
                                </SectionLabel>
                                <SectionLabel title="Current DPay Price"
                                              titleContainerClassName="manage-users-password-modal_section-title-container">
                                    <p>${transactionsResponse.dPayPricePerUsd}</p>
                                </SectionLabel>
                                <SectionLabel title="Balance in USD"
                                              titleContainerClassName="manage-users-password-modal_section-title-container">
                                    <p>${transactionsResponse.balanceInUsd}</p>
                                </SectionLabel>
                                {
                                    transactionsResponse.transactions.length > 0 ?
                                        transactionsResponse.transactions.map(renderTransaction) :
                                        <p><b>There are no DPay transactions for this user.</b></p>
                                }
                            </React.Fragment>
                        )
                }
            </FrameModalBody>
        </FrameOneModal>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.metaStore.fullToken,
            ...props,
        }
    });
}

type UserDPayModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(UserDPayModal);
