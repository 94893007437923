import {ReactElement, useState} from "react";
import {Business} from "@devour/client";
import React from "react";
import FrameButton from "../../buttons/FrameButton";
import EditRestaurantModal from "../../modals/EditRestaurantModal";

interface Props {
	business: Business;
	onDone: () => Promise<void>;
}

function BusinessesEditCell(props: Props): ReactElement {

	const [showEditModal, setShowEditModal] = useState(false);

	function toggleEditModal(): void {
		setShowEditModal(s => !s);
	}

	function onDoneEdit(): void {
		setShowEditModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<EditRestaurantModal
				isOpen={showEditModal}
				business={props.business}
				onClose={toggleEditModal}
				onDone={onDoneEdit}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="lightBlue"
					onClick={toggleEditModal}
				>
					Edit Business
				</FrameButton>
			</div>
		</React.Fragment>
	);
}

export default BusinessesEditCell;
