import React, {ReactElement, useEffect, useState} from "react";
import LoginProjectInfoLabel from "./LoginProjectInfoLabel";
import LoginFrameOneInfo from "./LoginFrameOneInfo";
import {UtilsApi, VersionResponse} from "@devour/client";

function LoginFooter(): ReactElement {

	const [version, setVersion] = useState<VersionResponse>()

	useEffect(() => {
		new UtilsApi().version()
			.then(setVersion)
			.catch(console.error);
	}, [])

	return (
		<div className="login-footer">
			<div className="login-footer_project-info">
				<LoginProjectInfoLabel
					name={process.env.REACT_APP_PROJECT_NAME}
					version={version?.version}
				/>
			</div>

			<div className="login-footer_frame-one">
				<LoginFrameOneInfo/>
			</div>
		</div>
	);
}

export default LoginFooter;
