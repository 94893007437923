import React, {ReactElement} from "react";
import {GetTotalDpayResponse,} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import SectionLabel from "../SectionLabel";


interface ViewTotalVdpayModalProps {
    isOpen: boolean;
    onClose: () => void;
    data: GetTotalDpayResponse;
}

function ViewTotalVdpayModal(props: ViewTotalVdpayModalProps): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="user-experience-modal"
        >
            <FrameModalHeader
                toggle={props.onClose}
                title="Total VDPAY of all users"
            />
            <FrameModalBody className="user-experience-modal_body">
                {props.data == null ? (
                    <div className="spinner"/>
                ) : (
                    <div>
                        <b>Note:</b>
                        <p> The displayed totals exclude users with "@devour" or "@frameonesoftware" in their
                            email. </p>
                        <div className="manage-user-referrals_line"></div>
                        <br/>
                        <SectionLabel
                            title="Total VDPAY of all users"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{props.data.vDpayTotal} VDPAY</p>
                        </SectionLabel>

                        <SectionLabel
                            title="Total in USD"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>${props.data.totalInUsd} USD</p>
                        </SectionLabel>

                    </div>
                )}
            </FrameModalBody>
        </FrameOneModal>
    );
}


export default ViewTotalVdpayModal;
