import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {Brand, BrandMap, BrandsApi, GetBrandMapsResponse} from "@devour/client";
import getConfig from "../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import FrameButton from "../components/buttons/FrameButton";
import {TfiMapAlt} from "react-icons/tfi";
import BrandMapCard from "../components/brands/BrandMapCard";
import FrameOnePaginator, {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";

function ManageBrandMapsPage() {
    const {brandId} = useParams<{brandId?: string}>();
    const history = useNavigate();
    const [brand, setBrand] = useState<Brand>(undefined);
    const [maps, setMaps] = useState<GetBrandMapsResponse>(undefined);
    const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);

    useEffect(() => {
        if (!brandId) {
            history("/manage-brands");
            return;
        }

        void getBrandMaps();
    }, [brandId, JSON.stringify(frontendPagination)]);


    /**
     * Gets the all the maps brand combo & saves them to state.
     *
     */
    async function getBrandMaps(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const brandRes = await new BrandsApi(getConfig()).getBrand({
                id: brandId,
            });
            const mapsRes = await new BrandsApi(getConfig(fullToken)).getBrandMaps({
                id: brandId,
                offset: frontendPagination.offset,
                limit: frontendPagination.limit,
            });

            setBrand(brandRes);
            setMaps(mapsRes);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderBrandMapCard(brandMap: BrandMap, i: number): ReactElement {
        return (
            <div
                key={`brand-${i}`}
                className="manage-brand-maps_content_map-list_card"
            >
                <BrandMapCard brandMap={brandMap}/>
            </div>
        )
    }

    return (
        <div className="manage-brand-maps">
            <div className="manage-brand-maps_spacer-top">
                <div className="auth-header-logo">
                    <img
                        src={process.env.REACT_APP_CDN_URL + "/images/DevourGO_Final-01.webp"}
                        alt="DevourGO Logo"
                    />
                </div>
            </div>

            <div className="manage-brand-maps_content">
                {(!maps || !brand) ? (
                    <div className="manage-brand-maps_content_loading">
                        <div className="spinner"/>
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="manage-brand-maps_content_header">
                            <h3>
                                {`Viewing Maps for ${brand?.name}`}
                            </h3>

                            <FrameButton
                                color="purple"
                                size="normal"
                                to={`/manage-brands/${brandId}/maps/create`}
                            >
                                Create Map
                            </FrameButton>
                        </div>

                        <div className="manage-brand-maps_content_map-list">
                            {maps?.brandMaps.length > 0 ? maps?.brandMaps.map(renderBrandMapCard) : (
                                <div className="manage-brand-maps_content_map-list_empty">
                                    <TfiMapAlt className="manage-brand-maps_content_map-list_empty_icon"/>

                                    <h4 className="manage-brand-maps_content_map-list_empty_message">
                                        You haven't created any maps for this brand yet.
                                        <br/>
                                        Use the "Create Map" button above to get started!
                                    </h4>
                                </div>
                            )}
                        </div>
                        <FrameOnePaginator
                            {...maps?.paginationInfo}
                            onPaginationChange={setFrontendPagination}
                        />
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default ManageBrandMapsPage;