import React, {ReactElement, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {User} from "@devour/client";
import ManageUsersToggleEnableModal from "../../modals/ManageUsersToggleEnableModal";
import UserExperienceModal from "../../modals/UserExperienceModal";
import ManageUserDebitCreditDpayModal from "../../modals/ManageUserDebitCreditDpayModal";

interface Props {
    user: User;
}

function DebitOrCreditDpayCell(props: Props): ReactElement {

    const [showModal, setShowModal] = useState(false);

    /**
     * Hide or Show the toggle modal.
     *
     */
    function toggleShowToggleModal(): void {
        setShowModal(!showModal);
    }

    /**
     * Hide the modal when done. Run any additional action if needed after.
     *
     */
    function onDoneDpayTransaction(): void {
        setShowModal(false);
    }

    return (
        <React.Fragment>
            <ManageUserDebitCreditDpayModal
                isOpen={showModal}
                user={props.user}
                onClose={toggleShowToggleModal}
                onDone={onDoneDpayTransaction}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    color="lightGray"
                    onClick={toggleShowToggleModal}
                >
                    Debit or Credit Dpay
                </FrameButton>
            </div>
        </React.Fragment>
    );
}

export default DebitOrCreditDpayCell;
