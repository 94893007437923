import React, {ReactElement, useEffect, useState} from "react";
import {
	MenuOrder,
	OrderDiscountType,
	OrderRefundResponsibility,
	Token,
	UtilsApi,
	MenuOrdersApi
} from "@devour/client";
import classNames from "classnames";
import {menuOrderFinalizedStatuses} from "./MenuOrderRefund";
import FrameButton from "../buttons/FrameButton";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface StateProps {
	fullToken: Token;
}

interface Props {
	menuOrder: MenuOrder;
	onDone?: () => void;
}

function MenuOrderPricing(props: MenuOrderPricingProps): ReactElement {
	const [stripeTestMode, setStripeTestMode] = useState<boolean>(false);

	useEffect(() => {
		getVersion().then().catch(console.error);
	}, []);

	async function getVersion() {
		const version = await new UtilsApi().version();
		let testMode: boolean = false;

		if (version.version.includes("Local") ||
			version.version.includes("develop") ||
			version.version.includes("staging1") ||
			version.version.includes("staging2")) {
			testMode = true;
		}
		setStripeTestMode(testMode);
	}

	async function submitTransfer() {
		if (props.menuOrder.refunds[0] && !window.confirm("This order has already been refunded. Are you sure?")) {
			return;
		}

		props.dispatch(incrementLoading());

		try {
			await new MenuOrdersApi(getConfig(props.fullToken)).transferPaymentMenuOrder({
				id: props.menuOrder.id,
			});
			if (props.onDone) {
				props.onDone();
			}
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function renderDiscounts(type: OrderDiscountType): JSX.Element {
		const discounts = props.menuOrder.discounts?.filter((discount) => discount.type === type);

		if (!discounts.length) {
			return null;
		}

		return (
			<div className="menu-order-pricing_discounts">
				{discounts.map((discount, index) => (
					<div
						key={`discount-${index}`}
						className="menu-order-pricing_discounts_item"
					>
						<div className="menu-order-pricing_discounts_item_label">
							({type}) {discount.label} (Origin: {discount.origin})
						</div>
						<div className="menu-order-pricing_row_value">
							- ${discount.amount?.toFixed(2)}
						</div>
					</div>
				))}
			</div>
		)
	}

	function renderServiceFees(): JSX.Element {

		if (!props.menuOrder.serviceFees?.length) {
			return null;
		}

		return (
			<div className="menu-order-pricing_service-fees">
				{props.menuOrder.serviceFees.map((serviceFee, index) => (
					<div
						key={`discount-${index}`}
						className="menu-order-pricing_service-fees_item"
					>
						<div className="menu-order-pricing_service-fees_item_label">
							{serviceFee.type}
						</div>
						<div className="menu-order-pricing_row_value">
							${serviceFee.amount?.toFixed(2)}
						</div>
					</div>
				))}
			</div>
		)
	}

	function renderTransferRow() {
		if (props.menuOrder.transferAmount <= 0) {
			return null;
		} else if (props.menuOrder.paymentTransferId) {
			return (
				<div className="menu-order-pricing_payment">
					<strong>Stripe Transfer: </strong>
					<a
						href={(stripeTestMode) ?
							`https://dashboard.stripe.com/test/connect/transfers/${props.menuOrder.paymentTransferId}` :
							`https://dashboard.stripe.com/connect/transfers/${props.menuOrder.paymentTransferId}`
						}
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.menuOrder.paymentTransferId}
					</a>
				</div>
			)
		} else if (!props.menuOrder.business?.stripeAccountId) {
			return (
				<div className="menu-order-pricing_payment">
					<strong>Stripe Transfer: </strong>
					N/A (Business Stripe not connected)
				</div>
			)
		} else if (props.menuOrder.refunds[0]?.responsibility === OrderRefundResponsibility.MERCHANT) {
			return (
				<div className="menu-order-pricing_payment">
					<strong>Stripe Transfer: </strong>
					N/A (Menu order has been refunded)
				</div>
			)
		} else {
			return (
				<div className="menu-order-pricing_row">
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="purple"
						onClick={submitTransfer}
					>
						Transfer ${props.menuOrder.transferAmount.toFixed(2)} to {props.menuOrder.business.name}
					</FrameButton>
				</div>
			);
		}

	}

	return (
		<div className={classNames("menu-order-pricing", {
			"is-nullified": props.menuOrder.refunds[0]?.responsibility === OrderRefundResponsibility.SYSTEMCANCELLED,
			"is-paid": menuOrderFinalizedStatuses.includes(props.menuOrder.status),
			"is-unpaid": !menuOrderFinalizedStatuses.includes(props.menuOrder.status),
		})}>
			<h3>
				Pricing
			</h3>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Subtotal
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.subtotal?.toFixed(2)}
				</div>
			</div>

			{renderDiscounts(OrderDiscountType.ORDER)}

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Tax
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.tax?.toFixed(2)}
				</div>
			</div>

			{renderDiscounts(OrderDiscountType.SALESTAX)}

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Service Fees
				</div>
				<div className="menu-order-pricing_row_value">
				</div>
			</div>

			{renderServiceFees()}

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Delivery Charge
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.deliveryCharge?.toFixed(2)}
				</div>
			</div>

			{renderDiscounts(OrderDiscountType.DELIVERY)}

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Restaurant Tip
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.tipRestaurant?.toFixed(2)}
				</div>
			</div>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Delivery Tip
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.tipDelivery?.toFixed(2)}
				</div>
			</div>

			{renderDiscounts(OrderDiscountType.PAYMENT)}

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Stripe Total
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.stripeTotal?.toFixed(2)}
				</div>
			</div>

			<div className="menu-order-pricing_payment">
				<strong>Stripe Payment: </strong>
				{(props.menuOrder.paymentIntentId) ? (
					<a
						href={(stripeTestMode) ?
							`https://dashboard.stripe.com/test/payments/${props.menuOrder.paymentIntentId}` :
							`https://dashboard.stripe.com/payments/${props.menuOrder.paymentIntentId}`
						}
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.menuOrder.paymentIntentId}
					</a>
				) : " N/A"}
			</div>

			<hr/>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					<strong>Stripe fee:</strong><br/>
					(Stripe total * 2.9%) + $0.30
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.stripeFee?.toFixed(2)}
				</div>
			</div>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					<strong>Devour marketplace percentage:</strong><br/>
				</div>
				<div className="menu-order-pricing_row_value">
					{props.menuOrder.marketplacePercentage}%
				</div>
			</div>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					<strong>Devour marketplace fee:</strong><br/>
					(subtotal - merchant originated {OrderDiscountType.ORDER} discounts) * marketplace percentage
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.marketplaceFee?.toFixed(2)}
				</div>
			</div>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					<strong>Merchant owed:</strong><br/>
					subtotal + restaurant tip - merchant originated ORDER discounts - Devour marketplace fee - refund
					merchant responsibility amount
				</div>
				<div className="menu-order-pricing_row_value">
					<span title="original merchant owed amount">
						${props.menuOrder.merchantOwed?.toFixed(2)}
					</span>
					{(props.menuOrder.refunds[0]?.responsibility === OrderRefundResponsibility.MERCHANT) && (
						<React.Fragment>
							<br/>
							{" - "}
							<span title="refund merchant responsibility amount">
								${props.menuOrder.refunds[0]?.responsiblePartyAmount.toFixed(2)}
							</span>
							<br/>
							{" = "}
							<span title="final merchant owed amount">
								${(props.menuOrder.merchantOwed - props.menuOrder.refunds[0]?.responsiblePartyAmount).toFixed(2)}
							</span>
						</React.Fragment>
					)}
				</div>
			</div>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					<strong>Stripe withholding:</strong><br/>
					Stripe total - merchant owed. Minimum $0.
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.marketplaceWithholding?.toFixed(2)}
				</div>
			</div>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					<strong>Stripe Transfer amount (Merchant still owed after customer payment):</strong><br/>
					Merchant owed - (Stripe total if not paid to Devour)<br/>
					- paid out amount
				</div>
				<div className="menu-order-pricing_row_value">
					<span title="Merchant still owed after customer payment">
						${props.menuOrder.transferAmount?.toFixed(2)}
					</span>
					{(props.menuOrder.paymentTransferId) && (
						<React.Fragment>
							<br/>
							{" - "}
							<span title="Devour paid out to merchant">
								${props.menuOrder.transferAmount.toFixed(2)}
							</span>
							<br/>
							{" = "}
							<span title="current merchant owed amount">
								$0.00
							</span>
						</React.Fragment>
					)}
				</div>
			</div>

			{renderTransferRow()}

		</div>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.metaStore.fullToken,
			...props,
		}
	});
}

type MenuOrderPricingProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(MenuOrderPricing);
