import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {GetTransactionsResponse, Token, Transaction, TransactionsApi, User,} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import SectionLabel from "../SectionLabel";
import FrameOneCard from "../FrameOneCard";

interface ViewDpayModalProps {
    isOpen: boolean;
    onClose: () => void;
    user: User;
}

function ViewVdpayModal(props: ViewDpayModalProps): ReactElement {

    const [dpayData, setDpayData] = useState<GetTransactionsResponse>();
    const fullToken = useSelector<IStore, Token | undefined>(state => state.metaStore.fullToken);
    const dispatch = useDispatch();

    useEffect(() => {
        void fetchTransactions();
    }, [props.isOpen]);

    async function fetchTransactions() {
        if (props.isOpen) {
            dispatch(incrementLoading());

            try {
                const response = await new TransactionsApi(getConfig(fullToken)).getTransactions({id: props.user.id});
                setDpayData(response);
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }
    }

    function closeHelper(): void {
        props.onClose();
        setDpayData(undefined);
    }

    function renderDpayTransaction(transaction: Transaction): ReactNode {
        const date = new Date(transaction.createdAt);

        return (
            <FrameOneCard
                className="user-experience-modal_transaction-entry"
            >
                <div className="user-experience-modal_transaction-entry_top">
                    <h6>{date.toLocaleString()}</h6>
                    <p>{transaction.subject}</p>
                </div>
                <div className="user-experience-modal_transaction-entry_bottom">
                    <h6>{transaction.amount} VDPAY</h6>

                    <h6>{(transaction.amount * dpayData.dPayPricePerUsd).toFixed(2)} USD</h6>

                    {transaction.notes && (
                        <aside>
                            <label>note:</label>
                            <p>{transaction.notes}</p>
                        </aside>
                    )}

                    {transaction.adminNotes && (
                        <aside>
                            <label>admin note:</label>
                            <p>{transaction.adminNotes}</p>
                        </aside>
                    )}
                </div>
            </FrameOneCard>
        );
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={closeHelper}
            size="sm"
            contentClassName="user-experience-modal"
        >
            <FrameModalHeader
                toggle={closeHelper}
                title="View VDPAY"
            />
            <FrameModalBody className="user-experience-modal_body">
                {dpayData == null ? (
                    <div className="spinner"/>
                ) : (
                    <>
                        <SectionLabel
                            title="VDPAY Account Balance"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>{dpayData.balance} VDPAY</p>
                        </SectionLabel>

                        <SectionLabel
                            title="VDPAY Account Balance in USD"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>${dpayData.balanceInUsd} USD</p>
                        </SectionLabel>

                        <SectionLabel
                            title="VDPAY Price Per USD"
                            titleContainerClassName="manage-users-password-modal_section-title-container"
                        >
                            <p>${dpayData.dPayPricePerUsd} USD</p>
                        </SectionLabel>

                        {dpayData.transactions.length > 0 ? dpayData.transactions.map(transaction => renderDpayTransaction(transaction)) : (
                            <p>There are no VDPAY transactions for this user.</p>
                        )}
                    </>
                )}
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default ViewVdpayModal;
