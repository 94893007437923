import {Asset, User, UsersApi, CreateAssetRequest, AssetsApi} from "@devour/client";
import React, {ChangeEvent, ChangeEventHandler, FormEvent, ReactElement, useEffect, useState} from "react";
import FrameButton from "../buttons/FrameButton";
import getConfig from "../../utils/getConfig";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import moment from "moment";
import FileInputButton from "../inputs/FileInputButton";

interface FormValues {
	name: string;
	description: string;
}

interface Props {
	asset: Asset;
	onUpdate: () => void;
}

function formatBytes(bytes: number, decimals: number = 2): string {
	if (bytes === 0) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}


function FileLibrarySelectedItem(props: Props): ReactElement {
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [uploader, setUploader] = useState<User>();
	const [formValues, setFormValues] = useState<CreateAssetRequest>({
		asset: undefined,
		name: props.asset?.name || "",
		description: props.asset?.description || "",
	});

	useEffect(() => {
		// Upload on form asset change
		if (formValues.asset) {
			void onUpdateAsset();
		}
	}, [formValues.asset]);


	useEffect(() => {
		void fetchUploader();
	}, [props.asset?.owner]);

	async function fetchUploader(): Promise<void> {
		if (!props.asset?.owner) {
			return;
		}
		try {
			const res = await new UsersApi(getConfig(fullToken)).getUser({
				id: props.asset?.owner,
			});

			setUploader(res.user);
		} catch (e) {
			// Media library modal is too high on the z-index so the other error modal won't show.
			// Good thing this API should never fail...
		}
	}

	async function onUpdateAsset(): Promise<void> {
		try {
			await new AssetsApi(getConfig(fullToken)).updateAsset({
				...formValues,
				id: props.asset.id,
			});
		} catch (e) {
			// Media library modal is too high on the z-index so the other error modal won't show.
			// Good thing this API should never fail...
		} finally {
			props.onUpdate?.();
		}
	}

	async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
		e.preventDefault();
		void onUpdateAsset();
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof FormValues): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
		return (e) => {
			setFormValues({
				...formValues,
				[key]: e.target.value,
			});
		}
	}

	function onFileChange(e: ChangeEvent<HTMLInputElement>): void {
		setFormValues({
			...formValues,
			asset: e.target.files[0],
		});
	}

	if (!props.asset) {
		return null;
	}

	return (
		<div className="react-media-library_file-library-selected-item">

			<form onSubmit={onFormSubmit}>
				<a
					href={props.asset.url}
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						src={props.asset.url}
						alt={props.asset.name}
						className="react-media-library_file-library-selected-item_image"
					/>
				</a>

				<FileInputButton
					accept="image/png, image/jpeg"
					onChange={onFileChange}
				>
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						forwardProps={{type: "button"}}
						color="purple"
						narrow={true}
						className="react-media-library_file-library-selected-item_image-replace"
					>
						Replace Image
					</FrameButton>
				</FileInputButton>
				<div className="react-media-library_file-library-selected-item_field">
					<label>
						Name
					</label>
					<input
						type="text"
						value={formValues.name}
						onChange={inputOnChange("name")}
					/>
				</div>
				<div className="react-media-library_file-library-selected-item_field">
					<label>
						Description
					</label>
					<input
						type="text"
						value={formValues.description}
						onChange={inputOnChange("description")}
					/>
				</div>

				<div className="react-media-library_file-library-selected-item_actions">
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						forwardProps={{
							type: "submit",
						}}
						color="purple"
						narrow={true}
					>
						Save
					</FrameButton>
				</div>
			</form>

			<div className="react-media-library_file-library-selected-item_info">
				<p>
					URL:
					<a
						href={props.asset.url}
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.asset.url}
					</a>
				</p>
				<p>
					Owner: {uploader?.email}
				</p>
				<p>
					Size: {formatBytes(props.asset.size)}
				</p>
				<p>
					Created: {moment(props.asset.createdAt).format("MMM DD YYYY hh:mma")}
				</p>
				<p>
					Updated: {moment(props.asset.updatedAt).format("MMM DD YYYY hh:mma")}
				</p>
			</div>
		</div>
	);
}

export default FileLibrarySelectedItem;
