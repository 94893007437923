import React, {ChangeEventHandler, ReactElement, FormEvent, useState} from "react";
import {UsersApi} from "@devour/client";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameButton from "../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import generateCrytoSecureString from "../../utils/generateCryptoSecureString";

interface FormValues {
	firstName: string;
	lastName: string;
}

const defaultValues: FormValues = {
	firstName: "",
	lastName: "",
};

interface StateProps {
}

interface Props {
	display: boolean;
	email: string;
	onClose: () => void;
	onUpdate: () => void;
}

function NewMerchantUserModal(props: NewMerchantUserModalProps): ReactElement {
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const [formValues, setFormValues] = useState<FormValues>(defaultValues);

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof FormValues): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
		return (e) => {
			setFormValues({
				...formValues,
				[key]: e.target.value,
			});
		}
	}

	async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const generatedPassword = generateCrytoSecureString(["symbols", "numbers", "lowercase", "uppercase"], 16);
			await new UsersApi(getConfig(fullToken)).registerMerchant({
				createUserBody: {
					password: generatedPassword,
					confirmPassword: generatedPassword,
					email: props.email,
					firstName: formValues.firstName,
					lastName: formValues.lastName,
				},
			});

			setFormValues(defaultValues);
			props.onUpdate();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.display}
			toggle={props.onClose}
			size="sm"
			contentClassName="new-merchant-user-modal"
		>
			<FrameModalHeader
				title="New Merchant User"
				toggle={props.onClose}
			/>

			<FrameModalBody className="new-merchant-user-modal_body">

				<form onSubmit={onFormSubmit}>

					<p>
						<strong>Email:</strong>{" "}
						{props.email}
					</p>

					<div className="new-merchant-user-modal_field new-merchant-user-modal_first-name-container">
						<label>First Name *</label>
						<input
							type="text"
							placeholder="First Name"
							value={formValues.firstName}
							onChange={inputOnChange("firstName")}
							required={true}
						/>
					</div>

					<div className="new-merchant-user-modal_field new-merchant-user-modal_last-name-container">
						<label>Last Name *</label>
						<input
							type="text"
							placeholder="Last Name"
							value={formValues.lastName}
							onChange={inputOnChange("lastName")}
							required={true}
						/>
					</div>

					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="purple"
						// size="large"
						className="new-merchant-user-modal_submit-button"
						forwardProps={{type: "submit"}}
					>
						Create New Merchant Account
					</FrameButton>
				</form>
			</FrameModalBody>

		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			...props,
		}
	});
}

type NewMerchantUserModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(NewMerchantUserModal);
